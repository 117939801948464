import { ReactNode } from "react";

type ContainerTypeProps = {
  children: ReactNode;
};

function Container({ children }: ContainerTypeProps): ReactNode {
  return <div className="container container_v2">{children}</div>;
}

export default Container;
