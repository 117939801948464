import { useEffect, useMemo, useState } from "react";
import KSButton from "../KSButton";
import KSLocationModal from "../KSLocationModal";
import { useUserLocationsQuery } from "../../../hooks/useUserLocationsQuery";
import { useNavigate, useParams } from "react-router-dom";
import { IconClose, IconMore } from "../../../components/Icons";
import KSCheckBox from "../KSCheckBox";

import "./KSCompareLocationsModal.scss";
import classNames from "classnames";
import { useQueryState } from "hooks/useQueryState";
import { FIVE_SECONDS, generateNotAllowedUrlAggregateSideBySide, showToast } from "lib/utils";
import { useLocationsStore } from "hooks/useLocationsStore";
import { shallow } from "zustand/shallow";
import { useReportsStore } from "hooks/useReportsStore";
import { stringify, parse as qsparse } from "querystring";
import { QueryParamsPayload } from "domain/models";
import KSMultipleLocationModal from "../KSMultipleLocationModal";
import { KSMLocation } from "../KSMultipleLocationModal/KSMultipleLocationModal";

interface KSCompareLocationsModalProps {
  currentLocationUser: HoneLocationUser;
  allLocations: HoneLocationUser[];
  handleApplyChanges: () => void;
}

export function KSCompareLocationsModal({
  currentLocationUser,
  allLocations,
  handleApplyChanges
}: KSCompareLocationsModalProps) {
  const navigateTo = useNavigate();
  const { locationId } = useParams();
  const { currentLocation } = useLocationsStore(
    (state) => ({ currentLocationId: state.currentLocationId, currentLocation: state.currentLocation }),
    shallow
  );
  const [active, setActive] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [compareLocations, setCompareLocations] = useQueryState<string>("compareLocations");
  const [consolidated, setConsolidated] = useQueryState<string>("consolidated");
  const [validate, setValidate] = useQueryState<string>("validate");

  useEffect(() => {
      setModal(!compareLocations);
      setActive(true);
  }, [compareLocations]);

  const [localCompareLocations, setLocalCompareLocations] = useState<string | undefined>(compareLocations);
  const isAggrSideBySide =
    localCompareLocations && (!consolidated || consolidated === "false")
      ? localCompareLocations?.split(",").length > 1
      : false;
  const localCompareLocationsIds = localCompareLocations ? String(localCompareLocations).split(",") : [];
  const queryParams = qsparse(location.search.replace("?", ""));


  
  useEffect(() => {
    if (localCompareLocationsIds.length > 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [localCompareLocationsIds]);


  const selected = useMemo(() => {
    if (localCompareLocationsIds.length > 0) {
      const selectedLocations = localCompareLocationsIds.map((id) => allLocations.find((loc) => loc.locationId === id ));
      return selectedLocations as HoneLocationUser[];
    }
  }, [localCompareLocations, allLocations]);
  

  const handleSelectionChanges = (locations: HoneLocationUser[]) => {
    const allLocationsIds = locations.map((loc) => loc.locationId);
    setLocalCompareLocations(allLocationsIds.join(","));
  };


  const handleLocalApplyChanges = () => {
    if (localCompareLocationsIds.length === 1) {
      handleSetActive()
      return
    }  
    const newQueriesAggregateSideBySide: QueryParamsPayload | null = generateNotAllowedUrlAggregateSideBySide(
      localCompareLocations!,
      queryParams,
      consolidated
    );
    if (newQueriesAggregateSideBySide) {
      showToast(
        "Some settings have been turned off to enable side-by-side comparisons. Please re-configure as needed.",
        "warning",
        FIVE_SECONDS
      );
      if (currentLocation?.id && newQueriesAggregateSideBySide) {
        navigateTo({
          pathname: `/app/location/${locationId}/report/weekly`,
          search: `?${stringify(newQueriesAggregateSideBySide as any)}`
        });
        useReportsStore.setState({ selectedReport: undefined });
      }
      return;
    }

    setModal(false);
    if (selected) {
      const newCompareLocations = selected.map((loc) => loc.locationId).join(",");
      setCompareLocations(newCompareLocations);
      handleApplyChanges();
    }
  };
  const selectedCount = selected ? selected.length : 0;

  const handleSetActive = () => {
    setLocalCompareLocations(undefined);
    setCompareLocations(undefined);
    if (validate || validate === "true") {
      setValidate(undefined);
    }
  };

  const handleEnableCompare = () => {
    localCompareLocationsIds.length === 0 && setLocalCompareLocations(currentLocationUser.locationId);
  };

  const handleConsolidatedChange = (consolidated: boolean) => {
    const newConsolidated = consolidated ? "true" : "false";

    const newQueriesAggregateSideBySide: QueryParamsPayload | null = generateNotAllowedUrlAggregateSideBySide(
      localCompareLocations!,
      queryParams,
      newConsolidated
    );
    if (newQueriesAggregateSideBySide) {
      showToast(
        "Some settings have been turned off to enable side-by-side comparisons. Please re-configure as needed.",
        "warning",
        FIVE_SECONDS
      );
      if (currentLocation?.id && newQueriesAggregateSideBySide) {
        navigateTo({
          pathname: `/app/location/${locationId}/report/weekly`,
          search: `?${stringify(newQueriesAggregateSideBySide as any)}`
        });
        useReportsStore.setState({ selectedReport: undefined });
      }
      return;
    }

    handleApplyChanges();
    setConsolidated(newConsolidated);
  };

  return (
    <div className={classNames("ks-compare-locations", { active })}>
      {active ? (
        <>
          <div className="locations-group">
            <KSButton
              onClick={handleSetActive}
              label={
                <>
                  <span>Compare</span>
                  <IconClose />
                </>
              }
              color="active"
            />

            {selected &&
              selected
                .slice(0, 2)
                .map((location) => (
                  <KSButton label={location.locationName} tag="title" onClick={() => setModal(prevModal => !prevModal)} />
                ))}
            <KSButton
              color={selectedCount > 2 ? "active" : "primary"}
              label={
                selectedCount > 2 ? (
                  <>
                    <label className="more-label">+{selectedCount - 2}</label>  <IconMore />
                  </>
                ) : <IconMore />
              }
              onClick={() => setModal(prevModal => !prevModal)}
              disabled={false}
            />
            <KSMultipleLocationModal 
              key={modal ? "open" : "closed"}
              open={modal}
              selectedLocations={selected as KSMLocation[]}
              allLocations={allLocations as KSMLocation[]}
              activeLocation={currentLocationUser as KSMLocation}
              onApplyChanges={handleLocalApplyChanges}
              onLocationsSelected={handleSelectionChanges}
              onClose={() => setModal((prevModal => !prevModal))}
            />
          </div>
          {selectedCount > 1 && (
            <KSCheckBox
              rounded
              label="Consolidated"
              checked={consolidated === "true"}
              onChange={handleConsolidatedChange}
            />
          )}
        </>
      ) : (
        <KSButton label="Compare Locations" color="secondary"
              fullHeight={true}  onClick={handleEnableCompare} />
      )}
    </div>
  );
}

function withLocations(WrappedComponent: any) {
  return function (props: KSCompareLocationsModalProps) {
    const { data: userLocations } = useUserLocationsQuery();
    const { locationId } = useParams();

    const currentLocationUser = (userLocations as HoneLocationUser[])?.find((loc) => loc.locationId === locationId);

    return <WrappedComponent currentLocationUser={currentLocationUser} allLocations={userLocations} />;
  };
}

export default withLocations(KSCompareLocationsModal);
