import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { useForecastReport } from "./useForecastReport";
import { cloneDeep, get, isString, update } from "lodash";
import {
  calculateAmountsFromReport,
  generateUIReport,
  getRevenueAmountByTag,
  getSectionByName,
  roundReport,
  updateUIReport,
} from "../main/factories/pages/forecast-report/utils";

export const useForecastUIStore = create(
  subscribeWithSelector<ForecastUIState>((set) => {
    return {
      activeType: "Revenue",
      activePeriod: 0,
      timeframe: "weeks",
      activeDate: null,
      report: null,
      roundedReport: null,
      activeCategory: null,
      pristine: true,
    };
  })
);

/**
 * Calculate the forecast for a specified period of time and store the result in the state stores.
 * @param {number} activePeriod - The active period for which the forecast needs to be calculated.
 */
function calculateForecastForPeriod(activePeriod: number) {
  // Retrieve the timeframe and activeDate from the useForecastUIStore state store.
  const { timeframe, activeDate } = useForecastUIStore.getState();
  // Retrieve the remoteForecastReport from the useForecastReport state store.
  const { remoteForecastReport } = useForecastReport.getState();

  // If either the remoteForecastReport or activeDate is not defined, return without doing anything else.
  if (!remoteForecastReport || !activeDate) return;

  // Retrieve the activeRemoteForecast from the remoteForecastReport.
  const activeRemoteForecast = remoteForecastReport[timeframe][activeDate].forecast;

  // If the activeRemoteForecast is not defined, return without doing anything else.
  if (!activeRemoteForecast) return;

  // Calculate the amounts from the activeRemoteForecast.
  const calculatedRemoteReport = calculateAmountsFromReport(activeRemoteForecast, activeDate, timeframe);

  // Make a deep copy of the remoteForecastReport.
  const remoteReportCopy = cloneDeep(remoteForecastReport);
  // Update the copy with the calculated amounts.
  remoteReportCopy[timeframe][activeDate].forecast = calculatedRemoteReport;

  // Generate the UI report from the remoteReportCopy.
  const generated = generateUIReport(remoteReportCopy, timeframe, activePeriod, activeDate);

  // Update the useForecastUIStore state store with the report and roundedReport.
  useForecastUIStore.setState({
    report: generated.report,
    roundedReport: roundReport(generated.report),
  });

  // Update the useForecastReport state store with the activeRemoteForecast and activeRemoteForecastPeriod.
  useForecastReport.setState({
    activeRemoteForecast,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    activeRemoteForecastPeriod: activeRemoteForecast.forecastData[timeframe][activePeriod],
  });
}

useForecastUIStore.subscribe((state) => state.activePeriod, calculateForecastForPeriod);

/**
 * Handles the change in net sales amount
 * @param value The new value of the net sales amount, either a number or a string
 */
export function handleNetSalesAmountChange(value: number | string): void {
  // Get the current report from the useForecastUIStore
  const report = useForecastUIStore.getState().report;

  // If no report is found, return immediately
  if (!report) return;

  if (isString(value) && value === "") {
    // If value is an empty string, update the net sales goal amount and goal percentage in the report
    const nextReport = {
      ...report,
      "net-sales": {
        ...report["net-sales"],
        goalAmount: "",
        goalPerc: 0,
      },
    };

    // Update the state of the useForecastUIStore with the updated report, set pristine to false, and round the report
    useForecastUIStore.setState({ report: nextReport, pristine: false, roundedReport: roundReport(nextReport) });
  } else {
    // Clone the current report for updates
    const newUIReport = cloneDeep(report);
    // Get the active remote forecast period from the useForecastReport
    const activeRemoteForecastPeriod = useForecastReport.getState().activeRemoteForecastPeriod;

    if (activeRemoteForecastPeriod) {
      // Extract the referenced value from the active remote forecast period
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const referencedValue = activeRemoteForecastPeriod.sections[0].amount;
      // Convert the value to a number
      const formValue = parseInt(value.toString().replace(/[^0-9.-]+/g, ""));

      // Calculate the new net sales percentage
      const newNetSalesPercentage = (formValue / referencedValue - 1) * 100;

      // Update the net sales goal amount and goal percentage in the report
      update(newUIReport, "net-sales.goalAmount", () => formValue);
      update(newUIReport, "net-sales.goalPerc", () => newNetSalesPercentage);

      // Update the report using the updateUIReport function twice
      const updatedUIReport = updateUIReport(newUIReport, "net-sales");
      const finalUIReport = updateUIReport(updatedUIReport, "net-sales");

      // Update the state of the useForecastUIStore with the final report, set pristine to false, and round the report
      useForecastUIStore.setState({
        report: finalUIReport,
        pristine: false,
        roundedReport: roundReport(finalUIReport),
      });
    }
  }
}

export function handleNetSalesPercentageChange(value: string | number): void {
  const report = useForecastUIStore.getState().report;
  const roundedReport = useForecastUIStore.getState().roundedReport;
  if (!report || !roundedReport) return;

  if (isString(value) && value === "") {
    const nextReport = {
      ...report,
      "net-sales": {
        ...report["net-sales"],
        goalPerc: "",
      },
    };
    const nextRoundedReport = {
      ...roundedReport,
      "net-sales": {
        ...roundedReport["net-sales"],
        goalPerc: "",
      },
    };
    useForecastUIStore.setState({
      report: nextReport,
      roundedReport: nextRoundedReport,
    });
  } else {
    const formValue = parseFloat(value.toString());
    const activeRemoteForecastPeriod = useForecastReport.getState().activeRemoteForecastPeriod;

    const newUIReport = cloneDeep(report);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const referencedValue = activeRemoteForecastPeriod.sections[0].amount;

    const newNetSalesAmount = referencedValue + (referencedValue * formValue) / 100;
    update(newUIReport, "net-sales.goalAmount", () => newNetSalesAmount);
    update(newUIReport, "net-sales.goalPerc", () => value);

    // calculates ui report
    const updatedUIReport = updateUIReport(newUIReport, "net-sales");

    useForecastUIStore.setState({
      report: updatedUIReport,
      roundedReport: roundReport(updatedUIReport),
    });
  }
}

export function handleRevenueAmountChange(value: string | number, name: string): void {
  const report = useForecastUIStore.getState().report;

  if (!report) return;
  const newUIReport = cloneDeep(report);
  if (isString(value) && value === "") {
    update(newUIReport, name, () => "$");
    update(newUIReport, name.replace("goalAmount", "goalPerc"), () => "");

    useForecastUIStore.setState({
      pristine: false,
      report: newUIReport,
      roundedReport: roundReport(newUIReport),
    });
  } else {
    const formValue = Number(value.toString().replace(/[^0-9.-]+/g, ""));

    // update the form value in the new ui report
    update(newUIReport, name, () => formValue);
    // calculate the new sum of revenues and update net sales
    const newNetSales = Object.values(newUIReport.revenue)
      .filter((section) => {
        return section.title !== "Net Sales";
      })
      .reduce((prev, section) => {
        return prev + parseFloat(section.goalAmount.toString());
      }, 0);

    const referenceNetSales = Number(report["net-sales"].amount);

    update(newUIReport, "net-sales.goalPerc", () => {
      return (newNetSales / referenceNetSales - 1) * 100;
    });

    update(newUIReport, "net-sales.goalAmount", () => {
      return newNetSales;
    });

    // recalculate whole ui report
    const updatedUIReport = updateUIReport(newUIReport, "revenue");
    const finalUIReport = updateUIReport(updatedUIReport, "revenue");

    useForecastUIStore.setState({
      pristine: false,
      report: finalUIReport,
      roundedReport: roundReport(finalUIReport),
    });
  }
}

export function handleExpenseAmountChange(value: string | number, name: string): void {
  const report = useForecastUIStore.getState().report;

  if (!report) return;
  const newUIReport = cloneDeep(report);
  const percentageLocation = name.replace("goalAmount", "goalPerc");

  if (isString(value) && value === "") {
    update(newUIReport, name, () => "");
    update(newUIReport, percentageLocation, () => 0);
    useForecastUIStore.setState({
      pristine: false,
      report: newUIReport,
      roundedReport: roundReport(newUIReport),
    });
  } else {
    const nextAmount = Number(value.toString().replace(/[^0-9.-]+/g, ""));
    update(newUIReport, name, () => nextAmount);
    const section = getSectionByName(report, name, "goalAmount");

    let referencedAmount;
    if (section.goalPercTag === "Net Sales") {
      referencedAmount = report["net-sales"].goalAmount;
    } else {
      referencedAmount = getRevenueAmountByTag(Object.values(report.revenue), section.foreignKey, "goalAmount");
    }

    const nextPerc = (nextAmount / referencedAmount) * 100;
    update(newUIReport, percentageLocation, () => nextPerc);

    useForecastUIStore.setState({
      pristine: false,
      report: newUIReport,
      roundedReport: roundReport(newUIReport),
    });
  }
}

export function handleExpensePercentageChange(value: string | number, name: string): void {
  const amountLocation = name.replace("goalPerc", "goalAmount");

  const report = useForecastUIStore.getState().report;

  if (!report) return;

  let newUIReport = cloneDeep(report);
  if (isString(value) && (value === "" || value === "." || value === "0.")) {
    newUIReport = update(newUIReport, name, () => value);

    useForecastUIStore.setState({
      pristine: false,
      report: newUIReport,
      roundedReport: roundReport(newUIReport),
    });
  } else {
    const formAmount = parseFloat(value.toString());
    const section = get(report, name.replace(".goalPerc", ""));

    let referencedAmount;
    if (section.goalPercTag === "Net Sales") {
      referencedAmount = Number(report["net-sales"].goalAmount.toString().replace(/[^0-9.-]+/g, ""));
    } else {
      referencedAmount = getRevenueAmountByTag(Object.values(report.revenue), section.foreignKey, "goalAmount");
    }

    update(newUIReport, name, () => value);
    const nextAmount = formAmount === 0 ? 0 : (formAmount / 100) * referencedAmount;
    update(newUIReport, amountLocation, () => nextAmount);

    useForecastUIStore.setState({
      pristine: false,
      report: newUIReport,
      roundedReport: roundReport(newUIReport),
    });
  }
}
