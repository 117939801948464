import { createRef, memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import ReactECharts from "echarts-for-react";

import "./ForecastHistorialGraph.styles.scss";

import buildChartConfig from "./config";
import Loading from "components/Loading";
import EChartsReact from "echarts-for-react";
import { useSidenavStore } from "hooks/useSidenavStore";
import { DATE_EXP, FIVE_SECONDS, showToast, TOAST_ERROR } from "lib/utils";
import { useForecastUIStore } from "hooks/useForecastUIStore";
import { useForecastReport } from "hooks/useForecastReport";
import { shallow } from "zustand/shallow";
import { CategoriesSelector } from "presentation/components";
import { Category } from "domain/models";
import ForecastActions from "../ForecastActions";
import { isEmpty } from "lodash";

const categoriesColor = ["blue", "teal", "turquoise", "purple", "orange", "darkerblue", "red"];

function ForecastHistoricalGraph() {
  const { categories, report } = useForecastReport((state) => ({
    categories: state.categories,
    report: state.remoteForecastReport,
  }));

  const {
    // report: uiReport,
    timeframe,
    activeDate,
    activePeriod,
    activeCategory,
  } = useForecastUIStore(
    (state) => ({
      // report: state.report,
      timeframe: state.timeframe,
      activeDate: state.activeDate,
      activePeriod: state.activePeriod,
      activeCategory: state.activeCategory,
    }),
    shallow
  );

  const isSidenavCollapsed = useSidenavStore((state) => state.isSidenavCollapsed);

  const onActiveCategoryChange = (activeCategory: string) => {
    useForecastUIStore.setState({ activeCategory });
  };

  const onActivePeriodChange = (nextPeriod: 0 | 1) => {
    useForecastUIStore.setState({ activePeriod: nextPeriod });
  };

  const [caretPosition, setCaretPosition] = useState<number>(0);
  const [titlesPosition, setTitlesPosition] = useState<number>(0);
  const [widthRefence, setWidthRefence] = useState<number>(50);

  const chartRef = createRef<EChartsReact>();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleEvents = (evt: Record<string, any>) => {
    if (evt.seriesType === "bar") {
      const index = evt.dataIndex;
      // when 1st forecast column clicked
      if (index === 6) {
        onActivePeriodChange(0);
      } else if (index === 7) {
        // when last column is clicked
        onActivePeriodChange(1);
      }
    }
  };

  const echartsOptions = useMemo(() => {
    if (!report || !activeCategory || !activeDate) return null;
    return buildChartConfig(report, timeframe, activeCategory, ["YearAgo"], activeDate, activePeriod, categories);
  }, [report, activeCategory, activeDate, timeframe, activePeriod, categories]);

  const chartDatesLength = echartsOptions?.chartDates.length || 8;

  const cardDate1 = echartsOptions?.chartDates[chartDatesLength - 2];
  const cardDate2 = echartsOptions?.chartDates[chartDatesLength - 1];

  useLayoutEffect(() => {
    function calculateGraphPositions() {
      // we get the chart svg
      const chartDom = chartRef.current?.getEchartsInstance().getDom();
      // calculate position of the chart
      const containerDom = containerRef.current?.getBoundingClientRect();
      if (chartDom && containerDom && activeDate) {
        const textTagsMap = new Map();
        const texts = Array.from(chartDom.getElementsByTagName("text"));
        // store in the map elements that matches a date format
        texts.forEach((textElement: SVGElement) => {
          if (textElement.textContent?.match(DATE_EXP)) {
            textTagsMap.set(textElement.textContent, textElement);
          }
        });

        // active period is used to check the caret position
        const dateLookup = activePeriod === 0 ? cardDate1 : cardDate2;

        const element = textTagsMap.get(dateLookup);
        if (element) {
          const extra = window.innerWidth > 1200 ? 52 : 0;
          setWidthRefence(element.getBoundingClientRect().width);
          setCaretPosition(element.getBoundingClientRect().left - containerDom.left - extra);
          // we always position the cards based on the first date (left card)
          const firstCard = textTagsMap.get(cardDate1);
          const sidenav = document.getElementById("SidenavMenu")?.getBoundingClientRect().width || 0;
          // 35 is the layout margin on both sides plus a correction factor of 5px
          const offsetSideNav = isSidenavCollapsed ? 105 : 95;

          setTitlesPosition(firstCard.getBoundingClientRect().x - sidenav - offsetSideNav);
        }
      }
    }

    window.addEventListener("resize", calculateGraphPositions);
    calculateGraphPositions();

    return () => {
      window.removeEventListener("resize", calculateGraphPositions);
    };
  }, [chartRef, containerRef, activeDate, activePeriod, timeframe, cardDate1, cardDate2, isSidenavCollapsed]);

  if (!categories || !activeCategory || !activeDate) {
    return <Loading />;
  }

  const categoryColor = categoriesColor[categories.indexOf(activeCategory)];

  /*
   * Used on the cards under Goals section
   **/
  const extraLabel = timeframe === "weeks" ? "Week" : "Month";

  const onCategoryChange = (category: Category) => {
    try {
      onActiveCategoryChange(category.name);
    } catch (error) {
      showToast("Error trying to get forecast report", TOAST_ERROR, FIVE_SECONDS);
    }
  };

  return (
    <div className="forecast-historical-graph" ref={containerRef}>
      <style>{`.echarts-for-react:after { left: ${caretPosition}px }`}</style>
      <div className="graph">
        <div className="extra">
          <div>Actuals</div>
          <div>Goals</div>
        </div>
        <div className="plot-selector">
          <span>Plot</span>
          <ForecastActions />
          <CategoriesSelector onChange={onCategoryChange} activeCategory={activeCategory} categories={categories} />
        </div>

        {echartsOptions && activeCategory && (
          <div className={"chart-container"}>
            <ReactECharts
              opts={{ renderer: "svg" }}
              ref={chartRef}
              option={echartsOptions?.chartOptions}
              onEvents={{ click: handleEvents }}
            />
            <div className="period_title">{`${extraLabel} starting`}</div>
            <div className={"forecast-weeks"}>
              <button
                onClick={() => onActivePeriodChange(0)}
                className={classNames({
                  active: 0 === activePeriod,
                  [categoryColor]: true,
                })}
              >
                <span>{extraLabel} 1</span>
                {cardDate1}
              </button>
              <button
                onClick={() => onActivePeriodChange(1)}
                style={{ width: `${widthRefence + 30}` }}
                className={classNames({
                  active: 1 === activePeriod,
                  [categoryColor]: true,
                })}
              >
                <span>{extraLabel} 2</span>
                {cardDate2}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(ForecastHistoricalGraph);
