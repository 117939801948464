import * as TemplateUtils from "../../../../lib/templateUtils";
import { useState, useEffect } from "react";
import classNames from "classnames";
import { find, isEmpty, omit, cloneDeep, findIndex, isNil, tail, noop } from "lodash";
import { useHoneChartOfAccounts, useHoneReportTemplates } from "components/HoneReportTemplates";
import BookkeeperTemplateCalculationsForm from "./BookkeeperTemplateCalculationsForm";
import { IconAdd, IconDown, IconRemove, IconUp } from "../../../../components/Icons";
import BookkeeperTemplateSectionForm from "./BookkeeperTemplateSectionForm";
import { useLocationsStore } from "hooks/useLocationsStore";
import type { SyntheticEvent } from "react";
import { useQueryState } from "hooks/useQueryState";

interface Props {
  expandAll: boolean;
  initialCustomCalculations: CustomCalculation[];
  templateSectionGroups: FlatTemplateSection[][];
  currentLocation: HoneLocation;
  currentTemplate: FlatTemplateSection[];
  onTemplateChange: (newTemplate: FlatTemplateSection[]) => void;
}

function BookkeeperTemplateSectionsForm({
  expandAll,
  currentLocation,
  currentTemplate,
  onTemplateChange,
  templateSectionGroups,
  initialCustomCalculations
}: Props): JSX.Element {
  const currentLocationId = useLocationsStore((state) => state.currentLocationId);
  const { data: chartOfAccounts } = useHoneChartOfAccounts(currentLocation.id);

  const initialCollapsedSections = templateSectionGroups.map((section) => ({
    id: section[0].title,
    collapsed: expandAll ? false : true
  }));

  const [collpasedSections, setcollpasedSections] = useState<TemplateSectionCollapsed[]>(initialCollapsedSections);
  const [customCalculations, setCustomCalculations] = useState<CustomCalculation[]>(initialCustomCalculations);

  useEffect(() => {
    setCustomCalculations(initialCustomCalculations);
  }, [initialCustomCalculations]);

  useEffect(() => {
    const updatedAllSections = [...initialCollapsedSections].map((section) => ({
      id: section.id,
      collapsed: !expandAll
    }));
    setcollpasedSections(updatedAllSections);
  }, [expandAll]);

  const chartSections = currentTemplate.filter((section) => TemplateUtils.hasAnyChart(section));

  const additionalCalculatables: HoneAccount[] = currentTemplate
    .filter((section) => section.total)
    .map((section) => {
      return {
        id: section.total?.id || section.id,
        Name: section.total?.title || section.title
      };
    })
    .concat(customCalculations.map((calc) => ({ id: calc.id, Name: calc.title })));

  let calculatableAccounts = (chartOfAccounts || []).concat(additionalCalculatables);

  const handleAddRootSection = (e: SyntheticEvent, section: FlatTemplateSection) => {
    e.preventDefault();

    const index = findIndex(currentTemplate, { id: section.id });

    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.addRootSection(currentTemplate, index));
  };

  const handleAddSameLevel = (e: SyntheticEvent, section: FlatTemplateSection) => {
    e.preventDefault();

    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.addSection(section, index, currentTemplate, currentTemplate[index]));
  };

  const handleAddNestedLevel = (e: SyntheticEvent, section: FlatTemplateSection) => {
    e.preventDefault();

    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.addNestedSection(section, index, currentTemplate));
  };

  const handleRemoveSection = (e: SyntheticEvent, section: FlatTemplateSection) => {
    e.preventDefault();

    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.removeSection(section, index, currentTemplate));
  };

  const handleSectionLevelChange = (operation: "increment" | "decrement", section: FlatTemplateSection) => {
    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.changeSectionLevel(section, index, operation, currentTemplate));
  };

  const handleSectionTotalToggle = (e: SyntheticEvent, section: FlatTemplateSection) => {
    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.toggleTotalSection(section, index, currentTemplate));
  };

  const handleSectionTitleChange = (
    e: SyntheticEvent<HTMLInputElement>,
    isTotal: boolean,
    section: FlatTemplateSection
  ) => {
    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.changeSectionTitle(section, index, e.currentTarget.value, currentTemplate, isTotal));
  };

  const handleRemoveRootSection = (e: SyntheticEvent, section: FlatTemplateSection) => {
    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.removeRootSection(section, index, currentTemplate));
  };

  const handleSectionChartChange = (
    e: SyntheticEvent<HTMLSelectElement>,
    isTotal: boolean,
    section: FlatTemplateSection
  ) => {
    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    const { value } = e.currentTarget;
    const newOrder = value ? Number(value) : undefined;

    onTemplateChange(TemplateUtils.changeSectionChart(section, index, newOrder, currentTemplate, isTotal));
  };

  const handleSectionPercentChange = (
    selected: HoneAccount | undefined,
    isTotal: boolean,
    section: FlatTemplateSection
  ) => {
    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.changeSectionPercent(section, index, selected, currentTemplate, isTotal));
  };

  const handleSectionDisplayChange = (
    e: SyntheticEvent<HTMLSelectElement>,
    isTotal: boolean,
    section: FlatTemplateSection
  ) => {
    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    // TODO narrow value to a HoneTemplateDisplay
    const { value } = e.currentTarget;

    onTemplateChange(
      TemplateUtils.changeSectionDisplay(section, index, value as HoneTemplateDisplay, currentTemplate, isTotal)
    );
  };

  const handleSectionAccountChange = (selected: HoneAccount | undefined, section: FlatTemplateSection) => {
    const index = findIndex(currentTemplate, { id: section.id });

    if (index < 0) throw new Error("bad index");
    if (selected) {
      const foundCalculatable = find(customCalculations, { id: selected.id });

      // short circuit to set a custom calc first
      if (foundCalculatable) {
        const newTemplate = cloneDeep(currentTemplate);

        // FIXME suspected that i neeed to support Operand here (right now only AccountOperand is supported?)
        newTemplate[index].value = { section: foundCalculatable.id };

        onTemplateChange(newTemplate);
        return;
      }
    }

    // otherwise just use an account
    onTemplateChange(TemplateUtils.changeSectionAccount(section, index, selected, currentTemplate));
  };

  const handleCustomCalculationChange = (
    changedCalculation: CustomCalculation | undefined,
    section: FlatTemplateSection
  ) => {
    const index = findIndex(currentTemplate, { id: section.id });

    if (index < 0) throw new Error("bad index");

    if (changedCalculation) {
      let foundIndex = findIndex(customCalculations, { id: changedCalculation.id });

      if (foundIndex < 0) {
        foundIndex = findIndex(customCalculations, { id: section.id });
      }

      if (foundIndex > -1) {
        const newTemplate = cloneDeep(currentTemplate);
        const newValue = omit(changedCalculation, ["title", "id"]);

        // update the existing calculations with this change
        newTemplate[index].value = newValue;

        // TODO update all the related sections that have this set

        onTemplateChange(newTemplate);

        //        setCustomCalculations(
        //          customCalculations.map((calc, jndex) => (jndex === foundIndex ? changedCalculation : calc))
        //        );
      } else {
        // TODO what happens when we remove a section that has a custom calculation
        // do we toggle `show` and send a change event with an empty calculation?

        // add the new calculation
        setCustomCalculations([...customCalculations, changedCalculation]);
      }
    }
  };

  function handleMoveAfterNextRootSection(e: SyntheticEvent, section: FlatTemplateSection) {
    e.preventDefault();

    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.moveAfterNextRootSection(section, index, currentTemplate));
  }

  function handleMoveBeforePreviousRootSection(e: SyntheticEvent, section: FlatTemplateSection) {
    e.preventDefault();

    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.moveBeforePreviousRootSection(section, index, currentTemplate));
  }

  function handleMoveAfterNextSection(e: SyntheticEvent, section: FlatTemplateSection) {
    e.preventDefault();

    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0 || index >= templateSectionGroups.length) {
      return;
    }

    onTemplateChange(TemplateUtils.moveAfterNextSection(section, index, currentTemplate));
  }

  function handleMoveBeforePreviousSection(e: SyntheticEvent, section: FlatTemplateSection) {
    e.preventDefault();

    const index = findIndex(currentTemplate, { id: section.id });
    if (index <= 0) {
      return;
    }

    onTemplateChange(TemplateUtils.moveBeforePreviousSection(section, index, currentTemplate));
  }

  const handleCollapseSection = (e: SyntheticEvent, section: FlatTemplateSection) => {
    e.preventDefault();

    const index = findIndex(collpasedSections, { id: section.title });
    if (index < 0) throw new Error("bad index");

    const newCollapsedSections = cloneDeep(collpasedSections);
    newCollapsedSections[index].collapsed = !newCollapsedSections[index].collapsed;

    setcollpasedSections(newCollapsedSections);
  };

  const handleSmoothingChange = (e: SyntheticEvent<HTMLSelectElement>, section: FlatTemplateSection) => {
    const index = findIndex(currentTemplate, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onTemplateChange(TemplateUtils.changeSmoothing(section, index, e.currentTarget.value, currentTemplate));
  };

  // Clear the customCalculatables when the report changes
  useEffect(() => {
    // eslint-disable-next-line
    calculatableAccounts = (chartOfAccounts || []).concat(additionalCalculatables);
  }, [currentLocationId, chartOfAccounts, additionalCalculatables]);

  return (
    <>
      {!isEmpty(templateSectionGroups) &&
        templateSectionGroups.map((sections: FlatTemplateSection[], index: number) => {
          const calculatedSectionCustomCalculations = sections.filter((section) =>
            TemplateUtils.isCustomCalculation(section.value)
          );

          const isSectionCollapsed = collpasedSections[index] && collpasedSections[index].collapsed;

          return (
            <div className="BKForm-sections" key={sections[0].id} data-testid={`BKForm-index${index}`}>
              <div className="BKForm-header d-flex justify-content-between mb-4">
                <p className="m-0" />
                <div>
                  {index > 0 && (
                    <button
                      className="button button_icon"
                      aria-label="Move before previous section"
                      type="button"
                      onClick={(e) => handleMoveBeforePreviousRootSection(e, sections[0])}
                    >
                      <IconUp />
                    </button>
                  )}
                  {index < templateSectionGroups.length - 1 && (
                    <button
                      className="button button_icon"
                      aria-label="Move after previous section"
                      type="button"
                      onClick={(e) => handleMoveAfterNextRootSection(e, sections[0])}
                    >
                      <IconDown />
                    </button>
                  )}
                  <button
                    className="button button_icon"
                    aria-label="Add section"
                    title="Add section"
                    type="button"
                    onClick={(e) => handleAddRootSection(e, sections[0])}
                  >
                    <IconAdd />
                  </button>
                  <button
                    className="button button_icon"
                    aria-label="Remove section"
                    type="button"
                    onClick={(e) => handleRemoveRootSection(e, sections[0])}
                  >
                    <IconRemove />
                  </button>
                </div>
              </div>

              {/* use level 0 as section header */}
              <BookkeeperTemplateSectionForm
                key={`${sections[0].id}${index}`}
                section={sections[0]}
                chartOfAccounts={chartOfAccounts}
                calculatableAccounts={calculatableAccounts}
                showControls={false}
                chartCount={chartSections.length}
                onRemoveSection={(e) => handleRemoveSection(e, sections[0])}
                onDisplayChange={(e, isTotal) => handleSectionDisplayChange(e, isTotal, sections[0])}
                onTotalToggle={(e) => handleSectionTotalToggle(e, sections[0])}
                onTitleChange={(e, isTotal) => handleSectionTitleChange(e, isTotal, sections[0])}
                onAccountChange={(selectedAccount) => handleSectionAccountChange(selectedAccount, sections[0])}
                onChartChange={(e, isTotal) => handleSectionChartChange(e, isTotal, sections[0])}
                onPercentChange={(selectedAccount, isTotal) =>
                  handleSectionPercentChange(selectedAccount, isTotal, sections[0])
                }
                onAddSameLevel={(e) => noop()}
                onLevelChange={(op) => noop()}
                onSmoothingChange={(e) => handleSmoothingChange(e, sections[0])}
                handleMoveAfterNextSection={(e, section) => handleMoveAfterNextSection(e, sections[0])}
                handleMoveBeforePreviousSection={(e, section) => handleMoveBeforePreviousSection(e, sections[0])}
                handleCollapseSection={(e) => handleCollapseSection(e, sections[0])}
                sectionCollapsed={isSectionCollapsed}
              />
              {/* skip header to render the rest of the sections, jndex+1 below to account for dropping header */}
              <div className={classNames("BKForm-group", { "BKForm-group-collpased": isSectionCollapsed })}>
                {/* only have header */}
                {sections.length === 1 && (
                  <div key={sections[0].id} className="BKForm-section">
                    <button
                      type="button"
                      className="button button_outline w-100"
                      onClick={(e) => handleAddNestedLevel(e, sections[0])}
                    >
                      Add subsection
                    </button>
                  </div>
                )}
                {sections.length > 1 &&
                  tail(sections).map((section, jndex) => (
                    <>
                      {!!sections[jndex]?.level && sections[jndex].level > section.level && (
                        <div key={`separator-${section.id}-${index}`} className="BKForm-separator" />
                      )}
                      <BookkeeperTemplateSectionForm
                        key={`${section.id}${index}${jndex + 1}`}
                        section={section}
                        chartOfAccounts={chartOfAccounts}
                        calculatableAccounts={calculatableAccounts}
                        disallowDecrementLevel={Boolean(
                          section.level <= 1 ||
                            sections[jndex].level > section.level ||
                            (sections[jndex + 2]?.level && sections[jndex + 2].level > section.level)
                        )}
                        disallowIncrementLevel={!isNil(sections[jndex]?.level) && sections[jndex].level < section.level}
                        chartCount={chartSections.length}
                        onRemoveSection={(e) => handleRemoveSection(e, section)}
                        onDisplayChange={(e, isTotal) => handleSectionDisplayChange(e, isTotal, section)}
                        onTotalToggle={(e) => handleSectionTotalToggle(e, section)}
                        onTitleChange={(e, isTotal) => handleSectionTitleChange(e, isTotal, section)}
                        onAccountChange={(selectedAccount) => handleSectionAccountChange(selectedAccount, section)}
                        onChartChange={(e, isTotal) => handleSectionChartChange(e, isTotal, section)}
                        onPercentChange={(selectedAccount, isTotal) =>
                          handleSectionPercentChange(selectedAccount, isTotal, section)
                        }
                        onAddSameLevel={(e) => handleAddSameLevel(e, section)}
                        onLevelChange={(op) => handleSectionLevelChange(op, section)}
                        onSmoothingChange={(e) => handleSmoothingChange(e, section)}
                        handleMoveAfterNextSection={(e, section) => handleMoveAfterNextSection(e, section)}
                        handleMoveBeforePreviousSection={(e, section) => handleMoveBeforePreviousSection(e, section)}
                        sectionCollapsed={isSectionCollapsed}
                      />
                    </>
                  ))}
              </div>
              {!isSectionCollapsed && (
                <BookkeeperTemplateCalculationsForm
                  section={sections[0]}
                  calculatedSections={calculatedSectionCustomCalculations}
                  calculatableAccounts={calculatableAccounts}
                  onCalculationsChange={(changedCalculations) =>
                    handleCustomCalculationChange(changedCalculations[0], sections[0])
                  }
                />
              )}
            </div>
          );
        })}
    </>
  );
}

export default BookkeeperTemplateSectionsForm;
