import {
  IconBalance,
  IconBookkeeper,
  IconCash,
  IconForecast,
  IconPlComparison,
  IconYearToDate,
  IconAgAging,
  IconNbAp,
  IconNbAsk,
  IconNbBalance,
  IconNbBk,
  IconNbCash,
  IconNbDash,
  IconNbFc,
  IconNbJe,
  IconNbLogout,
  IconNbPl,
  IconNbYtd
} from "./components/Icons";

export const HoneReportStatuses = {
  Published: "Published",
  Draft: "Draft"
} as Readonly<Record<string, HoneReportStatus>>;

export const HoneReportVisibilities = {
  AllEmployees: "All Employees",
  OwnerOnly: "Owner Only"
} as Readonly<Record<string, HoneReportVisibility>>;

export const HoneReportTimeframes = {
  Weekly: "Weekly",
  Monthly: "Monthly",
  YTDMonthly: "YTD Monthly"
} as Readonly<Record<string, HoneReportTimeframe>>;

export const HoneReportTypes = {
  BalanceSheet: "Balance Sheet",
  CashFlow: "Cash Flow",
  IncomeStatement: "Income Statement",
  PLComparison: "P&L Comparison",
  ApAging: "AP Aging"
} as Readonly<Record<string, HoneReportType>>;

export const HoneReportTypeTimeframes = {
  AllReportTypes: "All Report Types",
  MonthlyBalanceSheet: "Monthly Balance Sheet",
  MonthlyCashFlow: "Monthly Cash Flow",
  MonthlyPLComparison: "Monthly P&L Comparison",
  WeeklyPLComparison: "Weekly P&L Comparison",
  YTDIncomeStatement: "YTD Income Statement"
} as Readonly<Record<string, HoneReportTypeTimeframe>>;

export const HoneReportFormats = {
  JSON: "json",
  PDF: "pdf"
} as Readonly<Record<string, HoneReportFormat>>;

export const HoneTemplateDisplays = {
  Empty: "empty",
  Default: "default",
  Hidden: "hidden",
  Header1: "header_1",
  Header2: "header_2",
  Header3: "header_3",
  Header4: "header_4",
  Header5: "header_5",
  Total1: "total_1",
  Total2: "total_2"
} as Readonly<Record<string, HoneTemplateDisplay>>;

export const AccountTags = {
  BevCost: "Bev Cost",
  BevSales: "Bev Sales",
  ControllableExpenses: "Controllable Expenses",
  FoodCost: "Food Cost",
  FoodSales: "Food Sales",
  Labor: "Labor",
  NetSales: "Net Sales",
  TotalExpenses: "Total Expenses"
} as Readonly<Record<string, string>>;

export const MENU_ICONS = {
  "Balance Sheet": IconNbBalance,
  "Cash Flow": IconNbCash,
  "Income Statement": IconNbPl,
  "P&L Comparison": IconNbPl,
  "AP Aging": IconNbAp,
  Forecast: IconNbFc,
  Bookkeeper: IconNbBk
};

export const tellUsReOpenTransaction = "Please tell us why you want to reopen this transaction.";

export const TIME_ANIMATION_ASK_REQUESTS = 500;
