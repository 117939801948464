import React from "react";
import classNames from "classnames";
import { format, parseISO, parseJSON } from "date-fns";
import { IconEdit } from "components/Icons";
import { prefixClass } from "lib/utils";
import { CSSTransition } from "react-transition-group";

export type ChatBubbleProps<ShowDate extends boolean, DateProp extends Date | undefined = undefined> = {
  orientation: "left" | "right";
  fit: boolean;
  editable: boolean;
  showDate: ShowDate;
  content: string;
  glCode?: boolean;
  createdAt: Date;
} & (ShowDate extends true
  ? {
      date: DateProp extends undefined ? Date : DateProp;
    }
  : {
      date?: DateProp;
    });

export const ChatBubble = <ShowDate extends boolean, DateProp extends Date | undefined = undefined>(
  props: ChatBubbleProps<ShowDate, DateProp>
) => {
  const { showDate, content, date, fit, orientation, editable, glCode, createdAt } = props;
  const showEdit = orientation === "right" && editable;
  const prefix = prefixClass("chat-bubble");
  return (
    <CSSTransition timeout={300}>
      <section>
        {showDate && date && <div className="chat-date">{format(date, "LLL do, yyyy")}</div>}
        <div
          className={classNames("chat-bubble", {
            "chat-bubble-fit": fit,
            [prefix(orientation)]: true,
            [prefix("is-glcode")]: glCode
          })}
        >
          <blockquote>{content}</blockquote>
          <time>{format(parseJSON(createdAt), "hh:mm a")}</time>
          {showEdit && (
            <div>
              <IconEdit />
            </div>
          )}
        </div>
      </section>
    </CSSTransition>
  );
};

export default ChatBubble;
