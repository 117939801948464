import React, { useEffect, useRef, useState } from "react";

import Loading from "components/Loading";

import { useForecastReport } from "hooks/useForecastReport";

import ForecastTable from "./components/ForecastTable/ForecastTable";
import ForecastHistoricalGraph from "./components/ForecastHistoricalGraph/ForecastHistoricalGraph";
import ForecastTitle from "./components/ForecastTitle";

import { IconUnion } from "components/Icons";

import "./ForecastReport.styles.scss";
import { useLocationsStore } from "hooks/useLocationsStore";
import { ErrorBoundary } from "react-error-boundary";
import ForecastErrorView from "../../components/forecast/ForecastErrorView";
import ForecastTimeFrameSelector from "./components/ForecastTimeFrameSelector";
import { useForecastUIStore } from "hooks/useForecastUIStore";
import useLocationRouting from "hooks/useLocationRouting";
import { PageHeader } from "presentation/components";
import classNames from "classnames";
import { useQueryState } from "hooks/useQueryState";
import { useAuthContext } from "context/useAuthContext";
import KSHeader from "../../components/KSHeader";
import { useFlags } from "launchdarkly-react-client-sdk";

function ForecastView() {
  const { enableV3Location } = useFlags();

  useLocationRouting(false);
  const [chartCollapsed, setChartCollapsed] = useQueryState<string>("chartCollapsed", "false");
  const fetchForecastReport = useForecastReport((state) => state.fetchForecastReport);
  const remoteReport = useForecastReport((state) => state.remoteForecastReport);
  const loadingForecast = useForecastReport((state) => state.loadingForecast);
  const { userId } = useAuthContext();
  const currentLocationId = useLocationsStore((state) => state.currentLocationId);
  const [showForecastNotFound, setshowForecastNotFound] = useState(false);
  const forecastRef = useRef<HTMLDivElement>(null);
  const { timeframe } = useForecastUIStore((state) => ({
    timeframe: state.timeframe
  }));

  useEffect(() => {
    if (userId) {
      fetchForecastReport(userId);
    }
  }, [userId, fetchForecastReport, currentLocationId]);

  useEffect(() => {
    if (remoteReport) {
      const dates = timeframe === "months" ? Object.values(remoteReport?.months) : Object.values(remoteReport?.weeks);
      const hasForecast = dates.some((reportItem) => reportItem.forecast);
      setshowForecastNotFound(hasForecast);
    }
  }, [remoteReport, timeframe]);

  const toggleChartCollapse = () => {
    const nextState = chartCollapsed === "true" ? "false" : "true";
    setChartCollapsed(nextState);
    return nextState;
  };

  return (
    <ErrorBoundary FallbackComponent={ForecastErrorView}>
      <div className="report-view">
        {enableV3Location && <KSHeader />}
        <PageHeader customTitle={<ForecastTitle />} icon={<IconUnion />} pageTitle="Forecast">
          <div>
            <ForecastTimeFrameSelector />
          </div>
        </PageHeader>
        <div className="forecast-layout" ref={forecastRef}>
          <div className="card forecast-view">
            {loadingForecast ? (
              <div className="h-100 center">
                <Loading />
              </div>
            ) : !showForecastNotFound ? (
              <ForecastErrorView />
            ) : (
              <>
                <div
                  className={classNames("toggle-chart", {
                    "toggle-chart--collapsed": chartCollapsed === "true"
                  })}
                >
                  <button onClick={toggleChartCollapse}>{chartCollapsed === "true" ? "Show" : "Hide"} chart</button>
                </div>
                {chartCollapsed === "false" && (
                  <div className="graph">
                    <ForecastHistoricalGraph />
                  </div>
                )}
                <ForecastTable />
              </>
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default ForecastView;
