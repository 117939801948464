import React, { ChangeEvent, createRef, MutableRefObject, useRef, useState } from "react";
import KSListItem from "../KSListItem";
import "./KSLocationModal.scss";
import { IconCheck, IconClose, IconSearch } from "components/Icons";
import KSListTitle from "../KSListTitle/KSListTitle";
import classNames from "classnames";

import { useClickAway } from "react-use";
import KSCheckBox from "../KSCheckBox";
import KSButton from "../KSButton";

interface KSLocationModalProps {
  mode?: "single" | "multiple";
  activeLocation?: string;
  selectedLocations?: HoneLocationUser[];
  isOpen: boolean;
  recentLocations: HoneLocationUser[];
  locations: HoneLocationUser[];
  onLocationChange?: (locationId: string) => void;
  onSelectLocation?: (location: HoneLocationUser) => void;
  onSelectAll?: () => void;
  onSelectNone?: () => void;
  onApplyChanges?: () => void;
  handleClose: () => void;
  preventClickAway?: boolean;
}

function KSLocationModal({
  mode = "single",
  isOpen,
  handleClose,
  locations,
  recentLocations,
  activeLocation,
  onLocationChange,
  onSelectLocation,
  selectedLocations,
  onSelectAll,
  onSelectNone,
  onApplyChanges,
  preventClickAway = false
}: KSLocationModalProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const modalRef = useRef<HTMLDivElement>(null);

  useClickAway({ current: preventClickAway ? null : modalRef?.current }, handleClose);

  const groupByOrganization = (data: HoneLocationUser[]) => {
    return data.reduce((acc, item) => {
      const key = item.org;
      if (!acc.has(key)) {
        acc.set(key, []);
      }
      acc.get(key).push(item);
      return acc;
    }, new Map());
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleResetSearch = () => {
    setSearchQuery("");
  };

  const filteredData = locations.filter(
    (item) => item.locationName.toLowerCase().includes(searchQuery) || item.org?.toString().includes(searchQuery)
  );

  const groupedLocations = React.useMemo(() => groupByOrganization(filteredData), [searchQuery, locations]);

  return (
    <div ref={modalRef} className={classNames("ks-select", { open: isOpen, [mode]: true })}>
      <div>
        <div className="ks-select-searchbox">
          <>
            <IconSearch />
            <input type="text" value={searchQuery} placeholder="Search Location" onChange={handleSearchChange} />
          </>

          {searchQuery && (
            <button type="button" onClick={handleResetSearch}>
              <IconClose />
            </button>
          )}
        </div>
        <div className="ks-select-selected-box">
          {mode === "multiple" && <span>Select Locations ({selectedLocations?.length})</span>}
        </div>
        <div className="ks-select-items">
          {Array.from([
            ...(recentLocations.length > 0 ? new Map().set("Recent Locations", recentLocations).entries() : []),
            ...groupedLocations.entries()
          ]).map(([organization, units]) => (
            <>
              <KSListTitle>{organization}</KSListTitle>
              {units.map((unit: HoneLocationUser) => {
                const isActive =
                  unit.locationId === activeLocation ||
                  selectedLocations?.some((item) => item.locationId === unit.locationId);

                const handleClick = () =>
                  mode === "multiple" ? onSelectLocation?.(unit) : onLocationChange?.(unit.locationId);
                const disabled = activeLocation === unit.locationId;
                return (
                  <KSListItem
                    disabled={disabled}
                    onClickListItem={handleClick}
                    isSelected={!!isActive}
                    key={unit.locationId}
                    label={unit.locationName}
                    notBigText
                    beforeLabel={
                      mode === "multiple" ? (
                        <div className="ks-location-checkbox">
                          <KSCheckBox
                            disabled={disabled}
                            rounded
                            label={""}
                            checked={!!isActive}
                            onChange={handleClick}
                          />
                        </div>
                      ) : null
                    }
                    afterLabel={isActive && mode === "single" ? <IconCheck /> : null}
                  />
                );
              })}
            </>
          ))}
        </div>
      </div>
      {mode === "multiple" && (
        <div className="ks-modal-footer">
          <div>
            <div className="group">
              <KSButton label="SELECT" disabled color="tertiary" onClick={onSelectAll} />
              <KSButton label="ALL" color="tertiary" onClick={onSelectAll} />
              <KSButton label="NONE" color="tertiary" onClick={onSelectNone} />
            </div>
            <KSButton label="APPLY" color="primary" onClick={onApplyChanges} />
          </div>
        </div>
      )}
    </div>
  );
}

export default KSLocationModal;
