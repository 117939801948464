import { UIFlaggedTransaction } from "types/askcustomer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeRemotePostFlaggedTransactionComment } from "main/factories/usecases/ask-customer/remote-post-flagged-transaction-comment";
import { onFlaggedTransactionUpdate } from "hooks/flaggedTransactions/handlers/onFlaggedTransactionUpdate";
import { showToast } from "lib/utils";
import { useAskRequestsQuery } from "hooks/useAskRequestsQuery";
import { AskRequestStatus } from "@hone-automation/common";

import { useAskRequestsCountQuery } from "hooks/useAskRequestsCountQuery";
import { useFlaggedTransactionsQuery } from "./useFlaggedTransactionsQuery";
import { useFlaggedTransactions } from "./useFlaggedTransactions";

export const useCreateFlagTransactionCommentMutation = (
  status: AskRequestStatus,
  locationId: string,
  setActiveTransaction: (trx: UIFlaggedTransaction | undefined) => void
) => {
  const queryClient = useQueryClient();

  const { refetch } = useAskRequestsQuery(status);
  const { refetch: refetchCounts } = useAskRequestsCountQuery();
  const { refetch: refetchFlaggedCells } = useFlaggedTransactionsQuery();
  const { setAnimationTransaction } = useFlaggedTransactions((state) => ({
    setAnimationTransaction: state.setAnimatedTransaction,
  }));
  return useMutation({
    mutationFn: (data: any) => {
      const trxId = data.activeTransaction.flagged
        ? data.activeTransaction.flagged.id
        : data.activeTransaction.flaggedCell
        ? data.activeTransaction.flaggedCell.id
        : data.activeTransaction.id;

      const service = makeRemotePostFlaggedTransactionComment(trxId);

      const { comment, userId } = data;
      return service.post({ userId, comment, locationId });
    },
    onSuccess: (data, variables) => {
      refetch();
      refetchCounts();
      refetchFlaggedCells();
      setAnimationTransaction(undefined);
      onFlaggedTransactionUpdate(data, variables, locationId, queryClient, setActiveTransaction);
    },
    onError: (error) => {
      showToast("An error has occured creating message", "error");
    },
  });
};
