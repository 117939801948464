import { useLocationsStore } from "hooks/useLocationsStore";
import { useQuery } from "@tanstack/react-query";

import { makeGetSlackChannels } from "main/factories/usecases/slack";

export const useGetSlackChannels = () => {
  const currentLocationId = useLocationsStore((state) => state.currentLocation?.id);
  return useQuery({
    enabled: !!localStorage.getItem("hone:session"),
    queryKey: ["slackChannels", currentLocationId],
    queryFn: () => {
      const service = makeGetSlackChannels();
      return service.getSlackChannels();
    },
    staleTime: 1000 * 60 * 5
  });
};
