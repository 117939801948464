import { useState, useEffect } from "react";
import "./Loading.css";

interface Props {
  loadingCircleClass?: string;
  unmount?: boolean;
}

function Loading({ loadingCircleClass = "Loading-circle", unmount = true }: Props): JSX.Element {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (!unmount) {
      const timeoutId = setTimeout(() => {
        setShow(false);
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [unmount]);

  return (
    <div className="Loading">
      <span className={"Loading-wrapper" + (show ? " Loading-wrapper_show" : "")}>
        <span className={loadingCircleClass ? loadingCircleClass : "Loading-circle"}>
          <span className="visually-hidden">Loading&hellip;</span>
        </span>
      </span>
    </div>
  );
}

export default Loading;
