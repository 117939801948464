import { useCallback, useState } from "react";
import { currencyFormatter2Decimals, isPeriodReport } from "lib/utils";
import TransactionDetails from "components/TransactionDetails";
import Loading from "components/Loading";
import Chat from "presentation/components/Chat/Chat";

import { UIFlaggedTransaction, UIReportTransaction } from "types/askcustomer";
import {
  useCreateAskRequestMutation,
  useCreateFlagTransactionCommentMutation,
  useReopenFlaggedTransactionMutation
} from "hooks/flaggedTransactions";
import { useReportsStore } from "hooks/useReportsStore";
import { useActiveReportStore } from "hooks/useActiveReportStore";
import {
  AskRequest,
  AskRequestStatus,
  CreateAskRequest,
  CreateAskTransaction,
  LinkTypes
} from "@hone-automation/common";
import { useQueryState } from "hooks/useQueryState";
import Tag from "../Tag";
import { useAuthContext } from "context/useAuthContext";
import { useParams } from "react-router-dom";
import { DataCellState } from "domain/models";

type Props = {
  transaction: UIReportTransaction;
  updateTransaction: (transaction: UIReportTransaction) => void;
  reportId?: string;
  onCancel: () => void;
  modalState: DataCellState;
};
function AskBookkeeperModal({ transaction, updateTransaction, modalState }: Props) {
  const [activeTab] = useQueryState<AskRequestStatus>("activeTab", "Open");
  const { locationId: currentLocationId } = useParams();
  const { user } = useAuthContext();
  const { title, date } = modalState;

  const activeReport = useReportsStore((state) => state.selectedReport);

  const currentReportId = useActiveReportStore((state) => state.currentReportId);
  const [loading, setLoading] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");

  const detailsRows = [
    {
      label: "TRX Date",
      value: (transaction as any).flaggedCell
        ? "-"
        : transaction
          ? JSON.stringify((transaction as any).txnDate)
              .split("T")[0]
              .replaceAll('"', "")
          : "-"
    },
    {
      label: "Name",
      value: transaction?.name || "-"
    },
    {
      label: "Description",
      value: transaction?.description || "-"
    },
    {
      label: "Type",
      value: transaction?.type || "-"
    },
    {
      label: "Account",
      value: transaction?.flagged?.transaction?.account || "-"
    },
    {
      label: "Amount",
      value: transaction?.amount && currencyFormatter2Decimals(transaction?.amount)
    }
  ];

  const createAskRequest = useCreateAskRequestMutation(currentLocationId!, (response) => {
    if (response) {
      updateTransaction({
        ...transaction,
        flagged: response as AskRequest
      });
    }
  });

  const createCommentMutation = useCreateFlagTransactionCommentMutation(activeTab, currentLocationId!, (response) => {
    if (response) {
      updateTransaction({
        ...transaction,
        flagged: response as any
      });
    }
  });

  const postReopenFlaggedTransaction = useReopenFlaggedTransactionMutation(
    activeTab,
    currentLocationId!,
    (trx: UIFlaggedTransaction | undefined) => {
      updateTransaction({
        ...transaction,
        flagged: trx as any
      });
    }
  );

  const addComment = (comment: string) => {
    setLoading(true);

    createCommentMutation.mutate({
      comment,
      userId: user?.uid,
      locationId: currentLocationId,
      activeTransaction: transaction
    });

    setLoading(false);
  };

  const submit = async (comment: string) => {
    setLoading(true);
    if (!currentLocationId || !activeReport?.id) {
      console.error("No current location or report Id", currentReportId, currentLocationId);
      return;
    }
    const trx: CreateAskTransaction = {
      memo: transaction?.description,
      name: transaction?.name,
      externalTransactionType: String(transaction?.type),
      externalNum: transaction?.num,
      externalId: String(transaction?.eventId)
    };

    const periodReport = isPeriodReport(activeReport);
    const transactionToFlag: CreateAskRequest = {
      locationId: currentLocationId,
      type: "Ask Bookkeeper",
      reportId: periodReport && date?.reportId ? date.reportId : activeReport?.id,
      reportType: periodReport ? LinkTypes.Period_Report : LinkTypes.Location_Report,
      identifier: title,
      period: transaction?.txnDate,
      flaggedAmount: transaction?.amount,
      comment: comment,
      askTransaction: trx
    };

    createAskRequest.mutate(transactionToFlag);
    setLoading(false);
  };

  const handleSubmit = useCallback(async () => {
    await submit(comment);
  }, [comment, transaction, currentLocationId]);

  const handleReopen = (comment: string) => {
    postReopenFlaggedTransaction.mutate({
      trxId: transaction?.flagged?.id || "",
      comment
    });
  };
  const handleChatSubmit = async (comment: string) => {
    await addComment(comment);
  };

  const isClosedTrx =
    transaction?.flagged?.status === "Closed" || (transaction?.flagged?.status as any) === "Categorized";

  const canReopen = Boolean(transaction && transaction.flagged && transaction?.flagged?.canReopen && isClosedTrx);

  return (
    <div className="ask-bookkeeper">
      {loading && (
        <div className="loading">
          <Loading />
        </div>
      )}
      <TransactionDetails rows={detailsRows} transaction={transaction.flagged} />
      <div className="ask-bookkeeper-tag-info">
        {transaction?.flagged?.recentlyResolved && <Tag content="Newest" type={"info"} />}
      </div>
      {!transaction.flagged ? (
        <div className={"chat"}>
          <div className="chat-header" />
          <div className="chat-body ask-bookkeeper-form">
            <div className="form-control">
              <label htmlFor="comment">Tell KitchenSync why are you flagging this:</label>
              <textarea value={comment} onChange={(e) => setComment(e.target.value)} autoFocus name="comment" />
            </div>
          </div>
          <div className="button-wrapper">
            {!transaction.flagged && (
              <button onClick={handleSubmit} type="button" className="flag-transaction" disabled={!comment}>
                Flag Transaction
              </button>
            )}
          </div>
        </div>
      ) : (
        <Chat
          canReOpen={canReopen}
          onSubmit={(comment: string) => (canReopen ? handleReopen(comment) : handleChatSubmit(comment))}
          isClosedTrx={isClosedTrx}
          messages={transaction.flagged.comments}
        />
      )}
    </div>
  );
}

export default AskBookkeeperModal;
