import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/kitchensync_logo.svg";
import "./AuthForgotPasswordForm.css";
import { AnalyticsEvents, trackEvent } from "lib/analytics";
import type { FormEvent } from "react";
import { useAuthContext } from "context/useAuthContext";

// https://www.w3.org/TR/2012/WD-html-markup-20120329/input.email.html
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const messages = {
  "auth/invalid-email": "Invalid email",
  "auth/user-not-found": "Invalid email",
  generic: "That's an error"
} as Record<string, string>;

function AuthForgotPasswordForm(): JSX.Element {
  const auth = useAuthContext();
  const navigateTo = useNavigate();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  function handleError(error: any) {
    let message = messages[error.code];
    if (!message) message = messages["generic"];
    setErrorMessage(message);
  }

  function handleOnInput() {
    setErrorMessage("");
    setSuccessMessage("");
    setSubmitted(false);
    return true;
  }

  function handleResetPassword(e: FormEvent) {
    e.preventDefault();
    setSubmitted(true);

    if (email.search(EMAIL_REGEX) > -1) {
      auth
        .sendPasswordResetEmail({ email })
        .then(() => {
          setSuccessMessage("Check your email for the password reset link.");
          trackEvent(AnalyticsEvents.ForgotPasswordSubmitted);
        })
        .catch(handleError);
    } else {
      handleError({ code: "auth/invalid-email" });
    }
  }

  return (
    <div className="AuthForgotPasswordForm">
      <Logo />
      <h1 className="visually-hidden">Reset password</h1>
      <div className="AFFP-area">
        <form className="AuthForgotPasswordForm-form" onSubmit={handleResetPassword}>
          <p className="text-center">Enter your email and we'll send you password reset instructions.</p>
          <label>
            <span className="visually-hidden">Email</span>
            <input
              placeholder="Email"
              type="text"
              onInput={(e) => handleOnInput() && setEmail(e.currentTarget.value.trim())}
            />
          </label>
          <button className="button  KS-b-primary" disabled={!email || submitted} type="submit">
            Submit
          </button>
          {errorMessage ? (
            <label className="d-block text-error text-center" role="alert">
              {errorMessage}
            </label>
          ) : (
            ""
          )}
          {successMessage ? (
            <label className="d-block text-success text-center" role="alert">
              {successMessage}
            </label>
          ) : (
            ""
          )}
        </form>
      </div>
      <button className="button button_link mt-2" onClick={() => navigateTo(-1)} type="button">
        Go back
      </button>
    </div>
  );
}

export default AuthForgotPasswordForm;
