import { makeApiUrl } from "main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "main/factories/decorators";
import { PostReopenFlaggedTransaction } from "domain/usecases";
import { RemotePostFlaggedTransaction } from "data/usecases/ask-customer/remote-post-flagged-transaction";

export const makeRemotePostReopenFlaggedTransaction = (trxId: string): PostReopenFlaggedTransaction => {
  return new RemotePostFlaggedTransaction(
    makeApiUrl(`/ask-requests/${trxId}/reopen`),
    makeAuthorizeHttpClientDecorator()
  );
};
