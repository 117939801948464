import React from "react";
import "./KSButton.scss";
import classNames from "classnames";

export type KSButtonProps = {
  /** The label of the button */
  label: React.ReactNode;
  /** The color of the button */
  color?: "primary" | "secondary" | "tertiary" | "active";
  /** type */
  type?: "button" | "submit" | "reset";
  /** The size of the button */
  size?: "small" | "medium" | "large";
  /** The variant of the button */
  variant?: "contained" | "outlined" | "text";
  /** The tag behavior of the button */
  tag?: "allcaps" | "title";
  /** The height behavior of the button */
  fullHeight?: boolean;
  /** The onClick event of the button */
  disabled?: boolean;
  onClick?: () => void;
};

export default function KSButton({
  label,
  color = "primary",
  size = "small",
  variant = "contained",
  tag = "allcaps",
  fullHeight = false,
  onClick,
  type,
  disabled = false
}: KSButtonProps) {
  return (
    <button
      className={classNames("ks-button", {
        [color]: true,
        [size]: true,
        [variant]: true,
        "full-height": fullHeight
      })}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={classNames("ks-label",{[tag]:true})}>{label}</div>
    </button>
  );
}
