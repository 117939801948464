import React, { FormEvent, useState } from "react";
import useLocationRouting from "hooks/useLocationRouting";
import { PageHeader } from "../../components";
import { IconNbBk } from "../../../components/Icons";
import LocationSelector from "../../components/LocationSelector/LocationSelector";
import RoleSelector from "../../components/RoleSelector/RoleSelector";
import { Controller, useForm } from "react-hook-form";
import { HoneUserRoles } from "@hone-automation/common";
import { getUserLocations } from "hooks/useUserLocationsQuery";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "react-use";
import { confirmAlert } from "react-confirm-alert";
import {
  dismissToast,
  FIVE_SECONDS,
  ONE_MINUTE,
  showToast,
  TEN_SECONDS,
  TOAST_DEFAULT,
  TOAST_ERROR,
  TOAST_SUCCESS
} from "../../../lib/utils";
import Loading from "../../../components/Loading";
import { useAuthContext } from "../../../context/useAuthContext";
import { useHoneLocationUsers } from "../../../components/HoneLocationUsers";
import UserLocationRow from "./UserLocationRow";
import { isEmpty } from "lodash";
import { useQueryState } from "../../../hooks/useQueryState";
import Checkbox from "../../components/Checkbox/Checkbox";

type AddLocationFormValues = {
  location: {
    label: string;
    value: string;
  };
  role: {
    label: string;
    value: string;
  };
  emailOptOut: boolean;
};

const emailRegEx = new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/);

enum EmailError {
  Required = "Required",
  InvalidEmail = "Enter valid email"
}

function Admin() {
  const auth = useAuthContext();
  useLocationRouting(false);

  const [userEmail, setUserEmail] = useQueryState<string>("user");
  const [error, setError] = useState<undefined | EmailError>();
  const [debouncedValue, setDebouncedValue] = React.useState("");
  useDebounce(
    () => {
      setError(undefined);
      setDebouncedValue(userEmail ?? "");
    },
    1000,
    [userEmail]
  );
  const {
    data: userLocations,
    refetch,
    status,
    isFetching
  } = useQuery({
    queryKey: ["selectedUserLocations", debouncedValue],
    queryFn: async () => {
      if (debouncedValue && emailRegEx.test(debouncedValue)) {
        return await getUserLocations.get({ email: debouncedValue });
      } else {
        setError(EmailError.InvalidEmail);
      }
    },
    enabled: !!debouncedValue
  });

  const { addLocationUser } = useHoneLocationUsers();

  const { control, handleSubmit, reset, watch } = useForm<AddLocationFormValues>({
    defaultValues: {
      location: undefined,
      role: undefined,
      emailOptOut: true
    }
  });

  const handleReset = () => {
    reset({
      location: undefined,
      role: undefined,
      emailOptOut: true
    });
  };

  const handlePasswordReset = async (e: FormEvent<HTMLButtonElement>) => {
    const email = e.currentTarget.value;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h4 className="mb-8">Do you want to send a 'Set Password' email to: {email}?</h4>
            <button className="button BKForm-btn-secondary mr-2" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button BKForm-btn"
              onClick={() => {
                onClose();
                const toastId = showToast("Sending 'Set Password' email", TOAST_DEFAULT, TEN_SECONDS);
                auth
                  .sendPasswordResetEmail({ email })
                  .then(function () {
                    dismissToast(toastId);
                    showToast("Successfully send email", TOAST_SUCCESS, TEN_SECONDS);
                  })
                  .catch(function (error: any) {
                    dismissToast(toastId);
                    showToast(`Error sending password email, error=${error}`, TOAST_ERROR, TEN_SECONDS);
                  });
              }}
            >
              Send Email
            </button>
          </div>
        );
      }
    });
  };

  const handleAddLocationUser = async (formData: AddLocationFormValues) => {
    const toastId = showToast("Adding user to this location, please wait a bit.", TOAST_DEFAULT, ONE_MINUTE);
    const email = debouncedValue;
    let roleStr: string = formData.role.value;
    if (roleStr === undefined || roleStr === null || roleStr === "") {
      roleStr = HoneUserRoles.Bookkeeper;
    }

    const locationId = formData.location.value;
    const locationName = formData.location.label;

    addLocationUser(email, "", roleStr, locationId, formData.emailOptOut).then((result: any) => {
      if (result.data) {
        if (result.data.result === "ok" || result.data.result.includes("user linked")) {
          refetch();
          dismissToast(toastId);
          showToast(`User ${email} added to ${locationName}`, TOAST_SUCCESS, FIVE_SECONDS);
          handleReset();
        } else {
          dismissToast(toastId);
          showToast("Error adding user", TOAST_ERROR, TEN_SECONDS);
        }
      }
    });
  };

  return (
    <div className="mb-4" id="location-users-tab" style={{ overflowY: "auto", height: "80vh" }}>
      <form className="user-location-form" onSubmit={(e) => e.preventDefault()}>
        <div className="form-control" style={{ position: "relative" }}>
          <label htmlFor="user-lookup">Enter the user email:</label>
          <input
            type="text"
            name="user-lookup"
            className={error ? "error" : ""}
            onChange={(e) => setUserEmail(e.currentTarget.value)}
            value={userEmail}
          />
          {error && <span className="error">{error}</span>}
          {isFetching && (
            <span style={{ position: "absolute", bottom: 10, left: 250 }}>
              <Loading loadingCircleClass="Loading-circle-small" />
            </span>
          )}
        </div>
        {debouncedValue && !error && (
          <button type="button" onClick={handlePasswordReset} value={debouncedValue} className="btn btn-secondary">
            Send Password Reset Email
          </button>
        )}
      </form>
      {status === "pending" && <p className="mt-4">Select an user to get started.</p>}
      {status === "success" && isEmpty(userLocations) ? (
        <p className="mt-4 mb-4">To add a new user to a location, please utilize the form provided below.</p>
      ) : (
        status === "success" && (
          <>
            <div className="mt-8 mb-2">User Locations:</div>
            <table className="mb-8">
              <thead>
                <tr>
                  <th className="BKForm-cell-header">Location</th>
                  <th className="BKForm-cell-header">Role</th>
                  <th className="BKForm-cell-header">
                    Email <br /> Opt Out
                  </th>
                  <th className="BKForm-cell-header">
                    Remove <br /> User
                  </th>
                </tr>
              </thead>
              <tbody>
                {(userLocations as HoneLocationUser[])?.map((location) => (
                  <UserLocationRow refetch={refetch} key={location.locationId} location={location} />
                ))}
              </tbody>
            </table>
          </>
        )
      )}
      <div className="line" />
      {status === "success" && (
        <form
          style={{ minWidth: "450px", minHeight: "500px" }}
          className="BKForm-form"
          onSubmit={handleSubmit(handleAddLocationUser)}
        >
          <p className="mb-2">
            Add <strong>{debouncedValue}</strong> to location:
          </p>
          <table className="mb-2" style={{ width: "450px", borderCollapse: "separate", borderSpacing: "0 10px" }}>
            <tbody>
              <tr>
                <td>
                  <label className="mr-3">Location:</label>
                </td>
                <td>
                  <LocationSelector name="location" control={control} rules={{ required: true }} />
                </td>
              </tr>
              <tr>
                <td>
                  <label className="mr-3">Role:</label>
                </td>
                <td>
                  <RoleSelector name="role" control={control} rules={{ required: true }} />
                </td>
              </tr>
              <tr>
                <td>
                  <label className="mr-3">Email opt-out</label>
                </td>
                <td className="email">
                  <Controller
                    control={control}
                    name="emailOptOut"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Checkbox value={String(value)} onChange={(_, checked) => onChange(checked)} checked={value} />
                      );
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2} align="right" className="mt-8">
                  <button type="button" className="btn btn-secondary" onClick={() => reset()}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Add location
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      )}
    </div>
  );
}

export default Admin;
