import React, { useEffect } from "react";
import Modal from "react-modal";
import { IconRefresh, IconTriangleWarning } from "components/Icons";
import useVersionQuery from "hooks/useVersionQuery";
import { compareVersions } from "lib/utils/compareVersions";

function UpdateVersionModal() {
  const [enableUpdate, setEnableUpdate] = React.useState(false);
  const { data: manifestVersion } = useVersionQuery();

  useEffect(() => {
    const appVersion = __APP_VERSION__;
    if (appVersion && manifestVersion && !window.location.href.includes("localhost")) {
      localStorage.setItem("ks:appVersion", appVersion);
      localStorage.setItem("ks:manifestVersion", manifestVersion);
      const result = compareVersions(appVersion, manifestVersion);
      if (result === -1 && localStorage.getItem("ks:updated") === null) {
        setEnableUpdate(true);
      }
    }
  }, [manifestVersion]);

  const handleReload = () => {
    window.localStorage.setItem("ks:updated", "1");
    window.location.reload();
  };

  return (
    <Modal overlayClassName="update-overlay" className="modal-update-version" isOpen={enableUpdate}>
      <div className="modal-header">
        <div className="modal-title">
          <IconTriangleWarning />
          <h6> Browser Refresh Needed </h6>
        </div>
      </div>
      <div className="modal-body">
        <p>
          In order to improve the KitchenSync experience. We need you to refresh this website. Click the button below to
          proceed
        </p>
        <button onClick={handleReload}>
          Refresh
          <IconRefresh />
        </button>
      </div>
    </Modal>
  );
}

export default UpdateVersionModal;
