import classNames from "classnames";
import { IconChecklist, IconErrorBlack, IconValidate, IconWarningBlack } from "components/Icons";
import { ReactNode } from "react";

type InformativeMessageProps = {
  children: ReactNode;
  type: "warning" | "error" | "success" | "informative" | "checklist";
  center?: boolean;
  clickable?: boolean;
};

const Icons: Record<string, JSX.Element> = {
  error: <IconErrorBlack />,
  warning: <IconWarningBlack />,
  success: <IconValidate />,
  checklist: <IconChecklist />,
};

export default function InformativeMessage({
  children,
  clickable = false,
  type,
  center = false,
}: InformativeMessageProps): JSX.Element {
  return (
    <div
      className={classNames("informative-message", type, {
        "informative-message-center": center,
        clickable: clickable,
      })}
    >
      {type !== "informative" && Icons[type]}
      {children}
    </div>
  );
}
