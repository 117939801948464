import { ReactNode, SyntheticEvent, useCallback, useEffect } from "react";
import CoAViewer from "../CoAEditor/CoAViewer";
import CoAParentSelector from "../CoAEditor/CoAParentSelector";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { findIndex } from "lodash";
import { useLocationsStore } from "hooks/useLocationsStore";
import { useUpdateQboAccountsMutation } from "hooks/useUpdateQboAccountsMutation";
import { QboAccount } from "domain/models";

type CoAEditorProps = {
  data: { data: QboAccount[]; length: number; original: QboAccount[] };
};

type FormType = {
  selected: QboAccount[];
  parent: string;
  mode: "join" | "split";
  selectedCopy: QboAccount[];
};

const CoAEditor = ({ data }: CoAEditorProps): ReactNode => {
  const { mutate: save, data: result } = useUpdateQboAccountsMutation();
  const currentLocationId = useLocationsStore((state) => state.currentLocationId);
  const methods = useForm<FormType>({
    defaultValues: {
      selected: [],
      selectedCopy: [],
      mode: "join"
    }
  });

  const parent = methods.watch("parent");

  const { remove, prepend, fields, update } = useFieldArray({
    control: methods.control,
    name: "selected"
  });

  const handleItemClick = useCallback(
    (item: QboAccount) => {
      if (parent && item.externalId === parent) {
        return;
      }
      const index = findIndex(fields, item);
      if (index > -1) {
        remove(index);
      } else {
        prepend(item);
      }
    },
    [parent, fields]
  );

  const handleSplitClick = (item: QboAccount) => {
    const index = findIndex(fields, item);
    update(index, { ...item, removed: item.removed ? undefined : true });
  };

  const onSubmit = (values: FormType) => {
    const payload: { locationId: string; accounts: QboAccount[] } = {
      locationId: currentLocationId!,
      accounts: []
    };

    const mode = methods.watch("mode");
    if (mode === "join") {
      payload.accounts = values.selected.reduce((acc, val) => {
        if (!val.removed || typeof val.removed === "undefined") {
          acc.push({ ...val, parentExternalId: values.parent, removed: undefined });
        } else {
          acc.push({ ...val, parentExternalId: undefined, removed: undefined });
        }
        return acc;
      }, [] as QboAccount[]);
    } else if (mode === "split") {
      payload.accounts = values.selectedCopy.reduce((acc, { parentExternalId, ...rest }) => {
        acc.push(rest);
        return acc;
      }, [] as QboAccount[]);
    }

    save(payload);
  };

  useEffect(() => {
    if (result) {
      methods.setValue(
        "selected",
        (result as QboAccount[]).filter((rs) => rs.parentExternalId)
      );
      methods.setValue(
        "selectedCopy",
        (result as QboAccount[]).filter((rs) => rs.parentExternalId)
      );
    }
  }, [result]);

  const handleParentSelection = (item: any, e?: SyntheticEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    methods.setValue("parent", item.externalId, { shouldDirty: false });
    const siblings = data.original.filter((ac: any) => ac.parentExternalId === item.externalId);
    methods.setValue("selected", siblings, { shouldDirty: false });
    methods.setValue("selectedCopy", siblings, { shouldDirty: false });
  };

  useEffect(() => {
    if (parent) {
      handleParentSelection(data.original.find((ele) => ele.externalId === parent));
    }
  }, [parent]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <CoAViewer
          length={data.length}
          data={data}
          onItemClick={handleItemClick}
          onParentSelection={handleParentSelection}
        />
        <CoAParentSelector data={data.original} onSplit={handleSplitClick} onRemove={handleItemClick} />
      </form>
    </FormProvider>
  );
};

export default CoAEditor;
