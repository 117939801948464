import React, { useEffect } from "react";
import Loading from "components/Loading";
import useLocationRouting from "hooks/useLocationRouting";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useReportsStore } from "../../../../hooks/useReportsStore";

function ReportLocationFactory(): JSX.Element {
  useLocationRouting(true);
  return <Loading />;
}

export default ReportLocationFactory;
