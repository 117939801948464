import { IconRefreshDouble } from "components/Icons";
import { ReactNode } from "react";
import useQboAccountsQuery from "hooks/useQboAccountsQuery";
import { useLocationsStore } from "hooks/useLocationsStore";

const QboSettings = (): ReactNode => {
  const { currentLocation } = useLocationsStore();
  const { refetch } = useQboAccountsQuery(false);

  return (
    <div className={"qbo-settings-header-container"}>
      <div className={"qbo-settings-header"}>
        <div className={"qbo-settings-header-controls"}>
          <div className={"qbo-settings-header-controls-34204"}>
            <div className="qbo-settings-input-container">
              <label className="qbo-settings-label">QBO ID</label>
              <div className="qbo-settings-input-icon">
                <span className="visually-hidden">{currentLocation?.qboId}</span>
                <input
                  className="input-qbo"
                  type="text"
                  disabled
                  name="qbo-id"
                  aria-autocomplete="none"
                  value={currentLocation?.qboId}
                />
                <button onClick={() => refetch()}>
                  <IconRefreshDouble />
                </button>
              </div>
            </div>
          </div>
          {/*{!!data && (*/}
          {/*  <div className={"qbo-settings-btn-container"}>*/}
          {/*    <button className={"qbo-settings-btn-container-close"}>CANCEL</button>*/}
          {/*    <button disabled className={"qbo-settings-btn-container-save"}>*/}
          {/*      SAVE*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  );
};

export default QboSettings;
