import React, { FormEvent, useMemo, useState } from "react";
import { HoneUserRoles } from "@hone-automation/common";
import Select from "react-select";
import { Control, Controller, RegisterOptions } from "react-hook-form";

interface RoleSelectorProps {
  control: Control<any>;
  name: string;
  rules: RegisterOptions;
  resetAfterSuccess?: boolean;
}

function RoleSelector(props: RoleSelectorProps) {
  const { resetAfterSuccess = true } = props;
  const options = React.useMemo(() => {
    return Object.values(HoneUserRoles).map((option) => ({
      label: option.split("_").join(" "),
      value: option,
    }));
  }, [JSON.stringify(HoneUserRoles)]);

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ field, formState: { errors, isSubmitSuccessful } }) => {
        return (
          <>
            <Select
              menuPlacement="auto"
              {...field}
              ref={(ref) => {
                if (isSubmitSuccessful && resetAfterSuccess) {
                  ref?.clearValue();
                }
              }}
              options={options}
            />
            {errors?.[props.name] && (
              <p style={{ color: "red" }}>{(errors[props.name]!.message as string) ?? "Required"}</p>
            )}
          </>
        );
      }}
    />
  );
}

export default RoleSelector;
