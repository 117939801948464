import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import ReportTransactions, { matchFlaggedCell, ReportTransactionsProps } from "./ReportTransactions";
import { IconBack, IconClear, IconFlag, IconFlagFilled, IconForwardBtn } from "components/Icons";
import AskBookkeeperModal from "presentation/components/AskBookkeeper/Modal";
import { useFlaggedTransactions } from "hooks/flaggedTransactions/useFlaggedTransactions";
import { UIReportFlaggedCell, UIReportTransaction } from "types/askcustomer";
import { useQueryState } from "hooks/useQueryState";
import { useReportsStore } from "hooks/useReportsStore";
import { HoneReportTypes } from "../../constants";
import { format, parse } from "date-fns";

import { useFlaggedTransactionsQuery } from "hooks/flaggedTransactions";
import Whisper from "rsuite/Whisper";
import Tooltip from "rsuite/Tooltip";
import { AskRequest } from "@hone-automation/common";

import FlagAskRequest from "components/Table/FlagAskRequest";
import classNames from "classnames";
// binds modal to app mounting point
if (process.env.NODE_ENV !== "test") Modal.setAppElement("#modal");
// here we can extend props if needed
type DataRowModalProps = Omit<ReportTransactionsProps, "flagTransaction" | "audit" | "scrollTo">;
function DataRowModal(props: DataRowModalProps): JSX.Element {
  const { data: flaggedTransactions, refetch: refetchFlaggedCells } = useFlaggedTransactionsQuery();
  const selectedReport = useReportsStore((state) => state.selectedReport);

  const [flaggedTransaction, setFlaggedTrx] = useQueryState("flaggedTransaction");
  const [enableFlagCellView, setEnableFlagCellView] = useState<boolean>(false);
  const [prevFlaggedTrx, setPrevFlaggedTrx] = useState<string | null>(null);
  // There are internal actions that depend on this value being available on the store
  // when set to true, the modal will show change the tab to the ask customer flow
  const transactionBeingFlagged = useFlaggedTransactions((state) => state.transactionBeingFlagged);
  const setTransactionBeingFlagged = (trx: UIReportTransaction | UIReportFlaggedCell | undefined) =>
    useFlaggedTransactions.setState({ transactionBeingFlagged: trx });
  const handleCancelFlag = () => {
    setPrevFlaggedTrx(transactionBeingFlagged?.flagged?.id);
    setTransactionBeingFlagged(undefined);
    setFlaggedTrx(undefined);
  };
  const handleClose = () => {
    handleCancelFlag();
    props.handleClose();
  };
  const handleFlagTransaction = (trx: UIReportTransaction) => {
    setTransactionBeingFlagged(trx);
  };
  const { modalOpen, audit, date, dataRows } = props.modalState;
  const hasDataRowsOrAudit = dataRows?.length > 0;

  useEffect(() => {
    if (!audit && !hasDataRowsOrAudit && modalOpen) {
      // is flagging a cell
      setEnableFlagCellView(true);
    } else {
      setEnableFlagCellView(false);
    }
  }, [modalOpen]);

  const hasFlaggedTransactions = flaggedTransactions?.find((flagged: any) => {
    if ((flagged as AskRequest).type && (flagged as AskRequest).type === "Flagged Cell") {
      return matchFlaggedCell(flagged, date, props.modalState?.title, selectedReport);
    }

    return false;
  });

  useEffect(() => {
    if (flaggedTransaction && hasFlaggedTransactions?.id === flaggedTransaction) {
      setEnableFlagCellView(true);
    }
  }, [flaggedTransaction, hasFlaggedTransactions]);

  const handleFlagCell = (cell: UIReportFlaggedCell) => {
    setTransactionBeingFlagged(cell);
  };

  const handleBackBtn = () => {
    setPrevFlaggedTrx(transactionBeingFlagged?.flagged?.id);
    if (!audit && !hasDataRowsOrAudit && modalOpen) {
      handleClose();
    }

    if (flaggedTransaction) {
      setTransactionBeingFlagged(undefined);
      setEnableFlagCellView(false);
      setFlaggedTrx(undefined);
      return;
    }
    setEnableFlagCellView(false);
    handleCancelFlag();
  };

  const status = hasFlaggedTransactions?.status.toLowerCase() || "";

  return (
    <Modal id="data-row-modal" isOpen={modalOpen} onRequestClose={handleClose}>
      {(transactionBeingFlagged || enableFlagCellView) && (
        <div className="back">
          <button style={{ background: "transparent" }} onClick={handleBackBtn}>
            <IconBack />
          </button>
        </div>
      )}
      <div className="close">
        <Whisper speaker={<Tooltip>{!hasFlaggedTransactions ? "Flag this cell" : "Flagged cell"}</Tooltip>}>
          <button
            className={classNames("flag", { [status]: hasFlaggedTransactions })}
            onClick={() => setEnableFlagCellView(true)}
          >
            {hasFlaggedTransactions ? <IconFlagFilled /> : <IconFlag />}
          </button>
        </Whisper>
        <div className="divider" />

        <button style={{ background: "transparent" }} onClick={handleClose}>
          <IconClear />
        </button>
      </div>
      {props.modalState.modalOpen && (
        <div className="modal-header">
          <span className="title">{hasDataRowsOrAudit || flaggedTransaction ? "TRANSACTIONS" : "FLAG"}</span>
          <ol className="breadcrumb">
            {props.modalState.parent && (
              <>
                <li>{props.modalState.parent}</li>
                <li className="breadcrumb-separator">
                  <IconForwardBtn />
                </li>
              </>
            )}
            <li>{props.modalState.title}</li>
            {/* YTD */}
            {props.modalState?.date && props.modalState?.date.end === "" ? (
              <>
                <li className="breadcrumb-separator">
                  <IconForwardBtn />
                </li>
                <li>
                  {props.modalState?.date.start}{" "}
                  {selectedReport &&
                    selectedReport.type !== HoneReportTypes.ApAging &&
                    format(parse(selectedReport?.startDateText, "yyyy-MM-dd", new Date()), "yyyy")}
                </li>
              </>
            ) : (
              props.modalState?.date && (
                <>
                  <li className="breadcrumb-separator">
                    <IconForwardBtn />
                  </li>
                  <li>{format(parse(props.modalState?.date.end, "yyyy-MM-dd", new Date()), "MM/dd/yyyy")}</li>
                </>
              )
            )}
          </ol>
        </div>
      )}
      <TransitionGroup>
        <CSSTransition
          key={transactionBeingFlagged ? "componentA" : "componentB"}
          classNames={transactionBeingFlagged ? "swipe-left" : "swipe-right"}
          timeout={300}
        >
          <>
            {enableFlagCellView ? (
              <FlagAskRequest
                modalState={props.modalState}
                handleCancelFlag={handleClose}
                updateFlaggedCell={handleFlagCell}
                hasFlaggedTransactions={hasFlaggedTransactions}
              />
            ) : transactionBeingFlagged ? (
              <AskBookkeeperModal
                modalState={props.modalState}
                onCancel={handleCancelFlag}
                updateTransaction={handleFlagTransaction}
                transaction={transactionBeingFlagged}
                reportId={date ? date?.reportId : undefined}
              />
            ) : (
              <ReportTransactions
                {...props}
                scrollTo={prevFlaggedTrx}
                flagTransaction={handleFlagTransaction}
                audit={audit}
              />
            )}
          </>
        </CSSTransition>
      </TransitionGroup>
    </Modal>
  );
}
export default DataRowModal;
