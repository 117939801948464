import { create } from "zustand";
import { doDelete, doGet, doPost, reportingOrigin } from "../lib/utils";
import { subscribeWithSelector } from "zustand/middleware";
import { RemoteGetAuditReport } from "../data/usecases/remote-get-audit-report";
import { makeAuthorizeHttpClientDecorator } from "../main/factories/decorators";
import { GetAuditReport } from "../domain/usecases/get-audit-report";
import { makeApiUrl } from "../main/factories/http";

type ReportsErrors = "No reports found for location";

interface HoneReportsState {
  enableNewPlReportModal: boolean;
  allReports: HoneReportSummary[];
  auditReport: AuditReport[] | undefined | null;
  selectedReport: HoneReportSummary | undefined;
  setSelectedReport: (report: HoneReportSummary) => void;
  status: "idle" | "loading" | "error";
  error?: ReportsErrors;
  openDateRangeModal: boolean;
  setOpenDateRangeModal: (open: boolean) => void;
  timeframe: HoneReportTimeframe | undefined;
  setTimeframe: (timeframe: HoneReportTimeframe) => void;
}

const auditReport = new RemoteGetAuditReport(makeApiUrl("/auditReportData"), makeAuthorizeHttpClientDecorator());

export const useReportsStore = create(
  subscribeWithSelector<HoneReportsState>((set) => ({
    enableNewPlReportModal: false,
    allReports: [],
    auditReport: undefined,
    status: "idle",
    selectedReport: undefined,
    setSelectedReport: (report: HoneReportSummary) => set({ selectedReport: report }),
    dateRangeParams: undefined,
    openDateRangeModal: false,
    timeframe: undefined,
    setTimeframe: (timeframe: HoneReportTimeframe) => set({ timeframe }),
    setOpenDateRangeModal: (open: boolean) => set({ openDateRangeModal: open })
  }))
);

export const refreshAccounts = (locationId: string) => {
  const url = `${reportingOrigin()}/refreshAccounts`;
  return doPost(url, { locationId });
};

export const emailReportMetrics = (reportId: string, recipients: Array<any>, message: string) => {
  const url = `${reportingOrigin()}/sendReportEmail`;
  return doPost(url, { reportId, recipients, message });
};

export const generateReports = (
  location: HoneLocation,
  reportType: HoneReportType | undefined,
  reportTimeframe: HoneReportTimeframe | undefined,
  dateStr: string | undefined,
  endDateStr: string | undefined
) => {
  let generateReportsUrl = `${reportingOrigin()}/generateReports?locationId=${location.id}`;
  if (reportType) {
    generateReportsUrl += `&reportType=${encodeURIComponent(reportType)}`;
  }
  if (reportTimeframe) {
    generateReportsUrl += `&reportTimeframe=${encodeURIComponent(reportTimeframe)}`;
  }
  if (dateStr) {
    generateReportsUrl += `&dateStr=${dateStr}`;
  }
  if (endDateStr) {
    generateReportsUrl += `&endDateStr=${endDateStr}`;
  }
  return doGet(generateReportsUrl);
};

export const refreshReport = (reportIds: string[], opts?: { signal: AbortSignal }) => {
  const url = `${reportingOrigin()}/reports/refresh`;
  return doPost(url, { reports: reportIds }, opts);
};

export const deleteReport = (reportId: string) => {
  const url = `${reportingOrigin()}/deleteReport?reportId=${reportId}`;
  return doDelete(url);
};

export const getAuditReport = async (id: string): Promise<GetAuditReport.Model> => {
  const response = await auditReport.getAuditReportById({ id });
  useReportsStore.setState({ auditReport: response as AuditReport[] });
  return response;
};
