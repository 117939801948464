import React, { useMemo } from "react";
import Checkbox from "presentation/components/Checkbox/Checkbox";
import Select from "react-select";
import { useHoneChartOfAccounts } from "components/HoneReportTemplates";
import { syncToken, useLocationsStore } from "hooks/useLocationsStore";
import { useForm } from "react-hook-form";
import {
  dismissToast,
  doGet,
  doPost,
  FIVE_SECONDS,
  isProdHost,
  reportingOrigin,
  showToast,
  TEN_SECONDS,
  TOAST_DEFAULT,
  TOAST_ERROR,
  TOAST_SUCCESS
} from "lib/utils";

import { AccrualConfig, AccrualConfigData } from "@hone-automation/common";

import Loading from "components/Loading";
import { useGetAccrualsConfig } from "hooks/useAccrualsConfig";

interface SelectOption {
  name: keyof AccrualConfigData;
  label: string;
  options: Array<string> | "coa";
  multiple?: boolean;
}

const selects: SelectOption[] = [
  {
    name: "frequency",
    label: "Frequency",
    options: ["Bi-Weekly", "Weekly"]
  },
  {
    name: "defaultSearchIds",
    label: "Default search account",
    options: "coa"
  },
  {
    name: "liabilityId",
    label: "Accruals liability account",
    options: "coa"
  },
  {
    name: "replacedIds",
    label: "Accounts to be accrued",
    options: "coa",
    multiple: true
  }
];

interface Option {
  label: string;
  value: string | string[];
}

export const ChartOfAccountsSelect = ({
  selected,
  multiple,
  onChange
}: {
  selected: any;
  multiple?: boolean;
  onChange: any;
}) => {
  const currentLocationId = useLocationsStore((state) => state.currentLocationId);
  const { data: coa, isLoading } = useHoneChartOfAccounts(currentLocationId);
  if (isLoading) return <span>Loading...</span>;
  if (!coa) return null;

  const options: Option[] = coa.map((account: any) => ({
    value: account.Id,
    label: account.AcctNum ? `(${account.AcctNum}) ${account.Name}` : `${account.Name}`
  }));

  const value = multiple
    ? options.filter(({ value }) => selected?.includes(value))
    : options.find(({ value }) => selected === value);

  return (
    <Select
      isLoading={isLoading}
      placeholder="Select account..."
      isMulti={multiple}
      value={value}
      closeMenuOnSelect={!multiple}
      onChange={onChange}
      options={options}
    />
  );
};

function BookkeeperAccrualsSettingsForm() {
  const currentLocationId = useLocationsStore((state) => state.currentLocationId);

  const { data: accrualsConfig } = useGetAccrualsConfig(currentLocationId);

  const dataTransformed = useMemo(() => {
    const accruConfigData: AccrualConfigData | undefined =
      accrualsConfig && ((accrualsConfig as AccrualConfig)?.data as AccrualConfigData);
    const defaultSearchId =
      accruConfigData && accruConfigData.defaultSearchIds && accruConfigData.defaultSearchIds?.length > 0
        ? accruConfigData.defaultSearchIds[0]
        : "";
    return { ...accruConfigData, defaultSearchIds: defaultSearchId };
  }, [accrualsConfig]);

  const { watch, setValue, handleSubmit } = useForm<AccrualConfigData>({ values: dataTransformed as any });

  const { data: coa, isLoading, status, refetch } = useHoneChartOfAccounts(currentLocationId);

  const handleSyncToken = () => {
    if (!currentLocationId) {
      showToast("Invalid location", TOAST_ERROR, TEN_SECONDS);
      return;
    }
    const toastId = showToast("Syncing Token", TOAST_DEFAULT, TEN_SECONDS);
    syncToken(currentLocationId)
      .then((result: any) => {
        dismissToast(toastId);
        if (result && result.data === "ok") {
          showToast("Successfully synced token, good for 1 hour", TOAST_SUCCESS, FIVE_SECONDS);
          refetch();
        } else {
          showToast("Unable to sync token", TOAST_ERROR, TEN_SECONDS);
        }
      })
      .catch(function (error: any) {
        dismissToast(toastId);
        showToast("Error syncing token", TOAST_ERROR, TEN_SECONDS);
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (status === "error") {
    return (
      <div>
        QBO token has expired
        {!isProdHost() && (
          <button
            className="button button_outline mb-1"
            aria-label="Sync Token"
            type="button"
            onClick={() => handleSyncToken()}
          >
            Sync Token
          </button>
        )}
      </div>
    );
  }

  if (!coa) return null;

  const onSubmit = async (data: any) => {
    try {
      const payload = {
        ...data,
        defaultSearchIds: [data.defaultSearchIds]
      };
      await doPost(`${reportingOrigin()}/journal-entries/accruals/config`, {
        locationId: currentLocationId,
        data: payload
      });
      showToast("Accruals settings saved.", TOAST_SUCCESS, TEN_SECONDS);
    } catch (e) {
      showToast("Error saving accruals settings", TOAST_ERROR, TEN_SECONDS);
    }
  };

  return (
    <div className="d-flex justify-content-center mt-8 mb-4">
      <div className="mb-4" id="tab-accruals">
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className="BKForm-table">
            <tbody>
              <tr>
                <td>
                  <label className="mr-3">Payroll accruals active: </label>
                </td>
                <td>
                  <Checkbox checked={watch("enabled")} onChange={(_, checked) => setValue("enabled", checked)} />
                </td>
              </tr>
              <tr>
                <td>
                  <label className="mr-3">
                    Estimate using prior payroll
                    <br />
                    instead of actuals:{" "}
                  </label>
                </td>
                <td>
                  <Checkbox
                    checked={watch("usePriorPayroll") ?? false}
                    onChange={(_, checked) => setValue("usePriorPayroll", checked)}
                  />
                </td>
              </tr>
              {selects.map((select) => {
                return (
                  <tr key={select.name}>
                    <td>
                      <label className="mr-3">{select.label}</label>
                    </td>
                    <td style={{ width: "600px" }}>
                      {select.options === "coa" ? (
                        <ChartOfAccountsSelect
                          selected={watch(select.name)}
                          onChange={(option: any) => {
                            if (select.multiple && Array.isArray(option)) {
                              setValue(
                                select.name,
                                option.map((op) => op.value)
                              );
                            } else {
                              setValue(select.name, option.value);
                            }
                          }}
                          multiple={select.multiple}
                        />
                      ) : (
                        <Select
                          value={{ value: watch(select.name), label: watch(select.name) }}
                          onChange={(option) => {
                            setValue(select.name, option?.value);
                          }}
                          options={(select.options as Array<string>).map((option) => ({
                            label: option,
                            value: option
                          }))}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td />
                <td align="right">
                  <button className="button BKForm-btn-save" type="submit">
                    <span>Save</span>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
}

export default BookkeeperAccrualsSettingsForm;
