import { useEffect } from "react";
import { useReportsStore } from "hooks/useReportsStore";
import Loading from "components/Loading";
import { AppLayout, LocationsAutocomplete } from "presentation/components";

import { useNavigate } from "react-router-dom";
import { useLocationsStore } from "hooks/useLocationsStore";
import { useReportsQuery } from "hooks/reports/useReportsQuery";
import { useFlags } from "launchdarkly-react-client-sdk";

export const ReportViewFactory = () => {
  const { enableNewPLReportModal } = useFlags();

  const navigateTo = useNavigate();
  const currentLocationId = useLocationsStore((state) => state.currentLocationId);
  const reportsError = useReportsStore((state) => state.error);

  const { data: allReports } = useReportsQuery((reports) => reports);

  useEffect(() => {
    if (allReports && allReports.length) {
      const report = allReports[0];
      useReportsStore.setState({ selectedReport: enableNewPLReportModal ? undefined : report });
      navigateTo(`/app/location/${currentLocationId}/report/${report.id}`);
    }
  }, [allReports]);

  return (
    <AppLayout>
      <div className="report-view">
        <div className="report-view--header">
          <div />
          <div className="report-view--header__location">
            <LocationsAutocomplete />
          </div>
        </div>
        <div className="report-view-container center h-100">{reportsError ? reportsError : <Loading />}</div>
      </div>
    </AppLayout>
  );
};
