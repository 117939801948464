import { useMemo, useState, FormEvent, SyntheticEvent } from "react";
import { filter, find, findIndex, isEmpty } from "lodash";
import Loading from "components/Loading";
import "./BookkeeperForm.css";
import { useHoneLocationUsers } from "components/HoneLocationUsers";
import { IconDeleteOutline, IconEmailOutline } from "components/Icons";
import { HoneUserRoles } from "@hone-automation/common";
import { Can } from "@casl/react";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import {
  showToast,
  dismissToast,
  TOAST_DEFAULT,
  TOAST_SUCCESS,
  ONE_MINUTE,
  FIVE_SECONDS,
  TEN_SECONDS,
  TOAST_ERROR
} from "lib/utils";
import { useLocationsStore } from "hooks/useLocationsStore";
import { useAuthContext } from "context/useAuthContext";

function BookkeeperUsersForm(): JSX.Element {
  const auth = useAuthContext();
  const { status: locationsStatus } = useLocationsStore();
  const {
    allLocationUsers,
    setAllLocationUsers,
    currentLocationAbility,
    addLocationUser,
    updateLocationUser,
    removeLocationUser
  } = useHoneLocationUsers();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");

  const handleEmailChange = (e: FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value.trim());
  };

  const handlePasswordChange = (e: FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value);
  };

  const handleRoleChange = (e: FormEvent<HTMLSelectElement>) => {
    setRole(e.currentTarget.value);
  };

  const handleEmailOptOutChange = (e: FormEvent<HTMLInputElement>, email: string) => {
    const index = findIndex(allLocationUsers, { email });
    if (index >= 0) {
      updateUser(email, allLocationUsers[index].role, !allLocationUsers[index].emailOptOut);
    } else {
      showToast(`User not found: ${email}`, TOAST_ERROR, TEN_SECONDS);
    }
  };

  const handleRoleUpdate = (e: FormEvent<HTMLSelectElement>) => {
    const email = e.currentTarget.id;
    const newRole = e.currentTarget.value;
    const index = findIndex(allLocationUsers, { email });
    if (index >= 0) {
      updateUser(email, newRole, allLocationUsers[index].emailOptOut);
    } else {
      showToast(`User not found: ${email}`, TOAST_ERROR, TEN_SECONDS);
    }
  };

  const updateUser = (email: string, role: string, emailOptOut: boolean) => {
    const toastId = showToast("Updating user", TOAST_DEFAULT, ONE_MINUTE);
    updateLocationUser(email, role, emailOptOut)
      .then((result: any) => {
        if (result.data) {
          dismissToast(toastId);
          if (result.data.error === undefined) {
            const index = findIndex(allLocationUsers, { email });
            if (index >= 0) {
              const newLocationUsers = Array.from(allLocationUsers);
              newLocationUsers[index].role = role;
              newLocationUsers[index].emailOptOut = emailOptOut;
              setAllLocationUsers(newLocationUsers);
            }
          } else {
            showToast("Error trying to update user", TOAST_ERROR, TEN_SECONDS);
          }
        }
      })
      .catch((error) => {
        dismissToast(toastId);
        showToast(`Error trying to update user: ${error}`, TOAST_ERROR, TEN_SECONDS);
      });
  };

  const roleChoices = useMemo(() => {
    return Object.values(HoneUserRoles);
  }, []);

  const handleAddLocationUser = async (e: SyntheticEvent) => {
    const toastId = showToast("Adding user to this location, please wait a bit.", TOAST_DEFAULT, ONE_MINUTE);

    let roleStr: string = role;
    if (roleStr === undefined || roleStr === null || roleStr === "") {
      roleStr = HoneUserRoles.Bookkeeper;
    }

    // eslint-disable-next-line
    addLocationUser(email, "", roleStr).then((result: any) => {
      if (result.data) {
        if (result.data.result === "ok") {
          const userAlreadyAdded = find(allLocationUsers, { email });
          if (!userAlreadyAdded) {
            setAllLocationUsers([...allLocationUsers, result.data.userLocation]);
          }

          dismissToast(toastId);
          showToast("Successfuly added user.", TOAST_SUCCESS, FIVE_SECONDS);
        } else if (result && result.data.result && result.data.result.startsWith("User already created")) {
          const userAlreadyAdded = find(allLocationUsers, { email });
          if (!userAlreadyAdded) {
            setAllLocationUsers([...allLocationUsers, result.data.userLocation]);
          }

          dismissToast(toastId);
          showToast("User already created before, but has been linked to this location.", TOAST_SUCCESS, FIVE_SECONDS);
        } else {
          dismissToast(toastId);
          showToast("Error adding user", TOAST_ERROR, TEN_SECONDS);
        }
      }
    });
  };

  const handleRemoveLocationUser = async (e: FormEvent<HTMLButtonElement>) => {
    const remEmail = e.currentTarget.value;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3>Are you sure you want to remove '{remEmail}' from this location?</h3>
            <button className="button BKForm-btn-secondary" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button BKForm-btn"
              onClick={() => {
                onClose();

                const toastId = showToast(
                  "Removing user from this location, please wait a bit.",
                  TOAST_DEFAULT,
                  ONE_MINUTE
                );

                // eslint-disable-next-line
                removeLocationUser(remEmail).then((result: any) => {
                  if (result === "ok") {
                    const filteredUsers = filter(allLocationUsers, function (u) {
                      return u.email !== remEmail;
                    });
                    setAllLocationUsers(filteredUsers);

                    dismissToast(toastId);
                    showToast("Successfuly removed user.", TOAST_SUCCESS, FIVE_SECONDS);
                  }
                });
              }}
            >
              Remove User
            </button>
          </div>
        );
      }
    });
  };

  const handlePasswordReset = async (e: FormEvent<HTMLButtonElement>) => {
    const email = e.currentTarget.value;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h4 className="mb-8">Do you want to send a 'Set Password' email to: {email}?</h4>
            <button className="button BKForm-btn-secondary mr-2" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button BKForm-btn"
              onClick={() => {
                onClose();
                const toastId = showToast("Sending 'Set Password' email", TOAST_DEFAULT, TEN_SECONDS);
                auth
                  .sendPasswordResetEmail({ email })
                  .then(function () {
                    dismissToast(toastId);
                    showToast("Successfully send email", TOAST_SUCCESS, TEN_SECONDS);
                  })
                  .catch(function (error: any) {
                    dismissToast(toastId);
                    showToast(`Error sending password email, error=${error}`, TOAST_ERROR, TEN_SECONDS);
                  });
              }}
            >
              Send Email
            </button>
          </div>
        );
      }
    });
  };

  return (
    <div>
      <div className="BKForm-section d-flex justify-content-center mb-4">
        {locationsStatus === "loading" && (
          <div className="mr-3">
            <Loading loadingCircleClass="Loading-circle-small" />
          </div>
        )}
        {locationsStatus !== "loading" && !isEmpty(currentLocationAbility) && (
          <Can not I="read" a="User" ability={currentLocationAbility}>
            <div className="mt-4 mr-3">You do not have access to view/update this location's users.</div>
          </Can>
        )}

        {locationsStatus !== "loading" && !isEmpty(currentLocationAbility) && (
          <Can I="read" a="User" ability={currentLocationAbility}>
            <div
              className="mb-4"
              id="tab-settings"
              style={{ height: "80vh", overflowY: "scroll", overflowX: "hidden" }}
            >
              <div className="mb-2">Users:</div>
              <table className="mb-8">
                <tr>
                  <th className="BKForm-cell-header">Email</th>
                  <th className="BKForm-cell-header">Role</th>
                  <th className="BKForm-cell-header">
                    Email <br /> Opt Out
                  </th>
                  <th className="BKForm-cell-header">
                    Send Reset <br /> Email
                  </th>
                  <th className="BKForm-cell-header">
                    Remove <br /> User
                  </th>
                </tr>
                {allLocationUsers &&
                  allLocationUsers.map((user: HoneLocationUser) => (
                    <tr id={user?.email} className="BKForm-tr">
                      <td className="BKForm-cell">{user?.email}</td>
                      <td className="BKForm-cell">
                        <select id={user?.email} name="role" value={user?.role} onChange={(e) => handleRoleUpdate(e)}>
                          {roleChoices.map((roleChoice, index) => (
                            <option value={roleChoice} key={index}>
                              {roleChoice}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td className="BKForm-cell text-center">
                        <input
                          type="checkbox"
                          checked={user?.emailOptOut}
                          onClick={(e) => handleEmailOptOutChange(e, user.email)}
                        />
                      </td>
                      <td className="BKForm-cell text-center">
                        <button
                          className="button button_outline p-1"
                          type="button"
                          value={user?.email}
                          onClick={(e) => handlePasswordReset(e)}
                        >
                          <IconEmailOutline />
                        </button>
                      </td>
                      <td className="BKForm-cell text-center">
                        <button
                          className="button BKForm-btn-alert p-1"
                          type="button"
                          value={user?.email}
                          onClick={(e) => handleRemoveLocationUser(e)}
                        >
                          <IconDeleteOutline />
                        </button>
                      </td>
                    </tr>
                  ))}
              </table>

              <div className="line"></div>

              <form className="BKForm-form">
                <div className="mb-2">Add user:</div>
                <table className="mb-2">
                  <tbody>
                    <tr>
                      <td>
                        <label className="mr-3">Email: </label>
                      </td>
                      <td>
                        <input
                          type="text"
                          id="email"
                          data-testid="email"
                          name="email"
                          value={email}
                          placeholder="email"
                          className="mr-3"
                          onInput={handleEmailChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label className="mr-3">Role: </label>
                      </td>
                      <td>
                        <select id="role" name="role" value={role} onChange={handleRoleChange}>
                          {roleChoices.map((roleChoice, index) => (
                            <option value={roleChoice} key={index}>
                              {roleChoice}
                            </option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <button className="button BKForm-btn " type="button" onClick={(e) => handleAddLocationUser(e)}>
                    <span>Add</span>
                  </button>
                </div>
              </form>
            </div>
          </Can>
        )}
      </div>
    </div>
  );
}

export default BookkeeperUsersForm;
