import { find, isUndefined, omitBy } from "lodash";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import BookkeeperAccountsMultiSelect from "./BookkeeperAccountsMultiSelect";
import { isCustomCalculation } from "../../../../lib/templateUtils";

const Operations = {
  None: "",
  Sum: "sum",
  Minus: "minus",
  Div: "div"
} as Readonly<Record<string, string>>;

type Operation = keyof typeof Operations;

interface Props {
  section: FlatTemplateSection;
  calculatedSections: FlatTemplateSection[];
  calculatableAccounts: HoneAccount[];
  onCalculationsChange: (calculations: CustomCalculation[]) => void;
}

function isSectionOperand(operand: SectionOperand | AccountOperand): operand is SectionOperand {
  return (operand as SectionOperand).section !== undefined;
}

function isAccountOperand(operand: SectionOperand | AccountOperand): operand is AccountOperand {
  return (operand as AccountOperand).acct_num !== undefined;
}

function BookkeeperTemplateCalculationsForm({
  section,
  calculatedSections,
  calculatableAccounts,
  onCalculationsChange
}: Props): JSX.Element {
  // FIXME what should happen when we toggle this off but we've set a bunch of calculations?
  const [show, setShow] = useState(calculatedSections.length > 0);
  const emptyCalculation = [{ id: uuidv4(), title: section.title, sum: [] }];
  const initialCalculations = calculatedSections
    .filter((section) => isCustomCalculation(section.value))
    .map(({ title, value }) => ({ title, ...value }) as CustomCalculation);
  const [calculations, setCalculations] = useState<CustomCalculation[]>(
    initialCalculations.length > 0 ? initialCalculations : emptyCalculation
  );
  const initialMath = calculations.map((calc) => {
    if (calc.div) return Operations.Div;
    if (calc.minus) return Operations.Minus;
    // FIXME this assumes first input is always sum
    return Operations.None;
  });
  const [math, setMath] = useState<Operation[]>(initialMath.length > 0 ? initialMath : [Operations.None]);

  const findInitialChecked = (operands: Operand[]) => {
    return [...calculatableAccounts].filter((account) => {
      const operand = find(operands, (operand) => {
        if (isSectionOperand(operand)) {
          return account.id === operand.section || account.Name === operand.section;
        } else if (isAccountOperand(operand)) {
          return operand.acct_id ? account.AcctId === operand.acct_id : account.AcctNum === operand.acct_num;
        }
      });
      return operand;
    });
  };

  const handleCalculationsShow = (show: boolean) => {
    if (!show) {
      section.value = undefined;
    }
    setShow(show);
  };

  const handleCalculationsChange = (changes: Partial<CustomCalculation>, index: number) => {
    let newState: CustomCalculation[];

    const { minus, div } = changes;

    // allow optional and changing operand B
    const operandB = omitBy({ minus, div }, isUndefined);

    // TODO use a reducer?
    if (calculations.length === 0) {
      newState = [
        {
          id: changes.id || uuidv4(),
          title: section.title,
          sum: changes.sum || [],
          ...operandB
        }
      ];
    } else {
      newState = calculations.map((calculation, jndex) => {
        if (index !== jndex) return calculation;

        return {
          id: changes.id || calculations[index].id,
          title: changes.title || calculations[index].title,
          sum: changes.sum || calculations[index].sum,
          ...operandB
        };
      });
    }

    setCalculations(newState);
    onCalculationsChange(newState);
  };

  return (
    <>
      <div className="BKForm-section">
        <label className="BKForm-section-label">
          <input type="checkbox" checked={show} onChange={() => handleCalculationsShow(!show)} className="ml-2" />
          <span>Custom Calculation</span>
        </label>
      </div>
      {show &&
        calculations.length > 0 &&
        calculations.map((calc: CustomCalculation, index: number) => {
          const calculationSum = calc.sum && findInitialChecked(calc.sum);
          const calculationMinus = findInitialChecked((calc as Record<Operation, Operand[]>)[math[index]] || []);
          return (
            <div className="BKForm-section flex-gap" key={index}>
              <span className="padding-2">Sum(</span>
              {calculationSum && calculatableAccounts.length > 0 && (
                <BookkeeperAccountsMultiSelect
                  initialInputItems={calculatableAccounts}
                  initialSelectedItems={calculationSum}
                  onChange={(selectedAccounts) =>
                    handleCalculationsChange(
                      {
                        sum: selectedAccounts.map((account) => {
                          if (account.AcctNum) {
                            return { acct_num: account.AcctNum };
                          } else {
                            return { section: account.id || account.Name };
                          }
                        })
                      } as Partial<CustomCalculation>,
                      index
                    )
                  }
                />
              )}

              <span>)</span>
              <select
                value={math[index]}
                onChange={(e) => {
                  const prevOp = math[index];
                  setMath(math.map((m, jndex) => (0 === jndex ? e.currentTarget.value : m)));
                  handleCalculationsChange(
                    {
                      [prevOp]: undefined,
                      [e.currentTarget.value]: calc[prevOp]
                    } as Partial<CustomCalculation>,
                    index
                  );
                }}
              >
                <option value={Operations.None}></option>
                <option value={Operations.Minus}>-</option>
              </select>
              {math[index] && calculationMinus && (
                <BookkeeperAccountsMultiSelect
                  initialInputItems={calculatableAccounts}
                  // FIXME typing here is brittle because we can return a string from CustomCalculation index, but we are limiting
                  // to the Operations we list so be careful
                  initialSelectedItems={calculationMinus}
                  onChange={(selectedAccounts) =>
                    handleCalculationsChange(
                      {
                        [math[index]]: selectedAccounts.map((account) => {
                          if (account.AcctId) {
                            return { acct_id: account.AcctId };
                          } else if (account.AcctNum) {
                            return { acct_num: account.AcctNum };
                          } else {
                            return { section: account.id || account.Name };
                          }
                        })
                      } as Partial<CustomCalculation>,
                      index
                    )
                  }
                />
              )}
            </div>
          );
        })}
    </>
  );
}

export default BookkeeperTemplateCalculationsForm;
