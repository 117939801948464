import { HttpClient, HttpRequest, HttpResponse } from "data/protocols/http";
import { GetStorage } from "data/protocols/cache";

export class AuthorizeHttpClientDecorator implements HttpClient {
  constructor(
    private readonly getStorage: GetStorage,
    private readonly httpClient: HttpClient
  ) {}

  async request(data: HttpRequest, opts?: { signal: AbortSignal }): Promise<HttpResponse> {
    return this.httpClient.request(data, opts);
  }
}
