import classNames from "classnames";
import { useMemo } from "react";
import { useActiveReportStore } from "hooks/useActiveReportStore";
import * as ReportUtils from "lib/reportUtils";
import { useReportsStore } from "hooks/useReportsStore";
import { formatDate } from "lib/utils";

interface FooterProps {
  elementCount?: number;
}

export default function Footer({ elementCount = 0 }: FooterProps): JSX.Element {
  const { reportInfo } = useReportsStore((state) => ({
    reportInfo: state.selectedReport
  }));

  const selectedReport = useReportsStore((state) => state.selectedReport);
  const { currentReport, status } = useActiveReportStore((state) => ({
    currentReport: state.currentReport,
    status: state.status
  }));

  const reportSections = useMemo(() => {
    return currentReport ? currentReport.sections : [];
  }, [currentReport]);

  const rows: any[] = currentReport ? ReportUtils.collectRows(Array.from(reportSections) as any) : [];
  const rowCount: number = rows.length;

  const getFormattedDate = (report: NestedHoneReport | HoneReportSummary | undefined) =>
    report ? formatDate(report.updatedAt, "MM/dd/yyyy hh:mm aa") : null;

  const updatedAt = getFormattedDate(
    currentReport?.updatedAt ? currentReport : selectedReport?.updatedAt ? selectedReport : reportInfo
  );

  return (
    <div className="Layout-footer-container">
      <div className={classNames("Layout-footer")}>
        {rowCount > 0 && <div className="footer-counter">{rowCount + " items"}</div>}
        <div className="footer-version">{__APP_VERSION__}</div>
        {updatedAt && (
          <div className="footer-timestamp">
            UPDATED: <span>{updatedAt}</span>
          </div>
        )}
      </div>
    </div>
  );
}
