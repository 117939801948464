import classNames from "classnames";
import Tooltip from "rsuite/Tooltip";
import Whisper from "rsuite/Whisper";

interface SideNavIconProps {
  Icon: string;
  title?: string;
  beta?: boolean;
  selected?: boolean;
  badge?: number;
  tooltip?: string;
}

export default function SideNavIcon({ Icon, title, beta, selected, badge, tooltip }: SideNavIconProps): JSX.Element {
  return (
    <Whisper
      placement="right"
      controlId="control-id-hover"
      trigger="hover"
      speaker={<Tooltip>{tooltip || title}</Tooltip>}
    >
      <div className={classNames("NB-item", { "NB-item-selected": selected, beta: beta })}>
        <div className={classNames("NB-container", { beta: beta })}>
          <div className="NB-icn-def">
            <Icon />
          </div>
          <span>{title || ""}</span>
        </div>
        {beta && <span className="NB-item NB-beta">Beta</span>}
        {badge && badge > 0 ? <span className="NB-item NB-badge">{badge}</span> : null}
      </div>
    </Whisper>
  );
}
