import { GetReportAttachments } from "domain/usecases/reports";
import { BaseHttpService } from "main/factories/http";

export class RemoteGetReportAttachments
  extends BaseHttpService<GetReportAttachments.Model>
  implements GetReportAttachments
{
  async getReportAttachments(body: GetReportAttachments.Params): Promise<GetReportAttachments.Model> {
    return await this.request("post", body);
  }
}
