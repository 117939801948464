import { useState } from "react";
import { DismissedBalanceSheetPayload } from "@hone-automation/common";
import classNames from "classnames";
import EChartsReact from "echarts-for-react";
import { useActiveReportStore } from "hooks/useActiveReportStore";
import Whisper from "rsuite/Whisper";
import Tooltip from "rsuite/Tooltip";

type SparklinesChartProps = {
  // dismissed: boolean | undefined;
  chartData: number[];
  // dismissPayload: DismissedBalanceSheetPayload;
};

export default function BarsChart({ chartData }: SparklinesChartProps) {
  const dismissSparklines = useActiveReportStore((state) => state.dismissSparklines);
  // const [innerDismissed, setInnerDismissed] = useState<boolean | undefined>(dismissed);
  const options = {
    animation: false,
    xAxis: {
      show: true,
      type: "category",
      silent: true,
      axisLine: {
        lineStyle: {
          color: "#CCC",
        },
      },
      axisLabel: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      silent: true,
      show: false,
      type: "value",
    },
    visualMap: {
      show: false,
      dimension: 1,
      pieces: [
        {
          lt: -100,
          color: "var(--ks-table-sparkline-negative)",  
        },
        {
          gt: 0,
          color: "var(--ks-table-sparkline-positive)",  //"rgba(62,99,120,0.33)",
        },
      ],
    },
    series: [
      {
        data: chartData,
        type: "bar",
        smooth: true,
        showSymbol: false,
        hoverAnimation: false,
      },
    ],
  };

  const isZero = chartData.every((element) => element === 0);

  if (isZero) {
    return null;
  }

  return (
    <Whisper placement="left" speaker={<Tooltip>Plot</Tooltip>}>
      <div
        className={classNames("hone-report-table--sparklines", {
          // "hone-report-table__sparklines_dismissed_overlay": innerDismissed,
        })}
        onClick={() => {
          // setInnerDismissed(!innerDismissed);
          // dismissSparklines(dismissPayload);
        }}
      >
        <EChartsReact opts={{ renderer: "svg" }} style={{ height: "100px", width: "100%" }} option={options} />
      </div>
    </Whisper>
  );
}
