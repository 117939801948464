import CoAItem from "./CoAItem";
import { prefixClass } from "lib/utils";
import { GetQboAccounts } from "domain/usecases/get-qbo-accounts";
import { ChangeEvent, createRef, ReactNode, SyntheticEvent, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { IconClear } from "../../../components/Icons";
import { Simulate } from "react-dom/test-utils";
import input = Simulate.input;
import { QboAccount } from "domain/models";

type CoAViewerProps = {
  length: number;
  data: any;
  onItemClick: (item: QboAccount) => void;
  onParentSelection: any;
};

function filterData(data: QboAccount[], search: string): QboAccount[] {
  const _search = search.toLowerCase();

  function filterItem(item: QboAccount): boolean {
    return (item.classification.toLowerCase().includes(_search) ||
      item.name.toLowerCase().includes(_search) ||
      item.glCode?.toLowerCase().includes(_search)) as boolean;
  }

  function recursiveFilter(items: QboAccount[]): QboAccount[] {
    return items
      .map((item) => {
        if (filterItem(item)) {
          return {
            ...item,
            children: item.children ? recursiveFilter(item.children) : []
          };
        } else if (item.children) {
          const filteredChildren = recursiveFilter(item.children);
          if (filteredChildren.length > 0) {
            return {
              ...item,
              children: filteredChildren
            };
          }
        }
        return null;
      })
      .filter((item) => item !== null) as QboAccount[];
  }

  const filteredData = recursiveFilter(data).sort((a, b) => {
    if (a.glCode && b.glCode) {
      return parseInt(a.glCode) - parseInt(b.glCode);
    }
    return 0;
  });

  return filteredData;
}

const CoAViewer = ({ length, data, onItemClick, onParentSelection }: CoAViewerProps): ReactNode => {
  const inputRef = createRef<HTMLInputElement>();
  const { watch, setValue } = useFormContext();
  const [search, setSearch] = useState<string>("");
  const prefix = prefixClass("coAEditor-content");

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const _search = search.toLowerCase();

  const filteredData = filterData(data.data, _search).sort((a: QboAccount, b: QboAccount) => {
    if (a.glCode && b.glCode) {
      return parseInt(a.glCode) - parseInt(b.glCode);
    }
    return -1;
  });

  const handleSplitClick = (e: SyntheticEvent<HTMLButtonElement>, item: any) => {
    e.stopPropagation();
    setValue("parent", item.parentExternalId);
  };

  const selected = watch("selected");

  const handleClear = () => {
    setSearch("");
    inputRef.current?.focus();
  };

  return (
    <div className={"coAEditor-container-items"}>
      <div className={prefix("header")}>
        <input
          className={"coAEditor-input"}
          ref={inputRef}
          value={search}
          placeholder="Search"
          onChange={handleSearch}
        />
        {search.length > 0 && (
          <button onClick={handleClear} className={prefix("header-btn")}>
            <IconClear />
          </button>
        )}
      </div>
      <div className={prefix("items")}>
        {filteredData.map((item: any) => {
          return (
            <CoAItem
              onParentSelection={onParentSelection}
              depth={item.depth}
              item={item}
              key={`item-${item.depth}-${item.externalId}`}
              isSelected={selected.findIndex((sel: any) => sel.externalId === item.externalId) > -1}
              isParent={watch("parent") === item.externalId}
              isChildren={!!item.parentExternalId}
              onClick={onItemClick}
              onSplitClick={handleSplitClick}
            />
          );
        })}
      </div>
      <p className={prefix("items-count")}>{`${search ? filteredData.length : length} ITEM(s)`}</p>
    </div>
  );
};

export default CoAViewer;
