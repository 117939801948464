import { useQuery } from "@tanstack/react-query";
import { makeRemoteGetReports } from "main/factories/usecases/reports/remote-get-reports";
import { useAuthContext } from "context/useAuthContext";
import { useLocationsStore } from "../useLocationsStore";

const reportsService = makeRemoteGetReports();

export const useReportsQuery = <TData = HoneReportSummary[]>(
  select?: (data: HoneReportSummary[] | undefined) => TData | undefined,
  mustIncludeId?: string,
  allReports: boolean = false
) => {
  const currentLocation = useLocationsStore.getState().currentLocation;
  const currentLocationId = currentLocation?.id;
  const { userId } = useAuthContext();
  return useQuery({
    // NOTE: don't change this queryKey, it is used like this to perform zustand subscription handler
    queryKey: ["reportsSummary", currentLocationId, allReports],
    queryFn: async ({ queryKey }) => {
      if (!userId || !currentLocationId) return;
      return (await reportsService.getAll({
        userId: userId as string,
        locationId: currentLocationId as string,
        mustIncludeId: (mustIncludeId as string) ?? undefined,
        allReports: queryKey[2] as boolean
      })) as HoneReportSummary[];
    },
    staleTime: 1000 * 60 * 5,
    enabled: !!userId && !!currentLocation,
    select
  });
};
