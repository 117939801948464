import { DeleteAuditReportItem } from "../../domain/usecases/delete-audit-report-item";
import { BaseHttpService } from "main/factories/http";

export class RemoteDeleteAuditReportItem
  extends BaseHttpService<DeleteAuditReportItem.Model>
  implements DeleteAuditReportItem
{
  async dismiss(body: DeleteAuditReportItem.Params): Promise<DeleteAuditReportItem.Model> {
    return await this.request("post", {
      ...body,
      toDismiss: body.toDismiss.map((ele) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete ele.id;
        return ele;
      })
    });
  }
}
