import classNames from "classnames";
import { IconNext, IconPrev } from "components/Icons";
import { Category } from "domain/models";
import { useState } from "react";

export const categoriesColor = [
  "blue",
  "teal",
  "turquoise",
  "purple",
  "orange",
  "darkerblue",
  "red",
  "green",
  "darkgreen",
  "black",
];

export const transformedCategoriesColor = {
  blue: "var(--ks-chart-primary)",
  teal: "#89DBF6",
  turquoise: "#75C5CF",
  purple: "#757FCE",
  orange: "#F19949",
  darkerblue: "#2d9cdb",
  red: "#a81b4e",
  green: "#32B161",
  darkgreen: "#134531",
  black: "#000000",
};

interface CategoriesContainerProps {
  activeCategory: Category | undefined;
  categories: string[];
  onCategoryChange: (category: Category) => void;
}

export default function CategoriesContainer({
  activeCategory,
  categories,
  onCategoryChange,
}: CategoriesContainerProps): JSX.Element {
  const showCategories = categories.map((category: string, index: number) => ({
    name: category,
    color: categoriesColor[index],
  }));
  const [visibleCategories, setVisibleCategories] = useState([0, 4]);
  const categoryBtnVisible = categories.length > 4;
  const handleCategorySlider = (direction: "left" | "right") => {
    if (direction === "left") {
      if (visibleCategories[0] === 0) {
        return;
      }
      setVisibleCategories((visibleCategories) => visibleCategories.map((value) => value - 1));
    }
    if (direction === "right") {
      if (visibleCategories[visibleCategories.length - 1] === categories.length) return;
      setVisibleCategories((visibleCategories) => visibleCategories.map((value) => value + 1));
    }
  };
  return (
    <div className="categories-container">
      {categoryBtnVisible && (
        <button className="category-btn" onClick={() => handleCategorySlider("left")}>
          <IconPrev />
        </button>
      )}
      <div className="categories">
        {showCategories.slice(...visibleCategories).map((category: any) => (
          <button
            key={category.name}
            onClick={() => onCategoryChange(category)}
            className={classNames({
              [category.color]: true,
              active: activeCategory?.name === category.name || activeCategory?.name === `${category.name}\t`,
            })}
          >
            {category.name.replace("->", "")}
          </button>
        ))}
      </div>
      {categoryBtnVisible && (
        <button className="category-btn" onClick={() => handleCategorySlider("right")}>
          <IconNext />
        </button>
      )}
    </div>
  );
}
