import { IconCalendar, IconCustomerRequest, IconRange } from "components/Icons";

import { useEffect, useMemo, useState } from "react";
import KSCheckBox from "../KSCheckBox/KSCheckBox";
import KSListItem from "../KSListItem";
import KSButton from "../KSButton";

import { Option } from "types";
import { useReportsStore } from "hooks/useReportsStore";
import Modal from "react-modal";
import { useQueryState } from "hooks/useQueryState";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { stringify, parse as qsparse } from "querystring";

import { reportTimeframeOptions, reportTimeframeOptionsComparePrior } from "./utils";
import { ComparisonType, ReportRequest, ReportTimeframe } from "@hone-automation/common";
import KSDropdown from "../KSDropdown";
import { useLocationsStore } from "hooks/useLocationsStore";
import { shallow } from "zustand/shallow";

import { format } from "date-fns-tz";

import { convertTimeframeToReportType, getTransformTimeFrame } from "lib/reportUtils";

import "./ReportPLDatesModal.scss";
import KSInputNumberAutoincrease from "../KSInputNumberAutoincrease";
import { generateRanges } from "@hone-automation/common/lib/reportUtil";
import { StartEndDates } from "@hone-automation/common/lib/Reports";
import {
  FIVE_SECONDS,
  formatDateStringToUSFormat,
  generateNotAllowedUrlAggregateSideBySide,
  showToast
} from "lib/utils";
import { QueryParamsPayload } from "domain/models";
import { useGetReportConfiguration } from "hooks";
import classNames from "classnames";

type ReportPLDatesModalProps = {
  availableTemplates: HoneReportSummary[];
};

function ReportPLDatesModal({ availableTemplates }: ReportPLDatesModalProps) {
  const navigateTo = useNavigate();
  const { openDateRangeModal, setOpenDateRangeModal, selectedReport } = useReportsStore();
  // get reports configuration
  const { data: reportsConfiguration } = useGetReportConfiguration();

  const { locationId } = useParams();
  const [dateRange] = useQueryState<string>("dateRange");
  //get params from url
  const [templateId] = useQueryState<string>("templateId");
  const [customDateRange, setCustomDateRange] = useQueryState<string>("customDateRange");
  const [total] = useQueryState<string>("total");
  const [difference] = useQueryState<string>("difference");
  const [breakdownPeriods] = useQueryState<string>("breakdownPeriods");
  const [reverse] = useQueryState<string>("reverse");
  const [budgetInclude] = useQueryState<string>("budgetInclude");
  const [comparisonType] = useQueryState<string>("comparisonType");
  const [comparisonPeriods] = useQueryState<string>("comparisonPeriods");
  const [consolidated] = useQueryState<string>("consolidated");
  const [timeframe] = useQueryState<string>("timeframe");
  const [validate] = useQueryState<string>("validate");
  const [compareLocations] = useQueryState<string>("compareLocations");
  const [localTemplateId, setLocalTemplateId] = useState<string>(templateId);
  const [compareYTDEnabled, setCompareYTDEnabled] = useQueryState("compareYTDEnabled");
  const [optionsReportOptionsAvailables, setoptionsReportOptionsAvailables] = useState([] as Option[]);
  const [compareEnabled, setCompareEnabled] = useQueryState<string>("compareEnabled");
  const isCompareYTDEnabled = compareYTDEnabled === "true";
  const isCompareEnabled = compareEnabled === "true";
  const isCustomDateRange = customDateRange === "true";
  const { currentLocation } = useLocationsStore(
    (state) => ({ currentLocationId: state.currentLocationId, currentLocation: state.currentLocation }),
    shallow
  );

  const currentTemplate: HoneReportSummary | undefined = useMemo(() => {
    const currentTemplate = availableTemplates.find((avTemplate) => avTemplate.templateId === localTemplateId);
    return currentTemplate;
  }, [localTemplateId]);
  const fiveYearsAgo = format(new Date(new Date().setFullYear(new Date().getFullYear() - 5)), "yyyy-MM-dd");

  const genDateRanges = (selectedTimeFrame: ReportTimeframe, limit: number) => {
    const newDateRanges: StartEndDates[] = generateRanges(
      selectedTimeFrame,
      currentLocation?.weekStart,
      fiveYearsAgo,
      limit,
      undefined,
      reportsConfiguration
    );

    return newDateRanges;
  };

  const initialStartDate = useMemo(() => {
    if (comparisonPeriods && !dateRange) {
      const newDateRanges: StartEndDates[] = genDateRanges("Week" as ReportTimeframe, 1);
      return newDateRanges[0].start;
    }
    return dateRange ? dateRange.split(",")[0] : optionsReportOptionsAvailables[0]?.value.split(" - ")[0];
  }, [comparisonPeriods, dateRange, optionsReportOptionsAvailables]);

  const initialEndDate = useMemo(() => {
    if (comparisonPeriods && !dateRange) {
      const newDateRanges: StartEndDates[] = genDateRanges("Week" as ReportTimeframe, 1);
      return newDateRanges[0].end;
    }
    return dateRange ? dateRange.split(",")[1] : optionsReportOptionsAvailables[0]?.value.split(" - ")[1];
  }, [comparisonPeriods, dateRange, optionsReportOptionsAvailables]);

  const isAggrSideBySide =
    compareLocations && (!consolidated || consolidated === "false") ? compareLocations?.split(",").length > 1 : false;

  const { handleSubmit, reset, watch } = useForm<ReportRequest>({
    defaultValues: {
      locations: [],
      timeframe: (timeframe as ReportTimeframe) || "Week",
      baseId: "",
      type: "P&L Comparison",
      startDate: initialStartDate,
      endDate: initialEndDate,
      limit: 0,
      templateId: templateId || availableTemplates[0]?.templateId,
      total: total && !isAggrSideBySide ? total === "true" : false,
      difference: difference && !isAggrSideBySide ? difference === "true" : false,
      comparison: {
        comparisonType: comparisonType as ComparisonType,
        comparisonPeriods: comparisonPeriods ? parseInt(comparisonPeriods) : 1
      },
      budgets: {
        include: budgetInclude ? budgetInclude === "true" : false
      },
      reverse: reverse && !isAggrSideBySide ? reverse === "true" : false,
      breakdownPeriods: breakdownPeriods && !isAggrSideBySide ? breakdownPeriods === "true" : false,
      consolidated: consolidated ? consolidated === "true" : false
    }
  });

  const selectedTimeFrame = watch("timeframe");
  useEffect(() => {
    if (!currentTemplate || selectedTimeFrame === "Year to Date" || !selectedTimeFrame) return;
    const newDateRanges: StartEndDates[] = genDateRanges(selectedTimeFrame, 25);
    //reset start and endate dropdowns
    const newStartDate =
      dateRange && currentTemplate?.templateId === templateId ? dateRange.split(",")[0] : newDateRanges[0].start;
    const newEndDate =
      dateRange && currentTemplate?.templateId === templateId ? dateRange.split(",")[1] : newDateRanges[0].end;

    reset({
      ...watch(),
      startDate: newStartDate,
      endDate: newEndDate
    });

    setoptionsReportOptionsAvailables(
      newDateRanges.map((dateRange) => {
        return {
          value: `${dateRange.start} - ${dateRange.end}`,
          label: `${formatDateStringToUSFormat(dateRange.start)} - ${formatDateStringToUSFormat(dateRange.end)}`
        };
      })
    );
  }, [selectedTimeFrame, currentTemplate, isCustomDateRange]);

  //reset start and endate when custom date range is disabled
  useEffect(() => {
    if (!customDateRange) return;
    if (!isCustomDateRange) {
      reset({
        ...watch(),
        startDate:
          optionsReportOptionsAvailables && optionsReportOptionsAvailables.length > 1
            ? optionsReportOptionsAvailables[0].value.split(" - ")[0]
            : initialStartDate,
        endDate:
          optionsReportOptionsAvailables && optionsReportOptionsAvailables.length > 1
            ? optionsReportOptionsAvailables[0].value.split(" - ")[1]
            : initialEndDate
      });
    }
  }, [isCustomDateRange]);

  const startReportOptionsAvailables = useMemo(() => {
    return optionsReportOptionsAvailables?.map((option) => {
      return {
        value: option.value.split(" - ")[0],
        label: formatDateStringToUSFormat(option.value.split(" - ")[0])
      };
    });
  }, [optionsReportOptionsAvailables, currentTemplate, isCustomDateRange]);

  const endReportOptionsAvailables = useMemo(() => {
    //filter the end date options based on the selected start date
    if (watch("startDate") === startReportOptionsAvailables[0]?.value.split(" - ")[0]) {
      return new Array(1).fill(
        optionsReportOptionsAvailables.map((option) => {
          return {
            value: option.value.split(" - ")[1],
            label: formatDateStringToUSFormat(option.value.split(" - ")[1])
          };
        })[0]
      );
    }

    const newEndReportOptionsAvailables = [...optionsReportOptionsAvailables]
      ?.filter((option) => {
        return new Date(option.value.split(" - ")[0]) >= new Date(String(watch("startDate")));
      })
      .map((option) => {
        return {
          value: option.value.split(" - ")[1],
          label: formatDateStringToUSFormat(option.value.split(" - ")[1])
        };
      });
    return newEndReportOptionsAvailables;
  }, [optionsReportOptionsAvailables, watch("startDate"), currentTemplate, isCustomDateRange]);

  const onSelectOption = (option: string) => {
    setLocalTemplateId(option);
  };

  const toggleModal = () => {
    setOpenDateRangeModal(!openDateRangeModal);
  };

  const onSubmit = () => {
    const { startDate, endDate } = watch();
    if (startDate && endDate) {
      const currentTemplateId = localTemplateId || selectedReport?.templateId;
      const currentReport = availableTemplates.find((avTemplate) => {
        return avTemplate.templateId === localTemplateId;
      });

      const _templateId = currentTemplateId;

      const queryParams = qsparse(location.search.replace("?", ""));
      const newQueries: any = {
        ...queryParams,
        dateRange: `${watch("startDate")},${watch("endDate")}`,
        templateId: _templateId,
        total: String(watch("total")),
        difference: String(watch("difference")),
        timeframe: watch("timeframe"),
        breakdownPeriods: String(watch("breakdownPeriods")),
        reverse: String(watch("reverse")),
        budgetInclude: String(watch("budgets.include")),
        type: currentReport?.type,
        shouldReload: "true"
      };

      if (!isCustomDateRange && (isCompareEnabled || isCompareYTDEnabled)) {
        newQueries.comparisonType = watch("comparison.comparisonType");
        newQueries.comparisonPeriods = watch("comparison.comparisonPeriods");
      } else {
        delete newQueries.comparisonType;
        delete newQueries.comparisonPeriods;
      }

      //validation enabled delete
      if (validate) {
        delete newQueries.validate;
      }

      const newQueriesAggregateSideBySide: QueryParamsPayload | null = generateNotAllowedUrlAggregateSideBySide(
        compareLocations!,
        queryParams,
        consolidated
      );

      if (newQueriesAggregateSideBySide) {
        showToast(
          "Some settings have been turned off to enable side-by-side comparisons. Please re-configure as needed.",
          "warning",
          FIVE_SECONDS
        );
        if (currentLocation?.id && newQueriesAggregateSideBySide) {
          navigateTo({
            pathname: `/app/location/${locationId}/report/weekly`,
            search: `?${stringify(newQueriesAggregateSideBySide as any)}`
          });
          useReportsStore.setState({ selectedReport: undefined });
        }
        return;
      }

      const convertedReportType = convertTimeframeToReportType(currentReport?.timeframe);
      if (currentLocation?.id && newQueries) {
        navigateTo({
          pathname: `/app/location/${locationId}/report/${convertedReportType}`,
          search: `?${stringify(newQueries)}`
        });
        useReportsStore.setState({ selectedReport: undefined });
      }
      toggleModal();
    }
  };

  const dateRangeTitle = useMemo(() => {
    if (dateRange) {
      return (
        <>
          <span>{formatDateStringToUSFormat(watch("startDate"))}</span> <IconRange />{" "}
          <span>{formatDateStringToUSFormat(watch("endDate"))}</span>
        </>
      );
    }
    return null;
  }, [watch("startDate"), watch("endDate")]);

  const selectedTemplate = availableTemplates.find((avTemplate) => avTemplate.templateId === templateId);

  const periodTitle = useMemo(() => {
    if (!selectedTemplate) return availableTemplates[0]?.title;

    return `${selectedTemplate?.title}`;
  }, [selectedTemplate]);

  const handleToggleCustomDateRange = () => {
    const newCustomDateRange = customDateRange === "true" ? "false" : "true";

    //reset values depending on the custom date range
    if (newCustomDateRange === "false") {
      reset({
        ...watch(),
        total: false,
        difference: false,
        breakdownPeriods: false,
        reverse: false,
        budgets: {
          include: false
        },
        comparison: {
          comparisonType: "Prior Period",
          comparisonPeriods: 1
        }
      });
    } else {
      reset({
        ...watch(),
        comparison: {
          comparisonType: "Prior Period",
          comparisonPeriods: 0
        },
        difference: false,
        reverse: false,
        budgets: {
          include: false
        }
      });
    }
    setCustomDateRange(newCustomDateRange);
  };

  useEffect(() => {
    const innerComparisonPeriods = watch("comparison.comparisonPeriods");
    if (innerComparisonPeriods === 0) {
      compareEnabled === "true" && setCompareEnabled("false");
      compareYTDEnabled === "true" && setCompareYTDEnabled("false");
    }
  }, [watch("comparison.comparisonPeriods")]);

  const handlePeriodChange = (periodOption: Option) => {
    const startDateString = periodOption.value.split(" - ")[0];
    const endDateString = periodOption.value.split(" - ")[1];
    reset({
      ...watch(),
      startDate: startDateString,
      endDate: endDateString
    });
  };

  const handleStartWeekChange = (periodOption: Option) => {
    const startDateString = periodOption.value;
    const endDateString = optionsReportOptionsAvailables
      .find((option) => option.value.split(" - ")[0] === startDateString)
      ?.value.split(" - ")[1];

    reset({
      ...watch(),
      startDate: startDateString,
      endDate: endDateString ? endDateString : ""
    });
  };

  const handleEndWeekChange = (periodOption: Option) => {
    const endDateString = periodOption.value;
    reset({
      ...watch(),
      endDate: endDateString
    });
  };

  const handleComparisonChange = (comparisonValue: number | undefined) => {
    reset({
      ...watch(),
      comparison: {
        ...watch("comparison"),
        comparisonPeriods: comparisonValue
      }
    });
  };
  //validate value comparison variables
  useEffect(() => {
    if (watch("comparison.comparisonPeriods")! > 1) {
      reset({
        ...watch(),
        difference: false
      });
    }
  }, [watch("comparison.comparisonPeriods")]);
  const reportTimeframeOptionsComparePriorFiltered = useMemo(() => {
    const localTimeframe = watch("timeframe") as ReportTimeframe;
    if (localTimeframe) {
      const isWeekly = localTimeframe === "Week";
      const isMonthly = localTimeframe === "Month";
      const isQuarterly = localTimeframe === "Quarter";

      if (isWeekly) {
        return reportTimeframeOptionsComparePrior.filter((ro) => ro.label !== "Months" && ro.label !== "Quarters");
      }
      if (isMonthly) {
        return reportTimeframeOptionsComparePrior.filter((ro) => ro.label !== "Weeks" && ro.label !== "Quarters");
      }
      if (isQuarterly) {
        return reportTimeframeOptionsComparePrior.filter((ro) => ro.label !== "Weeks" && ro.label !== "Months");
      }

      return null;
    }
  }, [watch("timeframe")]);

  const regenerateDateRanges = () => {
    if (selectedTimeFrame === "Year to Date") return;
    const lastDateGenerated = {
      start: String(optionsReportOptionsAvailables[optionsReportOptionsAvailables.length - 1]?.value.split(" - ")[0]),
      end: String(optionsReportOptionsAvailables[optionsReportOptionsAvailables.length - 1]?.value.split(" - ")[1])
    };

    const newDateRanges: StartEndDates[] = generateRanges(
      selectedTimeFrame as ReportTimeframe,
      currentLocation?.weekStart,
      fiveYearsAgo,
      25,
      lastDateGenerated,
      reportsConfiguration
    );

    const newList = newDateRanges
      .map((dateRange) => {
        return {
          value: `${dateRange.start} - ${dateRange.end}`,
          label: `${formatDateStringToUSFormat(dateRange.start)} - ${formatDateStringToUSFormat(dateRange.end)}`
        };
      })
      .concat(optionsReportOptionsAvailables)
      .sort((a, b) => {
        return new Date(b.value.split(" - ")[0]).getTime() - new Date(a.value.split(" - ")[0]).getTime();
      });

    setoptionsReportOptionsAvailables(newList);
  };

  const handleCompareEnabled = () => {
    reset({
      ...watch(),
      budgets: {
        include: false
      },
      difference: false,
      comparison: {
        comparisonType:
          currentTemplate?.type === "Income Statement" || selectedTimeFrame === "Year"
            ? "Year Over Year"
            : "Prior Period",
        comparisonPeriods: 1
      }
    });
    setCompareEnabled("true");
  };

  const reportTimeframeCompareValueSelected = useMemo(() => {
    if (watch("comparison.comparisonType") === "Prior Period") {
      return {
        value: "Prior Period",
        label: `${selectedTimeFrame}s`
      };
    }
    return reportTimeframeOptionsComparePrior.find((rp) => rp.value === watch("comparison.comparisonType"));
  }, [watch("comparison.comparisonType"), selectedTimeFrame]);

  const renderDropdownReports = () => {
    return (
      <div className="modal-time-frame-content">
        {!isCompareEnabled ? (
          <div className="modal-time-frame-content__compare">
            {optionsReportOptionsAvailables &&
              optionsReportOptionsAvailables.length > 1 &&
              initialStartDate &&
              selectedTimeFrame !== "Year to Date" && (
                <KSDropdown
                  options={optionsReportOptionsAvailables as Option[]}
                  value={{
                    value: `${watch("startDate")} - ${watch("endDate")}`,
                    label: `${formatDateStringToUSFormat(watch("startDate"))} - ${formatDateStringToUSFormat(watch("endDate"))}`
                  }}
                  label="Period"
                  onSelect={handlePeriodChange}
                  enableScrollReload
                  reloadNewItemsFn={regenerateDateRanges}
                />
              )}
            <div className="modal-time-frame-content__compare_btn">
              <KSButton
                label="Compare"
                color="secondary"
                size="medium"
                variant="contained"
                type="button"
                onClick={handleCompareEnabled}
              />
            </div>
          </div>
        ) : (
          <>
            {optionsReportOptionsAvailables && optionsReportOptionsAvailables.length > 1 && (
              <KSDropdown
                options={optionsReportOptionsAvailables as Option[]}
                value={{
                  value: `${watch("startDate")} - ${watch("endDate")}`,
                  label: `${formatDateStringToUSFormat(watch("startDate"))} - ${formatDateStringToUSFormat(watch("endDate"))}`
                }}
                label="Period"
                onSelect={handlePeriodChange}
                enableScrollReload
                reloadNewItemsFn={regenerateDateRanges}
              />
            )}
            <div className="modal-time-frame-content-icon-container"><IconRange /></div>

            <KSInputNumberAutoincrease
              onChangeInput={() => {
                // TODO implement on change input
              }}
              value={watch("comparison.comparisonPeriods")}
              onDecrease={() => {
                const comparisonPeriods = watch("comparison.comparisonPeriods");
                handleComparisonChange(comparisonPeriods! - 1);
              }}
              onIncrease={() => {
                const comparisonPeriods = watch("comparison.comparisonPeriods") || 1;
                handleComparisonChange(comparisonPeriods + 1);
              }}
              disabledDecrease={watch("comparison.comparisonPeriods") === 0}
              disabledIncrease={watch("comparison.comparisonPeriods") === 13}
              label="Compare to Prior"
            />
            <div className={classNames("modal-time-frame-content__compare-dropdown",{"one-line": !reportTimeframeOptionsComparePriorFiltered})}>
              {reportTimeframeOptionsComparePriorFiltered ? (
                <KSDropdown
                  options={reportTimeframeOptionsComparePriorFiltered}
                  value={reportTimeframeCompareValueSelected!}
                  onSelect={(option) => {
                    reset({
                      ...watch(),
                      comparison: {
                        ...watch("comparison"),
                        comparisonType: option.value as ComparisonType
                      }
                    });
                  }}
                  placeholder="Monthly"
                />
              ) : (
                <span className="ks-input-number-unit">YEARS</span>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const reportTimeframeOptionsFiltered = useMemo(() => {
    if (currentTemplate) {
      const isWeekly = currentTemplate.timeframe === "Weekly";
      const isMonthly = currentTemplate.timeframe === "Monthly";

      if (isWeekly) {
        return reportTimeframeOptions.filter((ro) => ro.value === "Week");
      }
      if (isMonthly) {
        return reportTimeframeOptions.filter((ro) => ro.value !== "Week");
      }

      return reportTimeframeOptions.filter((ro) => ro.value !== "Week");
    }
  }, [currentTemplate]);

  const handleClickListItem = (template: HoneReportSummary) => {
    const clickedTemplateId = template.templateId;
    if (clickedTemplateId) {
      onSelectOption(clickedTemplateId);
      setCompareEnabled("false");
      setCustomDateRange("false");

      reset({
        ...watch(),
        startDate:
          startReportOptionsAvailables && startReportOptionsAvailables.length > 0
            ? startReportOptionsAvailables[0].value
            : initialStartDate,
        endDate:
          endReportOptionsAvailables && endReportOptionsAvailables.length > 0
            ? endReportOptionsAvailables[0].value
            : initialEndDate,
        timeframe: getTransformTimeFrame(template?.timeframe as HoneReportTimeframe) as ReportTimeframe,
        total: false,
        difference: false,
        breakdownPeriods: false,
        reverse: false,
        budgets: {
          include: false
        },
        comparison: {
          comparisonType: "Prior Period",
          comparisonPeriods: 0
        }
      });
    }
  };

  const renderWeeklySelectors = () => {
    if (isCustomDateRange) {
      return (
        <>
          {startReportOptionsAvailables && startReportOptionsAvailables.length > 0 && (
            <KSDropdown
              options={startReportOptionsAvailables as Option[]}
              value={{
                value: `${watch("startDate")}`,
                label: `${formatDateStringToUSFormat(watch("startDate"))}`
              }}
              label="Start Date"
              onSelect={handleStartWeekChange}
              enableScrollReload
              reloadNewItemsFn={regenerateDateRanges}
              enableMinWidth={true}
            />
          )}
          <div className="modal-time-frame-content-icon-container"><IconRange /></div>
          {endReportOptionsAvailables && endReportOptionsAvailables.length > 0 && (
            <KSDropdown
              options={endReportOptionsAvailables as Option[]}
              value={{
                value: `${watch("endDate")}`,
                label: `${formatDateStringToUSFormat(watch("endDate"))}`
              }}
              label="End date"
              onSelect={handleEndWeekChange}
              disabled={!watch("startDate")}
              enableMinWidth={true}
            />
          )}
        </>
      );
    }
    return renderDropdownReports();
  };

  const renderYTDCompareSelectors = () => {
    return (
      <>
        <div className="modal-time-frame-content-compare-btn">
          {!isCompareYTDEnabled && (
            <KSButton
              label="Compare"
              color="secondary"
              size="medium"
              variant="contained"
              type="button"
              onClick={() => {
                reset({
                  ...watch(),
                  comparison: {
                    comparisonType: "Year Over Year",
                    comparisonPeriods: 1
                  }
                });
                setCompareYTDEnabled("true");
              }}
            />
          )}
        </div>
        {isCompareYTDEnabled && (
          <div className="modal-time-frame-content__compare-selector">
            <KSInputNumberAutoincrease
              onChangeInput={() => {
                // TODO implement on change input
              }}
              value={watch("comparison.comparisonPeriods")}
              onDecrease={() => {
                const comparisonPeriods = watch("comparison.comparisonPeriods");
                handleComparisonChange(comparisonPeriods! - 1);
              }}
              onIncrease={() => {
                const comparisonPeriods = watch("comparison.comparisonPeriods") || 1;
                handleComparisonChange(comparisonPeriods + 1);
              }}
              disabledDecrease={watch("comparison.comparisonPeriods") === 0}
              disabledIncrease={watch("comparison.comparisonPeriods") === 13}
              label="Compare to Prior"
            />
            {reportTimeframeOptionsComparePriorFiltered ? (
              <div className="modal-time-frame-content__compare-dropdown">
                {
                  reportTimeframeOptionsComparePrior.find((rp) => rp.value === watch("comparison.comparisonType"))
                    ?.label
                }
                <KSDropdown
                  options={reportTimeframeOptionsComparePriorFiltered}
                  value={{
                    label:
                      reportTimeframeOptionsComparePrior.find((rp) => rp.value === watch("comparison.comparisonType"))
                        ?.label || `${selectedTimeFrame}s`,
                    value:
                      reportTimeframeOptionsComparePrior.find((rp) => rp.value === watch("comparison.comparisonType"))
                        ?.label || `${selectedTimeFrame}s`
                  }}
                  onSelect={(option) => {
                    reset({
                      ...watch(),
                      comparison: {
                        ...watch("comparison"),
                        comparisonType: option.value as ComparisonType
                      }
                    });
                  }}
                  placeholder="Monthly"
                />
              </div>
            ) : (
              <span className="ks-input-number-unit">YEARS</span>
            )}
          </div>
        )}
      </>
    );
  };

  const renderMonthlySelectors = () => {
    if (isCustomDateRange) {
      return (
        <>
          {startReportOptionsAvailables && startReportOptionsAvailables.length > 0 && (
            <KSDropdown
              options={startReportOptionsAvailables as Option[]}
              value={{
                value: `${watch("startDate")}`,
                label: `${formatDateStringToUSFormat(watch("startDate"))}`
              }}
              label="Start Date"
              onSelect={handleStartWeekChange}
              enableScrollReload
              reloadNewItemsFn={regenerateDateRanges}
              enableMinWidth={true}
            />
          )}
          <div className="modal-time-frame-content-icon-container"><IconRange /></div>
          {endReportOptionsAvailables && endReportOptionsAvailables.length > 0 && (
            <KSDropdown
              options={endReportOptionsAvailables as Option[]}
              value={{
                value: `${watch("endDate")}`,
                label: `${formatDateStringToUSFormat(watch("endDate"))}`
              }}
              label="End date"
              onSelect={handleEndWeekChange}
              disabled={!watch("startDate")}
              enableMinWidth={true}
            />
          )}
        </>
      );
    }
    return renderDropdownReports();
  };

  const renderYearToDateSelectors = () => {
    if (isCustomDateRange) {
      return (
        <>
          {startReportOptionsAvailables && startReportOptionsAvailables.length > 0 && (
            <KSDropdown
              options={startReportOptionsAvailables as Option[]}
              value={{
                value: `${watch("startDate")}`,
                label: `${watch("startDate")}`
              }}
              label="Start Date"
              onSelect={handleStartWeekChange}
              enableScrollReload
              reloadNewItemsFn={regenerateDateRanges}
              enableMinWidth={true}
            />
          )}
          <div className="modal-time-frame-content-icon-container"><IconRange /></div>
          {endReportOptionsAvailables && endReportOptionsAvailables.length > 0 && (
            <KSDropdown
              options={endReportOptionsAvailables as Option[]}
              value={{
                value: `${watch("endDate")}`,
                label: `${watch("endDate")}`
              }}
              label="End date"
              onSelect={handleEndWeekChange}
              disabled={!watch("startDate")}
              enableMinWidth={true}
            />
          )}
        </>
      );
    }
    return renderDropdownReports();
  };

  const renderContentBasedOnTimeframe = {
    Weekly: renderWeeklySelectors(),
    Monthly: renderMonthlySelectors(),
    "YTD Monthly": renderYearToDateSelectors(),
    YTD: renderYearToDateSelectors()
  };

  //validate uncheck budget
  useEffect(() => {
    if (watch("total") === true) return;
    if (watch("difference") === true) return;
    if (watch("budgets.include") === false) {
      reset({
        ...watch(),
        total: false,
        difference: false
      });
    }
  }, [watch("budgets.include"), watch("total")]);

  const shouldDisabledApplyButton = useMemo(() => {
    const { startDate, endDate } = watch();
    if (startDate && endDate) {
      return false;
    }
    return true;
  }, [watch("startDate"), watch("endDate")]);

  const shouldDisabledBreakdownPeriods = useMemo(() => {
    if (isAggrSideBySide) {
      return true;
    }
    const isMonthlyOrWeekly = selectedTimeFrame === "Month" || selectedTimeFrame === "Week";
    if (watch("difference")) {
      return true;
    }
    if (isCustomDateRange) {
      return false;
    }

    if (isCompareEnabled) {
      return true;
    }

    if (isMonthlyOrWeekly) {
      return true;
    }

    return false;
  }, [selectedTimeFrame, isCustomDateRange, isCompareEnabled, watch("difference")]);

  const shouldDisabledBudgetInclude = useMemo(() => {
    const isWeekly = selectedTimeFrame === "Week";
    if (isWeekly) {
      return true;
    }
    if (isAggrSideBySide) {
      return false;
    }
    if (watch("total")) {
      return true;
    }
    return false;
  }, [selectedTimeFrame, watch("total")]);

  const shouldDisabledReverse = useMemo(() => {
    if (isAggrSideBySide || consolidated === "true") {
      return true;
    }
    if (consolidated === "true") {
      return false;
    }
    if (watch("comparison.comparisonPeriods")! === 1 && isCompareEnabled) {
      return true;
    }
    if (isCompareEnabled || isCompareYTDEnabled) {
      return false;
    }

    return !watch("breakdownPeriods");
  }, [watch("breakdownPeriods"), isCompareEnabled, isCompareYTDEnabled, watch("comparison.comparisonPeriods")]);

  const shouldDisabledTotal = useMemo(() => {
    if (isAggrSideBySide) {
      return true;
    }
    if (watch("budgets.include") && watch("breakdownPeriods")) {
      return true;
    }
    if (watch("difference") || !watch("breakdownPeriods")) {
      return true;
    }

    if (watch("breakdownPeriods")) {
      return false;
    }
  }, [watch("difference"), watch("breakdownPeriods"), watch("budgets.include")]);

  const shouldDisabledDifference = useMemo(() => {
    if (isAggrSideBySide) {
      return true;
    }
    if (watch("total")) {
      return true;
    }
    if (watch("breakdownPeriods")) {
      return true;
    }
    if (watch("budgets.include") === false && watch("comparison.comparisonPeriods")! > 1) {
      return true;
    }
    if (watch("budgets.include") && isCompareEnabled) {
      return true;
    }
    if (watch("budgets.include")) {
      return false;
    }

    if (isCompareEnabled || isCompareYTDEnabled) {
      return false;
    }

    return true;
  }, [
    selectedTimeFrame,
    watch("total"),
    watch("budgets.include"),
    watch("breakdownPeriods"),
    isCompareEnabled,
    watch("comparison.comparisonPeriods"),
    isAggrSideBySide
  ]);

  const handleChangeTimeframe = (option: Option) => {
    reset({
      ...watch(),
      timeframe: option.value as ReportTimeframe,
      budgets: {
        include: false
      },
      difference: false,
      comparison: {
        comparisonType: option.value === "Year" ? "Year Over Year" : "Prior Period",
        comparisonPeriods: 0
      },
      total: false,
      reverse: false,
      breakdownPeriods: false
    });
    setCustomDateRange("false");
  };

  return (
    <>
      <button className="report-dates-control" onClick={toggleModal}>
        {periodTitle} {dateRangeTitle}
        <i>
          <IconCustomerRequest />
        </i>
      </button>
      <Modal id="report-pl-date-modal-container" isOpen={openDateRangeModal} onRequestClose={toggleModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="report-pl-dates-modal">
            <div className="modal-header">
              <h3 className="modal-title">
                <IconCalendar /> {periodTitle}
              </h3>
              <button className="close-button" onClick={toggleModal}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-list">
                {availableTemplates &&
                  availableTemplates.map((avTemplate, idx) => {
                    const isSelected = templateId ? localTemplateId === avTemplate.templateId : idx === 0;
                    return (
                      <KSListItem
                        key={avTemplate.templateId}
                        label={avTemplate.title}
                        isSelected={isSelected}
                        onClickListItem={() => {
                          handleClickListItem(avTemplate);
                        }}
                      />
                    );
                  })}
              </div>
              {
                <div className="modal-content">
                  <div className="modal-subsection">
                    <div className="modal-subsection-content">
                      <KSDropdown
                        options={reportTimeframeOptionsFiltered as Option[]}
                        value={{
                          value: watch("timeframe") || "Month",
                          label: watch("timeframe") || "Month"
                        }}
                        label="Timeframe"
                        onSelect={handleChangeTimeframe}
                        enableMinWidth
                      />
                      {selectedTimeFrame !== "Year to Date" ? (
                        <KSCheckBox
                          checked={isCustomDateRange}
                          onChange={handleToggleCustomDateRange}
                          label="Custom Range Period"
                        />
                      ) : (
                        renderYTDCompareSelectors()
                      )}
                    </div>
                    <div className="modal-time-frame-content">
                      {selectedTimeFrame !== "Year to Date" &&
                        renderContentBasedOnTimeframe[currentTemplate?.timeframe as HoneReportTimeframe]}
                    </div>
                  </div>

                  <div className="modal-options-check">
                    <KSCheckBox
                      disabled={shouldDisabledBudgetInclude}
                      label={"Show Budget"}
                      checked={watch("budgets.include")}
                      onChange={() => {
                        reset({
                          ...watch(),
                          budgets: {
                            include: !watch("budgets.include")
                          },
                          difference: false
                        });
                      }}
                    />
                    <KSCheckBox
                      disabled={shouldDisabledTotal}
                      label={"Show Total"}
                      checked={Boolean(watch("total"))}
                      onChange={() => {
                        reset({
                          ...watch(),
                          total: !watch("total")
                        });
                      }}
                    />
                    <KSCheckBox
                      disabled={shouldDisabledDifference}
                      label={"Show Difference/Budget Variance"}
                      checked={Boolean(watch("difference"))}
                      onChange={() => {
                        reset({
                          ...watch(),
                          difference: !watch("difference")
                        });
                      }}
                    />
                    <KSCheckBox
                      disabled={shouldDisabledBreakdownPeriods}
                      label={"Breakdown Periods"}
                      checked={Boolean(watch("breakdownPeriods"))}
                      onChange={() => {
                        reset({
                          ...watch(),
                          breakdownPeriods: !watch("breakdownPeriods"),
                          reverse: false,
                          total: false
                        });
                      }}
                    />
                    <KSCheckBox
                      disabled={shouldDisabledReverse}
                      label={"Reverse Column Order"}
                      checked={Boolean(watch("reverse"))}
                      onChange={() => {
                        reset({
                          ...watch(),
                          reverse: !watch("reverse")
                        });
                      }}
                    />
                  </div>
                </div>
              }
            </div>
            <div className="modal-footer">
              <KSButton
                label="Apply"
                color="primary"
                size="medium"
                variant="contained"
                type="submit"
                disabled={shouldDisabledApplyButton}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
}

export default ReportPLDatesModal;
