import classNames from "classnames";

import { useSidenavStore } from "hooks/useSidenavStore";
import { TopNavigationBar, Sidenav } from "presentation/components";
import "./Layout.css";
import Footer from "presentation/components/Footer/Footer";

type Props = {
  children: JSX.Element | JSX.Element[];
  grayBg?: boolean;
  noPadding?: boolean;
  noFooter?: boolean; 
};

function Layout({ children, grayBg = true, noPadding = false, noFooter = false  }: Props): JSX.Element {
  const { isSidenavCollapsed } = useSidenavStore();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div className="Layout d-print-block">
      {isMobile && <TopNavigationBar />}
      <nav className={classNames("Layout-nav", { "Layout-nav_open": isSidenavCollapsed })}>
        <Sidenav />
      </nav>
      <main className="Layout-main">
        <div className="Layout-content">{children}</div>
        {!noFooter && !isMobile && <Footer/>}
      </main>
    </div>
  );
}

export default Layout;
