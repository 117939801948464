import React from "react";
import useQboAccountsQuery from "hooks/useQboAccountsQuery";
import QboConnection from "../../../components/CoAEditor/QboConnection";
import CoAEditor from "../../../components/CoAEditor/CoAEditor";

function BookkeeperSetupCoA() {
  const { data } = useQboAccountsQuery(false);

  return (
    <div className="bookkeeper-coa">
      {data ? <CoAEditor data={data as { data: any; length: number; original: any }} /> : <QboConnection />}
    </div>
  );
}

export default BookkeeperSetupCoA;
