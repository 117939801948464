import { useState } from "react";
import "../ForecastTable.scss";
import classNames from "classnames";
import { shallow } from "zustand/shallow";

import { IconChart, IconReset, IconWarning } from "components/Icons";
import {
  dismissToast,
  FIVE_SECONDS,
  showToast,
  TEN_SECONDS,
  TOAST_DEFAULT,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from "lib/utils";

import ForecastTableBody from "./ForecastTableBody";

import Loading from "components/Loading";
import { useForecastUIStore } from "hooks/useForecastUIStore";
import { useForecastReport, saveRemoteForecast, resetForecastReport } from "hooks/useForecastReport";
import Tooltip from "rsuite/Tooltip";
import Whisper from "rsuite/Whisper";
import { useQueryState } from "hooks/useQueryState";

function ForecastTable(): JSX.Element | null {
  const { activeCategory, activePeriod, pristine } = useForecastUIStore(
    ({ activeCategory, activePeriod, pristine }) => ({ activeCategory, activePeriod, pristine }),
    shallow
  );

  const onSubmit = async () => {
    const toastId = showToast("Saving forecast", TOAST_DEFAULT, FIVE_SECONDS);
    try {
      await saveRemoteForecast();
      dismissToast(toastId);
      showToast("Forecast report updated", TOAST_SUCCESS, FIVE_SECONDS);
    } catch (e: any) {
      showToast(e, TOAST_ERROR, TEN_SECONDS);
    }
  };

  const activeRemoteForecast = useForecastReport((state) => state.activeRemoteForecast);

  const [goalsSet, setGoalsSet] = useState(activeRemoteForecast?.goalsSet);

  if (!activeCategory) {
    return <Loading />;
  }

  return (
    <main>
      <div
        className={classNames("table-container", {
          [activeCategory]: true,
          [`period-${activePeriod}`]: true,
          blue: true,
        })}
      >
        <div className="overflow">
          <div className="actions-wrapper">
            <div>
              <button className="btn-secondary" onClick={resetForecastReport}>
                RESET
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <IconReset width={16} height={16} />
              </button>
            </div>
            <div className="">
              {!pristine && (
                <div className="pending-changes">
                  <IconWarning />
                  You have pending changes <br />
                  Hit save to update goals
                </div>
              )}
              <button className="btn-secondary" disabled>
                Cancel
              </button>
              <button
                className="btn-primary"
                title={activeRemoteForecast?.status === "Published" ? "Forecast is published" : "Save"}
                disabled={activeRemoteForecast?.status === "Published"}
                onClick={onSubmit}
              >
                Save
              </button>
            </div>
          </div>
          <table className="forecast-table" cellSpacing={0} cellPadding={0}>
            <thead>
              <tr>
                <th colSpan={2} />
                <th className="align-center">Forecast</th>
                <th className="goal">Goal</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <ForecastTableBody />
            </tbody>
          </table>
        </div>
        {!goalsSet && (
          <div className="goals-description">
            <h3>Review your forecast and budgets to set goals.</h3>
            <p>
              This forecast is calculated from your historical performance and it does not factor in any future events
              or weather.
            </p>
            <button onClick={() => setGoalsSet(true)} className="btn-primary">
              Review & Modify
            </button>
            <a>Learn how KitchenSync calculates your Forecast</a>
          </div>
        )}
      </div>
    </main>
  );
}

export default ForecastTable;
