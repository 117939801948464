import { Route, Navigate, Routes } from "react-router-dom";

import {
  ReportByIdViewFactory,
  ReportViewFactory,
  DashboardViewFactory,
  MakeForecastReport,
  AskRequestsViewFactory
} from "main/factories/pages";

import { PrivateRoute } from "main/proxies";
import { Bookkeeper } from "presentation/pages";
import AuthForm from "components/AuthForm";
import AuthForgotPasswordForm from "components/AuthForgotPasswordForm";
import ReportLocationFactory from "../factories/pages/report-view/report-location-factory";
import { AccrualsViewFactory } from "main/factories/pages/accruals/accruals-view-factory";
import { JournalEntriesFactory } from "main/factories/pages/journal-entries/journal-entries-factory";
import { JournalEntriesByIdFactory } from "main/factories/pages/journal-entries/journarl-entries-by-id-factory";
import AdminViewFactory from "../factories/pages/admin/admin-view-factory";

import { HoneAbilityActions, HoneAbilitySubjects } from "@hone-automation/common";

const Router = () => {
  return (
    <Routes>
      <Route
        path="/app/location/:locationId/forecast"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Owner_Controls, action: HoneAbilityActions.read }}>
            <MakeForecastReport />
          </PrivateRoute>
        }
      />
      <Route
        path="/app/location/:locationId/report"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Employee_Controls, action: HoneAbilityActions.read }}>
            <ReportViewFactory />
          </PrivateRoute>
        }
      />
      <Route
        path="/app/location/:locationId/dashboard"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Employee_Controls, action: HoneAbilityActions.read }}>
            <DashboardViewFactory />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/location/:locationId/journal-entries/:journalEntryId/accruals"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Bookkeeper_Controls, action: HoneAbilityActions.read }}>
            <AccrualsViewFactory />
          </PrivateRoute>
        }
      />
      <Route
        path="/app/location/:locationId/journal-entries/:journalEntryId/"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Bookkeeper_Controls, action: HoneAbilityActions.read }}>
            <JournalEntriesByIdFactory />
          </PrivateRoute>
        }
      />
      <Route
        path="/app/location/:locationId/journal-entries"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Bookkeeper_Controls, action: HoneAbilityActions.read }}>
            <JournalEntriesFactory />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/location/:locationId/report/:reportType"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Employee_Controls, action: HoneAbilityActions.read }}>
            <ReportByIdViewFactory />
          </PrivateRoute>
        }
      />
      <Route
        path="/app/location/:locationId/report/:reportType/:reportId"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Employee_Controls, action: HoneAbilityActions.read }}>
            <ReportByIdViewFactory />
          </PrivateRoute>
        }
      />
      <Route
        path="/app/location/:locationId"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Employee_Controls, action: HoneAbilityActions.read }}>
            <ReportLocationFactory />
          </PrivateRoute>
        }
      />
      <Route
        path="/app/location"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Employee_Controls, action: HoneAbilityActions.read }}>
            <ReportLocationFactory />
          </PrivateRoute>
        }
      />
      <Route
        path="/app/location/:locationId/bookkeeper"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Bookkeeper_Controls, action: HoneAbilityActions.read }}>
            <Bookkeeper />
          </PrivateRoute>
        }
      />
      <Route
        path="/app/location/:locationId/bookkeeper/:activeTab"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Bookkeeper_Controls, action: HoneAbilityActions.read }}>
            <Bookkeeper />
          </PrivateRoute>
        }
      />
      <Route
        path="/app/location/:locationId/ask-requests"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Employee_Controls, action: HoneAbilityActions.read }}>
            <AskRequestsViewFactory />
          </PrivateRoute>
        }
      />

      <Route
        path="/app/location/:locationId/admin"
        element={
          <PrivateRoute acl={{ subject: HoneAbilitySubjects.Internal_Controls, action: HoneAbilityActions.update }}>
            <AdminViewFactory />
          </PrivateRoute>
        }
      />
      <Route path="/login" element={<AuthForm />} />
      <Route path="/forgot-password" element={<AuthForgotPasswordForm />} />
      <Route path="*" element={<Navigate to="/app/location" />} />
    </Routes>
  );
};

export default Router;
