import { useState } from "react";
import type { FormEvent } from "react";
import { HoneReportTimeframes, HoneReportTypes, HoneReportTypeTimeframes } from "../constants";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import { generateReports } from "hooks/useReportsStore";

import {
  showToast,
  dismissToast,
  TOAST_DEFAULT,
  TOAST_SUCCESS,
  TOAST_ERROR,
  HALF_MINUTE,
  TEN_SECONDS,
} from "lib/utils";

interface Props {
  currentLocation: HoneLocation | undefined;
  onSuccess: () => void;
}

const TOAST_GENERATE_REPORTS_TEXT =
  "Generating reports, depending on the date range will take a few minutes to complete.";

function GenerateReportsForm({ currentLocation, onSuccess }: Props): JSX.Element {
  const [reportTypeTimeframe, setReportTypeTimeframe] = useState(HoneReportTypeTimeframes.AllReportTypes as string);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSuccess();

    const data = new FormData(e.currentTarget);

    let reportType: HoneReportType | undefined = undefined;
    let reportTimeframe: HoneReportTimeframe | undefined = undefined;
    const reportTypeTimeframe: string = data.get("reportTypeTimeframe") as string;

    // Get Report Type/Timeframe for selected input
    switch (reportTypeTimeframe) {
      case HoneReportTypeTimeframes.AllReportTypes: {
        reportType = undefined;
        reportTimeframe = undefined;
        break;
      }
      case HoneReportTypeTimeframes.MonthlyBalanceSheet: {
        reportType = HoneReportTypes.BalanceSheet;
        break;
      }
      case HoneReportTypeTimeframes.MonthlyCashFlow: {
        reportType = HoneReportTypes.CashFlow;
        break;
      }
      case HoneReportTypeTimeframes.MonthlyPLComparison: {
        reportType = HoneReportTypes.PLComparison;
        reportTimeframe = HoneReportTimeframes.Monthly;
        break;
      }
      case HoneReportTypeTimeframes.WeeklyPLComparison: {
        reportType = HoneReportTypes.PLComparison;
        reportTimeframe = HoneReportTimeframes.Weekly;
        break;
      }
      case HoneReportTypeTimeframes.YTDIncomeStatement: {
        reportType = HoneReportTypes.IncomeStatement;
        reportTimeframe = HoneReportTimeframes.YTDMonthly;
        break;
      }
    }

    const dateStr = data.get("dateStr") !== "" ? (data.get("dateStr") as string) : undefined;
    const endDateStr = data.get("endDateStr") !== "" ? (data.get("endDateStr") as string) : undefined;

    const toastId = showToast(TOAST_GENERATE_REPORTS_TEXT, TOAST_DEFAULT, HALF_MINUTE);
    if (currentLocation) {
      generateReports(currentLocation, reportType, reportTimeframe, dateStr, endDateStr)
        .then((result) => {
          dismissToast(toastId);
          showToast(
            "Report generation tasks submitted, allow a few minutes for all reports to generate.",
            TOAST_SUCCESS,
            TEN_SECONDS
          );
        })
        .catch((error) => {
          dismissToast(toastId);
          showToast(`Error attempting to generate reports: ${error.toString()}`, TOAST_ERROR, HALF_MINUTE);
        });
    }
  };

  const handleReportTypeTimeframeChange = (e: FormEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;
    setReportTypeTimeframe(value);
  };

  return (
    <form className="GenerateReportsForm BKForm-form" onSubmit={handleFormSubmit}>
      <h3 className="mb-8">Generate Reports</h3>

      <div className="BKForm-inline mt-8 mb-4">
        <label className="BKForm-label" htmlFor="reportTypeTimeframe">
          Report
        </label>
        <select
          id="reportTypeTimeframe"
          name="reportTypeTimeframe"
          onChange={(e) => handleReportTypeTimeframeChange(e)}
        >
          {Object.values(HoneReportTypeTimeframes).map((typeChoice, index) => (
            <option value={typeChoice} key={index}>
              {typeChoice}
            </option>
          ))}
        </select>
      </div>

      <div className="BKForm-inline mb-4">
        <label className="BKForm-label" htmlFor="dateStr">
          Date
        </label>
        <DatePicker
          id="dateStr"
          name="dateStr"
          className="BKFrom-input-datepicker"
          dateFormat="yyyy-MM-dd"
          selected={startDate}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          onChange={(date: Date) => setStartDate(date)}
        />
      </div>

      <div className="BKForm-inline mb-8">
        <label className="BKForm-label" htmlFor="endDateStr">
          End Date
        </label>
        <DatePicker
          id="endDateStr"
          name="endDateStr"
          className="BKFrom-input-datepicker"
          dateFormat="yyyy-MM-dd"
          selected={endDate}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          onChange={(date: Date) => setEndDate(date)}
        />
      </div>

      <button className="button w-100 mt-6 mb-4" type="submit">
        Generate Reports
      </button>
    </form>
  );
}

export default GenerateReportsForm;
