import { prefixClass } from "lib/utils";
import { IconClose, IconSearch } from "components/Icons";
import "./KSMLMSearch.scss";
import classNames from "classnames";

type KSMLMSearchProps = {
  placeholder?: string;
  searchValue?: string;
  setSearchValue?: (value: string) => void;
  handleClose?: () => void;
};

const KSMLMSearch = ({ searchValue='', setSearchValue, handleClose, placeholder = "Search" }: KSMLMSearchProps) => {
  const prefix = prefixClass("ks-mlm-search");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue?.(event.target.value.toLowerCase());
  }

  const handleClear = () => {
    setSearchValue?.('');
  }

  const handleCloseModal = () => {
    handleClose?.();
  }

  return (
    <>
        <div className={prefix()}>
            <div className={prefix("search-icon")}><IconSearch /></div>
            <input placeholder={placeholder} value={searchValue} onChange={handleSearch} />
            {searchValue && <div className={prefix("clear-button")} onClick={handleClear}>Clear</div>}
            <div 
                className={classNames(prefix("close-button"),{searching: searchValue})}
                onClick={handleCloseModal}
            >
                
                <IconClose />
            </div>
        </div>
    </>
  )
}


export default KSMLMSearch;