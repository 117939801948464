import { Metric } from "web-vitals";

type ReportFunction = (m: Metric) => void;

export function reportWebVitals(onPerfEntry: ReportFunction): void {
  import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(onPerfEntry);
    getFID(onPerfEntry);
    getFCP(onPerfEntry);
    getLCP(onPerfEntry);
    getTTFB(onPerfEntry);
  });
}
