import { GetAuditReport } from "domain/usecases/get-audit-report";
import { BaseHttpService } from "main/factories/http";
export class RemoteGetAuditReport extends BaseHttpService<GetAuditReport.Model> implements GetAuditReport {
  async getAuditReportById(params: GetAuditReport.Params): Promise<GetAuditReport.Model> {
    return await this.request(
      "get",
      {},
      {
        params: {
          ...params,
          json: true
        }
      }
    );
  }
}
