import { useNavigate } from "react-router-dom";

const Error401 = () => {
  const navigate = useNavigate();
  const handleGoToHome = () => {
    localStorage.removeItem("hone:locationId");
    navigate("/");
  };

  return (
    <div className="unauthorized-container">
      <h1>Access Denied</h1>
      <p>You do not have permission to access this page.</p>
      <button className="btn btn-primary" onClick={handleGoToHome}>
        Go to home
      </button>
    </div>
  );
};

export default Error401;
