import React, { createContext, SetStateAction, useContext, useEffect, useState } from "react";
import { useLocationsStore } from "./useLocationsStore";
import { shallow } from "zustand/shallow";
import { useUserLocationsQuery } from "./useUserLocationsQuery";

// Define the maximum number of recent locations to keep
export const MAX_RECENT_LOCATIONS = 8;

interface Context {
  recentLocations: HoneLocationUser[];
  setRecentLocations: any;
}

const RecentLocationsContext = createContext<Context>({
  recentLocations: [],
  setRecentLocations: (prevState: HoneLocationUser[]) => {
    return prevState as HoneLocationUser[];
  }
});

export const useRecentLocations = () => useContext(RecentLocationsContext);

interface RecentLocationsProviderProps {
  children: React.ReactNode;
}

const RecentLocationsProvider = ({ children }: RecentLocationsProviderProps) => {
  const { data: locations } = useUserLocationsQuery();
  const castedLocations = locations as HoneLocationUser[];
  const { activeLocation } = useLocationsStore((state) => ({ activeLocation: state.currentLocationId }), shallow);
  const [recentLocations, setRecentLocations] = useState<HoneLocationUser[]>([]);

  useEffect(() => {
    const storedRecentLocations = localStorage.getItem("recentLocations");
    if (storedRecentLocations && locations) {
      const parsed = JSON.parse(storedRecentLocations);
      const active = parsed.findIndex((loc: HoneLocationUser) => loc.locationId === activeLocation) > -1;
      const location = castedLocations.find((loc) => loc.locationId === activeLocation);
      if (location) {
        setRecentLocations(active ? parsed : [location, ...parsed.slice(0, MAX_RECENT_LOCATIONS - 1)]);
      }
    } else if (castedLocations && castedLocations.length > 0) {
      setRecentLocations(castedLocations.slice(0, MAX_RECENT_LOCATIONS));
    }
  }, [locations, activeLocation]);

  useEffect(() => {
    if (recentLocations.length > 0) {
      localStorage.setItem("recentLocations", JSON.stringify(recentLocations.slice(0, MAX_RECENT_LOCATIONS)));
    }
  }, [recentLocations]);

  return (
    // @ts-ignore
    <RecentLocationsContext.Provider value={{ recentLocations, setRecentLocations }}>
      {children}
    </RecentLocationsContext.Provider>
  );
};

export default RecentLocationsProvider;
