import { createContext, useContext, useEffect, useState } from "react";
import type { PropsWithChildren } from "react";
import { trackError } from "lib/analytics";
import { doDelete, doPost, reportingOrigin } from "lib/utils";
import { HoneAbility, HoneUserRoles, defineAbilityFor } from "@hone-automation/common";
import { useLocationsStore } from "../hooks/useLocationsStore";
import { useUserLocationsQuery } from "hooks/useUserLocationsQuery";
import { useQuery } from "@tanstack/react-query";
import { makeRemoteGetLocationsUsers } from "main/factories/usecases";
import { makeRemoteGetUserInfo } from "main/factories/usecases/remote-get-user-info";
import { useAuthContext } from "context/useAuthContext";

interface Context {
  status: string;
  hasAdminRole: boolean;
  isBookkeeperAdmin: boolean;
  currentLocation: HoneLocationUser | undefined;
  allLocationUsers: HoneLocationUser[];
  isOwner: boolean;
  userInfoHasLoaded: boolean;
  userLocationsForUser: Record<string, HoneLocationUser>;
  currentLocationAbility: any;
  currentLocationAbilities: HoneAbility[];
  setAllLocationUsers: (allLocationUsers: HoneLocationUser[]) => void;
  addLocationUser: (
    email: string,
    password: string,
    role: string,
    locationId?: string,
    emailOptOut?: boolean
  ) => Promise<any>;
  updateLocationUser: (email: string, role: string, emailOptOut: boolean, locationId?: string) => Promise<any>;
  removeLocationUser: (email: string, locationId?: string) => Promise<any>;
}

const HoneLocationUsersContext = createContext<Context | undefined>(undefined);

const locationUsers = makeRemoteGetLocationsUsers();

function useHoneGetUsers(locationId: string | undefined) {
  const { user } = useAuthContext();

  return useQuery({
    enabled: !!locationId && !!user && !!user.uid && !!localStorage.getItem("hone:session"),
    queryKey: ["locationsUsers", locationId, user?.uid],
    queryFn: async () => {
      if (!user) return [];
      return locationUsers.getAll({ userId: user.uid, locationId });
    }
  });
}

const locationUser = makeRemoteGetUserInfo();

function useHoneGetUserInfo(user: any, locationId: string | undefined) {
  return useQuery({
    enabled: !!locationId && !!user && !!user.uid && !!localStorage.getItem("hone:session"),
    queryKey: ["userInfo", locationId, user?.uid],
    queryFn: async () => {
      if (!user) return undefined;
      return locationUser.get({ userId: user.uid, locationId });
    }
  });
}

export default function HoneLocationUsersProvider(props: PropsWithChildren<unknown>): JSX.Element {
  const currentLocationId = useLocationsStore((state) => state.currentLocationId);
  const { data: remoteAllLocationUsers, status } = useHoneGetUsers(currentLocationId); // unpack observable
  const [allLocationUsers, setAllLocationUsers] = useState<HoneLocationUser[]>([]);
  const [userLocationsForUser, setUserLocationsForUser] = useState<Record<string, HoneLocationUser>>({});
  const [currentLocation, setCurrentLocation] = useState<HoneLocationUser | undefined>();
  const [currentLocationAbility, setCurrentLocationAbility] = useState<any>([]);
  const [currentLocationAbilities, setCurrentLocationAbilities] = useState<HoneAbility[]>([]);
  const { user } = useAuthContext();
  const { data: userInfo, isSuccess: userInfoHasLoaded } = useHoneGetUserInfo(user, currentLocationId);

  const { data: userLocations } = useUserLocationsQuery();
  const userIsAdmin = (userInfo as HoneLocationUser)?.role === HoneUserRoles.Bookkeeper_Account_Admin;
  let isOwner = false;
  if (user && currentLocationId) {
    isOwner = userIsAdmin ? true : userInfo ? (userInfo as HoneLocationUser).role === HoneUserRoles.Owner : false;
  }

  const addLocationUser = async (
    email: string,
    password: string,
    role: string,
    locationId?: string,
    emailOptOut?: boolean
  ) => {
    const location = locationId ?? currentLocationId;
    try {
      return doPost(`${reportingOrigin()}/addLocationUser`, {
        userId: user?.uid,
        email,
        password,
        locationId: location,
        emailOptOut,
        role
      });
    } catch (error) {
      trackError({ error: error as Error });
      return { error: error };
    }
  };

  const updateLocationUser = async (email: string, role: string, emailOptOut: boolean, locationId?: string) => {
    const location = locationId ?? currentLocationId;
    try {
      return doPost(`${reportingOrigin()}/updateLocationUser`, {
        userId: user?.uid,
        email,
        locationId: location,
        role,
        emailOptOut
      });
    } catch (error) {
      trackError({ error: error as Error });
      return { error: error };
    }
  };

  const removeLocationUser = async (email: string, locationId?: string) => {
    const location = locationId ?? currentLocationId;

    try {
      return doDelete(
        `${reportingOrigin()}/removeLocationUser?userId=${user?.uid}&locationId=${location}&email=${email}`
      );
    } catch (error) {
      trackError({ error: error as Error });
      return { error: error };
    }
  };

  useEffect(() => {
    if (!user || !user?.uid) {
      setCurrentLocation(undefined);
      setCurrentLocationAbilities([]);
      setCurrentLocationAbility([]);
      setAllLocationUsers([]);
      setUserLocationsForUser({});
    } else {
      if (status === "success" && remoteAllLocationUsers && userLocations && currentLocationId) {
        setAllLocationUsers(remoteAllLocationUsers as HoneLocationUser[]);
        if (userLocations) {
          // Convert to record map for easier data access
          const ulMap: Record<string, HoneLocationUser> = {};
          (userLocations as HoneLocationUser[]).map((ul: HoneLocationUser) => (ulMap[ul.locationId] = ul));
          setUserLocationsForUser(ulMap);

          // Set current location permissions
          if (currentLocationId) {
            if (ulMap[currentLocationId]) {
              setCurrentLocation(ulMap[currentLocationId]);
              if (ulMap[currentLocationId].abilities) {
                const abilities = ulMap[currentLocationId].abilities ?? [];
                setCurrentLocationAbilities(abilities);
                setCurrentLocationAbility(defineAbilityFor(abilities));
              }
            }
          }
        }
      }
    }
  }, [user, status, remoteAllLocationUsers, userLocations, currentLocationId]);

  return (
    <HoneLocationUsersContext.Provider
      value={{
        status,
        isBookkeeperAdmin: currentLocation?.role === HoneUserRoles.Bookkeeper_Account_Admin,
        allLocationUsers,
        isOwner,
        hasAdminRole: userIsAdmin,
        userInfoHasLoaded,
        currentLocation,
        userLocationsForUser,
        currentLocationAbility,
        currentLocationAbilities,
        setAllLocationUsers,
        addLocationUser,
        updateLocationUser,
        removeLocationUser
      }}
      {...props}
    />
  );
}

export function useHoneLocationUsers(): Context {
  const users = useContext(HoneLocationUsersContext);
  if (!users) throw new Error("You must call useHoneLocationUsers from within HoneLocationUsersProvider tree");
  return users;
}
