import React, { FormEvent, useEffect, useRef } from "react";
import { IconDeleteOutline } from "components/Icons";
import {
  dismissToast,
  FIVE_SECONDS,
  ONE_MINUTE,
  showToast,
  TEN_SECONDS,
  TOAST_DEFAULT,
  TOAST_ERROR,
  TOAST_SUCCESS,
} from "../../../lib/utils";
import { useHoneLocationUsers } from "../../../components/HoneLocationUsers";
import { useForm } from "react-hook-form";
import LocationSelector from "../../components/LocationSelector/LocationSelector";
import { confirmAlert } from "react-confirm-alert";
import RoleSelector from "../../components/RoleSelector/RoleSelector";
import { Simulate } from "react-dom/test-utils";
import change = Simulate.change;

interface UserLocationRowProps {
  location: HoneLocationUser;
  refetch: () => void;
}

function UserLocationRow({ location, refetch }: UserLocationRowProps) {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      role: {
        label: location.role.split("_").join(" "),
        value: location.role,
      },
    },
  });

  useEffect(() => {
    const subscription = watch(() => handleSubmit(changeRoleHandler)());
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const { updateLocationUser, removeLocationUser } = useHoneLocationUsers();

  const updateUser = (email: string, role: string, emailOptOut: boolean, locationId: string) => {
    const toastId = showToast("Updating user", TOAST_DEFAULT, ONE_MINUTE);
    updateLocationUser(email, role, emailOptOut, locationId)
      .then((result: any) => {
        if (result.data) {
          dismissToast(toastId);
          if (result.data.error === undefined) {
            refetch();
            showToast("User updated", TOAST_SUCCESS, FIVE_SECONDS);
          } else {
            showToast("Error trying to update user", TOAST_ERROR, TEN_SECONDS);
          }
        }
      })
      .catch((error) => {
        dismissToast(toastId);
        showToast(`Error trying to update user: ${error}`, TOAST_ERROR, TEN_SECONDS);
      });
  };
  const handleEmailOptOutChange = (e: FormEvent<HTMLInputElement>, location: HoneLocationUser) => {
    updateUser(location.email, location.role, !location.emailOptOut, location.locationId);
  };

  const handleRemoveLocationUser = async (userEmail: string, location: HoneLocationUser) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3>
              Are you sure you want to remove '{userEmail}' from location: {location.locationName}?
            </h3>
            <button className="button BKForm-btn-secondary" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button BKForm-btn"
              onClick={() => {
                onClose();
                const toastId = showToast(
                  "Removing user from this location, please wait a bit.",
                  TOAST_DEFAULT,
                  ONE_MINUTE,
                );
                removeLocationUser(userEmail, location.locationId)
                  .then((result: any) => {
                    if (result === "ok") {
                      refetch();
                      dismissToast(toastId);
                      showToast("Successfully removed user.", TOAST_SUCCESS, FIVE_SECONDS);
                    }
                  })
                  .catch(() => {
                    dismissToast(toastId);
                    showToast("An unexpected error occurred, please try again", TOAST_ERROR, FIVE_SECONDS);
                  });
              }}
            >
              Remove User
            </button>
          </div>
        );
      },
    });
  };

  const changeRoleHandler = (formData: any) => {
    updateUser(location.email, formData.role.value, location.emailOptOut, location.locationId);
  };

  return (
    <tr key={location.locationId}>
      <td>{location.locationName}</td>
      <td>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(changeRoleHandler);
          }}
        >
          <RoleSelector resetAfterSuccess={false} control={control} name="role" rules={{ required: true }} />
        </form>
      </td>
      <td className="BKForm-cell text-center">
        <input type="checkbox" checked={location.emailOptOut} onClick={(e) => handleEmailOptOutChange(e, location)} />
      </td>
      <td className="BKForm-cell text-center">
        <button
          className="button BKForm-btn-alert p-1"
          type="button"
          onClick={() => location && handleRemoveLocationUser(location.email, location)}
        >
          <IconDeleteOutline />
        </button>
      </td>
    </tr>
  );
}

export default UserLocationRow;
