import useLocationRouting from "hooks/useLocationRouting";
import { AppLayout } from "presentation/components";
import AccrualsView from "presentation/pages/accurals/AccrualsView";

export const AccrualsViewFactory = () => {
  useLocationRouting(false);
  return (
    <AppLayout noPadding>
      <AccrualsView />
    </AppLayout>
  );
};
