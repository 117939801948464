import { useQuery } from "@tanstack/react-query";
import { makeGetAccrualsConfig } from "main/factories/usecases";
import { useParams } from "react-router-dom";

export const useGetAccrualsConfig = (currentLocationId: string | undefined) => {
  return useQuery({
    queryKey: ["accruals-config", currentLocationId],
    staleTime: 0,
    queryFn: async () => {
      if (currentLocationId) {
        const req = makeGetAccrualsConfig(currentLocationId);
        return req.getAccrualsConfig();
      }
    },
  });
};
