import { PostGenerateAccruals } from "../../../domain/usecases/";
import { BaseHttpService } from "main/factories/http";

export class RemoteGenerateAccruals
  extends BaseHttpService<PostGenerateAccruals.Model>
  implements PostGenerateAccruals
{
  async createAccruals(body: PostGenerateAccruals.Params): Promise<PostGenerateAccruals.Model> {
    return await this.request("post", body);
  }
}
