import { useState } from "react";
import { doGet, reportingOrigin, showToast, TEN_SECONDS, TOAST_ERROR } from "lib/utils";

const TOAST_ERROR_ATTACHMENT = "Error fetching image from QBO. Please try again later.";

function useAttachmentsModal() {
  const [showAttachmentImage, setShowAttachmentImage] = useState<boolean>(false);
  const [attachmentsUrl, setAttachmentsUrl] = useState<string[]>([]);
  const [attachmentAmount, setAttachmentAmount] = useState<number>(0);

  const getAttachmentsUrl = async (currentLocationId: string | undefined, attachments: string[], amount: number) => {
    if (!currentLocationId) return null;
    try {
      const response = await doGet(
        `${reportingOrigin()}/attachments?locationId=${currentLocationId}&ids=${attachments.join(",")}`
      );

      const results: string[] = Object.values(response.data);

      setShowAttachmentImage(true);
      setAttachmentsUrl(results);
      setAttachmentAmount(amount);
    } catch (error) {
      showToast(TOAST_ERROR_ATTACHMENT, TOAST_ERROR, TEN_SECONDS);
      setShowAttachmentImage(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseAttachmentImage = () => {
    setShowAttachmentImage(false);
    setAttachmentsUrl([]);
  };

  return {
    showAttachmentImage,
    attachmentsUrl,
    attachmentAmount,
    getAttachmentsUrl,
    handleCloseAttachmentImage,
  };
}

export default useAttachmentsModal;
