import { IconCircleClose } from "components/Icons";
import { ReactChild, ReactElement } from "react";

type ConfirmModalProps = {
  onClose: () => void;
  onConfirm: () => void;
  title: ReactElement | string;
  children?: ReactChild;
};

export default function ConfirmModal({ onClose, onConfirm, children, title }: ConfirmModalProps): JSX.Element {
  return (
    <div className="confirm-modal-form">
      <div className="confirm-modal-form__header">
        <div>{title}</div>
        {/* @ts-ignore */}
        <IconCircleClose onClick={onClose} />
      </div>
      <hr />
      <div className="confirm-modal-form__content">
        {children ?? "Are you sure that you want to confirm this action?"}
      </div>
      <div className="confirm-modal-form__actions">
        <button className="button button_outline" type="button" onClick={onClose}>
          <span>Cancel</span>
        </button>
        <button className="button BKForm-btn-warning" type="button" onClick={onConfirm}>
          <span>Confirm</span>
        </button>
      </div>
    </div>
  );
}
