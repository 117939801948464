import type { ReactNode } from "react";
import { Route, Navigate, useLocation } from "react-router-dom";
import { useAuthContext } from "context/useAuthContext";
import qs from "qs";
import AclGuard, { ACLObj, defaultACLObj } from "../../components/auth/AclGuard";
import useLocationRouting from "../../hooks/useLocationRouting";

type PrivateRouteProps = {
  children: ReactNode;
  acl: ACLObj;
};

const PrivateRoute = ({ children, acl }: PrivateRouteProps) => {
  useLocationRouting(false);
  const { loading } = useAuthContext();
  const location = useLocation();

  if (loading) {
    return null;
  }

  const existingQueries = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const queryString = qs.stringify(existingQueries, { skipNulls: true });

  const back = `${location.pathname}?${queryString}`;

  const publicRoutes = ["/login", "/forgot-password"];

  const aclAbilities = acl ?? defaultACLObj;

  return localStorage.getItem("hone:session") ? (
    <AclGuard aclAbilities={aclAbilities} authGuard={true} guestGuard={publicRoutes.includes(location.pathname)}>
      {children}
    </AclGuard>
  ) : (
    <Navigate to={`/login?back=${back}`} />
  );
};

export default PrivateRoute;
