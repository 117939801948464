import { Children, useState, useEffect, ReactNode } from "react";
import { usePopper } from "react-popper";
import { useClickAway } from "react-use";
import { useUniqueId } from "lib/hooks";
import { useAuthContext } from "context/useAuthContext";

interface Props {
  children: ReactNode;
}

function DropdownMenu({ children }: Props): JSX.Element {
  const [show, setShow] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const auth = useAuthContext();
  const { session, user } = auth;
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: "offset", options: { offset: [0, 8] } },
      { name: "preventOverflow", options: { padding: 16 } },
    ],
  });
  const uid = useUniqueId(DropdownMenu.name);

  useClickAway({ current: popperElement }, () => setShow(false));

  useEffect(() => {
    if (typeof update === "function") update();
  }, [show, update]);

  const handleSignOut = async () => {
    await auth.signOut();
  };
  // use the first child as the toggle, and the other children as the menu elements
  const arrChildren = Children.toArray(children);

  return (
    <>
      <button
        className="DropdownMenu-button p-0"
        onClick={() => setShow(true)}
        type="button"
        ref={setReferenceElement}
        aria-expanded={show}
        id={uid}
      >
        {arrChildren.shift()}
      </button>
      <div
        ref={setPopperElement}
        className={"DropdownMenu-dropdown" + (show ? " DropdownMenu-dropdown_show" : "")}
        style={styles.popper}
        {...attributes.popper}
        aria-labelledby={uid}
      >
        <ul className="DropdownMenu-items">
          {arrChildren.map((child, idx) => (
            <li className="DropdownMenu-item" key={idx}>
              {child}
            </li>
          ))}
          {session && user && show && (
            <li className="AuthForm">
              <p>{user.email}</p>

              <button className="button button_outline w-100" onClick={handleSignOut}>
                Sign Out
              </button>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

export default DropdownMenu;
