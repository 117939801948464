import DashboardFlaggedTransacionsSummaryItem from "./DashboardFlaggedTransacionsSummaryItem";

import { UIAskRequestsTitles, UIFlaggedTransactionStatus } from "types/askcustomer";
import { useAskRequestsCountQuery } from "hooks/useAskRequestsCountQuery";
import { AskRequestCount } from "domain/models";

export default function DashboardFlaggedTransacionsSummary() {
  const { data, isSuccess } = useAskRequestsCountQuery();

  return isSuccess ? (
    <div className="dashboard-flagged-transactions-container__summary">
      <DashboardFlaggedTransacionsSummaryItem
        indicator={(data as AskRequestCount)!.Open}
        title={UIAskRequestsTitles.CUSTOMER}
        activeTab={"Open"}
      />
      <DashboardFlaggedTransacionsSummaryItem
        indicator={(data as AskRequestCount)!.In_Progress}
        title={UIAskRequestsTitles.BOOKKEEPER}
        addBorder
        activeTab={"In Progress"}
      />
      <DashboardFlaggedTransacionsSummaryItem
        indicator={(data as AskRequestCount)!.Closed}
        title={UIAskRequestsTitles.RECENTLY_RESOLVED}
        activeTab={"Closed"}
      />
    </div>
  ) : null;
}
