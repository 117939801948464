import { useQuery } from "@tanstack/react-query";
import { makeRemoteAskRequest } from "main/factories/usecases/ask-requests/remote-get-ask-requests-transactions";
import { AskRequest, AskRequestStatus, FlaggedTransaction } from "@hone-automation/common";
import { transformApiTrxToUITrx, useFlaggedTransactions } from "hooks/flaggedTransactions";
import { FIVE_SECONDS, TOAST_ERROR, showToast } from "lib/utils";
import { shallow } from "zustand/shallow";
import { useHoneLocationUsers } from "../components/HoneLocationUsers";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { use } from "echarts";
import { rest } from "lodash";

const getAskRequests = makeRemoteAskRequest();

export const useAskRequestsQuery = (status?: AskRequestStatus) => {
  const { hasAdminRole } = useHoneLocationUsers();
  const { locationId } = useParams();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { activeFlaggedTransaction } = useFlaggedTransactions(
    (state) => ({
      activeFlaggedTransaction: state.activeFlaggedTransaction
    }),
    shallow
  );

  const {
    data: askRequestData,
    isSuccess,
    isError,
    error,
    refetch,
    ...rest
  } = useQuery({
    gcTime: 0,
    queryKey: ["askRequests", status],
    queryFn: async () => {
      return (await getAskRequests.get({ locationId, statuses: status })) as AskRequest[];
    },
    select: (data: AskRequest[]) => {
      return data.sort((a, b) => {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      });
    }
  });

  useEffect(() => {
    if (isSuccess && askRequestData) {
      if (!askRequestData) return;
      if (isMobile) return;
      if (hasAdminRole && status === "Closed" && activeFlaggedTransaction?.status === "Information Needed") return;
      if (!hasAdminRole && status === "Closed" && activeFlaggedTransaction?.status === "Submitted") return;
      if (askRequestData.length === 0) {
        useFlaggedTransactions.setState({
          activeFlaggedTransaction: undefined
        });
        return;
      }

      if (activeFlaggedTransaction) {
        const trx = askRequestData.find((trx: any) => trx.id === activeFlaggedTransaction.id);
        if (trx) {
          useFlaggedTransactions.setState({
            activeFlaggedTransaction: transformApiTrxToUITrx(trx as unknown as FlaggedTransaction)
          });
          return;
        }
      }

      useFlaggedTransactions.setState({
        activeFlaggedTransaction: transformApiTrxToUITrx(askRequestData[0] as unknown as FlaggedTransaction)
      });
    }
  }, [isSuccess, askRequestData]);

  useEffect(() => {
    if (isError && error) {
      showToast("An error has occurred creating message", TOAST_ERROR, FIVE_SECONDS);
    }
  }, [isError, error]);

  return { data: askRequestData, isSuccess, isError, error, refetch };
};
