import { IconAccruals, IconAccrualsDifferent, IconEye, IconEyeOff, IconForwardBtn } from "components/Icons";
import { PageHeader } from "presentation/components";

import { ErrorBoundary } from "react-error-boundary";
import AccrualsErrorView from "./AccrualsErrorView";
import HoneTable from "presentation/components/HoneTable";

import { useJournalEntriesGenerateAccruals } from "hooks/journal-entries/useJournalEntriesGenerateAccruals";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import Loading from "components/Loading";
import { GenerateAccrualsResponse, JournalEntry, JournalEntryLine, PostingTypes } from "@hone-automation/common";
import { FooterCell, Header } from "presentation/components/HoneTable/types";
import TextArea from "presentation/components/TextArea";
import { useCreateJEAccruals } from "hooks/journal-entries/useCreateJEAccruals";

import { FIVE_SECONDS, TOAST_ERROR, currencyFormatter2Decimals, formatDate, showToast } from "lib/utils";
import { useQueryState } from "hooks/useQueryState";
import AccrualsViewModalConfirmation from "./AccrualsViewModalConfirmation";
import { acHeaders } from "./AccrualsHeaders";
import classNames from "classnames";

export default function AccrualsView() {
  const navigateTo = useNavigate();
  const { locationId: currentLocationId, journalEntryId: journalEntryExternalId } = useParams();
  const [dateRange] = useQueryState("dateRange");
  const [searchIds] = useQueryState("searchIds");

  const [generatedAccrualResponse, setGeneratedAccrualResponse] = useState<GenerateAccrualsResponse | undefined>(
    undefined
  );

  const {
    mutate,
    data: accrualsData,
    isPending
  } = useJournalEntriesGenerateAccruals(currentLocationId!, Number(journalEntryExternalId));

  const castedAccrualsData = accrualsData as GenerateAccrualsResponse;

  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setGeneratedAccrualResponse(accrualsData as GenerateAccrualsResponse);
  }, [accrualsData]);

  const { mutate: createJeMutation, isPending: createJeLoading } = useCreateJEAccruals();

  useEffect(() => {
    mutate({});
  }, []);

  const getAccrualDifference = (entry: number, accrual: number) => {
    const difference = entry - accrual;
    return difference;
  };

  const renderAccountName = (accountName: string | undefined, accountExternalName: string | undefined) => {
    return (
      <div className="hone-table-body-row-cell align-left highlight-different">
        <div className="hone-table-body-row-cell-item">{accountName}</div>
        <div className="hone-table-body-row-cell-item subtitle-blue">
          <IconAccrualsDifferent />
          {accountExternalName}
        </div>
      </div>
    );
  };

  const transformedAccrusalsData = useMemo(() => {
    const tempAccrualsData: Partial<GenerateAccrualsResponse> = { ...castedAccrualsData };
    delete tempAccrualsData.periodDays;
    delete tempAccrualsData.accruedDays;

    return (
      tempAccrualsData &&
      tempAccrualsData.entry?.lines.map((line: JournalEntryLine, idx: number) => {
        const hasDifferentExternalId =
          (tempAccrualsData["entry"] as JournalEntry).lines[idx].accountExternalId !==
          (tempAccrualsData["accrual"] as JournalEntry).lines[idx].accountExternalId;

        return {
          label: hasDifferentExternalId
            ? renderAccountName(
                `${(tempAccrualsData["entry"] as JournalEntry).lines[idx].accountExternalNumber} ${
                  (tempAccrualsData["entry"] as JournalEntry).lines[idx].accountExternalName
                }`,
                `${(tempAccrualsData["accrual"] as JournalEntry).lines[idx].accountExternalNumber} ${
                  (tempAccrualsData["accrual"] as JournalEntry).lines[idx].accountExternalName
                }`
              )
            : `${(tempAccrualsData["entry"] as JournalEntry).lines[idx].accountExternalNumber} ${
                (tempAccrualsData["entry"] as JournalEntry).lines[idx].accountExternalName
              }`,
          entry: {
            debit: {
              values:
                (tempAccrualsData["entry"] as JournalEntry).lines[idx].postingType === PostingTypes.Debit
                  ? [
                      (tempAccrualsData["entry"] as JournalEntry).lines[idx].amount,
                      getAccrualDifference(
                        (tempAccrualsData["entry"] as JournalEntry).lines[idx].amount,
                        (tempAccrualsData["accrual"] as JournalEntry).lines[idx].amount
                      )
                    ]
                  : null,
              backgroundColor: "#F2F2F3"
            },
            credit: {
              values:
                line.postingType === PostingTypes.Credit
                  ? [
                      (tempAccrualsData["entry"] as JournalEntry).lines[idx].amount,
                      getAccrualDifference(
                        (tempAccrualsData["entry"] as JournalEntry).lines[idx].amount,
                        (tempAccrualsData["accrual"] as JournalEntry).lines[idx].amount
                      )
                    ]
                  : null,
              backgroundColor: "#F2F2F3"
            }
          },
          accrual: {
            debit: {
              values:
                (tempAccrualsData["accrual"] as JournalEntry).lines[idx].postingType === PostingTypes.Debit
                  ? [(tempAccrualsData["accrual"] as JournalEntry).lines[idx].amount]
                  : null,
              backgroundColor: "#F0F6EE"
            },
            credit: {
              values:
                (tempAccrualsData["accrual"] as JournalEntry).lines[idx].postingType === PostingTypes.Credit
                  ? [(tempAccrualsData["accrual"] as JournalEntry).lines[idx].amount]
                  : null,
              backgroundColor: "#F0F6EE"
            }
          },
          accrualReversal: {
            debit: {
              values:
                (tempAccrualsData["accrualReversal"] as JournalEntry).lines[idx].postingType === PostingTypes.Debit
                  ? [(tempAccrualsData["accrual"] as JournalEntry).lines[idx].amount]
                  : null,
              backgroundColor: "#EEF5F6"
            },
            credit: {
              values:
                (tempAccrualsData["accrualReversal"] as JournalEntry).lines[idx].postingType === PostingTypes.Credit
                  ? [(tempAccrualsData["accrual"] as JournalEntry).lines[idx].amount]
                  : null,
              backgroundColor: "#EEF5F6"
            }
          },
          description: (tempAccrualsData["entry"] as JournalEntry).lines[idx].description
        };
      })
    );
  }, [accrualsData]);

  const footerData: FooterCell[] = useMemo(() => {
    return [
      { key: "#", value: "" },
      { key: "label", value: "TOTAL" },
      {
        key: "entry",
        value: accrualsData && castedAccrualsData["entry"].totalDebits && castedAccrualsData["entry"].totalDebits
      },
      {
        key: "entry",
        value:
          castedAccrualsData && castedAccrualsData["entry"].totalCredits && castedAccrualsData["entry"].totalCredits
      },
      {
        key: "accrual",
        value: castedAccrualsData && castedAccrualsData["accrual"] && castedAccrualsData["accrual"].totalDebits
      },
      {
        key: "accrual",
        value: castedAccrualsData && castedAccrualsData["accrual"] && castedAccrualsData["accrual"].totalCredits
      },
      {
        key: "accrualReversal",
        value:
          castedAccrualsData &&
          castedAccrualsData["accrualReversal"] &&
          castedAccrualsData["accrualReversal"].totalDebits
      },
      {
        key: "accrualReversal",
        value:
          castedAccrualsData &&
          castedAccrualsData["accrualReversal"] &&
          castedAccrualsData["accrualReversal"].totalCredits
      },
      {
        key: "description",
        value: ""
      }
    ];
  }, [castedAccrualsData]);

  const [transformedHeaders, setTransformedHeaders] = useState<Header[] | undefined>(undefined);

  if (isPending || createJeLoading) {
    return (
      <div className="h-100">
        <Loading />
      </div>
    );
  }
  const accrualsHeaders = [...acHeaders].map((header: Header, idx: number) => {
    if (header.key === "entry") {
      return {
        ...header,
        title: `${castedAccrualsData?.entry.name}`
      };
    }
    if (header.key === "accrual") {
      return {
        ...header,
        title: `${castedAccrualsData?.accrual.name}`
      };
    }
    if (header.key === "accrualReversal") {
      return {
        ...header,
        title: `${castedAccrualsData?.accrualReversal.name}`
      };
    }
    return { ...header };
  });

  const breadcrumb = () => {
    return (
      <ol className="breadcrumb">
        <li onClick={redirectJournalEntries} className="breadcrumb-clickable">
          Journal Entries
        </li>
        <li className="breadcrumb-separator">
          <IconForwardBtn />
        </li>

        <li className="breadcrumb-current-item breadcrumb-clickable" onClick={redirectJournalEntriesByItem}>
          {castedAccrualsData?.entry.name}
        </li>
        <li className="breadcrumb-separator">
          <IconForwardBtn />
        </li>
        <li className="breadcrumb-current-item">Accrual</li>
      </ol>
    );
  };
  const shownHeaders = transformedHeaders || accrualsHeaders;

  const hideColumn = (key: "entry" | "description") => {
    const tempHeaders = [...shownHeaders];
    const indexLabel = tempHeaders.findIndex((header: Header) => header.key === "label");
    const index = tempHeaders.findIndex((header: Header) => header.key === key);
    if (index !== -1) {
      tempHeaders[index].hidden = !tempHeaders[index].hidden;
    }
    const isDescriptionHidden = [...tempHeaders].find((header: Header) => header.key === "description")?.hidden;
    const isEntryHidden = [...tempHeaders].find((header: Header) => header.key === "entry")?.hidden;
    // if (isDescriptionHidden) {
    if (isEntryHidden && isDescriptionHidden) {
      tempHeaders[indexLabel].footerStyles = {
        width: "950px"
      };
    } else if (!isEntryHidden && !isDescriptionHidden) {
      tempHeaders[indexLabel].footerStyles = {
        width: "390px"
      };
    } else {
      tempHeaders[indexLabel].footerStyles = {
        width: "740px"
      };
    }

    setTransformedHeaders(tempHeaders);
  };

  const redirectJournalEntriesByItem = () => {
    let url = `/app/location/${currentLocationId}/journal-entries/${journalEntryExternalId}?dateRange=${dateRange}`;
    if (typeof searchIds !== "undefined") url += `&searchIds=${searchIds}`;
    navigateTo(url);
  };

  const redirectJournalEntries = () => {
    let url = `/app/location/${currentLocationId}/journal-entries?dateRange=${dateRange}`;
    if (typeof searchIds !== "undefined") url += `&searchIds=${searchIds}`;
    navigateTo(url);
  };

  const createAccrual = () => {
    const payload: JournalEntry[] | undefined = generatedAccrualResponse?.accrual &&
      generatedAccrualResponse?.accrualReversal && [
        generatedAccrualResponse?.accrual,
        generatedAccrualResponse?.accrualReversal
      ];

    if (!payload) {
      showToast("Please validate the journal entries", TOAST_ERROR, FIVE_SECONDS);
      return;
    }

    createJeMutation(payload);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    if (generatedAccrualResponse) {
      setGeneratedAccrualResponse({
        ...generatedAccrualResponse,
        [name]: {
          ...(generatedAccrualResponse[name as keyof GenerateAccrualsResponse] as JournalEntry),
          memo: value
        }
      } as GenerateAccrualsResponse);
    }
  };

  const entryHidden = shownHeaders && shownHeaders?.find((item) => item.key === "entry")?.hidden;
  const descritionHidden = shownHeaders && shownHeaders?.find((item) => item.key === "description")?.hidden;

  const EntryHiddenIcon = () => {
    return entryHidden ? <IconEyeOff /> : <IconEye />;
  };

  const DescriptionHiddenIcon = () => {
    return descritionHidden ? <IconEyeOff /> : <IconEye />;
  };

  return (
    <ErrorBoundary FallbackComponent={AccrualsErrorView}>
      <div className="report-view">
        <PageHeader icon={<IconAccruals />} pageTitle={breadcrumb()} addPadding></PageHeader>
        <div className="report-view-journal-entries-header accruals-view">
          <div className="report-view-journal-entries-header-title">
            <span className="report-view-journal-entries-header-title-text">
              {castedAccrualsData?.entry.name}{" "}
              {` (${castedAccrualsData?.entry.date && formatDate(castedAccrualsData?.entry.date + "T23:59:59")})`}
            </span>
            <span className="report-view-journal-entries-header-title-text-sub">
              Accrual JE for{" "}
              <span>{`${castedAccrualsData?.accruedDays} of the ${castedAccrualsData?.periodDays}`}</span> days payroll
              period.
            </span>
          </div>
          <div className="report-view-journal-entries-header-btns">
            <button
              className="report-view-journal-entries-header-btns-green"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              CREATE ACCRUAL
            </button>
            <button className="report-view-journal-entries-header-btns-gray" onClick={redirectJournalEntriesByItem}>
              CANCEL
            </button>
            <div className="report-view-journal-entries-header-btns__eye-container">
              <div
                className="report-view-journal-entries-header-btns__eye-container__eye"
                onClick={() => hideColumn("entry")}
              >
                <EntryHiddenIcon />
                Original Journal Entry
              </div>
              <div
                className="report-view-journal-entries-header-btns__eye-container__eye"
                onClick={() => hideColumn("description")}
              >
                <DescriptionHiddenIcon />
                Description
              </div>
            </div>
          </div>
        </div>
        <div className="report-view-accruals">
          <HoneTable
            data={transformedAccrusalsData as any}
            headers={shownHeaders}
            enabledRowCounter
            containerHeight="550px"
          ></HoneTable>
          <div className="report-view-accruals-footer">
            <div className="report-view-accruals-footer__totals">
              <div
                className={classNames("report-view-accruals-footer__totals__total", {
                  "entry-visible": !entryHidden && descritionHidden,
                  "entry-description-visible ": !descritionHidden && !entryHidden,
                  "entry-hidden-description-visible": !descritionHidden && entryHidden
                })}
              >
                TOTAL
              </div>
              {!entryHidden && (
                <>
                  <div className="report-view-accruals-footer__totals__item entry">
                    {castedAccrualsData?.entry.totalDebits &&
                      currencyFormatter2Decimals(castedAccrualsData?.entry.totalDebits)}
                  </div>
                  <div className="report-view-accruals-footer__totals__item entry">
                    {castedAccrualsData?.entry.totalCredits &&
                      currencyFormatter2Decimals(castedAccrualsData?.entry.totalCredits)}
                  </div>
                </>
              )}
              <div className="report-view-accruals-footer__totals__item accrual">
                {castedAccrualsData?.accrual.totalDebits &&
                  currencyFormatter2Decimals(castedAccrualsData?.accrual.totalDebits)}
              </div>
              <div className="report-view-accruals-footer__totals__item accrual">
                {castedAccrualsData?.accrual.totalCredits &&
                  currencyFormatter2Decimals(castedAccrualsData?.accrual.totalCredits)}
              </div>
              <div className="report-view-accruals-footer__totals__item reversal">
                {castedAccrualsData?.accrualReversal.totalDebits &&
                  currencyFormatter2Decimals(castedAccrualsData?.accrualReversal.totalDebits)}
              </div>
              <div className="report-view-accruals-footer__totals__item reversal">
                {castedAccrualsData?.accrualReversal.totalCredits &&
                  currencyFormatter2Decimals(castedAccrualsData?.accrualReversal.totalCredits)}
              </div>
            </div>
            <div
              className={classNames("report-view-accruals-text-area-view", {
                "report-view-accruals-text-area-view--description-hidden": !entryHidden && descritionHidden,
                "report-view-accruals-text-area-view--entry-hidden": !entryHidden && !descritionHidden,
                "report-view-accruals-text-area-view--entry-hidden-description-visible":
                  entryHidden && !descritionHidden,
                "report-view-accruals-text-area-view--entry-hidden-description-hidden": entryHidden && descritionHidden
              })}
            >
              {!entryHidden && (
                <TextArea
                  label="JE Notes"
                  value={generatedAccrualResponse && generatedAccrualResponse.entry.memo}
                  rows={5}
                  type="default"
                  disabled={true}
                  width="205px"
                />
              )}

              <TextArea
                label="ACCRUALS NOTES"
                value={generatedAccrualResponse && generatedAccrualResponse.accrual.memo}
                rows={5}
                type="success"
                width="205px"
                name={"accrual"}
                onChange={handleChange}
              />
              <TextArea
                label="reversed accrual  notes"
                value={generatedAccrualResponse && generatedAccrualResponse.accrualReversal.memo}
                rows={5}
                type="info"
                width="205px"
                name={"accrualReversal"}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
      <AccrualsViewModalConfirmation
        title={`Confirm Accrual for ${(castedAccrualsData as GenerateAccrualsResponse)?.accrual.name}`}
        isOpen={openModal}
        handleClose={() => {
          setOpenModal(false);
        }}
        handleConfirm={createAccrual}
      />
    </ErrorBoundary>
  );
}
