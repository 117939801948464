import { UIFlaggedTransaction, UIReportTransaction } from "types/askcustomer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { onFlaggedTransactionUpdate } from "hooks/flaggedTransactions/handlers/onFlaggedTransactionUpdate";
import { PostAskRequest } from "domain/usecases";
import { FIVE_SECONDS, showToast, TOAST_ERROR, TOAST_SUCCESS } from "lib/utils";
import { makeRemotePostCreateAskRequest } from "main/factories/usecases/ask-customer/remote-post-create-ask-request";
import { AskRequest, AskRequestStatus } from "@hone-automation/common";
import { useAskRequestsCountQuery } from "hooks/useAskRequestsCountQuery";
import { useAskRequestsQuery } from "hooks/useAskRequestsQuery";
import { useQueryState } from "hooks/useQueryState";

export const useCreateAskRequestMutation = (
  locationId: string,
  setActiveTransaction: (trx: UIFlaggedTransaction | AskRequest | undefined) => void
) => {
  const [activeTab] = useQueryState<AskRequestStatus>("activeTab", "Open");
  const queryClient = useQueryClient();
  const createAskRequets = makeRemotePostCreateAskRequest();
  const { refetch: refetchCounts } = useAskRequestsCountQuery();
  const { refetch: refetchAll } = useAskRequestsQuery(activeTab);

  return useMutation({
    mutationFn: (data: PostAskRequest.Body) => {
      return createAskRequets.post(data);
    },
    onSuccess: (data, variables) => {
      refetchCounts();
      refetchAll();
      onFlaggedTransactionUpdate(data, variables, locationId, queryClient, setActiveTransaction);
      showToast("Request created successfully", TOAST_SUCCESS, FIVE_SECONDS);
    },
    onError: (e: Error) => {
      showToast(e.message, TOAST_ERROR, FIVE_SECONDS);
    },
  });
};
