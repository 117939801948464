import classNames from "classnames";
import { currencyFormatter2Decimals } from "lib/utils";
import { useFlaggedTransactions } from "hooks/flaggedTransactions";
import { shallow } from "zustand/shallow";
import { useQueryState } from "hooks/useQueryState";
import { AskRequest, AskRequestStatus } from "@hone-automation/common";
import Tag from "../Tag";

type AskCustomerRowProps = {
  transaction: AskRequest;
  onClick: () => void;
};

function TransactionRow({ transaction: rootTransaction, onClick }: AskCustomerRowProps) {
  const { activeFlaggedTransaction, animatedTransaction } = useFlaggedTransactions(
    (state) => ({
      activeFlaggedTransaction: state.activeFlaggedTransaction,
      animatedTransaction: state.animatedTransaction
    }),
    shallow
  );

  const transaction = rootTransaction.transaction;

  const [activeTab] = useQueryState<AskRequestStatus>("activeTab", "Open");

  const trxDate = String(transaction?.createdAt).split("T")[0];

  const name = transaction?.name;

  const isActiveTransaction = activeFlaggedTransaction?.id === rootTransaction?.id;

  const isLastRow = true;

  return (
    <div
      data-transaction-id={rootTransaction.id}
      className={classNames("ask-customer-row ask-flagged-transaction-row", {
        active_row_categorized: isActiveTransaction && activeTab === "Closed",
        active_row_information: isActiveTransaction && activeTab === "Open",
        active_row_submitted: isActiveTransaction && activeTab === "In Progress",
        animation_closed: isActiveTransaction && animatedTransaction === "Closed",
        animation_open: isActiveTransaction && animatedTransaction === "Open",
        animation_in_progress: isActiveTransaction && animatedTransaction === "In Progress",
        should_animate: isActiveTransaction && !!animatedTransaction
      })}
      onClick={onClick}
    >
      <div className={classNames("data-row", { isLastRow })}>
        <div className="info">
          <div className="name-description">
            <span className="mobile-date">{trxDate}</span>

            <span className="money-date mobile-hidden">{trxDate}</span>

            <div className="name">
              <div data-testid="row-name">{name === "" ? "No Vendor Name" : name}</div>
              <div className="mobile-money">${transaction?.amount}</div>
            </div>
            <span className="description">{transaction?.memo}</span>
          </div>
          <div className="money">
            <span className="money-value">
              {transaction?.amount && currencyFormatter2Decimals(transaction?.amount)}
            </span>
          </div>
          <div className="tag-info">{rootTransaction.recentlyResolved && <Tag content="Newest" type={"info"} />}</div>
        </div>
      </div>
    </div>
  );
}

export default TransactionRow;
