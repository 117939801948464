import "./KSMLMLocationItem.scss"
import { prefixClass } from "lib/utils";
import { KSMLocation } from "../KSMultipleLocationModal";
import { IconClose} from "components/Icons";
import classNames from "classnames";

type KSMLMLocationItemProps = {
    location: KSMLocation;
    selected?: boolean;
    onLocationSelect?: (location: KSMLocation) => void;
    onLocationRemove?: (location: KSMLocation) => void;
};


const KSMLMLocationItem = ({ location, selected=false, onLocationSelect, onLocationRemove }: KSMLMLocationItemProps) => {
    const prefix = prefixClass("ks-mlm-location-item");
    return (
        <div className={classNames(prefix(),{selected:selected})} >
            {selected ? (
                    <>
                        <div className={prefix("loc-container")}>
                            <div className={prefix("org")}>{location.org}</div>
                            <div className={prefix("name")}>{location.locationName}</div>
                        </div>
                        <div className={prefix("remove-location")} 
                            onClick={(e) => {onLocationRemove?.(location)}}>
                                <IconClose/>
                        </div>
                    </>
                ) : 
                (
                    <>
                        <div className={prefix("name")}>{location.locationName}</div>
                        <div className={prefix("add-location")} onClick={(e) => {onLocationSelect?.(location)}} >add to compare</div>
                    </>
                )
            }
            
        </div>
    )
}

export default KSMLMLocationItem;
