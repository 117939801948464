import { IconDown, IconExportCSV, IconExportPDF } from "./Icons";
import Tooltip from "rsuite/Tooltip";
import Whisper from "rsuite/Whisper";
import { useEffect, useRef, useState, MouseEvent } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { getConnectionLocation } from "lib/utils";

type ExportReportProps = {
  csvReport: any;
  pdfReport: any;
};

export function ExportReport({ csvReport, pdfReport }: ExportReportProps): JSX.Element {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const csvRef = useRef();
  const countryLocation = getConnectionLocation();
  const delimitator = countryLocation === "en-US" ? "," : ";";

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (ref.current && !ref.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleExportPdf = () => {
    pdfReport();
    setDropdownOpen(false);
  };

  return (
    <div className="relative" ref={ref}>
      <div className="table_header_button_group">
        <span>Export</span>
        <div className="table_header_button_group__actions export">
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Export to CSV (Excel, Google Sheets)</Tooltip>}
          >
            <CSVLink
              ref={csvRef}
              title="Export as CSV"
              separator={delimitator}
              className="table_header_button export-csv"
              target="_blank"
              {...csvReport}
            >
              <IconExportCSV />
            </CSVLink>
          </Whisper>
          <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Export as</Tooltip>}>
            <button
              onClick={() => {
                setDropdownOpen((status) => !status);
              }}
              className="table_header_button small"
            >
              <span className="spacer">
                <IconDown />
              </span>
            </button>
          </Whisper>
        </div>
      </div>
      {dropdownOpen && (
        <div className="export-dropdown">
          <CSVLink
            ref={csvRef}
            title="Export as CSV"
            separator={delimitator}
            className=""
            target="_blank"
            {...csvReport}
          >
            <IconExportCSV />
            CSV (Excel, Google Sheets)
          </CSVLink>
          <button onClick={handleExportPdf}>
            <IconExportPDF />
            PDF File
          </button>
        </div>
      )}
    </div>
  );
}
