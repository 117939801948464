import { Option } from "types";

export type ReportTimeframeOption = {
  value: "Week" | "Month" | "Quarter" | "Year to Date" | "Year";
  label: string;
}[];

export const reportTimeframeOptions: ReportTimeframeOption = [
  { value: "Week", label: "Week" },
  { value: "Month", label: "Month" },
  { value: "Quarter", label: "Quarter" },
  { value: "Year to Date", label: "YTD" },
  { value: "Year", label: "Year" }
];

export const reportTimeframeOptionsComparePrior: Option[] = [
  { value: "Prior Period", label: "Quarters" },
  { value: "Prior Period", label: "Weeks" },
  { value: "Prior Period", label: "Months" },
  { value: "Year Over Year", label: "Years" }
];

export const checkboxOptionsByReport = {
  "Manager Weekly": [
    { options: "Show Budget", isDisabled: false, isChecked: false },
    { options: "Show Total", isChecked: false, isDisabled: false },
    { options: "Break", isChecked: false, isDisabled: false },
    { options: "Show YTD", isChecked: false, isDisabled: false }
  ],
  "Manager Monthly": [
    { options: "Show Budget", isChecked: false, isDisabled: false },
    { options: "Show Variance", isChecked: false, isDisabled: false },
    { options: "Show YTD", isChecked: false, isDisabled: false }
  ],
  "Investor Period": [
    { options: "Show Budget", isChecked: false, isDisabled: false },
    { options: "Show Variance", isChecked: false, isDisabled: false },
    { options: "Show YTD", isChecked: false, isDisabled: false }
  ],
  Custom: [
    { options: "Show Budget", isChecked: false, isDisabled: false },
    { options: "Show Total", isChecked: false, isDisabled: false },
    { options: "Breakdown by Weeks/Months/Period", isChecked: false, isDisabled: false },
    { options: "Reverse Column Order", isChecked: false, isDisabled: false }
  ],
  NoCNop: [
    { options: "Show Budget", isChecked: false, isDisabled: false },
    { options: "Show Variance", isChecked: false, isDisabled: false },
    { options: "Show YTD", isChecked: false, isDisabled: false }
  ]
};
export type ReportPLDatesType = "Custom" | "Manager Weekly" | "Manager Monthly" | "Investor Period" | "NoCNop";

export const optionsPLReports: ReportPLDatesType[] = [
  "Custom",
  "Manager Weekly",
  "Manager Monthly",
  "Investor Period",
  "NoCNop"
];
