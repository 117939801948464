import { ReactNode } from "react";
import { PureAbility } from "@casl/ability";
import { useAuthContext } from "../../context/useAuthContext";
import { useLocation } from "react-router-dom";
import Loading from "../Loading";
import { AbilityContext } from "context/Can";

import NotAuthorized from "presentation/pages/401";
import { useHoneLocationUsers } from "../HoneLocationUsers";
import { useLocationsStore } from "../../hooks/useLocationsStore";

export type Subjects = string;
export type Actions = "manage" | "create" | "read" | "update" | "delete";

export type AppAbility = PureAbility<[Actions, Subjects]> | undefined;

export type ACLObj = {
  action: Actions;
  subject: string;
};
interface AclGuardProps {
  children: ReactNode;
  authGuard?: boolean;
  guestGuard?: boolean;
  aclAbilities: ACLObj;
}

const getHomeRoute = (role: string) => {
  return "/dashboard";
};

export const defaultACLObj: ACLObj = {
  action: "manage",
  subject: "all"
};

const AclGuard = (props: AclGuardProps) => {
  const currentLocationId = useLocationsStore((state) => state.currentLocationId);
  const { currentLocationAbility, status } = useHoneLocationUsers();

  const { aclAbilities, children, guestGuard = false, authGuard = true } = props;

  const auth = useAuthContext();

  const location = useLocation();

  let ability: AppAbility;

  // Not in use as this is only parent of private routes, but leaving to eventually use it
  // const navigate = useNavigate();
  // useEffect(() => {
  //   if (auth.user && !guestGuard && location.pathname === "/") {
  //     const homeRoute = getHomeRoute("");
  //     navigate(homeRoute);
  //   }
  // }, [auth.user, guestGuard, location]);

  // User is logged in, build ability for the user based on his role
  if (auth.user && !ability) {
    ability = currentLocationAbility;
  }

  if (
    (location.pathname == "/" || status === "pending") &&
    !location.pathname.includes("bookkeeper") &&
    currentLocationId !== ""
  ) {
    return (
      <div className="h-100 center">
        <Loading />
      </div>
    );
  }

  // If guest guard or no guard is true or any error page
  if (guestGuard || location.pathname === "/404" || location.pathname === "/500" || !authGuard) {
    // If user is logged in and his ability is built
    if (auth.user && ability) {
      return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
    } else {
      // If user is not logged in (render pages like login, register etc..)
      return <>{children}</>;
    }
  }

  if (
    ability &&
    auth.user &&
    typeof ability.can === "function" &&
    ability.can(aclAbilities.action, aclAbilities.subject)
  ) {
    if (location.pathname === "/") {
      return <Loading />;
    }
    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
  }

  return <NotAuthorized />;
};

export default AclGuard;
