import { useDashboardReport } from "hooks/useDashboard";
import { roundValuesToOneDecimal, currencyFormatter } from "lib/utils";
import { useLocationsStore } from "hooks/useLocationsStore";
import { useNavigate } from "react-router-dom";

export default function DashboardAPAging(): JSX.Element {
  const { dashboardReport } = useDashboardReport();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const dashboardAPAging = dashboardReport?.cards.find((item) => item.type === "AP Aging")?.data.items;
  const navigateTo = useNavigate();

  const { currentLocationId } = useLocationsStore((state) => ({
    currentLocationId: state.currentLocationId,
    currentLocation: state.currentLocation,
  }));

  const redirectAPAging = () => {
    navigateTo(`/app/location/${currentLocationId}/report/ap-aging`);
  };

  const renderApAgingValue = (value: number) => {
    if (isMobile) {
      return `$${roundValuesToOneDecimal(value)}`;
    }
    return currencyFormatter(value);
  };

  return (
    <div className="dashboard-ap-aging-container">
      <h2>AP Aging Summary {isMobile && "(in 000s)"}</h2>
      {dashboardAPAging && (
        <div className="dashboard-ap-aging-container__summary">
          <div className="dashboard-ap-aging-container__summary_item" onClick={redirectAPAging}>
            <div className="summary_item_indicator amt_current">
              {renderApAgingValue(dashboardAPAging[0].amount ?? 0)}
            </div>
            <div className="summary_item_title">Current</div>
          </div>

          <div className="dashboard-ap-aging-container__summary_item item-border" onClick={redirectAPAging}>
            <div className="summary_item_indicator amt_1_to_30">
              {renderApAgingValue(dashboardAPAging[1].amount ?? 0)}
            </div>
            <div className="summary_item_title">1-30 Days</div>
          </div>

          <div className="dashboard-ap-aging-container__summary_item" onClick={redirectAPAging}>
            <div className="summary_item_indicator amt_31_and_over">
              {renderApAgingValue(dashboardAPAging[2].amount ?? 0)}
            </div>
            <div className="summary_item_title">31 Days and Over</div>
          </div>
        </div>
      )}
    </div>
  );
}
