import { useRef, useEffect } from "react";
import { uniqueId } from "lodash";

export function useUniqueId(prefix = ""): string {
  // value is constant, don't need setter
  const id = useRef(uniqueId(prefix));
  return id.current;
}

export function useFontsReady(onFontsReady: () => void): void {
  const savedCallback = useRef(onFontsReady);

  useEffect(() => {
    savedCallback.current = onFontsReady;
  }, [onFontsReady]);

  useEffect(() => {
    const detectFontsReady = async () => {
      await (document as any).fonts.ready.then(() => {
        savedCallback.current();
      });
    };

    detectFontsReady();
  }, []);
}
