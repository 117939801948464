import classNames from "classnames";
import { SlackChannelDetail } from "@hone-automation/common";
import { prefixClass } from "lib/utils";
import { ReactNode, useState } from "react";
import { IconClose } from "components/Icons";

type SlackItemProps = {
  isSelected: boolean;
  isInSecondColumn: boolean;
  item: SlackChannelDetail;
  onClick?: (item: any) => void;
  onClickRemove?: (item: any) => void;
};

const SlackItem = ({ isSelected, isInSecondColumn, item, onClick, onClickRemove }: SlackItemProps): ReactNode => {
  const prefix = prefixClass("bookkeeper-slack-content");
  const [showActions, setShowActions] = useState(false);

  return (
    <div
      onClick={() => onClick?.(item)}
      className={classNames(prefix("container"), {
        [prefix("container-select")]: isSelected,
        [prefix("container-second")]: isInSecondColumn
      })}
      onMouseEnter={() => setShowActions(true)}
      onMouseLeave={() => setShowActions(false)}
    >
      <div
        className={classNames(prefix("item"), {
          [prefix("item-select")]: isSelected
        })}
      ></div>

      <div className={prefix()}>
        <div className={classNames(prefix("data"), { [prefix("data-second")]: isInSecondColumn })}>
          <h5 className={prefix("data-name")}>{item.name}</h5>
        </div>
        <div className={prefix("container-type")}>
          <div
            className={classNames(prefix("type"), {
              [prefix("type-state")]: isSelected || isInSecondColumn
            })}
          >
            <h6 className={prefix("type-name")}>{item.numMembers}</h6>
            <p className={prefix("type-name")}>Members</p>
          </div>
          {onClickRemove && (
            <button
              className={classNames(prefix("type-button"), {
                [prefix("type-button-show")]: showActions
              })}
              onClick={onClickRemove}
            >
              <IconClose />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SlackItem;
