import { Header } from "presentation/components/HoneTable/types";

export const headers: Header[] = [
  {
    title: "locationId",
    key: "locationId",
    width: 50,
    hidden: true,
    align: "left",
    sortable: false,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: "Date",
    key: "date",
    width: 72,
    align: "left",
    styles: {
      fontWeight: "bold",
      backgroundColor: "transparent",
    },
    contentStyles: {
      textAlign: "left",
    },
    sortable: false,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: "Name/#",
    key: "name",
    headerTitleColor: "#000",
    styles: {
      fontWeight: "bold",
      backgroundColor: "transparent",
    },
    contentStyles: {
      textAlign: "left",
    },
    width: 250,
    align: "left",
    sortable: true,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: "Memo",
    key: "memo",
    headerTitleColor: "#000",
    styles: {
      fontWeight: "bold",
      backgroundColor: "transparent",
    },
    align: "left",
    width: 400,
    sortable: true,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: "External ID",
    key: "externalId",
    width: 200,
    align: "left",
    sortable: false,
    hidden: true,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: "Total",
    key: "total",
    align: "left",
    styles: {
      fontWeight: "bold",
      backgroundColor: "transparent",
    },

    width: 100,
    sortable: true,
    render: (value: any) => {
      return value;
    },
  },
];

export const JournalEntriesGeneralHeaders: Header[] = [
  {
    title: "ACCOUNT",
    key: "account",
    styles: {
      fontWeight: "bold",
      backgroundColor: "transparent",
    },
    width: 400,
    align: "left",
    sortable: false,
    footerStyles: {
      textAlign: "right",
      fontWeight: "bold",
      color: "#3E6378",
      textTransform: "uppercase",
    },
    contentStyles: {
      fontSize: "11px",
      width: 350,
      textAlign: "left",
      maxWidth: 300,
    },
    editable: false,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: "DEBIT",
    key: "debit",
    width: 80,
    align: "center",
    styles: {
      fontWeight: "bold",
      backgroundColor: "transparent",
    },
    contentStyles: {
      color: "#3E6378",
      fontFamily: "Open Sans",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.2px",
      width: 100,
      maxWidth: 100,
      backgroundColor: "transparent",
      textAlign: "right",
    },
    footerStyles: {
      textAlign: "right",
      fontWeight: "bold",
      color: "#3E6378",
      borderTop: "3px solid #3E6378",
      lineHeight: "29px",
      paddingRight: "8px",
      width: 100,
      maxWidth: 100,
    },
    cellType: "number",
    sortable: false,
    editable: false,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: "CREDIT",
    key: "credit",
    headerTitleColor: "#2D9CDB",
    cellType: "number",
    styles: {
      fontWeight: "bold",
      backgroundColor: "transparent",
      color: "#2D9CDB",
    },
    contentStyles: {
      color: "#2D9CDB",
      fontFamily: "Open Sans",
      fontSize: "13px",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "0.2px",
      width: 100,
      maxWidth: 100,
      backgroundColor: "transparent",
      textAlign: "right",
    },
    footerStyles: {
      textAlign: "right",
      fontWeight: "bold",
      color: "#2D9CDB",
      borderTop: "3px solid #2D9CDB",
      lineHeight: "29px",
      paddingRight: "8px",
      width: 100,
    },
    width: 80,
    align: "center",
    sortable: true,
    editable: false,
    render: (value: any) => {
      return value;
    },
  },
  {
    title: "DESCRIPTION",
    key: "description",
    headerTitleColor: "#000",
    cellType: "text",
    styles: {
      backgroundColor: "transparent",
      fontWeight: "bold",
    },
    editable: false,
    contentStyles: {
      color: "rgba(0,0,0, 0.4)",
      fontSize: "10px",
      width: 400,
    },
    align: "left",
    width: 400,
    sortable: true,
    render: (value: any) => {
      return value;
    },
  },
];

export const JournalEntriesData = [
  {
    locationId: "00000000-0000-0000-0000-000000000001",
    lines: [
      {
        amount: 6000,
        postingType: "Debit",
        accountExternalId: "35",
        externalId: "0",
        accountExternalName: "Checking",
      },
      {
        amount: 3000,
        postingType: "Credit",
        accountExternalId: "94",
        externalId: "1",
        accountExternalName: "Labor:Labor FOH",
      },
      {
        amount: 3000,
        postingType: "Credit",
        accountExternalId: "95",
        externalId: "2",
        accountExternalName: "Labor:Labor BOH",
      },
    ],
    externalId: "175",
    date: "2023-09-01",
    name: "Payroll Test",
    memo: "Test Memo from Hone",
  },
  {
    locationId: "00000000-0000-0000-0000-000000000001",
    lines: [
      {
        amount: 15286.95,
        postingType: "Debit",
        accountExternalId: "35",
        externalId: "0",
        accountExternalName: "Checking",
      },
      {
        amount: 6543.21,
        postingType: "Credit",
        accountExternalId: "94",
        externalId: "1",
        accountExternalName: "Labor:Labor FOH",
      },
      {
        amount: 6789.1,
        postingType: "Credit",
        accountExternalId: "95",
        externalId: "2",
        accountExternalName: "Labor:Labor BOH",
      },
      {
        amount: 652.25,
        postingType: "Credit",
        accountExternalId: "97",
        externalId: "3",
        accountExternalName: "Labor:Payroll Taxes:EMAC Supplement Contribution",
      },
      {
        amount: 994.84,
        postingType: "Credit",
        accountExternalId: "98",
        externalId: "4",
        accountExternalName: "Labor:Payroll Taxes:FICA Employer",
      },
      {
        amount: 34.12,
        postingType: "Credit",
        accountExternalId: "100",
        externalId: "5",
        accountExternalName: "Labor:Payroll Taxes:FUTA Expense",
      },
      {
        amount: 81.69,
        postingType: "Credit",
        accountExternalId: "102",
        externalId: "6",
        accountExternalName: "Labor:Payroll Taxes:MPFMLA Expense",
      },
      {
        amount: 191.74,
        postingType: "Credit",
        accountExternalId: "99",
        externalId: "7",
        accountExternalName: "Labor:Payroll Taxes:SUTA Expense",
      },
    ],
    externalId: "176",
    date: "2023-09-15",
    name: "Payroll 9/15",
    memo: "Full Payroll Entry. Created by Hone.",
  },
  {
    locationId: "00000000-0000-0000-0000-000000000001",
    lines: [
      {
        amount: 15286.95,
        postingType: "Debit",
        accountExternalId: "35",
        externalId: "0",
        accountExternalName: "Checking",
      },
      {
        amount: 6543.21,
        postingType: "Credit",
        accountExternalId: "94",
        externalId: "1",
        accountExternalName: "Labor:Labor FOH",
      },
      {
        amount: 6789.1,
        postingType: "Credit",
        accountExternalId: "95",
        externalId: "2",
        accountExternalName: "Labor:Labor BOH",
      },
      {
        amount: 652.25,
        postingType: "Credit",
        accountExternalId: "97",
        externalId: "3",
        accountExternalName: "Labor:Payroll Taxes:EMAC Supplement Contribution",
      },
      {
        amount: 994.84,
        postingType: "Credit",
        accountExternalId: "98",
        externalId: "4",
        accountExternalName: "Labor:Payroll Taxes:FICA Employer",
      },
      {
        amount: 34.12,
        postingType: "Credit",
        accountExternalId: "100",
        externalId: "5",
        accountExternalName: "Labor:Payroll Taxes:FUTA Expense",
      },
      {
        amount: 81.69,
        postingType: "Credit",
        accountExternalId: "102",
        externalId: "6",
        accountExternalName: "Labor:Payroll Taxes:MPFMLA Expense",
      },
      {
        amount: 191.74,
        postingType: "Credit",
        accountExternalId: "99",
        externalId: "7",
        accountExternalName: "Labor:Payroll Taxes:SUTA Expense",
      },
    ],
    externalId: "177",
    date: "2023-09-29",
    name: "Payroll 9/29",
    memo: "Created by Hone.",
  },
  {
    locationId: "00000000-0000-0000-0000-000000000001",
    lines: [
      {
        amount: 15286.95,
        postingType: "Debit",
        accountExternalId: "35",
        externalId: "0",
        accountExternalName: "Checking",
      },
      {
        amount: 6543.21,
        postingType: "Credit",
        accountExternalId: "94",
        externalId: "1",
        accountExternalName: "Labor:Labor FOH",
      },
      {
        amount: 6789.1,
        postingType: "Credit",
        accountExternalId: "95",
        externalId: "2",
        accountExternalName: "Labor:Labor BOH",
      },
      {
        amount: 652.25,
        postingType: "Credit",
        accountExternalId: "97",
        externalId: "3",
        accountExternalName: "Labor:Payroll Taxes:EMAC Supplement Contribution",
      },
      {
        amount: 994.84,
        postingType: "Credit",
        accountExternalId: "98",
        externalId: "4",
        accountExternalName: "Labor:Payroll Taxes:FICA Employer",
      },
      {
        amount: 34.12,
        postingType: "Credit",
        accountExternalId: "100",
        externalId: "5",
        accountExternalName: "Labor:Payroll Taxes:FUTA Expense",
      },
      {
        amount: 81.69,
        postingType: "Credit",
        accountExternalId: "102",
        externalId: "6",
        accountExternalName: "Labor:Payroll Taxes:MPFMLA Expense",
      },
      {
        amount: 191.74,
        postingType: "Credit",
        accountExternalId: "99",
        externalId: "7",
        accountExternalName: "Labor:Payroll Taxes:SUTA Expense",
      },
    ],
    externalId: "178",
    date: "2023-10-13",
    name: "Payroll 10/13",
    memo: "Created by Hone.",
  },
  {
    locationId: "00000000-0000-0000-0000-000000000001",
    lines: [
      {
        amount: 15286.95,
        postingType: "Debit",
        accountExternalId: "35",
        externalId: "0",
        accountExternalName: "Checking",
      },
      {
        amount: 6543.21,
        postingType: "Credit",
        accountExternalId: "94",
        externalId: "1",
        accountExternalName: "Labor:Labor FOH",
      },
      {
        amount: 6789.1,
        postingType: "Credit",
        accountExternalId: "95",
        externalId: "2",
        accountExternalName: "Labor:Labor BOH",
      },
      {
        amount: 652.25,
        postingType: "Credit",
        accountExternalId: "97",
        externalId: "3",
        accountExternalName: "Labor:Payroll Taxes:EMAC Supplement Contribution",
      },
      {
        amount: 994.84,
        postingType: "Credit",
        accountExternalId: "98",
        externalId: "4",
        accountExternalName: "Labor:Payroll Taxes:FICA Employer",
      },
      {
        amount: 34.12,
        postingType: "Credit",
        accountExternalId: "100",
        externalId: "5",
        accountExternalName: "Labor:Payroll Taxes:FUTA Expense",
      },
      {
        amount: 81.69,
        postingType: "Credit",
        accountExternalId: "102",
        externalId: "6",
        accountExternalName: "Labor:Payroll Taxes:MPFMLA Expense",
      },
      {
        amount: 191.74,
        postingType: "Credit",
        accountExternalId: "99",
        externalId: "7",
        accountExternalName: "Labor:Payroll Taxes:SUTA Expense",
      },
    ],
    externalId: "179",
    date: "2023-10-27",
    name: "Payroll 10/27",
    memo: "Created by Hone.",
  },
  {
    locationId: "00000000-0000-0000-0000-000000000001",
    lines: [
      {
        amount: 15286.95,
        postingType: "Debit",
        accountExternalId: "35",
        externalId: "0",
        description: "bank account debit",
        accountExternalName: "Checking",
      },
      {
        amount: 6543.21,
        postingType: "Credit",
        accountExternalId: "94",
        externalId: "1",
        description: "FOH Labor",
        accountExternalName: "Labor:Labor FOH",
      },
      {
        amount: 6789.1,
        postingType: "Credit",
        accountExternalId: "95",
        externalId: "2",
        description: "BOH Labor",
        accountExternalName: "Labor:Labor BOH",
      },
      {
        amount: 652.25,
        postingType: "Credit",
        accountExternalId: "97",
        externalId: "3",
        description: "EMAC Taxes",
        accountExternalName: "Labor:Payroll Taxes:EMAC Supplement Contribution",
      },
      {
        amount: 994.84,
        postingType: "Credit",
        accountExternalId: "98",
        externalId: "4",
        description: "FICA",
        accountExternalName: "Labor:Payroll Taxes:FICA Employer",
      },
      {
        amount: 34.12,
        postingType: "Credit",
        accountExternalId: "100",
        externalId: "5",
        description: "FUTA",
        accountExternalName: "Labor:Payroll Taxes:FUTA Expense",
      },
      {
        amount: 81.69,
        postingType: "Credit",
        accountExternalId: "102",
        externalId: "6",
        description: "MPFMLA",
        accountExternalName: "Labor:Payroll Taxes:MPFMLA Expense",
      },
      {
        amount: 191.74,
        postingType: "Credit",
        accountExternalId: "99",
        externalId: "7",
        description: "SUTA",
        accountExternalName: "Labor:Payroll Taxes:SUTA Expense",
      },
    ],
    externalId: "180",
    date: "2023-11-10",
    name: "Payroll 11/10",
    memo: "Created by Hone.",
  },
];

export function transformDateFormat(inputDate: Date): string {
  // Parse the input date string
  const dateObject = new Date(inputDate);

  // Extract year, month, and day
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(dateObject.getDate()).padStart(2, "0");

  // Create the transformed date string
  const transformedDate = `${year}-${month}-${day}`;

  return transformedDate;
}

export const getLastDayOfPastMonth = () => {
  const today = new Date();
  today.setDate(1);
  today.setMonth(today.getMonth() - 1);
  return today;
};
