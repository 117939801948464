import { useEffect, useState } from "react";
import { useCombobox, useMultipleSelection } from "downshift";
import { matchesAccount } from "../../../../lib/templateUtils";
import { eq, find } from "lodash";

interface Props {
  initialInputItems: HoneAccount[];
  initialSelectedItems?: HoneAccount[];
  onChange: (selectedAccounts: HoneAccount[]) => void;
}

function BookkeeperAccountsMultiSelect({ initialInputItems, initialSelectedItems = [], onChange }: Props): JSX.Element {
  const [inputValue, setInputValue] = useState("");
  const [inputItems, setInputItems] = useState<HoneAccount[]>([]);

  const itemToString = (item: HoneAccount | null) => {
    if (item) {
      if (item.AcctNum) {
        return `(${item.AcctNum}) ${item.Name}`;
      }

      return item.Name;
    }

    return "";
  };

  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    setSelectedItems,
    selectedItems
  } = useMultipleSelection({
    initialSelectedItems,
    itemToString,
    onSelectedItemsChange: ({ selectedItems: changedSelectedItems, type: changeType }) => {
      switch (changeType) {
        // ignore when we set the selected items from changed initialInputItems
        case useMultipleSelection.stateChangeTypes.FunctionSetSelectedItems:
          return;
      }

      onChange(changedSelectedItems || []);
    }
  });

  useEffect(() => {
    if (eq(inputItems, initialInputItems)) {
      return;
    }

    setInputItems(initialInputItems);
    setSelectedItems(initialSelectedItems);
  }, [initialInputItems, initialSelectedItems, setSelectedItems, inputItems]);

  const getFilteredItems = () => inputItems.filter((item) => matchesAccount(inputValue, item));

  const idsInArray2 = new Set(selectedItems.map((item) => item.Id));
  const sortingItems = getFilteredItems().sort((a, b) => {
    const aInArray2 = idsInArray2.has(a.Id) ? 0 : 1;
    const bInArray2 = idsInArray2.has(b.Id) ? 0 : 1;

    if (aInArray2 !== bInArray2) {
      return aInArray2 - bInArray2;
    }
    return Number(a.id) - Number(b.id);
  });

  const {
    isOpen,
    openMenu,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    getComboboxProps
  } = useCombobox({
    inputValue,
    selectedItem: null,
    items: sortingItems,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex, // keep the selection highlighted
            isOpen: true // keep the menu open after selection
          };
      }
      return changes;
    },
    onStateChange: ({ inputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || "");
          break;
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputBlur:
          if (selectedItem) {
            setInputValue("");

            if (find(selectedItems, selectedItem)) {
              removeSelectedItem(selectedItem);
            } else {
              addSelectedItem(selectedItem);
            }
          }
          break;
        default:
          break;
      }
    }
  });

  return (
    <div className="Autocomplete">
      <label {...getLabelProps()} className="visually-hidden">
        Choose some accounts:
      </label>
      <div>
        <div {...getComboboxProps()}>
          <input
            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen, onFocus: () => !isOpen && openMenu() }))}
            placeholder={selectedItems.length > 0 ? selectedItems.map((item) => item.Name).join(", ") : "None"}
            title={selectedItems.length > 0 ? selectedItems.map((item) => item.Name).join(",\n") : "None"}
            className="select"
          />
        </div>
      </div>
      <ul {...getMenuProps()} className="Autocomplete-baseMenu">
        {isOpen &&
          sortingItems &&
          sortingItems.map((item, index) => (
            <li
              className="Autocomplete-item"
              style={highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}}
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              <label style={{ pointerEvents: "none" }}>
                <input
                  type="checkbox"
                  {...getItemProps({
                    item,
                    index,
                    checked: Boolean(find(selectedItems, item))
                  })}
                  onChange={() => null}
                  {...getSelectedItemProps({ selectedItem: item, index })}
                />
                <span>{itemToString(item)}</span>
              </label>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default BookkeeperAccountsMultiSelect;
