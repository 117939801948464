import { PostAskRequestReCategorization } from "domain/usecases";
import { BaseHttpService } from "main/factories/http";

export class RemotePostReCategorizeAskRequest
  extends BaseHttpService<PostAskRequestReCategorization.Model>
  implements PostAskRequestReCategorization
{
  async post(body: PostAskRequestReCategorization.Body): Promise<PostAskRequestReCategorization.Model> {
    return await this.request("post", body);
  }
}
