import "./KSListItem.scss";
import classNames from "classnames";

export type KSListItemProps = {
  /** Renders a React component before the label (eg. location-list-item) */
  beforeLabel?: React.ReactNode;
  /** Renders a React component after the label (eg. location-list-item) */
  afterLabel?: React.ReactNode;
  /** The label of the list item */
  label: string;
  isSelected: boolean;
  onClickListItem: (label: string) => void;
  disabled?: boolean;
  notBigText?: boolean;
};

export default function KSListItem({
  beforeLabel,
  afterLabel,
  label,
  isSelected = false,
  onClickListItem,
  disabled = false,
  notBigText = false
}: KSListItemProps) {
  const isBigText = !notBigText && label.length > 15;
  return (
    <div
      className={classNames("ks-list-item", {
        "is-selected": isSelected,
        "is-big-text": isBigText,
        disabled: disabled
      })}
      onClick={() => onClickListItem(label)}
    >
      {beforeLabel}
      <div className="ks-list-item__label">{label}</div>
      {afterLabel}
    </div>
  );
}
