import { createRef, useMemo } from "react";
import ReactECharts from "echarts-for-react";
import EChartsReact from "echarts-for-react";
import { BussinessMetricsTypes } from "domain/models";
import { EChartsOption } from "echarts";
import { buildChartConfig } from "./config";
import { SummaryItem } from "@hone-automation/common";

type DashboardChartProps = {
  chartItems: SummaryItem[];
  type: "bar" | "pie" | "line";
  bmSelected: BussinessMetricsTypes;
  showLegend: boolean;
  legendPosition: "top" | "bottom";
  xAxis2nd: string;
  backgroundColor?: string;
};

export default function DashboardChart({
  chartItems,
  type,
  bmSelected,
  showLegend,
  legendPosition,
  xAxis2nd,
  backgroundColor
}: DashboardChartProps) {
  const chartRef = createRef<EChartsReact>();

  const echartsOptions: EChartsOption = useMemo(() => {
    return buildChartConfig(chartItems, type, showLegend, bmSelected, xAxis2nd, legendPosition, backgroundColor);
  }, [chartItems, type, bmSelected, xAxis2nd, legendPosition, backgroundColor]);

  return <ReactECharts opts={{ renderer: "svg" }} ref={chartRef} option={echartsOptions} />;
}
