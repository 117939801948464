import { IconCircleClose } from "components/Icons";
import { Configuration, Metrics } from "domain/models";
import { useBookkeeperReport } from "hooks/useBookkeeper";
import { useLocationsStore } from "hooks/useLocationsStore";
import { ChangeEvent } from "react";
import "./BookkeeperForm.css";

export default function BookkeeperMetricForm(): JSX.Element {
  const { metric, handleMetric, setShowNewMetric, createMetric } = useBookkeeperReport();
  const { currentLocationId } = useLocationsStore();

  const handleMetricChange = (value: string | Configuration, key: keyof Metrics) => {
    const currentMetric: Metrics = { ...metric, [key]: value, type: "Report", locationId: currentLocationId };
    handleMetric(currentMetric);
  };

  const handleCreateMetric = () => {
    metric !== undefined && currentLocationId && createMetric(metric, currentLocationId);
  };

  const closeCreateMetric = () => {
    handleMetric(undefined);
    setShowNewMetric(false);
  };

  return (
    <div className="bookkeeper-form">
      <div className="bookkeeper-form__close">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <IconCircleClose onClick={closeCreateMetric} />
      </div>

      {metric?.id && (
        <div className="bookkeeper-form__row">
          <label className="mr-2">ID</label>

          <input
            className="bookkepper-metrics-form__input"
            type="text"
            id="ID"
            name="ID"
            value={metric?.id}
            placeholder="Title"
            disabled
          />
        </div>
      )}

      <div className="bookkeeper-form__row">
        <label className="mr-2">Title</label>

        <input
          type="text"
          id="title"
          name="title"
          value={metric?.configuration?.title}
          placeholder="Title"
          className="bookkepper-metrics-form__input"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const currentConf: Configuration = { ...metric?.configuration, title: e.target.value };
            handleMetricChange(currentConf, "configuration");
          }}
        />
      </div>
      <div className="bookkeeper-form__row">
        <label className="mr-2">Report Section Title</label>

        <input
          type="text"
          id="section"
          name="section"
          className="bookkepper-metrics-form__input"
          value={metric?.configuration?.value?.section}
          placeholder="Value"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const currentConf: Configuration = { ...metric?.configuration, value: { section: e.target.value } };
            handleMetricChange(currentConf, "configuration");
          }}
        />
      </div>
      <div className="bookkeeper-form__row">
        <label className="mr-2">Percent Title</label>

        <input
          type="text"
          id="percentTitle"
          name="percentTitle"
          className="bookkepper-metrics-form__input"
          value={metric?.configuration?.percentTitle}
          placeholder="Percent Title"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const currentConf: Configuration = { ...metric?.configuration, percentTitle: e.target.value };
            handleMetricChange(currentConf, "configuration");
          }}
        />
      </div>
      <div className="bookkeeper-form__actions">
        <button className="button button_outline" type="button" onClick={closeCreateMetric}>
          <span>Cancel</span>
        </button>
        <button
          className="button BKForm-btn-warning"
          type="button"
          disabled={!metric?.configuration?.title || !metric.configuration.value}
          onClick={handleCreateMetric}
        >
          <span>{metric?.id ? "Update Metric" : "Create Metric"}</span>
        </button>
      </div>
    </div>
  );
}
