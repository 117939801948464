import { AskRequest, AskRequestStatus, CreateAskRequest, LinkTypes } from "@hone-automation/common";
import {
  useCreateAskRequestMutation,
  useCreateFlagTransactionCommentMutation,
  useReopenFlaggedTransactionMutation
} from "hooks/flaggedTransactions";
import { useState } from "react";
import { DataCellState } from "domain/models";
import Loading from "components/Loading";
import { useReportsStore } from "hooks/useReportsStore";
import { shallow } from "zustand/shallow";
import Chat from "presentation/components/Chat/Chat";
import { UIFlaggedTransaction, UIReportFlaggedCell } from "types/askcustomer";
import { useQueryState } from "hooks/useQueryState";
import classNames from "classnames";
import { currencyFormatter2Decimals, isPeriodReport } from "lib/utils";
import ChatMarkAsSolvedButton from "presentation/components/Chat/ChatMarkAsSolvedButton";
import TransactionDetails from "../TransactionDetails";
import { useAuthContext } from "context/useAuthContext";
import { useParams } from "react-router-dom";

interface FlagAskRequestProps {
  modalState: DataCellState;
  handleCancelFlag: () => void;
  updateFlaggedCell: (cell: UIReportFlaggedCell) => void;
  hasFlaggedTransactions: any;
}

export default function FlagAskRequest({
  modalState,
  handleCancelFlag,
  updateFlaggedCell,
  hasFlaggedTransactions
}: FlagAskRequestProps) {
  const { locationId: currentLocationId } = useParams();

  const [activeTab] = useQueryState<AskRequestStatus>("activeTab", "Open");
  const { user } = useAuthContext();
  const { selectedReport } = useReportsStore(
    (state) => ({
      selectedReport: state.selectedReport
    }),
    shallow
  );

  const [comment, setComment] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { title, total, date } = modalState;

  const createAskRequestMutation = useCreateAskRequestMutation(
    currentLocationId!,
    (cell: UIFlaggedTransaction | AskRequest | undefined) => {
      if (cell) {
        updateFlaggedCell({
          flaggedCell: cell as AskRequest
        });
      }
    }
  );

  const createCommentMutation = useCreateFlagTransactionCommentMutation(activeTab, currentLocationId!, (response) => {
    if (response) {
      updateFlaggedCell({
        flaggedCell: response as any
      });
    }
  });

  const addComment = (comment: string) => {
    setLoading(true);

    createCommentMutation.mutate({
      comment,
      userId: user?.uid,
      locationId: currentLocationId,
      activeTransaction: hasFlaggedTransactions
    });
    setLoading(false);
  };
  const periodReport = isPeriodReport(selectedReport);
  const createAskRequest = () => {
    setLoading(true);
    const transactionToFlag: CreateAskRequest = {
      locationId: currentLocationId!,
      type: "Flagged Cell",
      reportId: periodReport && date?.reportId ? date.reportId : selectedReport?.id,
      reportType: periodReport ? LinkTypes.Period_Report : LinkTypes.Location_Report,
      identifier: title,
      period: date?.start,
      flaggedAmount: total,
      comment: comment
    };
    createAskRequestMutation.mutate(transactionToFlag);
    setLoading(false);
  };

  const isClosedTrx =
    hasFlaggedTransactions &&
    (hasFlaggedTransactions?.status === "Closed" || (hasFlaggedTransactions.status as any) === "Categorized");
  const canReopen = hasFlaggedTransactions?.canReopen && isClosedTrx;

  const postReopenFlaggedTransaction = useReopenFlaggedTransactionMutation(
    activeTab,
    currentLocationId!,
    (response) => {
      //
    }
  );

  const handleReopen = (comment: string) => {
    postReopenFlaggedTransaction.mutate({
      trxId: hasFlaggedTransactions?.id,
      comment
    });
  };

  const statusLocal = () => {
    if ((hasFlaggedTransactions?.status as any) === "Categorized") return "Closed";
    if ((hasFlaggedTransactions?.status as any) === "Information Needed") return "Open";
    return hasFlaggedTransactions?.status || "Open";
  };

  const flaggedAmountFormatted = () =>
    hasFlaggedTransactions?.flaggedAmount && currencyFormatter2Decimals(hasFlaggedTransactions.flaggedAmount);

  const flaggedAmount = () => {
    const trxAmount = flaggedAmountFormatted();
    const sectionAmountFormatted = modalState.total && currencyFormatter2Decimals(modalState.total);
    if (!sectionAmountFormatted || trxAmount === sectionAmountFormatted) {
      return <div>{trxAmount}</div>;
    }

    return (
      <div>
        <span>{trxAmount}</span>
        <span className="section-amount"> | {sectionAmountFormatted}</span>
      </div>
    );
  };

  const detailsRows = [
    {
      label: "Type",
      value: "Flagged Report Cell"
    },
    {
      label: "Amount",
      value: flaggedAmount()
    }
  ];

  return (
    <div className={classNames("chat-container", "flag-cell")}>
      {loading ? (
        <div className="loading">
          <Loading />
        </div>
      ) : (
        <>
          {!hasFlaggedTransactions ? (
            <>
              <div className="chat-header" />
              <div className="chat-body flag-ask-request-form">
                <div className="form-control">
                  <label htmlFor="comment"></label>
                  <textarea
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    autoFocus
                    name="comment"
                    placeholder="Tell KitchenSync why are you flagging this:"
                  />
                </div>
              </div>
              <div className={"chat-footer-btns"}>
                <button type="button" className={"cancel"} onClick={handleCancelFlag}>
                  CANCEL
                </button>
                <button
                  className={"send-bookkeeper button"}
                  type="button"
                  onClick={createAskRequest}
                  disabled={!comment}
                >
                  SUBMIT
                </button>
              </div>
            </>
          ) : (
            <>
              <TransactionDetails rows={detailsRows} transaction={hasFlaggedTransactions.flagged} />
              <div style={{ marginBottom: "18px" }}>
                {hasFlaggedTransactions?.id && statusLocal() !== "Closed" && (
                  <ChatMarkAsSolvedButton txId={hasFlaggedTransactions?.id} isSideView={false} />
                )}
              </div>
              <Chat
                canReOpen={canReopen}
                onSubmit={(comment: string) => (canReopen ? handleReopen(comment) : addComment(comment))}
                isClosedTrx={isClosedTrx}
                messages={hasFlaggedTransactions?.comments}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
