import React, { useState } from "react";
import { Cell, CellProps, Column, HeaderCell, SortType, Table } from "rsuite-table";
import { useReportsStore } from "hooks/useReportsStore";
import useAuditReportDismiss from "hooks/useAuditReportDismiss";
import classNames from "classnames";
import { auditTextResult, filterRows, getStatusIcon } from "lib/utils";
import Select from "react-select";

import Checkbox from "../Checkbox/Checkbox";
import Pagination from "../pagination/Pagination";

const STATUS = ["All", "Error", "Warning", "Fail", "Pass"];
const statusOptions = STATUS.map((status) => ({ value: status, label: status }));

type CheckCellProps = Omit<CellProps, "onChange"> & {
  checkedKeys: string[];
  dataKey: string;
  onChange: any;
};

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }: CheckCellProps) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: "46px" }}>
      <Checkbox
        value={rowData[dataKey]}
        onChange={onChange}
        checked={checkedKeys.some((item) => item === rowData[dataKey])}
      />
    </div>
  </Cell>
);

export const IconCell = ({ rowData, ...props }: CellProps): JSX.Element => (
  <Cell {...props}>
    <i className={classNames(["fa", "fa-solid", getStatusIcon(rowData.type), rowData.type])} />
  </Cell>
);

function AuditReportTable(): JSX.Element | null {
  const { onDismissRequest } = useAuditReportDismiss();
  const defaultData = useReportsStore((state) => state.auditReport);
  const [sortColumn, setSortColumn] = useState<"type">();
  const [sortType, setSortType] = useState<SortType | undefined>();
  const [filter, setFilter] = useState<string>("All");
  const [limit, setLimit] = useState<number>(15);
  const [page, setPage] = useState<number>(1);

  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);

  const handleCheckAll = (value: number | undefined | string, checked: boolean) => {
    const keys = checked ? data.map((item) => item.id) : [];
    setCheckedKeys(keys);
  };
  const handleCheck = (value: string, checked: boolean) => {
    const keys = checked ? [...checkedKeys, value] : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(keys);
  };

  if (!defaultData) {
    return null;
  }

  const getData = (): AuditReport[] => {
    if (sortColumn && sortType) {
      return defaultData.sort((a, b) => {
        const _a = a[sortColumn];
        const _b = b[sortColumn];
        let x = 0,
          y = 0;
        if (typeof _a === "string") {
          x = _a.charCodeAt(0);
        }
        if (typeof _b === "string") {
          y = _b.charCodeAt(0);
        }
        if (sortType === "asc") {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return defaultData;
  };

  const filteredRows = filterRows<AuditReport>(getData(), filter);

  const data = filteredRows.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  let checked = false;
  let indeterminate = false;

  if (checkedKeys.length === data.length) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
    indeterminate = true;
  }

  const handleDismissAllSelected = () => {
    const elementsToDismiss = defaultData.filter((row) => {
      return checkedKeys.includes(row.id);
    });
    onDismissRequest(elementsToDismiss);
  };

  const handleDismissRow = (id: string) => {
    const elementToDismiss = defaultData.find((row) => {
      return row.id === id;
    });
    if (elementToDismiss) {
      onDismissRequest([elementToDismiss]);
    }
  };

  const handleFilterChange = (option: any) => {
    if (option) {
      setFilter(option.value);
    }
  };

  const handleSortColumn = (sortColumn: string, sortType: SortType | undefined) => {
    setSortColumn("type");
    setSortType(sortType);
  };

  return (
    <div className="audit-report-details-table">
      <div style={{ width: "200px" }}>
        <label htmlFor="status_filter">Filter</label>
        <Select
          name="status_filter"
          onChange={handleFilterChange}
          defaultValue={{ value: "All" }}
          options={statusOptions}
        />
      </div>
      <Table sortColumn={sortColumn} sortType={sortType} onSortColumn={handleSortColumn} data={data}>
        <Column width={50} align="center">
          <HeaderCell align="center" style={{ padding: 0 }}>
            <div style={{ lineHeight: "40px" }}>
              <Checkbox inline checked={checked} indeterminate={indeterminate} onChange={handleCheckAll} />
            </div>
          </HeaderCell>
          <CheckCell dataKey="id" checkedKeys={checkedKeys} onChange={handleCheck} />
        </Column>
        <Column sortable width={100}>
          <HeaderCell align="center">Status</HeaderCell>
          <IconCell dataKey="type" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell align="center">
            {checkedKeys.length ? (
              <button onClick={handleDismissAllSelected}>Dismiss all selected ({checkedKeys.length})</button>
            ) : (
              "Field"
            )}
          </HeaderCell>
          <Cell dataKey="fieldName"></Cell>
        </Column>
        <Column flexGrow={3}>
          <HeaderCell align="center">Message</HeaderCell>
          <Cell dataKey="message">{(rowData) => auditTextResult(rowData as AuditReport)}</Cell>
        </Column>
        <Column width={108}>
          <HeaderCell align="center">Dismiss</HeaderCell>
          <Cell>
            {(rowData) =>
              rowData.type !== "Pass" && (
                <button className="button button_outline" onClick={() => handleDismissRow(rowData.id)}>
                  Dismiss
                </button>
              )
            }
          </Cell>
        </Column>
      </Table>
      <div style={{ paddingTop: 20 }}>
        <Pagination activePage={page} data={filteredRows} dataLimit={15} onChangePage={setPage} />
      </div>
    </div>
  );
}

export default AuditReportTable;
