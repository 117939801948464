import { AskRequestStatus } from "@hone-automation/common";
import classNames from "classnames";
import { useLocationsStore } from "hooks/useLocationsStore";
import { getDigits } from "lib/utils";
import { snakeCase } from "lodash";
import { useNavigate } from "react-router-dom";

type Props = {
  indicator: number;
  title: string;
  addBorder?: boolean;
  activeTab: AskRequestStatus;
};

export default function DashboardFlaggedTransacionsSummaryItem({
  addBorder = false,
  indicator,
  title,
  activeTab,
}: Props): JSX.Element {
  const navigateTo = useNavigate();
  const { currentLocationId } = useLocationsStore();
  const redirectAskCustomer = () => {
    navigateTo(`/app/location/${currentLocationId}/ask-requests?activeTab=${activeTab}`);
  };
  return (
    <div
      className={classNames("dashboard-flagged-transactions-container__summary_item", { "item-border": addBorder })}
      onClick={redirectAskCustomer}
    >
      <div
        data-testid={`transaction-${snakeCase(title)}`}
        className={classNames("summary_item_indicator", snakeCase(title), {
          "add-padding": getDigits(indicator) === 1,
        })}
      >
        {indicator}
      </div>
      <div className="summary_item_title">{title}</div>
    </div>
  );
}
