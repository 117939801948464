import { useQueryState } from "./useQueryState";

export type Action = "lines" | "bars";

type ActiveActions = Action[];

const DefaultValues = {
  amounts: "true",
  percentages: "true",
};

type UseActiveActions = {
  amounts: boolean;
  percentages: boolean;
  toggleAction: (action: Action) => void;
  activeActions: ActiveActions;
};

/**
 * The `useActiveActions` hook used to manage the state of amounts and percentages in reports.
 * @function
 * @returns {UseActiveActions} An object with two properties: `toggleAction` and `activeActions`.
 */
function useActiveActions(): UseActiveActions {
  const [amounts, setAmounts] = useQueryState<string>("amounts", DefaultValues.amounts);
  const [percentages, setPercentage] = useQueryState<string>("percentages", DefaultValues.percentages);

  /**
   * The `toggleAction` function used to toggle the state of a particular action.
   * @param {Action} action The action to toggle.
   * @returns {void}
   */
  const toggleAction = (action: Action): void => {
    switch (action) {
      case "lines":
        setPercentage((!(percentages === "true")).toString());
        break;
      case "bars":
        setAmounts((!(amounts === "true")).toString());
    }
  };

  /**
   * The `getActiveActions` function used to retrieve the currently active actions.
   * @returns {ActiveActions} An array of currently active actions.
   */
  const getActiveActions = (): ActiveActions => {
    let activeActions: Action[] = [];
    if (amounts === "true") {
      activeActions.push("bars");
    } else {
      activeActions = activeActions.filter((_action) => _action !== "bars");
    }

    if (percentages === "true") {
      activeActions.push("lines");
    } else {
      activeActions = activeActions.filter((_action) => _action !== "lines");
    }

    return activeActions;
  };

  return {
    amounts: /true/.test(amounts.toLowerCase()),
    percentages: /true/.test(percentages.toLowerCase()),
    toggleAction,
    activeActions: getActiveActions(),
  };
}

export default useActiveActions;
