import { Suspense, useState, useMemo, useRef, useCallback } from "react";
import { isEmpty } from "lodash";
import { useHoneReportTemplates } from "../../../../components/HoneReportTemplates";
import Loading from "../../../../components/Loading";
import "./BookkeeperForm.css";
import BookkeeperTemplateSectionsForm from "./BookkeeperTemplateSectionsForm";
import { IconSettings, IconCustomSections, IconSave, IconExpand, IconCollapse } from "../../../../components/Icons";
import type { SyntheticEvent } from "react";
import BookkeeperTemplatePreview from "./BookkeeperTemplatePreview";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import {
  getLocalStorage,
  setLocalStorage,
  dismissToast,
  showToast,
  TOAST_DEFAULT,
  TOAST_ERROR,
  TOAST_SUCCESS,
  FIVE_SECONDS,
  HALF_MINUTE,
  ONE_MINUTE,
  TOAST_SAVE_TEXT
} from "lib/utils";
import { SlidingPane } from "../../../../components/SlidingPane";
import FloatingActionButton from "../../../../components/FloatingActionButton";
import BookkeeperTemplateSettingsForm from "./BookkeeperTemplateSettingsForm";
import { useLocationsStore } from "hooks/useLocationsStore";
import { refreshAccounts } from "hooks/useReportsStore";
import { useQueryState } from "hooks/useQueryState";
import { useNavigate } from "react-router-dom";
import * as TemplateUtils from "../../../../lib/templateUtils";
import { FormProvider, useForm } from "react-hook-form";

const TOAST_COPY_TEXT = "Submitted copy request, will take a moment to copy template";
const TOAST_COA_TEXT = "Refreshing Chart of Accounts, will take a moment to fetch the latest";
const TOAST_DELETE_TEXT = "Submitted delete request, will take a moment to delete template";

function BookkeeperTemplateForm(): JSX.Element {
  const navigateTo = useNavigate();
  const currentLocationId = useLocationsStore((state) => state.currentLocationId);
  const { status: locationsStatus, currentLocation } = useLocationsStore();
  const {
    allTemplates,
    status: templatesStatus,
    currentTemplateSettings,
    currentTemplate,
    templateLoading,
    handleTemplateChange,
    currentTemplateId,
    setCurrentTemplateId,
    setTemplate
  } = useHoneReportTemplates();

  const [expandAll, setExpandAll] = useQueryState("expandAll", "true");

  const { copyTemplate, deleteTemplate } = useHoneReportTemplates();
  const [settings, setSettings] = useQueryState<string>("settings", "true,false");
  const methods = useForm<HoneTemplateSettings & { currentTemplate: FlatTemplateSection[] }>({
    defaultValues: {
      title: "",
      type: "Income Statement",
      visibility: "All Employees",
      status: "Draft",
      publishDelayDays: 0,
      timeframe: "Weekly",
      period: 1,
      currentTemplate
    }
  });
  const { handleSubmit } = methods;

  const [showPreview, setShowPreview] = useState<boolean>(false);

  ////////////////////// Available Templates section
  function handleHoneTemplateChange(newTemplateId: string) {
    if (newTemplateId === "") {
      return;
    }

    const template = allTemplates.find((t) => t.id === newTemplateId);
    if (!template) {
      showToast("Template Id doesn't exist", TOAST_ERROR, FIVE_SECONDS);
      navigateTo(`/app/location/${currentLocationId}/bookkeeper?activetab=Templates`);
    }

    // Force this to be 0, if the new template has a null value,
    // It won't clear out the current value unless we do this...
    setCurrentTemplateId(newTemplateId);
  }
  //////////////////////////////////////////////////

  const saveButtonEnabled = true;

  const copyButtonTime = useMemo(() => {
    const localTimeCopy = getLocalStorage("hone:timeCopy");
    return localTimeCopy ? new Date(localTimeCopy).getTime() + HALF_MINUTE : 0;
  }, []);
  const copyButtonEnabled = !(copyButtonTime > new Date().getTime());

  const deleteButtonTime = useMemo(() => {
    const localTimeDelete = getLocalStorage("hone:timeDelete");
    return localTimeDelete ? new Date(localTimeDelete).getTime() + HALF_MINUTE : 0;
  }, []);
  const deleteButtonEnabled = !(deleteButtonTime > new Date().getTime());

  const handleRefreshAccounts = async (e: SyntheticEvent<HTMLButtonElement>) => {
    if (currentLocation) {
      const toastId = showToast(TOAST_COA_TEXT, TOAST_DEFAULT, ONE_MINUTE);
      refreshAccounts(currentLocation.id)
        .then(() => {
          dismissToast(toastId);
          showToast("Successfully refreshed accounts (COAs)", TOAST_SUCCESS, FIVE_SECONDS);
        })
        .catch((error: any) => {
          dismissToast(toastId);
          showToast(`Error refreshing accounts: ${error.toString()}`, TOAST_ERROR, FIVE_SECONDS);
        });
    }
  };

  const onSubmit = useCallback(
    async (form: HoneTemplateSettings & { currentTemplate: FlatTemplateSection[] }) => {
      if (form.title && form.type && form.timeframe && form.period && form.period > 0) {
        if (currentLocation && currentTemplateSettings) {
          const toastId = showToast(TOAST_SAVE_TEXT, TOAST_DEFAULT, ONE_MINUTE);
          setTemplate(
            currentTemplateSettings.id,
            currentLocation.id,
            form.title,
            form.type as HoneReportType,
            form.visibility as HoneReportVisibility,
            form.status as HoneReportStatus,
            form.timeframe as HoneReportTimeframe,
            form.period,
            Number(form.publishDelayDays) ?? 0,
            form.currentTemplate
          )
            .then((result) => {
              dismissToast(toastId);
              showToast("Successfuly saved template and generated report.", TOAST_SUCCESS, FIVE_SECONDS);
            })
            .catch((error) => {
              dismissToast(toastId);
              showToast("Error saving template: " + error.message, TOAST_ERROR, FIVE_SECONDS);
            });
        }
      }
    },
    [currentLocation, currentTemplateSettings, setTemplate]
  );

  const handleCopy = async (e: SyntheticEvent<HTMLButtonElement>) => {
    const target = e.currentTarget;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3>Confirm copy template to 'Copy {currentTemplateSettings?.title}'?</h3>
            <button className="button BKForm-btn-secondary" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button BKForm-btn"
              onClick={() => {
                if (target) {
                  target.disabled = true;
                }
                onClose();
                showToast(TOAST_COPY_TEXT, TOAST_DEFAULT, HALF_MINUTE);
                setLocalStorage("hone:timeCopy", new Date().toString());
                copyTemplate("" + currentTemplateId);
              }}
            >
              Copy
            </button>
          </div>
        );
      }
    });
  };

  const handleDelete = async (e: SyntheticEvent<HTMLButtonElement>) => {
    const target = e.currentTarget;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3>Are you sure you want to delete '{currentTemplateSettings?.title}'?</h3>
            <button className="button BKForm-btn-secondary" onClick={onClose}>
              Cancel
            </button>
            <button
              className="button BKForm-btn"
              onClick={() => {
                if (target) {
                  target.disabled = true;
                }
                onClose();
                showToast(TOAST_DELETE_TEXT, TOAST_DEFAULT, ONE_MINUTE);
                deleteTemplate("" + currentTemplateId);
              }}
            >
              Delete
            </button>
          </div>
        );
      }
    });
  };

  const togglePreview = () => {
    handleClickSettings("false, true");
    setShowPreview(!showPreview);
  };

  const actions = [
    {
      label: "Save",
      icon: <IconSave />,
      onClick: methods.handleSubmit(onSubmit)
    }
  ];

  const handleClickSettings = (settings: string) => {
    setSettings(settings);
  };

  const tabs = typeof settings === "string" ? settings.split(",").map((s) => s === "true") : [true, false];

  const templateIdExists = useMemo(() => {
    return allTemplates.some((template) => template.id === currentTemplateId);
  }, [allTemplates, currentTemplateId]);

  const templateSectionGroups: FlatTemplateSection[][] = TemplateUtils.calculateTemplateGroups(currentTemplate);

  const initialCustomCalculations: CustomCalculation[] = TemplateUtils.makeInitialCustomCalculations(currentTemplate);

  return (
    <div style={{ width: "80vw", overflowY: "scroll", height: "80vh" }}>
      <div className="BKForm-section d-flex justify-content-center mb-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          {/* <button type="button" className="button button_link" onClick={handleAddNewReportClick}>
            <span className="visually-hidden">Add new template</span>
            <IconAddCircle />
          </button> */}
        </div>

        {locationsStatus !== "loading" && templatesStatus !== "pending" && allTemplates.length === 0 ? (
          <span>
            No templates available for <strong>{currentLocation?.name}</strong>
          </span>
        ) : (
          <section data-testid="template-selection-section">
            <span className="mr-3">Template: </span>
            <select
              id="templates"
              name="templates"
              role="combobox"
              className="BKForm-section-select width-350 "
              onChange={(e) => handleHoneTemplateChange(e.currentTarget.value)}
              value={currentTemplateId || ""}
              data-testid="templates"
            >
              <option value="" disabled>
                &lt;Select Template&gt;
              </option>
              {allTemplates.map((template, index) => (
                <option value={template.id} key={index}>
                  {template.title}
                </option>
              ))}
            </select>
          </section>
        )}
      </div>
      {templateIdExists && (
        <section data-testid="active-template-section">
          <div className="d-flex justify-content-center mb-8">
            <span className="mt-3">
              <button
                className="button BKForm-btn-alert"
                type="button"
                disabled={!(currentTemplateSettings && !isEmpty(currentTemplate) && deleteButtonEnabled)}
                onClick={(e) => handleDelete(e)}
              >
                Delete
              </button>
            </span>

            <span className="mt-3">
              <button
                className="button BKForm-btn btn-secondary"
                type="button"
                disabled={!currentLocation}
                onClick={(e) => handleRefreshAccounts(e)}
              >
                Refresh CoA
              </button>

              <button
                className="button BKForm-btn btn-secondary"
                type="button"
                disabled={!(currentTemplateSettings && !isEmpty(currentTemplate) && copyButtonEnabled)}
                onClick={(e) => handleCopy(e)}
              >
                Copy
              </button>

              <button
                className="button BKForm-btn-save"
                type="submit"
                disabled={!(currentTemplateSettings && !isEmpty(currentTemplate) && saveButtonEnabled)}
                onClick={handleSubmit(onSubmit)}
              >
                Save
              </button>
            </span>
          </div>
          <div className="d-flex justify-content-center mb-8">
            <button
              className={`d-flex flex-column align-items-center button button_outline px-2 mx-2${
                tabs[0] ? "" : " opacity-50"
              }`}
              type="button"
              onClick={() => handleClickSettings("true,false")}
            >
              <IconSettings />
              <span>Settings</span>
            </button>
            <button
              className={`d-flex flex-column align-items-center button button_outline px-2 mx-2${
                tabs[1] ? "" : " opacity-50"
              }`}
              type="button"
              onClick={() => handleClickSettings("false,true")}
            >
              <IconCustomSections />
              <span>Sections</span>
            </button>
          </div>

          {templateLoading ? (
            <Loading />
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="BKForm-tabs mb-4" id="tab-settings" style={{ display: tabs[0] ? "block" : "none" }}>
                  <BookkeeperTemplateSettingsForm />
                </div>
                {tabs[1] && (
                  <div>
                    {expandAll === "true" ? (
                      <div
                        className="BKforms-tabs-expand-collapse"
                        onClick={() => {
                          setExpandAll("false");
                        }}
                      >
                        <IconExpand /> Collapse All
                      </div>
                    ) : (
                      <div
                        className="BKforms-tabs-expand-collapse"
                        onClick={() => {
                          setExpandAll("true");
                        }}
                      >
                        <IconCollapse />
                        Expand all
                      </div>
                    )}
                  </div>
                )}

                <div className="BKForm-tabs" id="tab-sections" style={{ display: tabs[1] ? "block" : "none" }}>
                  {!currentLocation && <Loading />}
                  {currentLocation && (
                    <BookkeeperTemplateSectionsForm
                      initialCustomCalculations={initialCustomCalculations}
                      templateSectionGroups={templateSectionGroups}
                      currentLocation={currentLocation}
                      currentTemplate={currentTemplate}
                      expandAll={expandAll === "true"}
                      onTemplateChange={(newTemplate: FlatTemplateSection[]) => {
                        handleTemplateChange(newTemplate);
                      }}
                    />
                  )}
                </div>
              </form>
            </FormProvider>
          )}

          <SlidingPane
            onRequestClose={togglePreview}
            title={currentTemplateSettings?.title}
            width="90%"
            isOpen={showPreview}
          >
            {!currentLocation && <Loading />}
          </SlidingPane>
          {tabs[1] && <FloatingActionButton visible actions={actions} />}
        </section>
      )}
    </div>
  );
}

export default BookkeeperTemplateForm;
