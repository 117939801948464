import useLocationRouting from "hooks/useLocationRouting";
import { AppLayout } from "presentation/components";

import JournalEntriesByIdView from "presentation/pages/journal-entries/JournalEntriesByIdView";

export const JournalEntriesByIdFactory = () => {
  useLocationRouting(false);
  return (
    <AppLayout noPadding>
      <JournalEntriesByIdView />
    </AppLayout>
  );
};
