import { Fragment, useEffect, useMemo } from "react";
import {
  HoneReportStatuses,
  HoneReportTimeframes,
  HoneReportTypes,
  HoneReportVisibilities,
} from "../../../../constants";
import { useHoneReportTemplates } from "components/HoneReportTemplates";

import { useFormContext } from "react-hook-form";
import filter from "lodash/filter";
import { useLocationsStore } from "hooks/useLocationsStore";

const BookkeeperTemplateSettingsForm = () => {
  const { register, watch, setValue, reset } = useFormContext();
  const currentLocation = useLocationsStore((state) => state.currentLocation);
  const { allTemplates, currentTemplateSettings, currentTemplate } = useHoneReportTemplates();
  useEffect(() => {
    if (currentTemplateSettings) {
      reset({
        ...currentTemplateSettings,
        publishDelayDays: currentTemplateSettings?.publishDelayDays ?? 0,
      });
    }
    if (currentTemplate.length > 0) {
      reset({
        ...currentTemplateSettings,
        currentTemplate,
      });
    }
  }, [currentTemplateSettings, currentTemplate, reset]);

  const timeframeValue = watch("timeframe");

  useEffect(() => {
    if (timeframeValue === "Weekly" || timeframeValue === "Monthly") {
      setValue("type", HoneReportTypes.PLComparison);
    } else if (timeframeValue === "YTD Monthly") {
      setValue("type", HoneReportTypes.IncomeStatement);
    }
  }, [timeframeValue, setValue]);

  // form watchers
  const isPublished = watch("status") === HoneReportStatuses.Published;
  const formType = watch("type");

  const statusChoices = Object.values(HoneReportStatuses);

  const visibilityChoices = Object.values(HoneReportVisibilities);

  const timeframeChoices = useMemo(() => {
    return Object.values(HoneReportTimeframes).filter((timeframeChoice) => {
      // show all available timeframes
      if (currentTemplateSettings) return true;

      // find reports that match selected type
      // if any reports are of this timeframe, remove it
      const existingReportTimeframes = filter(allTemplates, { type: formType }).map((r) => r.timeframe);

      return !existingReportTimeframes.includes(timeframeChoice);
    });
  }, [allTemplates, currentTemplateSettings, formType]);

  return (
    <div className="BKForm-form">
      <fieldset disabled={!currentLocation}>
        <div className="BKForm-inline mb-4">
          <label className="BKForm-label" htmlFor="title">
            Name
          </label>
          <input
            className="BKFrom-input "
            type="text"
            id="title"
            placeholder="(untitled report)"
            {...register("title")}
          />
        </div>
        <div className="BKForm-inline mb-4">
          <label className="BKForm-label" htmlFor="type">
            Type
          </label>
          {watch("type")}
        </div>
        <div className="BKForm-inline mb-4">
          <label className="BKForm-label" htmlFor="visibility">
            Visibility
          </label>
          <select id="visibility" {...register("visibility")} className="BKFrom-input">
            {visibilityChoices.map((visibilityChoice, index) => (
              <option value={visibilityChoice} key={index}>
                {visibilityChoice}
              </option>
            ))}
          </select>
        </div>
        <div className="BKForm-inline mb-4">
          <label className="BKForm-label BKFrom-label-default-status" htmlFor="status">
            Default Status
          </label>
          <select id="status" data-testid="status" {...register("status")}>
            {statusChoices.map((statusChoice, index) => (
              <option value={statusChoice} key={index}>
                {statusChoice}
              </option>
            ))}
          </select>
          {isPublished && (
            <>
              <label className="BKForm-label BKFrom-label-pub-delay BKForm-label-after" htmlFor="publishDelayDays">
                Publish After
              </label>
              <input
                type="number"
                data-testid="publishDelayDays"
                id="publishDelayDays"
                className="BKFrom-input-number"
                {...register("publishDelayDays")}
              />
              <label className="BKForm-label BKFrom-label-pub-delay" htmlFor="publishDelayDays">
                Days
              </label>
            </>
          )}
        </div>
        <div className="BKForm-inline mb-4">
          <label className="BKForm-label" htmlFor="timeframe-0">
            Timeframe
          </label>
          <span className="BKForm-btnGroup">
            {timeframeChoices.map((timeframeChoice, index) => (
              <Fragment key={index}>
                <input type="radio" id={`timeframe-${index}`} value={timeframeChoice} {...register("timeframe")} />
                <label className="button button_outline" htmlFor={`timeframe-${index}`}>
                  {timeframeChoice}
                </label>
              </Fragment>
            ))}
          </span>
        </div>
        <div className="BKForm-inline BKForm-spacer">
          <label className="BKForm-label" htmlFor="periods">
            Periods
          </label>
          <input type="number" id="periods" className="BKFrom-input-number" {...register("period")} />
        </div>
        <div />
      </fieldset>
    </div>
  );
};

export default BookkeeperTemplateSettingsForm;
