import { Account } from "domain/models";
import React, { useState } from "react";

import Select from "react-select";

type JournalEntriesCoaAutomcompleteProps = {
  coa: Account[];
  value: { label: string | undefined; value: string | undefined };
  handleOnchange: (accountTag: string | undefined) => void;
};

export default function JournalEntriesCoaAutocomplete({
  coa,
  value,
  handleOnchange,
}: JournalEntriesCoaAutomcompleteProps) {
  const [localValue, setlocalValue] = useState<{ label: string | undefined; value: string | undefined }>(value);
  const getOptionValue = (option: Account) => {
    if (!option) return;
    if (option.AcctNum === undefined) return option.Name;
    return `${option.AcctNum} ${option.Name}`;
  };
  return (
    <div className="hone-table-cell-row-dropdown">
      {coa && (
        <Select
          onChange={(option) => {
            handleOnchange(`${option?.value}`);
            setlocalValue({
              label: option?.label,
              value: `${option?.value}`,
            });
          }}
          value={localValue}
          className="report-view-journal-entries-header-filter-accounts-select__select"
          options={(coa as Array<Account>).map((option) => ({
            label: getOptionValue(option),
            value: option.Id,
          }))}
          menuPlacement="bottom"
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? "#123171" : "rgba(0,0,0,0.2);",
              height: "20px",
              textAlign: "left",
              width: "300px",
              minHeight: "30px",
              padding: "0px",
              zIndex: 1,
              backgroundColor: "transparent",
              "&:hover": {
                borderColor: "#123171",
              },
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              paddingLeft: "5px",
              margin: "0px",
              height: "20px",
              minHeight: "30px",
              border: "none",
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              color: "black",
              fontSize: "12px",
              lineHeight: "12px",
              margin: "0px",
              width: "200px",
              zIndex: 1,
              border: "none",
              position: "absolute",
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              paddingLeft: "16px",
              margin: "0px",
              height: "20px",
              minHeight: "30px",
              textAlign: "left",
              position: "absolute",
              border: "none",
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: "black",
              fontSize: "11px",
              lineHeight: "12px",
              padding: "0px",
              margin: "0px",
              width: "200px",
              height: "13px",
              zIndex: 1,
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              display: "none",
            }),
            indicatorsContainer: (baseStyles) => ({
              ...baseStyles,
              padding: "0px",
              margin: "0px",
              height: "30px",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              wordWrap: "break-word",
              zIndex: 9999,
              backgroundColor: "white",
              position: "absolute",
            }),
          }}
        />
      )}
    </div>
  );
}
