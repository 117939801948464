import { ValidateReport } from "domain/models";
import { Cell, Column, HeaderCell, Table } from "rsuite-table";
import classNames from "classnames";
import { currencyFormatter2Decimals, getStatusIcon } from "../lib/utils";
import React from "react";

import { IconCell } from "../presentation/components/audit/AuditReportTable";

interface ValidationReportResultsProps {
  reportData: ValidateReport;
}

export default function ValidationReportResults({ reportData }: ValidationReportResultsProps): JSX.Element {
  const sortedReportResults = reportData?.results.sort((a, b) => a.type.localeCompare(b.type));
  const suggestions = reportData?.suggestions || [];

  const getTableHeight = (): number => {
    const { innerWidth } = window;
    const topNavHeight = 60;
    const title = 60;
    const actions = innerWidth < 1440 ? 64 : 42;

    return window.innerHeight - topNavHeight - title - actions - 60;
  };
  return (
    <div className="validation-report-container hone-report-table">
      <div className="validation-report--date">
        <b>Report created: </b>
        {new Date(reportData.createdAt).toDateString()} {new Date(reportData.createdAt).toLocaleTimeString()}
      </div>

      <div className="validation-report--suggestions">
        <b>Suggestions:</b> <span>{suggestions.length || "none"}</span>
        {suggestions.map((s) => (
          <div className="validation-report--suggestion-item">
            <i className={classNames(["fa", "fa-solid", getStatusIcon("Fail"), "Fail"])} />
            {s.type}: {s.message}
          </div>
        ))}
      </div>

      <div className="validation-report--results">
        <b>Results:</b>
      </div>
      <div className="validation-report--summary">
        <div className="validation-report--summary-item">
          <i className={classNames(["fa", "fa-solid", getStatusIcon("Error"), "Error"])} />
          {reportData.error} errors
        </div>
        <div className="validation-report--summary-item">
          <i className={classNames(["fa", "fa-solid", getStatusIcon("Fail"), "Fail"])} />
          {reportData.fail} failed
        </div>
        <div className="validation-report--summary-item">
          <i className={classNames(["fa", "fa-solid", getStatusIcon("Pass"), "Pass"])} />
          {reportData.pass} passed
        </div>
        <div className="validation-report--summary-item">
          <i className={classNames(["fa", "fa-solid", getStatusIcon("Warning"), "Warning"])} />
          {reportData.warning} warnings
        </div>
      </div>

      <Table
        height={getTableHeight()}
        hover
        data={sortedReportResults}
        rowHeight={50}
        affixHeader
        disabledScroll={false}
        affixHorizontalScrollbar={true}
        virtualized
      >
        <Column width={80}>
          <HeaderCell className="header-data-cell" align="center">
            Result
          </HeaderCell>
          <IconCell dataKey="type" className="icon-check" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell className="header-data-cell" align="center">
            Field
          </HeaderCell>
          <Cell>
            {(rowData) => {
              return <div className="wrap-content">{rowData.fieldName}</div>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell className="header-data-cell" align="center">
            Name
          </HeaderCell>
          <Cell>
            {(rowData) => {
              return <div className="wrap-content">{rowData.reportName}</div>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell className="header-data-cell">Report</HeaderCell>
          <Cell>
            {(rowData) => {
              return <div className="wrap-content">{currencyFormatter2Decimals(rowData.reportValue)}</div>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1} align="center">
          <HeaderCell className="header-data-cell">Qbo</HeaderCell>
          <Cell>
            {(rowData) => {
              return <div className="wrap-content">{currencyFormatter2Decimals(rowData.qboValue)}</div>;
            }}
          </Cell>
        </Column>
        <Column flexGrow={1}>
          <HeaderCell className="header-data-cell">Message</HeaderCell>
          <Cell>
            {(rowData) => {
              return <div className="ellipsis">{rowData.message}</div>;
            }}
          </Cell>
        </Column>
      </Table>
    </div>
  );
}
