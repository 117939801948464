import DateRangePicker, { DateRange } from "rsuite/DateRangePicker";
import HoneTable from "presentation/components/HoneTable";
import JournalEntriesErrorView from "./JournalEntriesErrorView";
import Loading from "components/Loading";
import Select from "react-select";
import Tag from "presentation/components/Tag";
import { Account } from "domain/models";
import { FIVE_SECONDS, TOAST_ERROR, currencyFormatter2Decimals, doGet, reportingOrigin, showToast } from "lib/utils";
import { endOfMonth } from "date-fns";
import { ErrorBoundary } from "react-error-boundary";
import { getLastDayOfPastMonth, headers, transformDateFormat } from "./utils";
import { IconAccruals, IconJEEmptyState } from "components/Icons";
import { JournalEntryFormmatted } from "domain/models/JournalEntries";
import { AccrualConfig, JournalEntryLine, PostingTypes } from "@hone-automation/common";
import { PageHeader } from "presentation/components";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useHoneChartOfAccounts } from "components/HoneReportTemplates";
import { useJournalEntries } from "hooks/journal-entries/useJournalEntries";
import { useJournalEntriesStore } from "hooks/journal-entries/useJournalEntriesStore";
import { useQueryState } from "hooks/useQueryState";
import { useQuery } from "@tanstack/react-query";
import { useLocationsStore } from "hooks/useLocationsStore";
import { useFlags } from "launchdarkly-react-client-sdk";
import KSHeader from "../../components/KSHeader";

export default function JournalEntriesView() {
  const { enableV3Location } = useFlags();
  const { locationId: currentLocationId } = useParams();
  const { setCurrentLocationId } = useLocationsStore();
  const [dateRange, setDateRange] = useQueryState("dateRange");
  const navigateTo = useNavigate();
  const {
    data: jeConfig,
    isFetching: jeConfigLoading,
    isSuccess: jeConfigSuccess,
    isError: jeConfigIsError,
    error: jeConfigError
  } = useQuery<AccrualConfig>({
    queryKey: ["accruals-config", currentLocationId],
    queryFn: async () => {
      const responseConfig = await doGet(
        `${reportingOrigin()}/journal-entries/accruals/config?locationId=${currentLocationId}`
      );
      return responseConfig.data;
    },
    staleTime: 0
  });
  const { journalEntries, setJournalEntries } = useJournalEntriesStore();
  const [status] = useQueryState("status");
  const [searchIds, setSearchIds] = useQueryState("searchIds");

  const { startDate, endDate } = useMemo(() => {
    const parsedDateRange = dateRange && String(dateRange).split(",");

    return {
      startDate: parsedDateRange && parsedDateRange[0],
      endDate: parsedDateRange && parsedDateRange[1]
    };
  }, [dateRange]);

  const { data: coa, isLoading: coaLoading } = useHoneChartOfAccounts(currentLocationId);
  useEffect(() => {
    const searchIdsIsGreaterThanOne = searchIds && searchIds?.toString().split(",").length > 1;
    if (!jeConfigSuccess || searchIdsIsGreaterThanOne) return;
    const jeConfigValues = jeConfig?.data;
    const defaultSearchIds = jeConfigValues?.defaultSearchIds;
    setSearchIds(defaultSearchIds?.toString());
  }, [jeConfigSuccess, jeConfig]);

  useEffect(() => {
    if (!currentLocationId) return;
    setCurrentLocationId(currentLocationId);
  }, [currentLocationId]);

  useEffect(() => {
    if (jeConfigError && jeConfigIsError) showToast("Error getting the accruals config", TOAST_ERROR, FIVE_SECONDS);
  }, [jeConfigIsError, jeConfigError]);

  const defaultStartDate = startDate ?? transformDateFormat(endOfMonth(getLastDayOfPastMonth()));
  const defaultEndDate = endDate ?? transformDateFormat(endOfMonth(new Date()));

  const {
    data: journalEntriesData,
    isLoading,
    isRefetching,
    isSuccess,
    refetch
  } = useJournalEntries(String(defaultStartDate), String(defaultEndDate), String(searchIds));

  useEffect(() => {
    refetch();
  }, [dateRange, searchIds]);

  useEffect(() => {
    if (
      isSuccess &&
      journalEntriesData &&
      journalEntriesData.length > 0 &&
      journalEntries.length === 0 &&
      status === undefined
    ) {
      setJournalEntries(journalEntriesData);
    }
  }, [isSuccess]);

  const trasnformedJounalEntriesData: JournalEntryFormmatted[] | undefined = useMemo(() => {
    return (
      journalEntriesData &&
      journalEntriesData.map((item: any) => {
        const amounts = item.lines
          .filter((line: JournalEntryLine) => line.postingType === PostingTypes.Credit)
          .map((line: JournalEntryLine) => line.amount);

        const total = amounts.reduce((acc: number, curr: number) => {
          return acc + curr;
        }, 0);
        return {
          locationId: item.locationId,
          date: item.date,
          name: item.name,
          memo: item.memo,
          externalId: item.externalId,
          total: currencyFormatter2Decimals(total)
        };
      })
    );
  }, [journalEntriesData]);

  const handleClickJournalEntry = (journalEntry: JournalEntryFormmatted) => {
    let url = `/app/location/${currentLocationId}/journal-entries/${journalEntry.externalId}?dateRange=${defaultStartDate},${defaultEndDate}`;
    if (typeof searchIds !== "undefined") url += `&searchIds=${searchIds}`;
    navigateTo(url);
  };

  const tagStyles: React.CSSProperties = {
    width: "160px",
    textAlign: "left",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#EEF5F6",
    fontSize: "11px",
    fontWeight: "400"
  };

  const handleOnChange = (selectedDate: DateRange | null) => {
    const startDateIso = selectedDate?.[0].toISOString().split("T")[0];
    const endDateIso = selectedDate?.[1].toISOString().split("T")[0];
    setDateRange(`${startDateIso},${endDateIso}`);
  };

  const idxNewJournalEntry = useMemo(() => {
    if (!journalEntriesData) return;
    for (let i = 0; i < journalEntries.length; i++) {
      if (journalEntriesData[i] && journalEntriesData[i].externalId !== journalEntries[i].externalId) {
        return i;
      }
    }
  }, [journalEntriesData]);

  const handleAddNewAccount = (account: string) => {
    const accName = account[0] === "(" ? account.split(")")[1].trim() : account;
    const id = coa && coa.find((acc: Account) => acc.Name === accName).Id;
    const currenSearchIds = searchIds;
    const newSearchIds = currenSearchIds ? currenSearchIds?.toString().concat(`,${id}`) : id;
    setSearchIds(newSearchIds);
  };

  const handleRemoveAccount = (account: string) => {
    const accName = account[0] === "(" ? account.split(")")[1].trim() : account;
    const id = coa && coa.find((acc: Account) => acc.Name === accName).Id;
    const numberSearchIds = searchIds?.toString().split(",").length;

    if (numberSearchIds === 1)
      return navigateTo(
        `/app/location/${currentLocationId}/journal-entries?dateRange=${defaultStartDate},${defaultEndDate}`
      );
    const newSearchIds = searchIds
      ?.toString()
      .split(",")
      .filter((accountId: string) => accountId !== id);
    newSearchIds && setSearchIds(newSearchIds.toString());
  };

  const getOptionValue = (option: Account) => {
    if (!option) return;
    if (option.AcctNum === undefined) return option.Name;
    return `(${option.AcctNum}) ${option.Name}`;
  };

  const accounts = useMemo(() => {
    if (!searchIds) return [];

    const searchIdsArray = String(searchIds).split(",");
    return searchIdsArray.map((id) => {
      const account = coa?.find((account: Account) => account.Id === id);
      return getOptionValue(account);
    });
  }, [searchIds, coa]);

  return (
    <ErrorBoundary FallbackComponent={JournalEntriesErrorView}>
      <div className="report-view">
        {enableV3Location && <KSHeader />}
        <PageHeader icon={<IconAccruals />} pageTitle="Journal Entries" addPadding />
        <div className="report-view-journal-entries-header">
          <div className="report-view-journal-entries-header-filter-accounts">
            {coaLoading || jeConfigLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                <div className="report-view-journal-entries-header-filter-accounts-select">
                  <label className="mr-3">FILTER BY ACCOUNTS</label>
                  {coa && (
                    <Select
                      onChange={(option) => {
                        const accountTag = `${option?.label}`;
                        handleAddNewAccount(accountTag);
                      }}
                      className="report-view-journal-entries-header-filter-accounts-select__select"
                      options={(coa as Array<Account>).map((option) => ({
                        label: getOptionValue(option),
                        value: option.Id
                      }))}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? "#123171" : "rgba(0,0,0,0.2);",
                          // boxShadow: state.isFocused ? "0 0 0px 4px rgba(255, 0, 0, 1)" : "none",
                          "&:hover": {
                            borderColor: "#123171"
                          }
                        }),
                        menu: (baseStyles) => ({
                          ...baseStyles,
                          overflowX: "hidden",
                          wordWrap: "break-word"
                        })
                      }}
                    />
                  )}
                </div>
                <div className="report-view-journal-entries-header-filter-accounts-tags">
                  {coa &&
                    accounts.map((account, idx) => {
                      if (!account) return;
                      return (
                        <Tag
                          key={`${idx}-account`}
                          id={account}
                          content={account}
                          type="info"
                          styles={tagStyles}
                          handleClose={(name: string) => {
                            handleRemoveAccount(name);
                          }}
                        />
                      );
                    })}
                </div>
              </>
            )}
          </div>
          <div className="report-view-journal-entries-header-date">
            <DateRangePicker
              onChange={handleOnChange}
              placement="bottom"
              showOneCalendar
              value={[new Date(`${defaultStartDate} 00:00:00` || ""), new Date(`${defaultEndDate} 00:00:00` || "")]}
              cleanable={false}
              className="report-view-journal-entries-header-date__picker"
            />
          </div>
        </div>
        <div className="report-view-je no-banner-bottom">
          {isLoading || isRefetching ? (
            <Loading />
          ) : trasnformedJounalEntriesData && trasnformedJounalEntriesData.length > 0 ? (
            <HoneTable<JournalEntryFormmatted>
              data={trasnformedJounalEntriesData}
              headers={headers}
              enableOddBackground
              handleClickRow={handleClickJournalEntry}
              hightLightRow={{
                idx: status === "accrual-created" ? idxNewJournalEntry || 0 : undefined,
                type: "success"
              }}
              containerHeight="800px"
            ></HoneTable>
          ) : (
            <div className="report-view-je-empty-state">
              <IconJEEmptyState />
              <span>
                Currently, no journal entries are available for the selected filters and time range. Please adjust your
                criteria and try again or reset the filters.
              </span>
              <button
                onClick={() => {
                  const defaultSearchIds = jeConfig?.data?.defaultSearchIds;
                  navigateTo(`/app/location/${currentLocationId}/journal-entries?searchIds=${defaultSearchIds}`);
                }}
              >
                Reset filters
              </button>
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
}
