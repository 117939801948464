import { makeApiUrl } from "main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "main/factories/decorators";
import { GetReportAttachments } from "domain/usecases/reports";
import { RemoteGetReportAttachments } from "data/usecases";

export const makeGetReportAttachments = (): GetReportAttachments => {
  const getReportAttachments = `/reports/attachments`;

  return new RemoteGetReportAttachments(makeApiUrl(getReportAttachments), makeAuthorizeHttpClientDecorator());
};
