import { useQuery } from "@tanstack/react-query";
import { useLocationsStore } from "./useLocationsStore";
import { makeRemoteGetQboAccounts } from "../main/factories/usecases/remote-get-qbo-accounts";
import { QboAccount } from "domain/models";

const qboService = makeRemoteGetQboAccounts();

function groupByParentId(items: QboAccount[]): any {
  const itemMap: any = {};

  // First, map all items by their externalId for quick access
  items.forEach((item) => {
    itemMap[item.externalId] = { ...item, children: [], depth: 0 };
  });

  // Now, structure the items by assigning children to their respective parents
  const result: any[] = [];
  items.forEach((item) => {
    if (item.parentExternalId && itemMap[item.parentExternalId]) {
      itemMap[item.externalId].depth = itemMap[item.parentExternalId].depth + 1;
      itemMap[item.parentExternalId].children.push(itemMap[item.externalId]);
    } else {
      result.push(itemMap[item.externalId]); // Add root level items to result
    }
  });

  return result;
}

function useQboAccountsQuery(doRequest: boolean) {
  const currentLocation = useLocationsStore.getState().currentLocation;
  const currentLocationId =
    currentLocation?.link && currentLocation?.link !== "" ? currentLocation?.link : currentLocation?.id;

  return useQuery({
    queryKey: ["qboAccounts"],
    queryFn: async ({ queryKey }) => {
      return (await qboService.getAllByLocationId({ locationId: currentLocationId! })) as QboAccount[];
    },
    enabled: true,
    select: (data: QboAccount[]) => (data ? { data: groupByParentId(data), length: data.length, original: data } : [])
  });
}

export default useQboAccountsQuery;
