import { format, parse, parseJSON } from "date-fns";
import { HoneReportTimeframes, HoneReportTypes } from "../../constants";
import { PeriodStartEndDates, ReportColumnTypes } from "@hone-automation/common";

export function transformPercentage(value: string | number): number {
  return Number((Number(value) * 100).toFixed(1));
}

export function toFixed(value: number | string, decimals = 2): number {
  return Number(Number(value).toFixed(decimals));
}

export function transformPercentages(activeReport: any): any {
  function transformSections(sections: any): any {
    if (Array.isArray(sections)) {
      return sections.map((section: any) => {
        return {
          ...section,
          goalPerc: transformPercentage(section.goalPerc),
          sections: transformSections(section.sections)
        };
      });
    }
    return undefined;
  }

  const forecastData = activeReport.forecastData.weeks.map((week: any) => {
    return {
      ...week,
      sections: transformSections(week.sections)
    };
  });

  return {
    ...activeReport,
    forecastData: {
      weeks: forecastData
    }
  };
}

export function formatDate(date: string | Date, _format = "MM/dd/yyyy", utc = true): string {
  if (typeof date === "string") {
    if (!/\d/.test(date)) {
      return date;
    }
    if (utc) {
      const dUTC = toUTCDate(date);
      return format(dUTC, _format);
    } else {
      const dateMs: number = Date.parse(date);
      const d: Date = new Date(dateMs);
      const offset: number = d.getTimezoneOffset() * 60 * 1000;
      d.setTime(dateMs + offset);
      return format(d, _format);
    }
  }
  return format(date, _format);
}

export function formatDateQboWeekly(startDate: string, endDate: string): string {
  if (startDate === "Total" || startDate === "") return startDate === "" ? "DIFFERENCE" : "TOTAL";
  const today = new Date();
  const todayTime = today.getTime();

  const start = new Date(startDate + "T00:00:00");
  const end = new Date(endDate + "T00:00:00");

  const startDay = start.getDate();
  const endDay = end.getDate();

  const startMonth = start.toLocaleString("en-US", { month: "short" }).toLocaleUpperCase();
  const endMonth = end.toLocaleString("en-US", { month: "short" }).toLocaleUpperCase();

  const startYear = start.getFullYear();
  const endYear = end.getFullYear();

  const currentDay = today.getDate();
  const currentMonth = today.toLocaleString("en-US", { month: "short" }).toLocaleUpperCase();
  const currentYear = today.getFullYear();

  // Check if the date range straddles two different years
  if (startYear !== endYear) {
    return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
  }

  // Check if the date range straddles two different months within the same year
  if (startMonth !== endMonth) {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}`;
  }

  // Default case for date range within the same month and year
  return `${startMonth} ${startDay} - ${endDay}`;
}

export function formatDateQboMonthly(startDate: string, endDate: string): string {
  if (startDate === "Total" || startDate === "") return startDate === "" ? "DIFFERENCE" : "TOTAL";

  const start = new Date(startDate + "T00:00:00");
  const end = new Date(endDate + "T00:00:00");

  const startDay = start.getDate();
  const endDay = end.getDate();

  const startMonth = start.toLocaleString("en-US", { month: "short" }).toLocaleUpperCase();
  const endMonth = end.toLocaleString("en-US", { month: "short" }).toLocaleUpperCase();

  const startYear = start.getFullYear();
  const endYear = end.getFullYear();

  // Check if the start date is the first of the month and end date is the last day of the month
  const isFullMonth = (startDate: Date, endDate: Date) => {
    const lastDayOfMonth = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate();
    return startDate.getDate() === 1 && endDate.getDate() === lastDayOfMonth;
  };

  if (isFullMonth(start, end) && startMonth === endMonth && startYear === endYear) {
    return `${startMonth} ${startYear}`;
  }

  // Check if the date range straddles different years
  if (startYear !== endYear) {
    return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
  }

  // Check if the date range straddles different months within the same year
  if (startMonth !== endMonth) {
    return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${startYear}`;
  }

  // Default case for date range within the same month and year
  return `${startMonth} ${startDay} - ${endDay}, ${startYear}`;
}

export function getHeader(
  date: { end: string; start: string; reportId?: string },
  timeframe: HoneReportTimeframe,
  reportType: HoneReportType,
  year?: number
) {
  const isWeekly = timeframe.includes("Weekly");
  const isMonthly = timeframe.includes("Monthly");

  if (reportType === HoneReportTypes.ApAging) {
    return date.start;
  }

  if (date.start === "Total") {
    return date.start.toUpperCase();
  }

  if (date.end === "") {
    if (date.start === "") {
      return timeframe === HoneReportTimeframes.Monthly ? "DIFFERENCE" : "";
    }
    return `${date.start.toUpperCase()} ${year}`;
  }

  if (isWeekly) {
    return formatDateQboWeekly(date.start, date.end);
  }

  if (isMonthly) {
    return formatDateQboMonthly(date.start, date.end);
  }

  return formatDateQbo(new Date(date.end + "T00:00:00"));
}

export function getTimeframePeriod(timeframe: HoneReportTimeframe) {
  return timeframe === "Monthly" ? " Month" : " Week";
}

export function getHeaderFormattedText(
  date: PeriodStartEndDates,
  report: HoneReportSummary,
  yearPlotted: number | undefined,
  compareLocationsEnabled: boolean = false
) {
  const header = getHeader(date, report.timeframe, report.type, yearPlotted!);
  if (date.type === ReportColumnTypes.Difference) {
    return "Difference";
  }
  if (date.type === ReportColumnTypes.Total) {
    return "Total";
  }

  if (header.startsWith("2")) {
    return new Date(header).getTime() > new Date().getTime()
      ? `Current${getTimeframePeriod(report.timeframe)}`
      : header;
  }

  return date.location && compareLocationsEnabled
    ? date.location?.name.concat(" (").concat(header.toLocaleUpperCase()).concat(")")
    : header.toLocaleUpperCase();
}

export function formatDateQbo(date: string | Date, startDay = 1): string {
  const today = new Date();
  const todayDay = today.getDate();
  const currentMonth = today.toLocaleString("en-US", { month: "short" }).toLocaleUpperCase();
  const month = date.toLocaleString("en-US", { month: "short" }).toLocaleUpperCase();
  const currentYear = today.getFullYear();
  const year = new Date(date).getFullYear();

  if (month === currentMonth && currentYear === year) {
    return `${month} ${startDay} - ${todayDay}, ${year}`;
  }

  return `${month} ${year}`;
}

export function toUTCDate(date: string): Date {
  const _date = parse(date, "MM-dd-yyyy", new Date());
  return new Date(_date.getTime() + _date.getTimezoneOffset() * 6000);
}

export function groupMessagesByDate<T extends { createdAt: Date | string }>(messages: T[]): { [key: string]: T[] } {
  // Create object to hold grouped messages
  const groupedMessages: { [key: string]: T[] } = {};

  // Iterate over messages
  messages.forEach((message) => {
    const dateOnly = format(parseJSON(message.createdAt), "yyyy-MM-dd");
    // Check if date already exists in grouped messages object
    if (dateOnly in groupedMessages) {
      // Add message to existing array of messages for that date
      groupedMessages[dateOnly].push(message);
    } else {
      // Create new array for that date and add message to it
      groupedMessages[dateOnly] = [message];
    }
  });

  // Return object with grouped messages for each date
  return groupedMessages;
}
