import { Cell, CellProps } from "rsuite-table";
import { memo, useMemo } from "react";
import { useActiveReportStore } from "hooks/useActiveReportStore";
import classNames from "classnames";
import { useQueryState } from "hooks/useQueryState";
import BarsChart from "presentation/components/ReportTable/BarsChart";
import { HoneReportTimeframes, HoneReportTypes } from "../../../constants";

function PlotGraphCell(props: CellProps) {
  const activeCategory = useActiveReportStore.getState().activeCategory?.name;
  const [_activeCategory, setActiveCategory] = useQueryState("activeCategory");
  const { rowData } = props;

  const currentReport = useActiveReportStore((state) => state.currentReport);

  const shouldShowLast =
    (currentReport?.timeframe === HoneReportTimeframes.Weekly ||
      currentReport?.timeframe === HoneReportTimeframes.Monthly) &&
    currentReport?.type === HoneReportTypes.PLComparison;

  const noContent = rowData.display === "empty" || rowData.display === "hidden" || rowData.display === "header_1";

  const chartData = useMemo(
    () =>
      rowData.data.slice(0, shouldShowLast ? rowData.data.length : -1).map(({ amount }: { amount: number }) => amount),
    [rowData.data]
  );

  const handlePlotChange = () => {
    useActiveReportStore.setState((state) => {
      setActiveCategory(rowData.title);
      const nextAllCategories = state.allCategories;
      if (!state.allCategories.includes(rowData.title)) {
        nextAllCategories.push(rowData.title);
      }

      return {
        activeCategory: { name: rowData.title, color: "blue" },
        allCategories: nextAllCategories,
        smoothingEnabled: false,
      };
    });
  };

  if (noContent) {
    return <Cell {...props} className={rowData.display} />;
  }

  return (
    <Cell {...props} className={classNames(rowData.display, "plot-cell")}>
      <button
        className={classNames("plot-cell-button", {
          active: rowData.title === activeCategory,
        })}
        onClick={handlePlotChange}
      >
        <BarsChart chartData={chartData} />
      </button>
    </Cell>
  );
}

export default memo(PlotGraphCell);
