import { Report } from "lib/honeTableUtils";
import { noop } from "lodash";
import { createContext } from "react";

interface ReportViewContextProps {
  data: Report[];
  report: NestedHoneReport | null;
  expandedRows: string[];
  showMoneyValues: boolean;
  showPercentageValues: boolean;
}

export const initialReportContext: ReportViewContextProps = {
  data: [],
  report: null,
  expandedRows: [],
  showMoneyValues: true,
  showPercentageValues: true,
};

export const ReportViewContext = createContext({ reportViewState: initialReportContext, setReportViewState: noop });
