import { useMutation } from "@tanstack/react-query";
import { queryClient } from "../lib/queryClient";

import { FIVE_SECONDS, ONE_MINUTE, showToast, TOAST_ERROR, TOAST_SUCCESS } from "lib/utils";
import { makeRemotePostQboAccounts } from "../main/factories/usecases/remote-post-qbo-accounts";
import { PostQboAccounts } from "../domain/usecases/post-qbo-accounts";
import { useLocationsStore } from "./useLocationsStore";

export const useUpdateQboAccountsMutation = () => {
  const currentLocation = useLocationsStore.getState().currentLocation;
  const updateQboAccountService = makeRemotePostQboAccounts();

  return useMutation({
    mutationFn: (data: PostQboAccounts.Body) => {
      return updateQboAccountService.updateAccounts(data);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ["qboAccounts"] });
    },
    onSuccess: async (data, variables) => {
      let errorCounter = 0;
      // check for validation errors
      if (Array.isArray(data)) {
        data.forEach((ele) => {
          if (ele?.errorType === "ValidationFault") {
            errorCounter++;
            showToast(ele.detail!, TOAST_ERROR, ONE_MINUTE);
          }
        });
      }

      if (errorCounter === 0) {
        showToast("Accounts updated", TOAST_SUCCESS, FIVE_SECONDS);
      }
    },
    onError: (e: Error) => {
      // showToast(e.message, TOAST_ERROR, FIVE_SECONDS);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["qboAccounts"] });
    }
  });
};
