import React, { useEffect, useState } from "react";
import { Option } from "types";
import { IconDown, IconUp } from "components/Icons";

import "./KSDropdown.scss";

import classNames from "classnames";

interface CustomDropdownProps {
  options: Option[];
  onSelect: (option: Option) => void;
  placeholder?: string;
  icon?: React.ReactNode;
  label?: string;
  value: Option | null;
  disabled?: boolean;
  enableScrollReload?: boolean;
  enableMinWidth?: boolean;
  reloadNewItemsFn?: () => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({
  label,
  options,
  value,
  icon,
  placeholder,
  enableScrollReload = false,
  enableMinWidth = false,
  disabled = false,
  onSelect,
  reloadNewItemsFn
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const refContainer = React.useRef<HTMLDivElement>(null);
  const refContent = React.useRef<HTMLDivElement>(null);
  const refItemsContainer = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: Option) => {
    setIsOpen(false);
    onSelect(option);
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (refContainer.current && !refContainer.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleScroll = (ev: any) => {
    if (
      refItemsContainer.current?.clientHeight &&
      refItemsContainer.current?.scrollTop &&
      enableScrollReload &&
      reloadNewItemsFn
    ) {
      const isEndScroll =
        refItemsContainer.current.scrollTop + refItemsContainer.current.clientHeight >=
        refItemsContainer.current.scrollHeight;
      if (isEndScroll) {
        reloadNewItemsFn();
      }
    }
  };

  return (
    <div className="ks-custom-dropdown" ref={refContainer}>
      {label && <div className="ks-dropdown-label">{label}</div>}
      <div className={classNames("ks-dropdown-content", { "enable-min-width": enableMinWidth })} ref={refContent}>
        <button
          className={classNames("ks-dropdown-toggle", { "enable-min-width": enableMinWidth })}
          type="button"
          onClick={handleToggle}
          disabled={disabled}
        >
          {value ? value.label : placeholder} {icon}
          <span className="ks-arrow">{isOpen ? <IconUp /> : <IconDown />}</span>
        </button>
        {isOpen && (
          <div
            className={classNames("ks-dropdown-menu", { "enable-min-width": enableMinWidth })}
            onScroll={handleScroll}
            ref={refItemsContainer}
          >
            {options.map((option, idx) => (
              <div key={`${option.value}_${idx}`} onClick={() => handleOptionClick(option)}>
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
