import React, { FunctionComponent, ReactNode, useState } from "react";
import { useQueryState } from "hooks/useQueryState";
import { useReportsStore } from "hooks/useReportsStore";
import { countByType } from "lib/honeTableUtils";
import { SlidingPane } from "components/SlidingPane";
import AuditReportTable from "./AuditReportTable";

export const AuditResultsSummary = ({ children }: { children?: ReactNode }) => {
  const auditReport = useReportsStore((state) => state.auditReport);

  const totalErrors = auditReport && countByType<AuditReport>(auditReport, "Error");
  const totalFails = auditReport && countByType<AuditReport>(auditReport, "Fail");
  const totalPass = auditReport && countByType<AuditReport>(auditReport, "Pass");
  const totalWarnings = auditReport && countByType<AuditReport>(auditReport, "Warning");
  return (
    <div className="summary-wrapper">
      {children}
      <span className="error">
        {totalErrors} Errors <i className="fa fa-solid fa-exclamation" />
      </span>
      <span className="fail">
        {totalFails} Fails <i className="fa fa-solid fa-times" />
      </span>
      <span className="pass">
        {totalPass} Pass <i className="fa fa-solid fa-check" />
      </span>
      <span className="warning">
        {totalWarnings} Warnings <i className="fa fa-solid fa-exclamation-triangle" />
      </span>
    </div>
  );
};

function AuditSummary() {
  const [auditMode] = useQueryState("audit");

  const [isSlidingPaneOpen, setSlidingPane] = useState<boolean>(false);

  const toggleSlidingPane = () => {
    setSlidingPane((prevState) => !prevState);
  };

  return auditMode === "true" ? (
    <>
      <div className="audit-summary">
        <div>
          <h2>Audit Mode</h2>
        </div>
        <AuditResultsSummary>
          <button className="button" onClick={toggleSlidingPane}>
            View all
          </button>
        </AuditResultsSummary>
      </div>
      <SlidingPane onRequestClose={toggleSlidingPane} title="Audit report" width={"75%"} isOpen={isSlidingPaneOpen}>
        <AuditResultsSummary />
        <AuditReportTable />
      </SlidingPane>
    </>
  ) : null;
}

export default AuditSummary;
