import { SummaryItem } from "@hone-automation/common";
import { IconSmooth } from "components/Icons";
import { useDashboardReport } from "hooks/useDashboard";
import DashboardBussinessMetricsItem from "./DashboardBussinessMetricsItem";
import classNames from "classnames";

export default function DashboardBussinessMetrics(): JSX.Element {
  const { dashboardReport, activeBussinessMetric } = useDashboardReport();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const dashboardBusinessMetrics = dashboardReport?.cards.find((item) => item.type === "Business Metrics")?.data.items;

  return (
    <div
      className={classNames("dashboard-bm-container", {
        mobile: isMobile,
      })}
    >
      <div
        className={classNames("dashboard-bm-content", {
          mobile: isMobile,
        })}
      >
        {isMobile && <div className="dashboard-bm-container-title">Business Metrics</div>}
        {dashboardBusinessMetrics?.map((item: SummaryItem, idx: number) => (
          <DashboardBussinessMetricsItem key={idx} cardData={item} active={activeBussinessMetric === item.title} />
        ))}
        <div className="dashboard-bm-content__smooth">
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore */}
          <IconSmooth width={24} height={24} /> Smoothed Value
        </div>
      </div>
    </div>
  );
}
