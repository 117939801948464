import { GetForecasts } from "domain/usecases";
import { BaseHttpService } from "main/factories/http";
import { LocationForecast } from "domain/models";

export class RemoteGetForecasts extends BaseHttpService<GetForecasts.Model> implements GetForecasts {
  async get(params: GetForecasts.Params): Promise<GetForecasts.Model> {
    return await this.request(
      "get",
      {},
      {
        params: {
          ...params,
          years: params.years?.join(",")
        }
      }
    );
  }

  async getAll(params: GetForecasts.Params): Promise<GetForecasts.Model> {
    return (await this.request(
      "get",
      {},
      {
        params
      }
    )) as LocationForecast[];
  }
}

export namespace RemoteGetForecasts {
  export type Model = GetForecasts.Model;
}
