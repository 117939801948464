import { IconCircleClose } from "components/Icons";
import { ConfigurationMetricsReport, MetricsReport } from "domain/models";
import { useBookkeeperReport } from "hooks/useBookkeeper";
import { useLocationsStore } from "hooks/useLocationsStore";
import { isEmpty } from "lodash";
import { ChangeEvent, useState } from "react";
import { CardTypes } from "@hone-automation/common";

export default function BookkeeperMetricsReportForm(): JSX.Element {
  const { metricReport, bookkeeperMetrics, setShowNewMetric, createMetricReport, handleMetricReport } =
    useBookkeeperReport();
  const { currentLocationId } = useLocationsStore();
  const [localMetricReport, setLocalMetricReport] = useState<MetricsReport>({
    ...metricReport,
    type: "Profit and Loss",
  });

  const handleMetricReportChange = (value: string | ConfigurationMetricsReport, key: keyof MetricsReport) => {
    const currentMetric: MetricsReport = {
      ...localMetricReport,
      [key]: value,
      locationId: currentLocationId,
    };
    setLocalMetricReport(currentMetric);
  };

  const handleCreateMetric = () => {
    localMetricReport !== undefined && currentLocationId && createMetricReport(localMetricReport, currentLocationId);
  };

  const closeCreateMetric = () => {
    handleMetricReport(undefined);
    setShowNewMetric(false);
  };

  return (
    <div className="bookkeeper-form">
      <div className="bookkeeper-form__close" onClick={closeCreateMetric}>
        <IconCircleClose />
      </div>

      {localMetricReport?.id && (
        <div className="bookkeeper-form__row">
          <label className="mr-2">ID</label>

          <input
            type="text"
            id="ID"
            name="ID"
            value={localMetricReport?.id}
            placeholder="ID"
            className="bookkepper-metrics-form__input"
            disabled
          />
        </div>
      )}
      <div className="bookkeeper-form__row">
        <label className="mr-2">Title</label>

        <input
          type="text"
          id="title"
          name="title"
          value={localMetricReport?.title}
          placeholder="Title"
          className="bookkepper-metrics-form__input"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleMetricReportChange(e.target.value, "title");
          }}
        />
      </div>
      <div className="bookkeeper-form__row">
        <label className="mr-2">Type</label>
        <select
          id="type"
          name="type"
          value={localMetricReport?.type}
          className="bookkepper-metrics-form__input"
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            handleMetricReportChange(e.target.value, "type");
          }}
        >
          {Object.values(CardTypes).map((cardType: string) => (
            <option value={cardType}>{cardType}</option>
          ))}
        </select>
      </div>
      <div className="bookkeeper-form__row">
        <label className="mr-2">Metrics config ID</label>
        <div className="bookkepper-metrics-form__input">
          {bookkeeperMetrics?.map((item, idx) => (
            <div className="bookkeeper-form__row-checkbox">
              <input
                key={idx}
                type="checkbox"
                id="scales"
                name={item.configuration?.title}
                checked={
                  !isEmpty(localMetricReport?.configuration?.metricConfigIds)
                    ? Boolean(
                        item?.id &&
                          localMetricReport?.configuration?.metricConfigIds &&
                          localMetricReport?.configuration?.metricConfigIds.includes(item?.id)
                      )
                    : false
                }
                onChange={() => {
                  if (item?.id) {
                    const currentConf = { ...localMetricReport?.configuration };
                    const configIds = currentConf?.metricConfigIds || [];
                    configIds?.includes(item?.id)
                      ? configIds.splice(configIds.indexOf(item.id), 1)
                      : configIds?.push(item?.id);
                    const config: ConfigurationMetricsReport = { metricConfigIds: configIds };
                    handleMetricReportChange(config, "configuration");
                  }
                }}
              />
              <label className="bookkepper-metrics-form__label">{item.configuration?.title}</label>
            </div>
          ))}
        </div>
      </div>
      <div className="bookkeeper-form__actions">
        <button className="button button_outline" type="button" onClick={closeCreateMetric}>
          <span>Cancel</span>
        </button>
        <button
          className="button BKForm-btn-warning"
          type="button"
          disabled={isEmpty(localMetricReport.configuration?.metricConfigIds)}
          onClick={handleCreateMetric}
        >
          <span>{localMetricReport?.id ? "Update Metric Report " : "Create Metric Report"}</span>
        </button>
      </div>
    </div>
  );
}
