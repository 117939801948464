import { useMutation } from "@tanstack/react-query";
import { useLocationsStore } from "../useLocationsStore";
import { FIVE_SECONDS, showToast, TOAST_ERROR } from "lib/utils";
import { makeGetReportAttachments } from "main/factories/usecases";
import { ReportRow, ReportTransactionDataRow } from "@hone-automation/common";

export const useGetReportAttachments = (reportTransactionsRows: ReportTransactionDataRow[]) => {
  const currentLocation = useLocationsStore.getState().currentLocation;
  return useMutation({
    mutationFn: () => {
      const reportAttachmentsService = makeGetReportAttachments();
      return reportAttachmentsService.getReportAttachments({
        locationId: currentLocation!.id,
        dataRows: reportTransactionsRows
      }) as Promise<ReportRow[]>;
    },
    onError: (error: any) => {
      showToast(error.message, TOAST_ERROR, FIVE_SECONDS);
    }
  });
};
