import React from "react";
import {
  dismissToast,
  FIVE_SECONDS,
  mergeByFieldNameAndName,
  ONE_MINUTE,
  showToast,
  TOAST_DEFAULT,
  TOAST_ERROR,
  TOAST_SUCCESS
} from "../lib/utils";
import { confirmAlert } from "react-confirm-alert";
import { makeRemoteDeleteAuditReportItem } from "../main/factories/usecases";
import { useReportsStore } from "./useReportsStore";
import { useActiveReportStore } from "hooks/useActiveReportStore";

const service = makeRemoteDeleteAuditReportItem();

type AuditReportDismiss = {
  onDismissRequest: (audit: AuditReport[]) => void;
};

function useAuditReportDismiss(onDismiss?: () => void): AuditReportDismiss {
  const reportId = useActiveReportStore((state) => state.currentReportId);

  const onDismissRequest = (audit: AuditReport[]) => {
    const onConfirm = async (onClose: () => void) => {
      onClose();
      const toastId = showToast("Dismissing audit report data", TOAST_DEFAULT, ONE_MINUTE);

      if (!reportId) {
        showToast("No report id", TOAST_ERROR, FIVE_SECONDS);
        dismissToast(toastId);
        return;
      }

      try {
        const response = await service.dismiss({
          reportId,
          toDismiss: audit
        });

        const allAudits = useReportsStore.getState().auditReport;
        if (allAudits && response) {
          const nextAudits = mergeByFieldNameAndName(allAudits).with(response as AuditReport[]);
          useReportsStore.setState({ auditReport: nextAudits });
        }

        showToast("Audit data dismissed!", TOAST_SUCCESS, FIVE_SECONDS);
        onDismiss?.();
      } catch (e: any) {
        showToast(e.message, TOAST_ERROR, FIVE_SECONDS);
      } finally {
        dismissToast(toastId);
      }
    };

    const identifier = audit.length > 1 ? "these" : "this";

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="alert-ui">
            <h3>Are you sure you want to dismiss {identifier}?</h3>
            <button className="button BKForm-btn-secondary" onClick={onClose}>
              Cancel
            </button>
            <button className="button BKForm-btn" onClick={() => onConfirm(onClose)}>
              Dismiss
            </button>
          </div>
        );
      }
    });
  };

  return { onDismissRequest };
}

export default useAuditReportDismiss;
