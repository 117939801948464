import { SummaryItem } from "@hone-automation/common";
import { BussinessMetricsTypes } from "domain/models";
import { EChartsOption } from "echarts";
import { currencyFormatter, percentageFormatter } from "lib/utils";

export const buildChartConfig = (
  chartItems: SummaryItem[],
  type: "bar" | "pie" | "line",
  showLegend: boolean,
  bmSelected: BussinessMetricsTypes,
  xAxis2nd: string,
  legendPosition: "top" | "bottom",
  backgroundColor?: string
): EChartsOption => {
  const pieColors: string[] = ["var(--ks-dashboard-netsales)", "var(--ks-dashboard-selected)"];
  const salesData = chartItems
    .filter((item) => item.title === "Net Sales")
    .map((innerItem) => (type === "bar" ? innerItem.amount : innerItem.percent));
  const bmSelectedData = chartItems
    .filter((item) => item.title === bmSelected)
    .map((innerItem) =>
      type === "bar" ? innerItem.smoothedAmount ?? innerItem.amount : innerItem.smoothedPercent ?? innerItem.percent
    );
  const dataChartTitles =
    !!chartItems?.length &&
    chartItems
      .filter((filterItem) => filterItem.title === "Net Sales")
      .map((item) => {
        const date = item.endDate?.split("-");
        return date && `${date[1]}/${date[2]}`;
      });

  const series = [
    {
      name: "Net Sales",
      barGap: 0,
      data: salesData,
      type: type,
      itemStyle: {
        color: pieColors[0],
      },
      emphasis: {
        itemStyle: {
          color: pieColors[0], // Change the color of the bars on hover/focus,
          focus: type === "line" ? "none" :"self"
        }
      },
      label: {
        show: true,
        position: "top",
        fontSize: 10,
        fontWeight: 800,
        formatter: (value: any) => {
          return type === "bar" ? currencyFormatter(value.data) : percentageFormatter(value.data);
        },
      },
    },
    {
      name: bmSelected,
      data: bmSelectedData,
      type: type,
      itemStyle: {
        color: type === "line" ?  pieColors[0] : pieColors[1],
      },
      emphasis: {
        itemStyle: {
          color: type === "line" ?  pieColors[0] : pieColors[1], // Change the color of the bars on hover/focus
          focus: type === "line" ? "none" :"self"
        },
        lineStyle: {
          color: type === "line" ?  pieColors[0] : pieColors[1], // Change the color of the bars on hover/focus
          focus: type === "line" ? "none" :"self"
        }
      },      
      label: {
        show: true,
        position: "top",
        fontSize: 10,
        fontWeight: 800,
        formatter: (value: any) => {
          return type === "bar" ? currencyFormatter(value.data) : percentageFormatter(value.data);
        },
      },
    },
  ];
  if (type === "line") {
    series.shift();
  }

  const echartsOptions = {
    legend: {
      data: type === "line" ? [bmSelected] : ["Net Sales", bmSelected],
      orient: "horizontal",
      padding: [0, 0],
      right: 30,
      top: 0,
      textStyle: {
        fontSize: 12,
      },
      show: showLegend,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        if (type === "line") {
          return `
          <b>${params[0].axisValue}</b><br/>
          ${params[0].marker}<b>${params[0].seriesName}</b>: ${percentageFormatter(params[0].value)}
          `;
        }
        return `
        <b>${params[0].axisValue}</b><br/>
        ${params[0].marker}<b>${params[0].seriesName}</b>: ${currencyFormatter(params[0].value)}<br />
        ${params[1].marker}<b>${params[1].seriesName}</b>: ${currencyFormatter(params[1].value)}
        `;
      },
    },
    toolbox: {
      show: true,
    },
    xAxis: [
      {
        type: "category",
        axisTick: {
          alignWithLabel: true,
        },
        data: dataChartTitles,
      },
      {
        type: "category",
        data: [xAxis2nd],
        position: legendPosition,
        offset: 30,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        show: xAxis2nd ? true : false,
        axisLabel: {
          fontWeight: "bolder",
          color: "#444",
        },
      },
    ],
    yAxis: {
      axisLabel: {
        show: false,
        formatter: function (value: number) {
          return "$" + value / 1000 + "k";
        },
      },
      type: "value",
    },
    backgroundColor: backgroundColor,
    series,
  };
  return echartsOptions as EChartsOption;
};
