import { ReactNode } from "react";
import Select from "react-select";
import { prefixClass } from "lib/utils";
import { Controller, useFormContext } from "react-hook-form";
import CoASelectedItem from "presentation/components/CoAEditor/CoASelectedItem";
import { QboAccount } from "domain/models";

type QboContentProps = {
  data: QboAccount[];
  onRemove: (item: QboAccount) => void;
  onSplit: (item: QboAccount) => void;
};

const CoAParentSelector = ({ data, onRemove, onSplit }: QboContentProps): ReactNode => {
  const prefix = prefixClass("coAParentSelector-container");

  const {
    watch,
    control,
    setValue,
    formState: { isDirty }
  } = useFormContext();

  const options = data.map((item) => ({
    value: item.externalId,
    label: item.glCode !== undefined ? `(${item.glCode}) ${item.name}` : item.name,
    name: item.name
  }));

  const handleClearSelection = () => {
    // setValue("parent", undefined);
    setValue("selected", watch("selectedCopy"));
    // setValue("mode", "join");
  };

  const selected = watch("selected");
  const mode = watch("mode");

  return (
    <div className={prefix()}>
      <div className={prefix("select")}>
        <Controller
          control={control}
          name="parent"
          rules={{
            required: true
          }}
          render={({ field: { onChange, value } }) => (
            <>
              <label className="qbo-settings-label">Parent account</label>
              <Select
                isClearable
                value={options.find((c) => c.value === value)}
                options={options}
                placeholder={"Select Parent Account"}
                onChange={(val) => onChange(val?.value)}
              />
            </>
          )}
        />
      </div>
      <div className={prefix("selection")}>
        <div className={prefix("title-controls")}>
          <h1 className={prefix("title-controls-h1")}>SUB-ACCOUNTS</h1>
          <div className={prefix("container-btn-join")}>
            <button
              disabled={JSON.stringify(watch("selected")) === JSON.stringify(watch("selectedCopy"))}
              type="submit"
              className={prefix("title-controls-btn save")}
            >
              {mode === "split" ? "REMOVE ALL" : "SAVE"}
            </button>
            {selected.length > 0 && (
              <button
                disabled={JSON.stringify(watch("selected")) === JSON.stringify(watch("selectedCopy"))}
                type="button"
                className={prefix("title-controls-btn")}
                onClick={handleClearSelection}
              >
                CLEAR
              </button>
            )}
          </div>
        </div>
        <div className={prefix("list")}>
          {selected.map((item: QboAccount, index: number) => (
            <CoASelectedItem key={index} item={item} onSplit={onSplit} onRemove={onRemove} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CoAParentSelector;
