import React, { ReactNode } from "react";
import classNames from "classnames";

import { useSidenavStore } from "hooks/useSidenavStore";
import { Sidenav, PageHeader } from "presentation/components";
import "./NewLayout.css";
import "styles/components/_coAEditor.scss";
import "styles/components/_coAItem.scss";

import ChartArea from "./components/ChartArea";
import ScreenSettings from "./components/ScreenSettings";
import Container from "./components/Container";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { MENU_ICONS } from "../../../constants";
import KSHeader from "../KSHeader";
import { useFlags } from "launchdarkly-react-client-sdk";

type Props = {
  icon: ReactNode;
  pageTitle: ReactNode;
  className: string;
  chartArea: ReactNode;
  screenSettings?: ReactNode;
  children: ReactNode;
  grayBg?: boolean;
  noPadding?: boolean;
};

function NewLayout({
  icon: Icon,
  pageTitle,
  className,
  screenSettings,
  children,
  chartArea,
  grayBg = true,
  noPadding = false
}: Props): ReactNode {
  const { enableV3Location } = useFlags();
  const { isSidenavCollapsed } = useSidenavStore();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div className={classNames("NewLayout d-print-block", className)}>
      {/*{isMobile && <TopNavigationBar />}*/}
      <nav className={classNames("Layout-nav", { "NewLayout-nav_open": isSidenavCollapsed })}>
        <Sidenav />
      </nav>
      <main className="NewLayout-main">
        <div className={classNames("NewLayout-content", { "bg-gray": grayBg, "no-padding": noPadding })}>
          {enableV3Location && <KSHeader />}
          <Header>
            {/* @ts-ignore */}
            <PageHeader icon={<Icon />} pageTitle={pageTitle} canAddNewLocation />
          </Header>
          <ChartArea>{chartArea}</ChartArea>
          {screenSettings && <ScreenSettings>{screenSettings}</ScreenSettings>}
          <Container>{children}</Container>
          <Footer />
        </div>
        {isMobile && <div className="Layout-footer">footer</div>}
      </main>
    </div>
  );
}

export default NewLayout;
