export interface ReportGraphData {
  title: string;
  def: string;
  rows: number[];
  percentages: number[];
  labels: string[];
  goal: Goal;
}

export interface Goal {
  hiLo: boolean;
  val: number[];
}

export interface ReportGraphDataSummary {
  reportValues: number[];
  reportLabels: string[];
  reportPercentages: number[];
}

export interface ReportGraphDataTotalSummary {
  [key: string]: ReportGraphDataSummary;
}

export interface Category {
  name: string;
  color: string;
}

export enum ReportView {
  money = "Money",
  percentage = "Percentage",
}
