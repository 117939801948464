import { AccrualConfig } from "@hone-automation/common";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import classNames from "classnames";
import { IconAccruals } from "components/Icons";
import { useGetAccrualsConfig } from "hooks/useAccrualsConfig";
import { doGet, reportingOrigin } from "lib/utils";
import React from "react";
import { Link, useParams } from "react-router-dom";
import SideNavIcon from "./SideNavIcon";

type SideNavAccrualsLinkProps = {
  pathname: string;
  isMobile: boolean;
  toggleSidenav: () => void;
};

export default function SideNavAccrualsLink({
  pathname,
  isMobile,
  toggleSidenav
}: SideNavAccrualsLinkProps): JSX.Element {
  const { locationId: currentLocationId } = useParams();

  const { data: accrualsConfig } = useGetAccrualsConfig(currentLocationId);
  const isEnabled = (accrualsConfig as AccrualConfig)?.data?.enabled ?? false;

  // this should be abstracted to be used both in SideNav and here, if there is really a need to have this component
  const isBeta = (value: string) => {
    return ["none"].includes(value);
  };

  if (!isEnabled) {
    return <></>;
  }
  return (
    <Link
      key="link-journal-entries"
      to={`/app/location/${currentLocationId}/journal-entries`}
      onClick={() => {
        isMobile && toggleSidenav();
      }}
      title={"Journal Entries"}
    >
      <SideNavIcon
        Icon={IconAccruals}
        selected={pathname.includes("journal-entries")}
        title="Entries"
        tooltip="Journal Entries"
        beta={isBeta("Journal Entries")}
      />
    </Link>
  );
}
