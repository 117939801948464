import { useAskRequestsCountQuery } from "hooks/useAskRequestsCountQuery";
import { useHoneLocationUsers } from "components/HoneLocationUsers";
import { AskRequestCount } from "domain/models";

export const useAskRequestsPendingCount = () => {
  const { isBookkeeperAdmin } = useHoneLocationUsers();
  const { data: askRequestsCount, isSuccess: ftStatus } = useAskRequestsCountQuery();

  return ftStatus
    ? isBookkeeperAdmin
      ? (askRequestsCount as AskRequestCount).In_Progress
      : (askRequestsCount as AskRequestCount)?.Open
    : 0;
};
