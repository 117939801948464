import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "index.scss";
import { AppWrapper as ConcurrentModeApp } from "./App";
import { reportWebVitals } from "./reportWebVitals";
import { performanceReporter, trackError } from "./lib/analytics";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { getHostEnvironment, launchDarklyClientSideKey } from "./lib/utils";
import { AuthProvider } from "context/useAuthContext";
import { createRoot } from "react-dom/client";

class UnhandledPromiseRejectionError extends Error {}

if (!("process" in window)) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  window.process = {};
}

window.onunhandledrejection = function (e) {
  const { reason } = e;
  const stack = reason.stack || "";
  trackError({ error: new UnhandledPromiseRejectionError(reason.message), stack });
};

Sentry.init({
  enabled: true,
  dsn: "https://89607eb2edd64b8bb5f7d3b7fd7ee7ad@o4504526481195008.ingest.sentry.io/4504526511341568",
  integrations: [],
  environment: getHostEnvironment(),
  // Capturing 10% of transactions for performance monitoring.
  tracesSampleRate: 0.1,
  release: __APP_VERSION__
});

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchDarklyClientSideKey()
  });

  const root = createRoot(document.getElementById("kitchensync-app") as HTMLElement);

  root.render(
    <React.StrictMode>
      <AuthProvider>
        <LDProvider>
          <ConcurrentModeApp />
        </LDProvider>
      </AuthProvider>
    </React.StrictMode>
  );

  reportWebVitals(performanceReporter);
})();
