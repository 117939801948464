import { useCallback, useEffect } from "react";
import { useLocationsStore } from "./useLocationsStore";
import { useNavigate, useParams } from "react-router-dom";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { useReportsStore } from "./useReportsStore";

/**
 * A custom React hook for handling location routing, user access to location and dashboard redirection
 * based on feature flag
 * @param {boolean} redirect - Whether to redirect the user to the appropriate path.
 */
function useLocationRouting(redirect = true): void {
  const ldClient = useLDClient();
  const navigateTo = useNavigate();
  const { locationId: URLLocationID } = useParams();
  const allLocations = useLocationsStore((state) => state.allLocations);

  /**
   * Sets the current locationId in the location store, updates the localStorage, and navigates to the appropriate path.
   * @param {string} currentLocationId - The locationId to be set as the current location.
   * @returns {Promise<void>} - Returns a Promise that resolves when the operation is complete.
   */
  const setCurrentLocation = async (currentLocationId: string) => {
    useLocationsStore.setState({ currentLocationId });
    await ldClient?.identify({ key: currentLocationId });
    useReportsStore.setState({ enableNewPlReportModal: ldClient?.variation("enable-new-pl-report-modal") });

    if (redirect || URLLocationID !== currentLocationId) {
      const newPath = `/app/location/${currentLocationId}/dashboard`;

      localStorage.setItem("hone:locationId", currentLocationId);
      navigateTo(newPath);
    }
  };

  /**
   * Checks if the user has access to the given locationId.
   * @param {string} checkLocationId - The locationId to be checked.
   * @returns {string | undefined} - Returns the locationId if the user has access, otherwise returns undefined.
   */
  const userHasAccessToLocation = useCallback(
    (checkLocationId: string): string | undefined => {
      if (Object.keys(allLocations).length === 0) return;
      const location = allLocations.find((loc) => loc.id === checkLocationId);
      if (!location) return;
      // check if the user has access
      return location.id;
    },
    [allLocations]
  );

  /**
   * Finds the current locationId based on the URLLocationID, stored locationId in localStorage, or the first location from the allLocations array.
   * @returns {string | undefined} - Returns the current locationId if found, otherwise returns undefined.
   */
  const findCurrentLocationId = () => {
    const sources = [URLLocationID, localStorage.getItem("hone:locationId")];

    for (const source of sources) {
      if (source) {
        const foundLocationId = userHasAccessToLocation(source);
        if (foundLocationId) {
          return foundLocationId;
        }
      }
    }

    return sources[1] ? sources[1] : allLocations.length ? allLocations[0].id : undefined;
  };

  useEffect(() => {
    if (Object.keys(allLocations).length === 0) return;
    const currentLocationId = findCurrentLocationId();

    if (currentLocationId) {
      setCurrentLocation(currentLocationId);
    }
  }, [allLocations]);
}

export default useLocationRouting;
