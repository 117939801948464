import { CardTimeframe } from "@hone-automation/common";
import classNames from "classnames";
import { useQueryState } from "hooks/useQueryState";

type TimeSelectorProps = {
  disabledItem?: CardTimeframe | ForecastTimeframe | undefined;
  timeframeSelected: ForecastTimeframe | CardTimeframe;
  timeframes: ForecastTimeframe[] | CardTimeframe[];
  onTimeframeChange: (timeframe: ForecastTimeframe | CardTimeframe) => void;
};

export default function TimeSelector({
  disabledItem,
  timeframeSelected,
  timeframes,
  onTimeframeChange,
}: TimeSelectorProps): JSX.Element {
  return (
    <div className="timeframe-selector">
      <div>
        {timeframes.map((tf, idx) => (
          <button
            key={idx}
            disabled={disabledItem === tf}
            className={classNames({
              active: timeframeSelected === tf,
              firstBtn: idx === 0,
              lastBtn: idx === timeframes.length - 1,
            })}
            onClick={() => onTimeframeChange(tf)}
          >
            {tf}
          </button>
        ))}
      </div>
    </div>
  );
}
