import classNames from "classnames";
import { currencyFormatter, percentFormatter } from "lib/utils";

interface DashboardCardDataMetricProps {
  title: string;
  percentTitle: string;
  number: number;
  percentage?: number;
  type: "result" | "sales" | "expenses";
}

export default function DashboardCardDataMetric({
  title,
  number,
  percentage,
  type,
  percentTitle,
}: DashboardCardDataMetricProps): JSX.Element {
  return (
    <div className="dashboard-cards-layout--results-ctn">
      <div className="dashboard-cards-layout--results-title">{title}</div>
      <div className="dashboard-cards-layout--results">
        <div className={classNames("dashboard-cards-layout--results-text", type)}>{currencyFormatter(number)}</div>
        <div className={classNames("dashboard-cards-layout--results-percentage", type)}>
          {percentage || percentage === 0 ? (
            <>
              <span> / </span>
              {percentFormatter(percentage)}
            </>
          ) : null}
        </div>
      </div>
      <div className="dashboard-cards-layout--results-title right">
        {percentage || percentage === 0 ? percentTitle : null}
      </div>
    </div>
  );
}
