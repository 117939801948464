import { memo, useEffect, useMemo } from "react";
import { Cell, CellProps } from "rsuite-table";
import { checkDataRows, currencyFormatterShort, isDateInMonth, percentFormatter, smoothingField } from "lib/utils";
import classNames from "classnames";

import { create } from "zustand";
import { HoneReportTimeframes } from "../../../constants";
import { useReportsStore } from "hooks/useReportsStore";
import { isEqual, isNumber } from "lodash";
import { useFlaggedTransactionsQuery } from "hooks/flaggedTransactions";
import { AskRequest, CreateFlaggedTransaction, matchTransactionToParams, ReportRow } from "@hone-automation/common";
import { UIFlaggedTransaction } from "types/askcustomer";
import { DateTransaction, matchFlaggedCell } from "components/Table/ReportTransactions";
import { useActiveReportStore } from "hooks/useActiveReportStore";
import { useFindMonthlyDataRows } from "hooks/reports/useFindMontlyDataRows";
import { useGetReportAttachments } from "hooks";
import { shallow } from "zustand/shallow";
import { DataCellState } from "domain/models";

interface DataCellProps extends CellProps {
  smoothingEnabled: boolean;
  columnIndex: number;
  isColumnActive: boolean;
  setDataModal: (obj: DataCellState) => void;
  showMoney: boolean;
  showPercentage: boolean;
  date: DateTransaction;
  isAgReport?: boolean;
  smallReport?: boolean;
}

function toMatchDate(date: Date) {
  return (date as unknown as string).replace("T00:00", "T23:59");
}

export const dataRowsStore = create((set: any, get: any) => {
  return {
    path: undefined,
    dataRows: undefined,
    loading: false
  };
});

function DataCell(props: DataCellProps): JSX.Element | null {
  const {
    rowData,
    dataKey,
    columnIndex: index,
    isColumnActive,
    setDataModal,
    date,
    smoothingEnabled,
    showMoney,
    showPercentage,
    isAgReport = false,
    smallReport,
    ...rest
  } = props;

  const { data: flaggedTransactions } = useFlaggedTransactionsQuery();

  const { yearPlotted, currentReport, currentDataCell, setCurrentDataCell, modalCellState } = useActiveReportStore(
    (state) => ({
      yearPlotted: state.yearPlotted,
      currentReport: state.currentReport,
      currentDataCell: state.currentDataCell,
      setCurrentDataCell: state.setCurrentDataCell,
      modalCellState: state.modalCellState
    }),
    shallow
  );
  const selectedReport = useReportsStore((state) => state.selectedReport);
  const _date = dataKey && rowData[dataKey][index];

  const {
    data: dataRows,
    mutate: getMontlyDataRows,
    isSuccess: getDataRowsMonthlySuccess,
    isPending: getDataRowsMonthlyPending
  } = useFindMonthlyDataRows(selectedReport, rowData, date);

  const {
    data: dataRowsWithAttachments,
    mutate: getReportAttachments,
    isSuccess: getDataRowsWithAttachmentsSuccess,
    isPending: getDataRowsWithAttachmentsPending
  } = useGetReportAttachments(_date?.dataRows);

  const isNegative = _date?.amount < 0;
  const isYtd = selectedReport?.timeframe === HoneReportTimeframes.YTDMonthly;

  /* When audit is present, we grab the one that corresponds to this cell period */
  const filteredAudits =
    rowData.audit &&
    rowData.audit.filter((r: AuditReport) => {
      return date.end !== "" && r.reportName.includes(date.end);
    });
  const audit = Array.isArray(filteredAudits) && filteredAudits.length > 0 && filteredAudits[0];
  const hasAudit = !!audit;

  const hasDataRowsOrAudit = checkDataRows(_date, rowData, !isYtd) || hasAudit;
  const [amount, amountIsDifferent] = smoothingField(_date, smoothingEnabled, "amount", "amtAvg");
  const [percentage, percentageIsDifferent] = smoothingField(_date, smoothingEnabled, "perc", "percAvg");

  const formattedAmount = isNumber(amount) ? currencyFormatterShort(amount) : null;
  const formattedPercentage = isNumber(percentage) ? percentFormatter(percentage) : null;

  const shouldHaveBigRow = showMoney && showPercentage;

  const dateHasPercentage = _date && Object.prototype.hasOwnProperty.call(_date, "perc");
  const store: any = dataRowsStore();
  const sym = Reflect.ownKeys(rowData).find((s) => {
    return String(s) === "Symbol(parent)";
  });

  const parent = sym ? rowData[sym]?.title : null;

  useEffect(() => {
    if (getDataRowsMonthlySuccess) {
      setCurrentDataCell(_date);
      setDataModal({
        dataRows: dataRows,
        date,
        title: rowData.title,
        parent: parent,
        total: _date.amount,
        loading: store.loading,
        modalOpen: true
      });
    }
  }, [getDataRowsMonthlySuccess]);

  useEffect(() => {
    if (getDataRowsWithAttachmentsSuccess) {
      setCurrentDataCell(_date);
      setDataModal({
        dataRows: dataRowsWithAttachments,
        date,
        title: rowData.title,
        parent: parent,
        total: _date.amount,
        loading: store.loading,
        modalOpen: true
      });
    }
  }, [getDataRowsWithAttachmentsSuccess]);

  const hasFlaggedTransactions = useMemo(
    () =>
      flaggedTransactions?.find((flagged: any) => {
        if ((flagged as AskRequest).type && (flagged as AskRequest).type === "Flagged Cell") {
          return matchFlaggedCell(flagged, date, rowData.title, selectedReport);
        }

        if (!isYtd) {
          if (_date && (flagged as UIFlaggedTransaction).flagType === "Ask Bookkeeper" && _date.dataRows) {
            return _date.dataRows.find((reportTransaction: any) => {
              return matchTransactionToParams(
                {
                  ...flagged.transaction,
                  date: flagged.transaction && new Date(toMatchDate(flagged.transaction.date))
                },
                {
                  name: reportTransaction.name,
                  externalId: reportTransaction.eventId,
                  externalTransactionType: reportTransaction.type,
                  date: reportTransaction.txnDate,
                  externalNum: reportTransaction.num,
                  memo: reportTransaction.description,
                  amount: reportTransaction.amount
                } as unknown as CreateFlaggedTransaction
              );
            });
          }
        } else if (date.start !== "Total" && flagged.transaction) {
          const {
            transaction: { deleted, account, date: trxDate }
          } = flagged;

          try {
            if (
              !deleted &&
              rowData.glCode &&
              account.includes(rowData.glCode) &&
              date.start &&
              date.start != "" &&
              isDateInMonth(toMatchDate(trxDate), date.start, yearPlotted)
            ) {
              return flagged.transaction;
            }
          } catch (e) {
            console.log({ trxDate, match: toMatchDate(trxDate), start: date.start, yearPlotted });
          }
        }
        return false;
      }),

    [_date, flaggedTransactions]
  );

  const noContent =
    typeof amount === "undefined" ||
    rowData.display === "empty" ||
    rowData.display === "hidden" ||
    (rowData.display === "header_1" && amount === 0) ||
    (rowData.display === "header_2" && amount === 0);

  const handleActionRowButtonClick = async () => {
    if (date.start === "" || date.start === "Total" || noContent) return;
    if (currentReport) {
      if (!isEqual(currentDataCell, _date)) {
        if (currentReport?.noDataRows) {
          getMontlyDataRows();
          return;
        } else if (currentReport?.noAttachments) {
          getReportAttachments(_date.dataRows);
          return;
        }
      } else {
        setDataModal({
          dataRows: modalCellState.dataRows as ReportRow[],
          date,
          title: rowData.title,
          total: _date.amount,
          modalOpen: true,
          audit: audit,
          loading: getDataRowsMonthlyPending || getDataRowsWithAttachmentsPending,
          parent: parent,
          cellTransactionFlagged: hasFlaggedTransactions,
          hasDataRowsOrAudit
        });
        return;
      }
    }

    setDataModal({
      dataRows: _date.dataRows,
      date,
      title: rowData.title,
      total: _date.amount,
      modalOpen: true,
      audit: audit,
      loading: getDataRowsMonthlyPending || getDataRowsWithAttachmentsPending,
      parent: parent,
      cellTransactionFlagged: hasFlaggedTransactions,
      hasDataRowsOrAudit
    });
  };

  const auditDidNotPass = hasAudit && audit.type !== "Pass";
  const dismissed = hasAudit && audit.message?.includes("dismissed");
  const auditError = hasAudit && ["Fail", "Error"].includes(audit.type);
  const noPercentageBecauseSmallReport = smallReport;

  /*   if (hasDataRowsOrAudit) console.log(hasDataRowsOrAudit,rowData)
  if (hasFlaggedTransactions) console.log(hasFlaggedTransactions, rowData); */
  const flaggedTransactionType = hasFlaggedTransactions
    ? (hasFlaggedTransactions?.status as string).replace(" ", "-").toLowerCase()
    : "";

  return (
    <Cell
      key={rowData.id}
      className={classNames(rowData.display, {
        flagged:
          hasFlaggedTransactions && hasFlaggedTransactions?.flagType === "Ask Bookkeeper"
            ? !noContent && hasDataRowsOrAudit
            : hasFlaggedTransactions,
        [flaggedTransactionType]: hasFlaggedTransactions,
        audit: auditDidNotPass,
        dismissed,
        active: isColumnActive,
        auditError: auditError,
        highlighted: amountIsDifferent || percentageIsDifferent
      })}
      height={60}
      width={215}
      {...rest}
    >
      <div className={"column " + rowData.display}>
        <button
          className={classNames("row-action-button", {
            noIcon: !hasDataRowsOrAudit
          })}
          onClick={handleActionRowButtonClick}
        >
          <div className="has-transactions"> {hasDataRowsOrAudit && !noContent && <span>&#9679;</span>}</div>
          <div className={classNames("column_money_percentage", { hasIcon: hasDataRowsOrAudit })}>
            {!noContent && showMoney && (
              <span
                data-testid="money-value"
                className={classNames("formatted-money", {
                  "negative-amount": isNegative,
                  column_row_big: shouldHaveBigRow
                })}
              >
                {formattedAmount === "$0" ? "--" : formattedAmount}
              </span>
            )}
            {!noContent && showPercentage && !noPercentageBecauseSmallReport && (
              <span
                data-testid="percentage-value"
                className={classNames(showMoney ? "formatted-percent" : "formatted-percent--percent-only", {
                  column_row_big: shouldHaveBigRow
                })}
              >
                {dateHasPercentage ? (formattedPercentage === "0.00%" ? "" : formattedPercentage) : " "}
              </span>
            )}
          </div>
        </button>
      </div>
    </Cell>
  );
}

export default memo(DataCell);
