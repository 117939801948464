import { defaults, omit } from "lodash";
import type { Metric } from "web-vitals";

export const AnalyticsEvents = {
  ForgotPasswordSubmitted: "forgot_password_submitted",
  Login: "login",
  ChartPreviousClicked: "click_charts_prev",
  ChartNextClicked: "click_charts_next",
  ChartEditGoalClicked: "click_edit_chart_goal",
  ReportViewed: "report_view",
  DatatableRowDetailsClicked: "click_datatable_row_details",
  Exception: "exception"
} as const;

type AnalyticsEvent = (typeof AnalyticsEvents)[keyof typeof AnalyticsEvents];

export function initializeAnalytics(user: User): void {
  gtag("config", process.env.VITE_GA_MEASUREMENT_ID as string, { user_id: user?.uid });

  if (user?.uid) {
    // set user data after login
    pendo.initialize({
      visitor: {
        id: user.email || user.uid
      }
    });
  } else {
    // anonymize user after logout
    pendo.identify({
      visitor: {
        id: "",
        email: ""
      }
    });
  }
}

export function validatePendoInstallation(): any {
  return pendo.validateInstall();
}

export function launchPendoDesigner(user: User): void {
  try {
    if (!pendo) {
      return;
    }
    pendo.initialize({
      visitor: {
        id: user.email || user.uid
      }
    });
    // (pendo as any).designerv2.launchDesigner();
  } catch (error) {
    pendo.initialize({
      visitor: {
        id: user.email || user.uid
      }
    });
    launchPendoDesigner(user);
  }
}

export function performanceReporter({ name, delta, value, id }: Metric): void {
  // https://github.com/GoogleChrome/web-vitals#using-gtagjs-google-analytics-4
  gtag("event", name, {
    // Built-in params:
    value: delta, // Use `delta` so the value can be summed.
    // Custom params:
    metric_id: id, // Needed to aggregate events.
    metric_value: value, // Optional.
    metric_delta: delta // Optional.

    // OPTIONAL: any additional params or debug info here.
    // See: https://web.dev/debug-web-vitals-in-the-field/
    // metric_rating: 'good' | 'ni' | 'poor',
    // debug_info: '...',
    // ...
  });
}

export function trackEvent(event: AnalyticsEvent, eventParams?: Gtag.EventParams | Gtag.CustomParams): void {
  if (eventParams) {
    gtag("event", event, eventParams);
  } else {
    gtag("event", event);
  }
}

// https://developers.google.com/analytics/devguides/collection/ga4/exceptions
export function trackError(
  meta: { error: Error; stack?: string; componentStack?: string },
  eventParams: Pick<Gtag.EventParams, "description" | "fatal"> = {}
): void {
  const description = JSON.stringify({ name: meta.error.name, message: meta.error.message, ...omit(meta, "error") });
  trackEvent(AnalyticsEvents.Exception, defaults(eventParams, { description }));
}
