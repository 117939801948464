import { useDashboardReport } from "hooks/useDashboard";

import { timesframes } from "domain/models";
import DashboardCardDataMetric from "./DashboardCardDataMetric";
import DashboardAPAging from "./DashboardAPAging/DashboardAPAging";
import DashboardBussinessMetrics from "./DashboardBussinessMetrics";
import DashboardChart from "./DashboardChart/DashboardChart";
import { isEmpty } from "lodash";
import InformativeMessage from "presentation/components/InformativeMessage/InformativeMessage";
import { formatDate, prefixClass } from "lib/utils";
import { Badge, TimeSelector } from "presentation/components";
import { CardTimeframe, SummaryCard, SummaryItem } from "@hone-automation/common";
import { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import DashboardFlaggedTransactions from "presentation/pages/dashboard/components/DashboardFlaggedTransacions/DashboardFlaggedTransactions";
import { IconBulletBlue, IconBulletOrange, IconForwardBtn } from "components/Icons";
import { useNavigate } from "react-router-dom";
import { useLocationsStore } from "hooks/useLocationsStore";
import { useAskRequestsPendingCount } from "hooks/useAskRequestsPendingCount";

export interface PieDataValue {
  value: number;
  name: string;
}

type DashboardCardsLayoutProps = {
  showNoDataMessage: boolean;
  handleChangeTimeframeSeletor: (timeframe: CardTimeframe | ForecastTimeframe) => void;
};

export default function DashboardCardsLayoutMobile({
  showNoDataMessage,
  handleChangeTimeframeSeletor,
}: DashboardCardsLayoutProps): JSX.Element {
  const navigateTo = useNavigate();

  const prefix = prefixClass("dashboard-cards");
  const { currentLocationId } = useLocationsStore((state) => ({
    currentLocationId: state.currentLocationId,
    currentLocation: state.currentLocation,
  }));
  const { dashboardReport, activeBussinessMetric, timeframe } = useDashboardReport();

  const plReport: SummaryCard | undefined = dashboardReport?.cards.find((card) => card.type === "Insights");

  const askRequestsPendinForCurrentUser = useAskRequestsPendingCount();

  const netSales = plReport?.data.items.find((item) => item.title === "Net Sales");
  const expenses = plReport?.data.items.find((item) => item.title === activeBussinessMetric);
  const chartItems =
    !isEmpty(netSales) && !isEmpty(expenses) && netSales?.chartItems?.concat(expenses.chartItems as SummaryItem[]);
  const isMobileLanscape = () => window.orientation === 90 || window.orientation === -90;
  const setShouldShowDesktopView = useState<boolean>(isMobileLanscape)[1];

  useEffect(() => {
    window.addEventListener("orientationchange", () => {
      setShouldShowDesktopView(window.orientation === 90 || window.orientation === -90);
    });
  });

  const redirectAskCustomer = () => {
    navigateTo(`/app/location/${currentLocationId}/ask-requests`);
  };
  return (
    <>
      <div className={prefix("container")}>
        <div className={prefix("layout")}>
          <div className={prefix("layout-time-chart-metric")}>
            <div className="mobile-chart-container">
              <div className="dashboard-view--timeframe-selector-ld">
                <TimeSelector
                  disabledItem={showNoDataMessage ? "Current Week" : undefined}
                  timeframes={timesframes}
                  timeframeSelected={timeframe}
                  onTimeframeChange={handleChangeTimeframeSeletor}
                />
              </div>
              <div className={prefix("layout--messages-charts-extras")}>
                {dashboardReport && dashboardReport?.cards.length > 0 && (
                  <InformativeMessage type="informative" center>
                    <>
                      <span>WEEK ENDING ON</span>
                      <b>
                        {formatDate(
                          new Date(dashboardReport?.cards[0].endDate.toString().replaceAll("-", "/").split("T")[0]),
                        )}
                      </b>
                    </>
                  </InformativeMessage>
                )}
                {showNoDataMessage && (
                  <InformativeMessage type="warning">Data for the current week isn’t available yet. </InformativeMessage>
                )}
                {askRequestsPendinForCurrentUser !== 0 && (
                  <InformativeMessage type="checklist" clickable>
                    <div className="dashboard-informative-flagged-transactions" onClick={redirectAskCustomer}>
                      Ask Requests Pending
                      <div className="badge-icon">
                        <Badge content={askRequestsPendinForCurrentUser} />
                        <IconForwardBtn />
                      </div>
                    </div>
                  </InformativeMessage>
                )}
              </div>
              <div className={prefix("charts-data-container")}>
                <div className={prefix("container-title-card-container")}>
                  <span className={prefix("container-title-card")}>Overview</span>
                  <div className={prefix("container-title-card--legend")}>
                    <div>
                      <IconBulletBlue />
                      Net Sales
                    </div>
                    <div>
                      <IconBulletOrange />
                      {activeBussinessMetric}
                    </div>
                  </div>
                </div>

                <div className={prefix("layout--charts-container")}>
                  {chartItems && (
                    <Carousel showStatus={false}>
                      <div>
                        <DashboardChart
                          chartItems={chartItems}
                          type={"bar"}
                          showLegend={false}
                          bmSelected={activeBussinessMetric}
                          xAxis2nd="AMOUNT"
                          legendPosition="top"
                          backgroundColor="#fff"
                        />
                      </div>
                      <div>
                        <DashboardChart
                          chartItems={chartItems}
                          type={"line"}
                          bmSelected={activeBussinessMetric}
                          xAxis2nd="PERCENTAGE"
                          showLegend={false}
                          legendPosition="top"
                          backgroundColor="#fff"
                        />
                      </div>
                    </Carousel>
                  )}
                </div>
              </div>
              <div className={prefix("layout--charts-container metrics")}>
                <DashboardCardDataMetric
                  title={netSales?.title || ""}
                  number={Number(netSales?.smoothedAmount ?? netSales?.amount)}
                  percentage={Number(netSales?.smoothedPercent ?? netSales?.percent)}
                  percentTitle={netSales?.percentTitle || ""}
                  type="result"
                />
                <DashboardCardDataMetric
                  title={expenses?.title || ""}
                  number={Number(expenses?.smoothedAmount ?? expenses?.amount)}
                  percentage={Number(expenses?.smoothedPercent ?? expenses?.percent)}
                  percentTitle={expenses?.percentTitle || ""}
                  type={"expenses"}
                />
              </div>
            </div>
            <div className="mobile-metrics-container">
              <DashboardBussinessMetrics />
              <DashboardFlaggedTransactions />
              <DashboardAPAging />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
