import "./KSMLMLocations.scss";
import { KSMLocation } from "../KSMultipleLocationModal";
import { KSMLMGroup } from "../KSMLMLocationGroup/KSMLMLocationGroup";
import KSMLMSelected from "../KSMLMSelected";
import KSMLMLocationGroup from "../KSMLMLocationGroup";
import { prefixClass } from "lib/utils";
import { useEffect, useState } from "react";

type KSMLMLocationsProps = {
  activeLocation: KSMLocation;
  allLocations: KSMLocation[];
  selectedLocations: KSMLocation[];
  onLocationSelect?: (location: KSMLocation) => void;
  onLocationRemove?: (location: KSMLocation) => void;
};

const KSMLMLocations = ({
  activeLocation,
  allLocations,
  selectedLocations,
  onLocationSelect,
  onLocationRemove
}: KSMLMLocationsProps) => {
  const prefix = prefixClass("ks-mlm-locations");
  const [groupedLocations, setGroupedLocations] = useState<KSMLMGroup[]>([]);

  useEffect(() => {
    const transformedLocations = allLocations.reduce((acc: KSMLMGroup[], location: KSMLocation) => {
      const group = acc.find((group) => group.org === location.org);
      if (group) {
        group.locations.push(location);
        group.locations.sort((a, b) => a.locationName.localeCompare(b.locationName));
      } else {
        acc.push({
          org: location.org,
          locations: [location]
        });
      }
      return acc;
    }, []);

    transformedLocations.sort((a, b) => a.org.localeCompare(b.org));
    setGroupedLocations(transformedLocations);
  }, [allLocations]);

  return (
    <div className={prefix()}>
      <KSMLMSelected
        activeLocation={activeLocation}
        selectedLocations={selectedLocations}
        onLocationRemove={onLocationRemove}
        />
      {allLocations.length === 0 && <div className={prefix("none")}>No locations found</div>}
      <div className={prefix("list")}>
        {groupedLocations.map((location, index) => {
          return <KSMLMLocationGroup key={index} groupLocation={location} onLocationSelect={onLocationSelect} />;
        })}
      </div>
    </div>
  );
};

export default KSMLMLocations;
