import { makeApiUrl } from "main/factories/http";
import { makeAuthorizeHttpClientDecorator } from "main/factories/decorators";
import { PostFlaggedTransactionComment } from "domain/usecases";
import { RemotePostFlaggedTransactionComment } from "data/usecases/ask-customer/remote-post-flagged-transaction-comment";

export const makeRemotePostFlaggedTransactionComment = (trxId: string): PostFlaggedTransactionComment => {
  return new RemotePostFlaggedTransactionComment(
    makeApiUrl(`/ask-requests/${trxId}/comment`),
    makeAuthorizeHttpClientDecorator()
  );
};
