import React, { ChangeEvent } from "react";
import "./ForecastSliderInput.styles.scss";

interface ForecastSliderInputProps {
  step?: number;
  label?: React.ReactNode;
  defaultValue?: number;
  value?: number | string;
  // register: any;
  name: string;
  onChange: (value: string) => void;
}

const DEFAULT_STEP = 1;

function ForecastSliderInput({
  step = DEFAULT_STEP,
  name,
  value: propValue,
  onChange,
}: ForecastSliderInputProps): JSX.Element {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <div className="forecast-range-input">
      <input type="range" name={name} value={propValue} min={-100} max={100} onChange={handleChange} />
      <div className="percentage-input">
        <input type="text" name={name} value={propValue} onChange={handleChange} />
        <span>%</span>
      </div>
    </div>
  );
}

export default ForecastSliderInput;
