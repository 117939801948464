import { createRef, SyntheticEvent, KeyboardEvent, useEffect, useMemo, useState, ChangeEvent } from "react";
import ChatBubble from "./ChatBubble";
import { IconCheck, IconClear, IconQuestion, IconRefreshDouble } from "components/Icons";
import { useHoneLocationUsers } from "components/HoneLocationUsers";
import { FlaggedTransactionComment } from "@hone-automation/common";
import { groupMessagesByDate } from "presentation/utils";
import { parseISO } from "date-fns";

import classNames from "classnames";

import Whisper from "rsuite/Whisper";
import Tooltip from "rsuite/Tooltip";

type CommentOrientation = "left" | "right";

type Comment = {
  orientation: CommentOrientation;
  showDate: boolean;
  glCode?: boolean;
  date?: Date;
  content: string;
  editable: boolean;
  createdAt: Date;
};

type ChatProps<T> = {
  messages?: T[];
  canReOpen: boolean;
  isSideView?: boolean;
  isClosedTrx: boolean;
  onSubmit: (comment: string) => void;
};

function Chat<T extends FlaggedTransactionComment>({
  messages,
  canReOpen,
  isSideView,
  isClosedTrx,
  onSubmit
}: ChatProps<T>) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const chatBodyRef = createRef<HTMLDivElement>();
  const inputRef = createRef<HTMLTextAreaElement>();
  const [comment, setComment] = useState<string | undefined>(undefined);
  const { isBookkeeperAdmin, currentLocation } = useHoneLocationUsers();

  inputRef.current?.blur();

  const handleClear = () => {
    setComment(undefined);
    inputRef.current?.focus();
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!comment) return;
    onSubmit(comment as string);
    setComment(undefined);
  };

  let userOrder = ["KitchenSync Accountant", "You"];

  if (isBookkeeperAdmin) {
    userOrder = ["Client", "KitchenSync Accountant"];
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTo(0, chatBodyRef.current.scrollHeight);
    }
  }, [chatBodyRef.current, messages]);

  const groupedMessages = useMemo(() => groupMessagesByDate<T>(messages ?? []), [messages]);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && comment === undefined) {
      e.preventDefault();
      return;
    }
    if (e.key === "Enter" && !e.shiftKey && comment !== undefined) {
      e.preventDefault();
      handleSubmit(e);
    }
  };
  const renderClosedTransaction = () => {
    return !canReOpen ? (
      <div className={"chat-input-can-not-reopen"}>
        <IconQuestion />
        <span>This request cannot be reopened. Please check your current reports to flag new issues.</span>
      </div>
    ) : (
      <form className="chat-input-group">
        {/*TODO enable file upload*/}
        {/*<button className="btn file-upload">*/}
        {/*  <IconAttachment />*/}
        {/*</button>*/}
        <textarea
          ref={inputRef}
          autoFocus
          autoComplete="false"
          value={(comment as string) || ""}
          onChange={handleChange}
          placeholder="Write your message..."
          onKeyDown={handleKeyDown}
          className={classNames({ "reopen-input": canReOpen })}
        />
        <Whisper speaker={<Tooltip>{comment ? "Reopen request" : "Can not reopen"}</Tooltip>} placement="top">
          <button
            className={classNames("btn submit", { reopen: canReOpen })}
            onClick={handleSubmit}
            disabled={!comment}
          >
            <span>REOPEN</span>
            <IconRefreshDouble />
          </button>
        </Whisper>
        {comment && (
          <button className={classNames("btn clear-input", { "reopen-clear-input": canReOpen })} onClick={handleClear}>
            <IconClear />
          </button>
        )}
      </form>
    );
  };

  return (
    <div className="chat">
      <div className="chat-header">
        {userOrder.map((ele) => (
          <span key={ele}>{ele}</span>
        ))}
      </div>
      <section className={classNames("chat-body", { "side-view-chat": isSideView })} ref={chatBodyRef}>
        {currentLocation?.role &&
          Object.entries(groupedMessages).map(([date, messages]) =>
            messages.map((message: T, idx: number) => {
              const showDate = idx === 0;
              let orientation: CommentOrientation = "left";
              const { isHoneInternalUser } = message.userLocation ?? {};

              if ((isBookkeeperAdmin && isHoneInternalUser) || (!isBookkeeperAdmin && !isHoneInternalUser)) {
                orientation = "right";
              }

              const comment: Comment = {
                orientation: orientation,
                editable: false,
                date: parseISO(date),
                showDate,
                content: message.comment,
                createdAt: message.createdAt
              };
              return <ChatBubble<typeof showDate, Date> key={message.id} {...comment} fit={false} />;
            })
          )}
      </section>
      <footer className="chat-input">
        {/*NOTE on submit at form level is required to submit on enter key press */}
        {isClosedTrx ? (
          renderClosedTransaction()
        ) : (
          <form className="chat-input-group">
            <textarea
              ref={inputRef}
              autoFocus={!isMobile}
              autoComplete="false"
              value={(comment as string) || ""}
              onChange={handleChange}
              placeholder="Write your message..."
              onKeyDown={handleKeyDown}
              className={classNames({ "reopen-input": canReOpen })}
            />
            <button className={classNames("btn submit", { reopen: canReOpen })} onClick={handleSubmit}>
              <IconCheck />
            </button>
            {comment && (
              <button
                className={classNames("btn clear-input", { "reopen-clear-input": canReOpen })}
                onClick={handleClear}
              >
                <IconClear />
              </button>
            )}
          </form>
        )}
      </footer>
    </div>
  );
}

export default Chat;
