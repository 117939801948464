import { UIFlaggedTransaction } from "types/askcustomer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { onFlaggedTransactionUpdate } from "hooks/flaggedTransactions/handlers/onFlaggedTransactionUpdate";
import { makeRemotePatchFlaggedTransaction } from "main/factories/usecases/ask-customer";
import { FIVE_SECONDS, TOAST_ERROR, TOAST_SUCCESS, showToast } from "lib/utils";
import { useFlaggedTransactions } from "./useFlaggedTransactions";
import { useAskRequestsQuery } from "hooks/useAskRequestsQuery";
import { AskRequestStatus } from "@hone-automation/common";
import { useQueryState } from "hooks/useQueryState";
import { useAskRequestsCountQuery } from "hooks/useAskRequestsCountQuery";

export const usePatchFlaggedTransactionMutation = (
  locationId: string,
  status: AskRequestStatus,
  onSuccessCallback: (trx: UIFlaggedTransaction | undefined) => void,
  comment?: string
) => {
  const { refetch } = useAskRequestsQuery(status);
  const { refetch: refetchCounts } = useAskRequestsCountQuery();
  const { setAnimationTransaction } = useFlaggedTransactions((state) => ({
    setAnimationTransaction: state.setAnimatedTransaction,
  }));
  const [activeTab] = useQueryState<AskRequestStatus>(status);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => {
      const trxId = data.txId;
      const service = makeRemotePatchFlaggedTransaction(trxId);

      const { status } = data;
      return service.patch({ status, locationId, comment });
    },
    onSuccess: (data, variables) => {
      refetchCounts();
      setAnimationTransaction(undefined);
      onFlaggedTransactionUpdate(data, variables, locationId, queryClient, onSuccessCallback);
      if (activeTab !== "Closed") {
        useFlaggedTransactions.setState({
          activeFlaggedTransaction: undefined,
        });
        refetch();
      }
      showToast("Request Updated", TOAST_SUCCESS, FIVE_SECONDS);
    },
    onError: () => {
      showToast("Error while updating request", TOAST_ERROR, FIVE_SECONDS);
    },
  });
};
