import { useQuery } from "@tanstack/react-query";
import { useLocationsStore } from "../useLocationsStore";
import { makeRemoteGetReportsConfig } from "main/factories/usecases";
import { ReportConfigurationData } from "@hone-automation/common";

const reportConfigurationService = makeRemoteGetReportsConfig();

export const useGetReportConfiguration = () => {
  const currentLocation = useLocationsStore.getState().currentLocation;
  const currentLocationId = currentLocation?.id;

  return useQuery({
    // NOTE: don't change this queryKey, it is used like this to perform zustand subscription handler
    queryKey: ["reportConfiguration", currentLocationId],
    queryFn: async () => {
      return (await reportConfigurationService.getConfig({
        locationId: currentLocationId as string
      })) as ReportConfigurationData;
    },
    staleTime: 1000 * 60 * 5,
    enabled: !!currentLocation
  });
};
