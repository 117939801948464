import React, { useCallback, useRef, useState } from "react";
import { IconNext, IconPrev, IconRotate, IconZoomIn, IconZoomOut } from "components/Icons";
import Loading from "components/Loading";

type ImageViewerProps = {
  images: string[];
};

const steps = [0.5, 0.75, 1, 1.5, 2, 3, 4];

function findNearestIndex(arr: number[], num: number) {
  let nearestIndex = 0;
  let minDifference = Math.abs(arr[0] - num);

  for (let i = 1; i < arr.length; i++) {
    const currentDifference = Math.abs(arr[i] - num);
    if (currentDifference < minDifference) {
      nearestIndex = i;
      minDifference = currentDifference;
    }
  }
  return nearestIndex;
}

const ImageViewer = ({ images }: ImageViewerProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const canvasRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [scaleIndex, setScaleIndex] = useState(2);
  const [rotation, setRotation] = useState(0);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const [scale, setScale] = useState(steps[scaleIndex]);

  const handleZoomIn = useCallback(() => {
    if (scaleIndex >= steps.length - 1) return;
    setScaleIndex(scaleIndex + 1);
    setScale(steps[scaleIndex + 1]);
  }, [scaleIndex]);

  const handleZoomOut = useCallback(() => {
    if (scaleIndex <= 0) return;
    setScaleIndex(scaleIndex - 1);
    setScale(steps[scaleIndex - 1]);
  }, [scaleIndex]);

  const resetZoom = () => {
    if (canvasRef?.current && imageRef?.current) {
      const x = canvasRef.current.clientWidth / imageRef.current.width;
      const y = canvasRef.current.clientHeight / imageRef.current.height;
      const max = Math.max(x, y);
      const index = findNearestIndex(steps, max);
      setScale(Math.max(x, y));
      setScaleIndex(index);
      const width = (canvasRef.current.clientWidth - imageRef.current.width) / 2;
      const height = (canvasRef.current.clientHeight - imageRef.current.height) / 2;
      imageRef.current?.style.setProperty("left", `${width}px`);
      imageRef.current?.style.setProperty("top", `${height}px`);
    }
  };

  const handleRotateCounterClockwise = () => {
    setRotation(rotation - 90);
  };

  const handleThumbnailClick = (index: number) => {
    setCurrentIndex(index);
    resetZoom();
  };

  const handleMoveDown = (e: any) => {
    e.preventDefault();
    setIsDragging(true);
    setPosition({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
  };

  const handleMove = (e: any) => {
    const { clientX, clientY } = e.nativeEvent;

    if (isDragging && imageRef.current) {
      // convert margin left to integer
      const marginTop = parseInt(imageRef.current.style.top.replace("px", ""));
      const marginLeft = parseInt(imageRef.current.style.left.replace("px", ""));

      const offsetX = clientX - position.x;
      const offsetY = clientY - position.y;

      const newMarginLeft = marginLeft + offsetX;
      const newMarginTop = marginTop + offsetY;
      imageRef.current?.style.setProperty("left", `${newMarginLeft}px`);
      imageRef.current?.style.setProperty("top", `${newMarginTop}px`);

      setPosition({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
    }
  };

  const handleMoveUp = (e: any) => {
    setPosition({ x: e.nativeEvent.clientX, y: e.nativeEvent.clientY });
    setIsDragging(false);
  };

  const renderThumbnails = () => {
    return images.map((image, index) => (
      <img
        key={index}
        src={image}
        alt={`Thumbnail ${index + 1}`}
        className={`thumbnail ${currentIndex === index ? "active" : ""}`}
        onClick={() => handleThumbnailClick(index)}
      />
    ));
  };

  const showActionButtons = images.length > 1;

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < images.length) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleImageLoaded = (event: any) => {
    if (canvasRef?.current) {
      const width = (canvasRef.current.clientWidth - event.target.width) / 2;
      const height = (canvasRef.current.clientHeight - event.target.height) / 2 - 48;
      imageRef.current?.style.setProperty("left", `${width}px`);
      imageRef.current?.style.setProperty("top", `${height}px`);
    }
    setLoading(false);
  };

  if (/\.pdf?/.test(images[currentIndex])) {
    return <iframe style={{ pointerEvents: "all" }} width="100%" height="730px" src={images[currentIndex]} />;
  }

  return (
    <div className="image-viewer-container" style={{ height: "75vh", maxHeight: "730px" }}>
      <div className="control-bar">
        <div className="zoom-controls">
          <button onClick={handleZoomIn}>
            <IconZoomIn />
          </button>
          <button className="btn-fit" onClick={resetZoom}>
            FIT
          </button>
          <button onClick={handleZoomOut}>
            <IconZoomOut />
          </button>
        </div>
        <div className="rotate-controls">
          <button onClick={handleRotateCounterClockwise}>
            <IconRotate />
          </button>
        </div>
      </div>
      <div className="button-wrapper left">
        {showActionButtons && (
          <button onClick={handlePrev} disabled={currentIndex === 0}>
            <IconPrev />
          </button>
        )}
      </div>
      <div className="button-wrapper right">
        {showActionButtons && (
          <button onClick={handleNext} disabled={currentIndex === images.length - 1}>
            <IconNext />
          </button>
        )}
      </div>
      <div className="canvas" ref={canvasRef}>
        <div className="image-container" onMouseDown={handleMoveDown} onMouseMove={handleMove} onMouseUp={handleMoveUp}>
          {loading && <Loading />}
          <img
            ref={imageRef}
            onLoadStart={() => setLoading(true)}
            onLoad={handleImageLoaded}
            src={images[currentIndex]}
            alt="Zoomable Rotatable"
            style={{
              left: 0,
              top: 0,
              transform: `scale(${scale}) rotate(${rotation}deg)`,
            }}
          />
        </div>
      </div>
      <div className="thumbnails">{renderThumbnails()}</div>
    </div>
  );
};

export default ImageViewer;
