import React, { useState, ChangeEvent, useEffect } from "react";
import classNames from "classnames";

export type ValueType = string | number;

type CheckboxProps<V = ValueType> = {
  checked: boolean;
  onChange?: (value: V | undefined, checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: V;
  indeterminate?: boolean;
  inline?: boolean;
};

const Checkbox = ({ checked, onChange, value, indeterminate }: CheckboxProps): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleClick = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked((checked) => !checked);
    onChange?.(value, !checked, event);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div
      className={classNames("checkbox checkbox-inline", {
        "checkbox-checked": isChecked && !indeterminate,
        "checkbox-indeterminate": indeterminate,
      })}
    >
      <div className="checkbox-checker">
        <label>
          <span className="checkbox-wrapper">
            <input
              type="checkbox"
              aria-checked={indeterminate ? "mixed" : checked}
              onChange={handleClick}
              onClick={(event) => event.stopPropagation()}
            />
            <span className="checkbox-inner" aria-hidden="true" role="presentation"></span>
          </span>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
