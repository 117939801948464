import React, { useRef } from "react";
import Modal from "react-modal";
import { currencyFormatter2Decimals } from "lib/utils";
import { IconClear, IconForwardBtn } from "components/Icons";
import Loading from "components/Loading";
import ImageViewer from "presentation/components/Images/ImageViewer";

const attachmentModalStyle = {
  content: {
    top: "5%",
    left: "25%",
    width: "60%",
    height: "800px",
  },
};

type AttachmentModalProps = {
  title: string;
  showAttachmentImage: boolean;
  handleClose: () => void;
  attachmentAmount: number;
  attachmentsUrl: string[];
  breadcrumb?: React.ReactNode;
};

function AttachmentModal(props: AttachmentModalProps) {
  const { showAttachmentImage, handleClose, attachmentAmount, attachmentsUrl, title, breadcrumb } = props;

  const modalRef = useRef(null);

  return (
    <Modal
      ref={modalRef}
      id="attachment-modal"
      style={attachmentModalStyle}
      isOpen={showAttachmentImage}
      onRequestClose={handleClose}
    >
      <div className="close">
        <span className="attachment-amount">{currencyFormatter2Decimals(attachmentAmount)}</span>
        <button style={{ background: "transparent" }} onClick={handleClose}>
          <IconClear />
        </button>
      </div>
      <div className="modal-header">
        <span className="title">{title}</span>
        {breadcrumb}
      </div>
      <div>
        {!attachmentsUrl && <Loading />}
        {attachmentsUrl && <ImageViewer images={attachmentsUrl} />}
      </div>
    </Modal>
  );
}

export default AttachmentModal;
