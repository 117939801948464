import { PatchFlaggedTransaction } from "domain/usecases";
import { BaseHttpService } from "main/factories/http";

export class RemotePatchFlaggedTransaction
  extends BaseHttpService<PatchFlaggedTransaction.Model>
  implements PatchFlaggedTransaction
{
  async patch(body: PatchFlaggedTransaction.Body): Promise<PatchFlaggedTransaction.Model> {
    return await this.request("patch", body);
  }
}
