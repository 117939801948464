import { transformApiTrxToUITrx } from "hooks/flaggedTransactions/useFlaggedTransactions";

import type { QueryClient } from "@tanstack/react-query";
import { UIFlaggedTransaction, UIReportTransaction } from "types/askcustomer";
import { AskRequest } from "@hone-automation/common";

/**
 * Update the flagged transaction in the cache
 * @param data
 * @param variables
 * @param locationId
 * @param queryClient
 * @param onSuccess
 */
export const onFlaggedTransactionUpdate = (
  // TODO type data and variables
  data: any,
  variables: any,
  locationId: string,
  queryClient: QueryClient,
  onSuccess?: (trx: UIFlaggedTransaction | undefined) => void
) => {
  queryClient.setQueryData(["flaggedTransactions", locationId], (old?: AskRequest[]) => {
    if (old) {
      // Find the index of the object in the array
      const objectIndex = old?.findIndex((obj) => obj.id === data.id);
      if (objectIndex && objectIndex > -1) {
        // updating
        // Create a copy of the existing data array
        const updatedObjectsArray = [...old];

        // Replace the updated object in the array
        updatedObjectsArray[objectIndex] = data;

        return [...updatedObjectsArray];
      } else {
        // adding
        return [...old, data];
      }
    }

    return old;
  });

  typeof onSuccess === "function" && onSuccess(transformApiTrxToUITrx(data));
};
