import React from "react";
interface FallbackProps {
  error?: Error;
}
function ForecastErrorView({ error }: FallbackProps) {
  return (
    <div className="report-view">
      <div className="forecast-layout">
        <div className="card forecast-view h-100">
          <h2>Forecast report not found</h2>
          <pre style={{ display: process.env.NODE_ENV === "development" ? "block" : "none" }}>{error?.message}</pre>
        </div>
      </div>
    </div>
  );
}

export default ForecastErrorView;
