import React, { ChangeEvent, useCallback } from "react";
import { IconAddCircle, IconRemoveCircle } from "components/Icons";
import "./ForecastPlusMinusInput.scss";
import { toFixed } from "presentation/utils";

interface ForecastPlusMinusInputProps {
  step?: number;
  label: React.ReactNode;
  value?: number | string;
  name: string;
  onChange: (newValue: string | number, name: string) => void;
}

const MIN_VALUE = 0;
const DEFAULT_STEP = 1;

function ForecastPlusMinusInput({
  step = DEFAULT_STEP,
  label,
  value,
  name,
  onChange,
}: ForecastPlusMinusInputProps): JSX.Element {
  const handleChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    return onChange(evt.target.value, name);
  };

  const handleIncrease = useCallback(() => {
    const currentValue = Number(value);
    const nextValue = toFixed(currentValue + step);
    onChange(nextValue, name);
  }, [value]);

  const handleDecrease = useCallback(() => {
    const currentValue = Number(value);
    let nextValue = currentValue - step;
    if (currentValue - step < MIN_VALUE) {
      nextValue = MIN_VALUE;
    }
    onChange(nextValue, name);
  }, [name, onChange, step, value]);

  return (
    <div className="forecast-plus-minus-input">
      <div className="percentage-input">
        <div className="btn btn-icon btn-decrease" onClick={handleDecrease}>
          <IconRemoveCircle />
        </div>
        <input placeholder="0.00" type="text" value={value} onChange={handleChange} />

        <span>%</span>
        <div className="btn btn-icon btn-increase" onClick={handleIncrease}>
          <IconAddCircle />
        </div>
      </div>
      <span>{label}</span>
    </div>
  );
}

export default ForecastPlusMinusInput;
