import Select, { SingleValue } from "react-select";
import { Category } from "domain/models";
import { categoriesColor } from "../CategoriesContainer/CategoriesContainer";
import { useQueryState } from "hooks/useQueryState";
import { useEffect } from "react";

interface CategoriesSelectorProps {
  onChange: (category: Category) => void;
  activeCategory?: Category | string | null;
  categories: string[];
}

type Option = {
  value?: string;
  label?: string;
  name?: string;
  color?: string;
};

function CategoriesSelector({ categories, activeCategory, onChange }: CategoriesSelectorProps): JSX.Element {
  const [_activeCategory, setActiveCategory] = useQueryState("activeCategory");
  const options = categories.map((category, index) => ({
    value: category,
    label: String(category).toLowerCase().replace("->", ""),
    name: category,
    color: categoriesColor[index],
  }));

  const handleChange = (newValue: SingleValue<Option>) => {
    if (!newValue) return;
    if (newValue.value) {
      setActiveCategory(newValue.value);
    }
  };

  useEffect(() => {
    if (typeof _activeCategory === "string") {
      onChange({ name: _activeCategory, color: categoriesColor[0] });
    }
  }, [_activeCategory]);

  const activeValue =
    typeof activeCategory === "string"
      ? { value: activeCategory, label: activeCategory?.toLowerCase().replace("->", "") }
      : { value: activeCategory?.name, label: activeCategory?.name.toLowerCase().replace("->", "") };

  return (
    <Select
      options={options}
      onChange={handleChange}
      value={activeValue}
      className="react-select-container"
      id="plot-select"
      classNamePrefix="react-select"
    />
  );
}

export default CategoriesSelector;
