import classNames from "classnames";
import Tooltip from "rsuite/Tooltip";
import { IconClose } from "components/Icons";
import Whisper from "rsuite/Whisper";

type Props = {
  id?: string;
  content: string;
  type: "warning" | "success" | "error" | "info";
  styles?: React.CSSProperties;
  handleClose?: (e: string) => void;
};

export default function Tag({ id, content, type, styles, handleClose }: Props): JSX.Element {
  return (
    <Whisper
      followCursor
      placement="top"
      controlId="control-id-hover"
      trigger="hover"
      speaker={<Tooltip>{content.toUpperCase()}</Tooltip>}
    >
      <div
        className={classNames("tag-container", {
          "tag-warning": type === "warning",
          "tag-success": type === "success",
          "tag-error": type === "error",
          "tag-info": type === "info",
        })}
        style={styles}
        key={id}
      >
        <span>{content.toUpperCase()}</span>
        {handleClose && (
          <div className="tag-close-button" onClick={() => id && handleClose(content)}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <IconClose width={20} className="tag-close-svg" />
          </div>
        )}
      </div>
    </Whisper>
  );
}
