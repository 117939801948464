import { useState, useEffect } from "react";
import { find, isEmpty, omit, cloneDeep, findIndex, tail, noop } from "lodash";
import { useHoneChartOfAccounts } from "components/HoneReportTemplates";
import * as forecastUtils from "../../../../lib/forecastUtils";
import { IconDown, IconUp } from "../../../../components/Icons";
import type { SyntheticEvent } from "react";
import BookkeeperForecastSectionForm from "./BookkeeperForecastSectionForm";
import { useLocationsStore } from "hooks/useLocationsStore";

interface Props {
  currentLocation: HoneLocation;
  currentMapping: ForecastMappingSection[];
  onMappingChange: (newMapping: ForecastMappingSection[]) => void;
}

function BookkeeperForecastSectionsForm({ currentLocation, currentMapping, onMappingChange }: Props): JSX.Element {
  const currentLocationId = useLocationsStore((state) => state.currentLocationId);
  const { data: chartOfAccounts } = useHoneChartOfAccounts(currentLocation.id);
  const initialCustomCalculations = currentMapping
    .filter((section) => section.value && forecastUtils.isCustomCalculation(section.value))
    .map(
      (section) =>
        ({
          id: section.id,
          title: section.title,
          sum: section.value?.sum || []
        }) as CustomCalculation
    );
  const [customCalculations, setCustomCalculations] = useState<CustomCalculation[]>(initialCustomCalculations);

  const sectionGroups = currentMapping.reduce((memo, section) => {
    // collect rows by section
    if (section.level === 0) {
      // new group
      memo.push([]);
    }

    if (memo.length > 0) {
      memo[memo.length - 1].push(section);
    }

    return memo;
  }, [] as ForecastMappingSection[][]);

  let calculatableAccounts = chartOfAccounts || [];

  const handleAddSameLevel = (e: SyntheticEvent, section: ForecastMappingSection) => {
    e.preventDefault();

    const index = findIndex(currentMapping, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onMappingChange(forecastUtils.addSection(section, index, currentMapping));
  };

  const handleAddNestedLevel = (e: SyntheticEvent, section: ForecastMappingSection) => {
    e.preventDefault();

    const index = findIndex(currentMapping, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onMappingChange(forecastUtils.addNestedSection(section, index, currentMapping));
  };

  const handleRemoveSection = (e: SyntheticEvent, section: ForecastMappingSection) => {
    e.preventDefault();

    const index = findIndex(currentMapping, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onMappingChange(forecastUtils.removeSection(section, index, currentMapping));
  };

  const handleSectionTitleChange = (e: SyntheticEvent<HTMLInputElement>, section: ForecastMappingSection) => {
    const index = findIndex(currentMapping, { id: section.id });
    if (index < 0) throw new Error("bad index");
    onMappingChange(forecastUtils.changeSectionTitle(section, index, e.currentTarget.value, currentMapping));
  };

  const handleTagChange = (selected: string | undefined, section: ForecastMappingSection) => {
    const index = findIndex(currentMapping, { id: section.id });
    if (index < 0) throw new Error("bad index");
    onMappingChange(forecastUtils.changeTag(section, index, selected, currentMapping));
  };

  const handleSectionPercentChange = (selected: string | undefined, section: ForecastMappingSection) => {
    const index = findIndex(currentMapping, { id: section.id });
    if (index < 0) throw new Error("bad index");
    onMappingChange(forecastUtils.changeSectionPercent(section, index, selected, currentMapping));
  };

  const handleSectionAccountChange = (selected: HoneAccount | undefined, section: ForecastMappingSection) => {
    const index = findIndex(currentMapping, { id: section.id });
    if (index < 0) throw new Error("bad index");

    if (selected) {
      const foundCalculatable = find(customCalculations, { id: selected.id });

      // short circuit to set a custom calc first
      if (foundCalculatable) {
        const newTemplate = cloneDeep(currentMapping);

        // FIXME suspected that i neeed to support Operand here (right now only AccountOperand is supported?)
        newTemplate[index].value = { section: foundCalculatable.id };

        onMappingChange(newTemplate);
        return;
      }
    }

    // otherwise just use an account
    onMappingChange(forecastUtils.changeSectionAccount(section, index, selected, currentMapping));
  };

  function handleMoveAfterNextRootSection(e: SyntheticEvent, section: ForecastMappingSection) {
    e.preventDefault();

    const index = findIndex(currentMapping, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onMappingChange(forecastUtils.moveAfterNextRootSection(section, index, currentMapping));
  }

  function handleMoveBeforePreviousRootSection(e: SyntheticEvent, section: ForecastMappingSection) {
    e.preventDefault();

    const index = findIndex(currentMapping, { id: section.id });
    if (index < 0) throw new Error("bad index");

    onMappingChange(forecastUtils.moveBeforePreviousRootSection(section, index, currentMapping));
  }

  function handleMoveAfterNextSection(e: SyntheticEvent, section: ForecastMappingSection) {
    e.preventDefault();

    const index = findIndex(currentMapping, { id: section.id });
    if (index < 0 || index >= sectionGroups.length) {
      return;
    }

    onMappingChange(forecastUtils.moveAfterNextSection(section, index, currentMapping));
  }

  function handleMoveBeforePreviousSection(e: SyntheticEvent, section: ForecastMappingSection) {
    e.preventDefault();

    const index = findIndex(currentMapping, { id: section.id });
    if (index <= 0) {
      return;
    }

    onMappingChange(forecastUtils.moveBeforePreviousSection(section, index, currentMapping));
  }

  // Clear the customCalculatables when the report changes
  useEffect(() => {
    // eslint-disable-next-line
    calculatableAccounts = chartOfAccounts || [];
  }, [currentLocationId]);

  return (
    <>
      {!isEmpty(sectionGroups) &&
        sectionGroups.map((sections, index) => (
          <div className="BKForm-sections" key={sections[0].id} data-testid={`BKForm-index${index}`}>
            <div className="BKForm-header d-flex justify-content-between mb-4">
              <p className="m-0" />
              <div>
                {index > 0 && (
                  <button
                    className="button button_icon"
                    aria-label="Move before previous section"
                    type="button"
                    onClick={(e) => handleMoveBeforePreviousRootSection(e, sections[0])}
                  >
                    <IconUp />
                  </button>
                )}
                {index < sectionGroups.length - 1 && (
                  <button
                    className="button button_icon"
                    aria-label="Move after previous section"
                    type="button"
                    onClick={(e) => handleMoveAfterNextRootSection(e, sections[0])}
                  >
                    <IconDown />
                  </button>
                )}
              </div>
            </div>
            {/* use level 0 as section header */}
            <BookkeeperForecastSectionForm
              key={`${sections[0].id}${index}`}
              section={sections[0]}
              chartOfAccounts={chartOfAccounts}
              calculatableAccounts={calculatableAccounts}
              showControls={false}
              onRemoveSection={(e) => handleRemoveSection(e, sections[0])}
              onTitleChange={(e) => handleSectionTitleChange(e, sections[0])}
              onTagChange={(accountTag) => handleTagChange(accountTag, sections[0])}
              onAccountChange={(selectedAccount) => handleSectionAccountChange(selectedAccount, sections[0])}
              onPercentChange={(accountTag) => handleSectionPercentChange(accountTag, sections[0])}
              onAddSameLevel={(e) => noop()}
              handleMoveAfterNextSection={(e, section) => handleMoveAfterNextSection(e, sections[0])}
              handleMoveBeforePreviousSection={(e, section) => handleMoveBeforePreviousSection(e, sections[0])}
              forecastMapping={currentMapping}
            />
            {/* skip header to render the rest of the sections, jndex+1 below to account for dropping header */}
            <div className="BKForm-group">
              {/* only have header */}
              {sections.length === 1 && (
                <div key={sections[0].id} className="BKForm-section">
                  <button
                    type="button"
                    className="button button_outline w-100"
                    onClick={(e) => handleAddNestedLevel(e, sections[0])}
                  >
                    Add subsection
                  </button>
                </div>
              )}
              {sections.length > 1 &&
                tail(sections).map((section, jndex) => (
                  <>
                    <BookkeeperForecastSectionForm
                      key={`${section.id}${index}${jndex + 1}`}
                      section={section}
                      chartOfAccounts={chartOfAccounts}
                      calculatableAccounts={calculatableAccounts}
                      onRemoveSection={(e) => handleRemoveSection(e, section)}
                      onTitleChange={(e) => handleSectionTitleChange(e, section)}
                      onTagChange={(accountTag) => handleTagChange(accountTag, section)}
                      onAccountChange={(selectedAccount) => handleSectionAccountChange(selectedAccount, section)}
                      onPercentChange={(accountTag) => handleSectionPercentChange(accountTag, section)}
                      onAddSameLevel={(e) => handleAddSameLevel(e, section)}
                      handleMoveAfterNextSection={(e, section) => handleMoveAfterNextSection(e, section)}
                      handleMoveBeforePreviousSection={(e, section) => handleMoveBeforePreviousSection(e, section)}
                      forecastMapping={currentMapping}
                    />
                  </>
                ))}
            </div>
          </div>
        ))}
    </>
  );
}

export default BookkeeperForecastSectionsForm;
