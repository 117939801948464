import { useLocationsStore } from "hooks/useLocationsStore";
import DashboardFlaggedTransacionsSummary from "./DashboardFlaggedTransacionsSummary";
import { UIAskRequestsTitles } from "types/askcustomer";
import classNames from "classnames";

export default function DashboardFlaggedTransactions() {
  const currentLocation = useLocationsStore((state) => state.currentLocation);
  const isAskCustomerEnabled = currentLocation?.askCode !== "";

  return (
    <div className={classNames("dashboard-bm-content")}>
      <div className="dashboard-flagged-transactions-container">
        {isAskCustomerEnabled && (
          <>
            <h2>{UIAskRequestsTitles.ASK_REQUESTS}</h2>
            <DashboardFlaggedTransacionsSummary />
          </>
        )}
      </div>
    </div>
  );
}
