import React from "react";
import { AppLayout } from "presentation/components";
import { AskRequests } from "presentation/pages";

export const AskRequestsViewFactory = () => {
  return (
    <AppLayout  noFooter={true}>
      <AskRequests />
    </AppLayout>
  );
};
