import { prefixClass } from "lib/utils";
import "./KSMLMSelected.scss";
import { KSMLocation, AllSelected } from "../KSMultipleLocationModal";
import KSMLMLocationItem from "../KSMLMLocationItem";
import classNames from "classnames";
import { useEffect, useState, useContext, useRef } from "react";

type KSMLMSelectedProps = {
  activeLocation?: KSMLocation;
  selectedLocations: KSMLocation[];
  onLocationRemove?: (location: KSMLocation) => void;
};

const KSMLMSelected = ({ activeLocation, selectedLocations, onLocationRemove }: KSMLMSelectedProps) => {
  const prefix = prefixClass("ks-mlm-selected");
  const [cleanSelectedLocations, setCleanSelectedLocations] = useState<KSMLocation[]>([]);
  const handleRemoveLocation = (location: KSMLocation) => {
    onLocationRemove?.(location);
  };

  const { allSelected } = useContext(AllSelected) || {};
  const selectedListRef = useRef<HTMLDivElement>(null);


  // Filters the `selectedLocations` array to exclude the `activeLocation` and updates the `cleanSelectedLocations` state with the filtered array.
  
  useEffect(() => {
    const filteredLocations = selectedLocations.filter((location) => location.locationId !== activeLocation?.locationId);
    setCleanSelectedLocations(filteredLocations);

    // Scroll to the bottom of the selected list
    const selectedList = selectedListRef.current;
    if (selectedList) {
      selectedList.scrollIntoView();
    }
  }, [selectedLocations]);

  return (
    <>
      {cleanSelectedLocations?.length > 0 ? (
        <div className={prefix()}>
          <div className={prefix("title")}>
            <b>{activeLocation?.locationName}</b> vs:
          </div>
          <div 
            ref={selectedListRef}
            className={classNames(prefix("selected-list"),{"all-selected": allSelected, "deep-shadow": cleanSelectedLocations.length>4 && !allSelected})}
          >
            {cleanSelectedLocations.map((location) => (
              <KSMLMLocationItem
                key={location.id}
                location={location}
                selected={true}
                onLocationRemove={handleRemoveLocation}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={classNames(prefix("title"),"no-selected")}>
           Choose one or more locations<br/>for comparison.
        </div>        
      )}
    </>
  );
};

export default KSMLMSelected;
